import {IEventInfo} from "./IEventInfo";
import {IEventFeedback} from "./IEventFeedback";
import {IEventFeedbackAttr} from "./IEventFeedbackAttr";
import {ICustomer} from "../user/ICustomer";
import {IUser} from "../user/IUser";
import {IEventInfoUser} from "../relations/IEventInfoUser";
import {IEventInfoMail} from "./IEventInfoMail";

export interface IEvent {

    id: number
    title?: string;
    content?: string;
    emailText?: string;
    additional_info?: string;
    type: string;
    status: string;
    author: number;
    deleted: boolean;
    eventSections: IEventSection[];
    users: IUser[];
    eventFeedbacks?: IEventFeedback[];
    eventFeedbackAttrs?: IEventFeedbackAttr[];
    masterId?: number;
    customers?: ICustomer[];
    SerialEventId?: number;
    mailInfos: any;
    is_serial: boolean;
}

export interface ISerialEvent extends IEvent {
    events: IEvent[];
}

export interface IEventSection {
    id: number,
    date: Date,
    locationId: string,
    locationValue: any,
    locationLabel: string,
    locationLat: number,
    locationLng: number,
    meeting_time?: Date,
    general_starting_time?: Date,
    starting_time: Date,
    ending_time: Date,
    title: string,
    person?: number,
    backup_person?: number
    gender: string,
    status: EVENT_SECTION_STATUS,
    eventInfoUsers?: IEventInfoUser[];
    eventInfoMails?: IEventInfoMail[];
    all_users_confirmed: boolean;
    EventId: number;
}

export enum EVENT_SECTION_STATUS {
    SECTION_CREATED = 'SECTION_CREATED',
    SECTION_OPENED = 'SECTION_OPENED',
    SECTION_CANDIDATE_AVAILABLE = 'SECTION_CANDIDATE_AVAILABLE',
    SECTION_NOT_ALL_CONFIRMED = 'SECTION_NOT_ALL_CONFIRMED',
    SECTION_ALL_CONFIRMED = 'SECTION_ALL_CONFIRMED',
    SECTION_OPERATIONAL = 'SECTION_OPERATIONAL',
    SECTION_STARTED = 'SECTION_STARTED',
    SECTION_ENDED = 'SECTION_ENDED',
    SECTION_PROTOCOL_SEND = 'SECTION_PROTOCOL_SEND',
    SECTION_FINISHED = 'SECTION_FINISHED',
    SECTION_STORNO = 'SECTION_STORNO'
}

export type TStatusEvent = {
    created: string;
    candidature: string;
    not_apply: string;
    apply: string;
    reject: string;
    confirm_for_event: string;
    not_confirmed: string;
    all_at_place: string;
    protocol_send: string;
    operational: string;
    confirm: string;
    confirmed: string;
    ended: string;
    finish: string;
    completed: string;
    finished: string;
}

export interface ICalendarEvent extends IEvent {
    show: boolean,
    statusEvent: keyof TStatusEvent,
    link: string,
    editLink: string,
    own: boolean
}

export interface IBillableEvent {
    event_id: number,
    event_info_id: number,
    title: string,
    user: IUser,

    user_billed?: boolean,

    user_billing_date: Date,

    user_id: number
}