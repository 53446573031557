import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from "react";
import instance from "../../util/api";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import {connect, ConnectedProps} from "react-redux";
import {eventInfoStatusRanking, EventStatus, eventStatusRanking, statusRanking} from "../../util/helper/eventHelper";
import Moment from "react-moment";
import {faBookOpen, faTrash, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EventLogisticUserInfoModal from "../../components/modal/EventLogisticUserModal";
import {useHistory, useRouteMatch} from 'react-router-dom';
import {ADMIN_EVENT, ADMIN_EVENT_LOGISTIC_EDIT} from "../../util/routes/routes";
import Dropzone from "../../components/dropzone/Dropzone";
import BackButton from "../../components/backButton/BackButton";
import {getImage} from "../../util/helper/images";
import ImageLightbox from "../../components/imageLightbox/ImageLightbox";
import EventIcons from "../../components/icons/EventIcons";
import {useWindowSize} from "../../util/helper/screen";
import {toast} from "react-toastify";
import FusionToast from "../../components/toast/FusionToast";
import {RootState} from "../../store";
import className from "classnames";
import {EVENT_SECTION_STATUS, IEvent, IEventSection} from "../../model/interfaces/event/IEvent";
import {AxiosResponse} from "axios";
import BriefingModal from "../../pages/admin/event/components/BriefingModal";
import StornoModal from "../../pages/admin/event/components/StornoModal";
import TodoModal from "../../pages/admin/event/components/TodoModal";
import EventUserFeedback from "../../pages/admin/event/components/EventUserFeedback";

const eventTimeInit = {
    "id": 0,
    "timeStart": null,
    "timeEnd": null,
    "eventTitle": "",
    "person": ""
}

const eventInfoInit = {
    id: -1,
    date: new Date(),
    locationId: "",
    locationValue: "",
    locationLabel: "",
    locationLat: 0,
    locationLng: 0,
    starting_time: new Date(),
    ending_time: new Date(),
    title: "",
    gender: "",
    status: EVENT_SECTION_STATUS.SECTION_CREATED,
    eventInfoUsers: [],
    all_users_confirmed: false,
    EventId: -1
};

const eventInit = {
    "id": -1,
    "title": "",
    "content": "",
    "emailText": "",
    "additional_info": "",
    "type": "EVENT",
    "status": "created",
    "author": 0,
    "deleted": false,
    "eventSections": [],
    "users": [],
    "mailInfos": "",
    "is_serial": false
} as IEvent;

const feedbackTextInit = {
    "id": null,
    "title": "",
    "description": ""
}

const feedbackInit = {
    "photo" : false,
    "photoNumber" : 10,
    "text" : false
}


interface MatchProps {
    eventType: string,
    id: string
}
type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

interface EventResponse {
    event: IEvent,
    error: any
}

interface IEventShow extends PropsFromRedux {
    eventType: string,
    id: number,
    isModal?: boolean
}

const EventShow : React.FC<IEventShow> = (props) => {
    const history = useHistory();
    const match = useRouteMatch<MatchProps>();
    
    const [eventSections, setEventInfos] = useState<IEventSection[]>([eventInfoInit]);
    const [eventNew, setEventNew] = useState<IEvent>(eventInit);
    const [eventUser, setEventUser] = useState<any[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [id, setId] = useState<number>(-1);
    const [eventId, setEventId] = useState<number>(-1);
    const [pdfs, setPdfs] = useState({unset: [{content: "", preview: ""}]});
    const [uploadedPDFs, setUploadedPdfs] = useState<any[]>([]);
    const [eventInfoOperational, setEventInfoOperational] = useState<any>({});

    const [feedbackAttr, setFeedbackAttr] = useState<any>(feedbackInit);
    const [feedbackText, setFeedbackText] = useState<any[]>([feedbackTextInit]);
    const [feedback, setFeedback] = useState<any>(feedbackInit)

    useEffect(() => {
        loadEvent();
    }, [props.id]);

    const loadEvent = () => {
        setId(props.id);
        setLoaded(false);

        instance.get(`/admin/event/one`, {
            params: {
                type: props.eventType.toUpperCase(),
                id: props.id
            }
        })
            .then(async (resp : AxiosResponse<EventResponse>) => {
                console.log("EVENTONE", resp.data);
                if (!resp.data.error){
                    setEventNew(resp.data.event);
                    const eventFeedbackText = [] as any[];
                    const eventFeedbackAttr = {...feedbackInit} as any;
                    const operationalStatus = {} as any;
                    const eventPdfs = [] as any[];

                    /*
                                        //console.log("LOADED EVENT", resp.data.event);
                    */

                    if (resp.data.event.eventFeedbackAttrs) {
                        resp.data.event.eventFeedbackAttrs.forEach((feedAttr : any) => {
                            const description = JSON.parse(feedAttr.description);
                            //console.log(description);
                            switch (description.type) {
                                case "STANDARD":
                                    eventFeedbackAttr[feedAttr.title as keyof typeof eventFeedbackAttr] = description.value;
                                    break;
                                case "TEXT":
                                    const newText = {...feedbackTextInit};
                                    newText.id = feedAttr.id;
                                    newText.title = feedAttr.title;
                                    newText.description = description.value;
                                    eventFeedbackText.push(newText);
                                    break;
                                case "PDF":
                                    eventPdfs.push(description.value);
                                    break;
                                default:
                                    break;
                            }
                        })
                    }

                    //console.log(eventFeedbackAttr);

                    setFeedbackAttr(eventFeedbackAttr);
                    setFeedbackText(eventFeedbackText);
                    setUploadedPdfs(eventPdfs);

                    const respDataEventInfos = [];
                    for (const event of resp.data.event.eventSections){
                        const eventInfoUsers = [];
                        if (event.eventInfoUsers) {

                            for (const userInfo of event.eventInfoUsers){
                                if (userInfo.user_content && userInfo.user_content !== "") {
                                    userInfo.user_content = JSON.parse(userInfo.user_content);
                                }
                                if (userInfo.user_images){
                                    userInfo.user_images = JSON.parse( userInfo.user_images);
                                    userInfo.files = [] as any[];
                                    if (userInfo.user_images) {
                                        for (const file of userInfo.user_images) {
                                            //console.log(file);
                                            userInfo.files.push({url: await getImage(file)});
                                        }
                                    }
                                }
                                eventInfoUsers.push(userInfo);
                            }

                        }

                        if (eventInfoStatusRanking[event.status] < 2){
                            operationalStatus[event.id] = false
                        } else {
                            operationalStatus[event.id] = true
                        }

                        console.log("OPERATIONAL STATUS", operationalStatus, event);

                        event.eventInfoUsers = eventInfoUsers;
                        respDataEventInfos.push(event);
                        setEventInfoOperational(operationalStatus);
                    }

                    setEventInfos(respDataEventInfos);

                    /*respDataEventInfos.forEach(event => {
                        const date = moment(event.date)
                        const start = moment(event.starting_time)
                        const end = moment(event.ending_time)

                        event.starting_time = moment(date).hour(start.hours()).minute(start.minute()).toDate();
                        event.ending_time = moment(date).hour(end.hours()).minute(end.minute()).toDate();

                        //eventAdditionalInfoMap.set(date.format("DD.MM.YYYY"), {starting_time: event.starting_time, meeting_time: event.meeting_time})

                        eventInfoMap.has(event.date) ?
                            eventInfoMap.set(event.date, [...eventInfoMap.get(event.date), {...event, id: event.id, timeStart: event.starting_time, timeEnd: moment(event.ending_time).toDate(), eventTitle: event.title, person: event.person, gender: event.gender, meeting_time: event.meeting_time, starting_time: event.starting_time}]) :
                            eventInfoMap.set(event.date, [{...event, id: event.id, timeStart: event.starting_time, timeEnd: moment(event.ending_time).toDate(), eventTitle: event.title, person: event.person, gender: event.gender, meeting_time: event.meeting_time, starting_time: event.starting_time}])
                    });

                    let id = 0;
                    eventInfoMap.forEach((event, key) => {
                        eventInfo.push({date: moment(key).toDate(), times: event, id: id});
                        id++;
                    });*/

                    const userData = [];
                    //console.log("USERS", resp.data.event.users)
                    for (const user of resp.data.event.users){
                        /*if (data && data.user_content && data.user_content !== ""){
                            data.user_content = JSON.parse(data.user_content);
                            data.files = [];
                            if (data.user_content) {
                                for (const file of data.user_content.files) {
                                    data.files.push({url: await getImage(file)});
                                }
                            }
                        }

                        user.UserEvent = data;*/
                        userData.push(user);
                    }

                    //console.log("USER DATA", respDataEventInfos);

                    const availableUsers = [] as any[];
                    respDataEventInfos.forEach(dataEvent => {
                        if (dataEvent.eventInfoUsers) {
                            dataEvent.eventInfoUsers.forEach((eventInfo : any) => {
                                if (eventInfo.status !== "not_apply") {
                                    availableUsers.push(eventInfo.user_id);
                                }
                            })
                        }
                    })

                    //setEventInfos(eventInfo);
                    setEventUser(userData.filter(user => user.id !== resp.data.event.author).filter(user => {
                        return availableUsers.includes(user.id);
                    }));
                    setLoaded(true);
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
            }).catch(err => {
            //console.log(err);
        });
    }

    const saveUserApplication = () => {
        loadEvent();
    }

    //console.log("ID", eventId);

    /*const userHeaders = [
        {slug: "firstname", name: "Name"},
        {slug: "email", name: "Email"},
        {slug: "wunsch", name: "Wunschtermin"},
        {slug: "status", name: "Status"},
    ];

    const userRowData = eventUser.map(user => {
        const dataClass = classNames("status", user.UserEvent.status);
        const userPreference = (user.UserEvent.user_preference) ? JSON.parse(user.UserEvent.user_preference) : null;
        let userEventPref = [];

        if (userPreference){
            eventSections.forEach(info => {
                userEventPref.push(...info.times.filter(event => Object.keys(userPreference).find(key => parseInt(key) === event.id)))
            });
            //console.log(userPreference, userEventPref);
        }

        return {
            show: "",
            id: user.id,
            data: [
                {val: user.firstname + " " + user.lastname, classes: [], type: ListType.STRING, slug: "firstname"},
                {val: user.email, classes: [], type: ListType.STRING, slug: "email"},
                {val: userEventPref.length > 0 ? <div className="user-preferences">
                        {userEventPref.map(event => (
                            <div className="small">
                                <p>{event.eventTitle}</p>
                                <Moment format="DD.MM.YYYY HH:mm">{event.starting_time}</Moment> - <Moment format="DD.MM.YYYY HH:mm">{event.ending_time}</Moment>
                            </div>
                        ))}
                    </div> : "" , classes: [], type: ListType.HTML, slug: "wunsch"},
                {val: <div className={dataClass}>
                        <h5>{EventUserStatus[user.UserEvent.status]}</h5>
                    </div> , classes: [], type: ListType.HTML, slug: "status"},
               /!* {val: <div className="btn-holder">
                        <ButtonForUserInteraction status={user.UserEvent.status} eventId={id} eventInfoId={eventId} userId={user.id} />
                    </div>, classes: [], type: ListType.HTML, slug: "status"}*!/
            ]}
    });*/

    const onFeedbackInputChange = (e : ChangeEvent<HTMLInputElement>) => {
        e.persist();
        const name = e.target.name;
        const val = e.target.value;
        const type = e.target.type;

        if (type !== "number" && type !== "text"){
            setFeedbackAttr((feed : any) => ({...feed, [name]: e.target.checked}))
        } else {
            setFeedbackAttr((feed : any) => ({...feed, [name]: val}))
        }
    }

    const onFeedbackTextChange = (e : ChangeEvent<FormControlElement>, index : number) => {
        const name = e.target.name;
        const val = e.target.value;

        //console.log(feedbackText);

        setFeedbackText(text => text.map((t, i) => {
            if (i === index) {
                return {...t, [name]: val}
            }
            return t
        }));
    }

    const onAddFeedback = (e : SyntheticEvent<any>) => {
        e.preventDefault();
        //console.log(feedbackTextInit);
        setFeedbackText(text => [...text, feedbackTextInit])
    }

    const onRemoveFeedback = (e : SyntheticEvent<any>, index : number) => {
        e.preventDefault();
        instance.delete("/admin/event/feedback/attr", {
            params: {
                id: feedbackText[index].id
            }
        }).then(resp => {
            if (!resp.data.error) {
                setFeedbackText(text => [...[...text.keys()].filter(key => key !== index).map(key => text[key])])
            } else {
                console.error(resp);
                toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
            }
        }).catch(err => {
            //console.log(err);
        })};

    const onSaveEvent = (e : SyntheticEvent<any>) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("id", id.toString());
        formData.append("additional_info", eventNew.additional_info ? eventNew.additional_info : "");
        formData.append("feedback", JSON.stringify(feedbackAttr));
        formData.append("feedbackText", JSON.stringify(feedbackText));

        //console.log("SAVE EVENT", id, eventNew, feedbackAttr, feedbackText, pdfs);

        pdfs.unset.forEach((pdf) => {
            formData.append("file", pdf.content);
        })

        Object.entries(eventInfoOperational).map(([event_info_id, value]) => {
            formData.append("operation_status[]", JSON.stringify({id: event_info_id, checked: value}));
        })

        instance.post(`/admin/event/edit`, formData)
            .then(resp => {
                if (!resp.data.error) {
                    history.push(ADMIN_EVENT.path.replace(":eventType", props.eventType))
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
                //console.log(resp);
            }).catch(err => {
                //console.log(err);
        })

    }

    const onCheckStatus = (e: ChangeEvent<HTMLInputElement>, event_info_id: number) => {
        const isChecked = e.target.checked;
        setEventInfoOperational((info : any) =>({...info, [event_info_id]: isChecked}))
    }

    /*const onChangeEventInfoStatus = (e, id, status) => {
        e.preventDefault();

        instance.get(`/admin/eventInfo/finish`, {
            params: {
                id: id,
                status: status
            }
        })
            .then(resp => {
                if (!resp.data.error) {
                    history.push(ADMIN_EVENT.path.replace(":eventType", props.eventType))
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
                //console.log(resp);
            }).catch(err => {
            console.error(err);
        })
    }*/

    const onEndEvent = (e : SyntheticEvent<any>, status : string) => {
        e.preventDefault();

        instance.get(`/admin/event/finish`, {
            params: {
                id: id,
                status: status
            }
        })
            .then(resp => {
                if (!resp.data.error) {
                    history.push(ADMIN_EVENT.path.replace(":eventType", props.eventType))
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
                //console.log(resp);
            }).catch(err => {
            console.error(err);
        })

    }

    const onSendBriefingNow = (e: SyntheticEvent<any>, eventInfoId : number, date : Date) => {
        e.preventDefault();

        instance.get(`/admin/event/briefing`, {
            params: {
                eventId: id,
                id: eventInfoId,
                date: date
            }
        })
            .then(resp => {
                if (!resp.data.error) {
                    toast(<FusionToast className="success" header="Briefing gesendet" message={resp.data.message} />)
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Briefing nicht gesendet" message={resp.data.message} />)
                }
                //console.log(resp);
            }).catch(err => {
            console.error(err);
        })
    }

    const onSendStorno = (e : SyntheticEvent<any>, eventInfoId : number) => {
        e.preventDefault();

        instance.get(`/admin/event/storno`, {
            params: {
                eventId: id,
                id: eventInfoId,
            }
        })
            .then(resp => {
                if (!resp.data.error) {
                    setEventInfos(eventSections => eventSections.map(eventInfo => ({
                        ...eventInfo,
                        status: eventInfo.id === eventInfoId ? EVENT_SECTION_STATUS.SECTION_STORNO : eventInfo.status
                    })));
                    toast(<FusionToast className="success" header="Event storniert" message="Das Event wurde storniert und alle beteiligten User informiert!" />)
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Stornierung nicht gesendet" message={resp.data.message} />)
                }
                //console.log(resp);
            }).catch(err => {
            console.error(err);
        })
    }

    const addBriefingPDF = (data : any) => {
        //setPdfs(data);

        const formData = new FormData();
        formData.append("id", id.toString());

        data.unset.forEach((pdf : any) => {
            formData.append("file", pdf.content);
        })

        //console.log("ADD BRIEFING PDF", data);

        instance.post(`/admin/event/edit`, formData)
            .then(resp => {
                if (!resp.data.error) {
                    const pdfAttrList = resp.data.attributes.filter((attr : any) => attr.title === "infoPDF");

                    //console.log("PDF UPLOADED", resp.data, pdfAttrList);

                    const pdfList = [] as any[];
                    if (pdfAttrList){
                        pdfAttrList.forEach((pdfAttr : any) => {
                            const pdf = JSON.parse(pdfAttr.description)

                            //console.log(pdf);

                            if (pdf){
                                pdfList.push(pdf.value);
                            }
                        })
                    }

                    setUploadedPdfs([...uploadedPDFs, ...pdfList]);
                    setPdfs({unset: [{content: "", preview: ""}]});
                    //console.log(resp.data);
                    toast(<FusionToast className="success" header="PDF gespeichert" message="Briefings PDF wurde erfolgreich gespeichert" />);
                    //history.push(ADMIN_EVENT.path.replace(":eventType", props.eventType))
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
                //console.log(resp);
            }).catch(err => {
            //console.log(err);
        })
    }

    const deleteBriefingPDF = (pdf : any) => {
        //console.log(id, pdf)

        instance.delete("/admin/event/briefing", {
            params: {
                id: id,
                name: pdf
            }
        }).then(resp => {
            if (!resp.data.error) {
                setUploadedPdfs(pdfs => pdfs.filter(p => p !== pdf));
            } else {
                console.error(resp);
                toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
            }
        }).catch(err => {
            //console.log(err);
        })
    };

    const sendEmailForEvent = () => {
        instance.get("/admin/event/sendEmailNew", {
            params: {
                id: id
            }
        }).then(res => {
            toast(<FusionToast className="success" header="Emails wurden erneut an alle versendet" message="" />);
        }).catch(err => {
            console.error(err);
            toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
        })
    }

    const onDownloadExcelFile = () => {
        instance.get("/admin/event/excel/single", {
            params: {
                id: id
            }
        })
            .then(async resp => {
                //console.log(resp);
                if (!resp.data.error){
                    const foundFile = await instance.get("/file/files/" + resp.data.file, {
                        responseType: 'blob',
                    })
                    //console.log(foundFile);
                    if (foundFile.data) {
                        const url = URL.createObjectURL(new Blob([foundFile.data], {type: foundFile.headers["content-type"]}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', resp.data.file); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }

            }).catch(err => {
            //console.log(err);
        })
    }

    const logoClass = className({
        "big-logo-info": true,
        "big-logo-info-event": props.eventType.toLowerCase() === "event",
        "big-logo-info-logistic": props.eventType.toLowerCase() === "logistic"
    })

    console.log("EMAIL", eventSections)

    const TooltipCreator = (props : any) => (
        <OverlayTrigger overlay={<Tooltip id={props.id}>{props.text}</Tooltip>}>
            {props.children}
        </OverlayTrigger>
    )

    console.log("STATUS", eventNew.status)

    return (
        <div>
            <div className="EventDetail">
                <Row className="align-items-center">
                    <Col xs={12} md={6} lg={6}>
                        {!props.isModal ? <BackButton /> : <></>}
                    </Col>
                    <Col xs={12} md={3} lg={6} className="text-end">
                        <ButtonGroup>
                            <Button onClick={onDownloadExcelFile} className="btn-secondaryDark">Export Event</Button>

                            {statusRanking[eventNew.status as keyof typeof statusRanking] < 8 ?
                                <>
                                    <Button onClick={sendEmailForEvent} className="btn-secondaryDark">Event erneut aussenden</Button>
                                    <Button onClick={() => history.push({
                                        pathname: ADMIN_EVENT_LOGISTIC_EDIT.path.replace(":eventType", props.eventType).replace(":id", props.id.toString()),
                                        state: {id: props.id}
                                    })} className="btn-secondaryDark">Event bearbeiten</Button>
                                </>
                            : <></>}
                        </ButtonGroup>
                    </Col>
                </Row>
                {loaded ?
                <div className="event-create-container mt-4">
                    <Form className="event-form">
                        <div className="row align-items-center">
                            <div className="col-4 col-md-2 col-lg-1">
                                <div className={logoClass}>
                                    <p className="text">
                                        {props.eventType === "event" ? "E" : "L"}
                                    </p>
                                </div>
                            </div>
                            <div className="col-8 col-md-6 col-lg-8">
                                <header>
                                    <h3>{eventNew.title}</h3>
                                </header>
                                <p>{eventNew.content}</p>
                            </div>
                            <div className="col-12 col-md-2 offset-lg-1 col-lg-2">
                                <EventLogisticUserInfoModal eventId={eventNew.id} eventUser={eventUser} eventSections={eventSections} saveChanges={saveUserApplication} buttonLabel={<Row className="align-items-center"><Col xs={2}><FontAwesomeIcon icon={faUser}/></Col><Col xs={10}><span className="pl-3">Zu den Bewerbern</span></Col></Row>}/>
                            </div>
                        </div>
                        {eventSections.map((eventInfo : IEventSection, index, array) => {
                            let firstDate = true;

                            //console.log("EVENTINFO", eventInfo);

                            return (
                            <div>
                                <Card key={"Event_"+eventInfo.id} className="mb-2 mt-4">
                                    <CardHeader>
                                        <strong>Eventdatum:</strong> <Moment className="bold" format="DD.MM.YYYY">{eventInfo.date}</Moment>
                                    </CardHeader>
                                    <CardBody>
                                        <div key={eventInfo.id} className="event-row-container mb-4">
                                                <Row className="align-items-center col-mb-2 mb-3">
                                                    <Col xs={6} lg={3} xl={2}><h4 className="bold">Tätigkeit:</h4></Col>
                                                    <Col xs={6} lg={9} xl={10}><h4 className="bold">{eventInfo.title}</h4></Col>
                                                    <Col xs={6} lg={3} xl={2} className="bold">Zeit:</Col>
                                                    <Col xs={6} lg={3} xl={2}><Moment format="HH:mm">{eventInfo.starting_time}</Moment> - <Moment format="HH:mm">{eventInfo.ending_time}</Moment></Col>
                                                    <Col xs={6} lg={3} xl={2} className="bold">Gewünschtes Geschlecht:</Col>
                                                    <Col xs={6} lg={3} xl={2}>{eventInfo.gender}</Col>
                                                    <Col xs={6} lg={3} xl={2} className="bold">Anzahl an Personen:</Col>
                                                    <Col xs={6} lg={3} xl={2}>{eventInfo.person}</Col>

                                                    {eventInfo.meeting_time ? <>
                                                            <Col xs={6} lg={3} xl={2} className="bold">Treffzeitpunkt:</Col>
                                                            <Col xs={6} lg={3} xl={2}><Moment format="HH:mm">{eventInfo.meeting_time}</Moment></Col>
                                                        </> : <></>}

                                                    {eventInfo.starting_time ? <>
                                                        <Col xs={6} lg={3} xl={2} className="bold">Einsatzzeit:</Col>
                                                        <Col xs={6} lg={3} xl={2}><Moment format="HH:mm">{eventInfo.starting_time}</Moment></Col>
                                                    </> : <></>}
                                                </Row>
                                                <Row className="align-items-center col-mb-2 ">

                                                    <Col xs={6} lg={3} xl={2} className="bold status-info status-info-header"><p className="mb-2">Status:</p><p className="small">Event muss operational sein damit es stattfinden kann!</p></Col>
                                                    <Col xs={6} lg={3} xl={2} className="status-info">
                                                        <span className={className("status", eventInfo.status)}>{EventStatus[eventInfo.status]}</span>
                                                        <FormGroup className="mt-3">
                                                            <StatusCheckbox status={eventInfo.status} checked={eventInfoOperational[eventInfo.id]} event_info_id={eventInfo.id} onChecked={onCheckStatus} />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col xs={12} lg={6} xl={4}>
                                                        {eventInfo.status !== EVENT_SECTION_STATUS.SECTION_STORNO ?
                                                            <ButtonGroup>
                                                                <BriefingModal onClick={onSendBriefingNow} event={eventInfo}/>
                                                                <StornoModal onClick={onSendStorno} event={eventInfo} />
                                                            </ButtonGroup>
                                                        : <></>}
                                                    </Col>

                                                    <Col xs={12} lg={6} xl={4}>
                                                        {eventInfo.eventInfoUsers && eventInfo.eventInfoUsers.map((info : any) => {
                                                            console.log("INFO NEW", info);
                                                            const user = eventUser.find(user => user.id === info.user_id);
                                                            if (user){
                                                                if ((parseInt(info.user_assigned) || info.user_assigned) && info.status !== "reject" && info.status !== "apply"){
                                                                    return <span className="user-assigned-status">{user.firstname} {user.lastname}
                                                                        {info.briefing_read &&
                                                                            <TooltipCreator
                                                                                id={"eventInfoUser_" + user.id}
                                                                                text="Briefing gelesen und erhalten">
                                                                                    <span
                                                                                        className="clickable">
                                                                                        <FontAwesomeIcon icon={faBookOpen}/>
                                                                                    </span>
                                                                            </TooltipCreator>
                                                                            }
                                                                                <TodoModal info={info} user={user}/>

                                                                    <EventIcons info={info} user={user} />
                                                                    <br />
                                                                </span>;
                                                                }
                                                            }
                                                        })}
                                                    </Col>

                                                </Row>
                                            </div>
                                    </CardBody>
                                </Card>
                                <Row>
                                    {eventInfo.eventInfoUsers && eventInfo.eventInfoUsers.map((infoUser : any) => {
                                        const user = eventUser.find(u => u.id === infoUser.user_id);
                                        console.log("INFO USER FILES", infoUser)
                                        if (infoUser.status === "finish" && ((infoUser.user_content && Array.isArray(infoUser.user_content.content)) || (infoUser.files && infoUser.files.length > 0)) && user){
                                            return (
                                                <Col md={4}>
                                                    <Card className="mb-4">
                                                        <CardHeader>User Feedback von {user.firstname} {user.lastname} zu {eventInfo.title} <Moment format="HH:mm">{eventInfo.starting_time}</Moment> - <Moment format="HH:mm">{eventInfo.ending_time}</Moment>
                                                        </CardHeader>
                                                        <CardBody>
                                                            {infoUser.user_content && Array.isArray(infoUser.user_content.content) && <div>
                                                                {infoUser.user_content.content.map((content : any, i : number) => (
                                                                <div>
                                                                    <p><strong>Feedback:</strong> {feedbackText && feedbackText[i] ? feedbackText[i].title : ""}</p>
                                                                    <p>{content}</p>
                                                                </div>
                                                                ))}
                                                            </div>}
                                                            {infoUser.files && infoUser.files.length > 0 && <div>
                                                                <ImageLightbox alt="Additinal Image" images={infoUser.files}  isSingle/>
                                                            </div>}
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            )
                                        } else {
                                            return "";
                                        }
                                    })}


                                    {/*{eventInfo.times.map(event => eventUser.map(user => {
                                            let assignedToObj = {};
                                            if (user.UserEvent.user_assigned !== ""){
                                                assignedToObj = JSON.parse(user.UserEvent.user_assigned);
                                            }
                                            const assignedTo = Object.keys(assignedToObj);
                                            const status = user.UserEvent.status;
                                            const userInEvent = assignedTo.find(to => parseInt(to) === event.id);
                                            if (firstDate && status === "finish" && userInEvent && assignedToObj[parseInt(userInEvent)]){
                                                firstDate = false;
                                                return (
                                                    <Col md={4}>
                                                        <Card className="mb-4">
                                                            <CardHeader>User Feedback von {user.firstname} {user.lastname}</CardHeader>
                                                            <CardBody>
                                                                {user.UserEvent.user_content.content !== "" && <div>
                                                                    <p><strong>Feedback:</strong> {user.UserEvent.user_content.content}</p>
                                                                </div>}
                                                                {user.UserEvent.files.length > 0 && <div>
                                                                    <ImageLightbox alt="Additinal Image" images={user.UserEvent.files} />
                                                                </div>}
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                )
                                            } else {
                                                return ""
                                            }
                                    })
                                    )}*/}
                                </Row>
                            </div>
                        )})}
                        <div className="row">
                            <div className="col-md-4">
                                {(eventNew.emailText && eventNew.emailText !== '<p></p>') ?
                                <Card className="mt-2">
                                    <CardBody>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: eventNew.emailText
                                            }}/>
                                    </CardBody>
                                </Card> : <></>}
                                <Card className="mt-2">
                                    <CardHeader>Upload PDF-Files</CardHeader>
                                    <CardBody>
                                        <div className="event-feedback">
                                            <div className="event-pdf-upload">
                                                <p className="small">Aktuelle PDFS:</p>
                                                <ul className="list-group pb-3">
                                                    {uploadedPDFs.map(pdf => <li className="list-group-item d-flex justify-content-between align-items-center">{pdf}
                                                    <span className="badge badge-primary badge-pill clickable" style={{padding: 10}} onClick={() => deleteBriefingPDF(pdf)}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </span>
                                                    </li>)}
                                                </ul>
                                                <Dropzone files={pdfs} name="unset" deleteAble={true} acceptedFiles="pdf" setFiles={addBriefingPDF}/>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-md-8">
                                <Card className="mt-2">
                                    <CardHeader>
                                        <h6>Event Feedback</h6>
                                        <p className="small mb-0 pb-0">Angaben über das Feedback?</p>
                                    </CardHeader>
                                    <CardBody>
                                        <FormGroup as={Row}>
                                            <Form.Label htmlFor="feedbackPhoto" sm={4}>Werden Fotos benötigt?</Form.Label>
                                            <Col sm={8}>
                                                <Form.Check
                                                    type="switch"
                                                    id="feedbackPhoto"
                                                    label=""
                                                    checked={feedbackAttr.photo}
                                                    onChange={onFeedbackInputChange}
                                                    name="photo"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <Form.Label htmlFor="photoNumber" sm={4}>Wie viele Fotos?</Form.Label>
                                            <Col sm={3}>
                                                <Form.Control type="number" value={feedbackAttr.photoNumber} name="photoNumber" id="photoNumber" onChange={onFeedbackInputChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <Form.Label htmlFor="feedbackText" sm={4}>Wird weiteres Feedback benötigt?</Form.Label>
                                            <Col sm={8}>
                                                <Form.Check
                                                    type="switch"
                                                    id="feedbackText"
                                                    label=""
                                                    checked={feedbackAttr.text}
                                                    onChange={onFeedbackInputChange}
                                                    name="text"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <div className="event-feedback">
                                            {feedbackText.map((text, index) => (
                                                <div className="feedback-holder">
                                                    <div className="feedback-card card">
                                                        <FormGroup as={Row}>
                                                            <Form.Label htmlFor={"Title" + index} sm={4}>Titel</Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Control type="text" value={text.title} name="title" id={"Title" + index} onChange={(e) => onFeedbackTextChange(e, index)} />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup as={Row}>
                                                            <Form.Label htmlFor={"Desc" + index} sm={4}>Beschreibung</Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Control type="textarea" name="description" id={"Desc" + index} onChange={(e) => onFeedbackTextChange(e, index)} value={text.description} />
                                                            </Col>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="feedback-delete pt-2 pb-4">
                                                        <Button variant="danger" onClick={(e) => onRemoveFeedback(e, index)} >Feedback löschen</Button>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="feedback-card-add row justify-content-end">
                                                <div className="col-md-4">
                                                    <EventUserFeedback eventSections={eventSections} eventUser={eventUser} />
                                                </div>
                                                <div className="col-md-4">
                                                    <Button variant="primary" className="w-100" onClick={onAddFeedback} >Feedback hinzufügen</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-md-8">
                                <Card className="mt-2">
                                    <CardHeader>
                                        <h6>Event Informations</h6>
                                        <p className="small">Trage weitere Informationen für die Administration ein...</p>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="event-info">
                                            <FormGroup>
                                                <Form.Label htmlFor="eventInformation">Informationen</Form.Label>
                                                <FormGroup>
                                                    <Form.Control type="textarea" id="eventInformation" value={eventNew.additional_info} name="additional_info" onChange={(e) => {
                                                        setEventNew((event : any) => ({
                                                            ...event,
                                                            additional_info: e.target.value
                                                        }))
                                                    }} />{' '}
                                                </FormGroup>
                                            </FormGroup>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        <div className="event-end-section d-flex justify-content-end pt-2">
                            <ButtonGroup>
                                {eventNew.status === "completed" ?
                                    <Button variant="primary" onClick={(e) => onEndEvent(e, "finished")}>Event ins Archiv legen</Button>
                                    :
                                    <Button variant="primary" onClick={(e) => onEndEvent(e, "completed")}>Event schließen</Button>
                                }
                                <Button variant="secondary" onClick={onSaveEvent}>Event speichern</Button>
                            </ButtonGroup>
                        </div>
                    </Form>
                </div> : <></>}
            </div>
        </div>
    );
};

interface IStatusCheckBox {
    status: EVENT_SECTION_STATUS,
    event_info_id: number,
    checked: boolean,
    onChecked(event: ChangeEvent<HTMLInputElement>, infoID: number): void
}

const StatusCheckbox : React.FC<IStatusCheckBox> = (props) => {
    //console.log("WINDOWS SIZE", useWindowSize());

    const {width} = useWindowSize();

    if (eventStatusRanking[props.status] < 2){
        return (<>
            <label className="pe-2" htmlFor={`check_operational_${props.event_info_id}`}>Operational ?</label>
            <input type="checkbox" id={`check_operational_${props.event_info_id}`} checked={props.checked} onChange={(e) => props.onChecked(e, props.event_info_id)}  />
            </>)
    } else if (eventStatusRanking[props.status] === 2) {
        return (<>
            <label className="pe-2" htmlFor={`check_operational_${props.event_info_id}`}>Operational ?</label>
            <input type="checkbox" id={`check_operational_${props.event_info_id}`} checked={props.checked} onChange={(e) => props.onChecked(e, props.event_info_id)}  />
            </>)
    } else {
        return (
            <>
                <label className="pe-2" htmlFor={`check_operational_${props.event_info_id}`}>Operational ?</label>
                <input type="checkbox" id={`check_operational_${props.event_info_id}`} checked={true} disabled={true} onChange={(e) => props.onChecked(e, props.event_info_id)}  />
            </>
            )
    }
}

/*const ButtonForUserInteraction = (props) => {

    const onClick = (evId, userId, newStatus, eventInfoId = 0) => {
        instance.get("/admin/event/status", {params: {
                userId: userId,
                action: newStatus,
                id: evId,
                eventInfoId: eventInfoId,
                admin: true
            }}).then(resp => {

        }).catch(err => {
            //console.log(err);
        })
    };

    switch(props.status){
        case "apply":
            return <Button onClick={() => onClick(props.eventId, props.userId, "confirm_for_event", props.eventInfoId)} className="small">Annehmen</Button>;
        case "confirm_for_event":
            return <Button onClick={() => onClick(props.eventId, props.userId, "reject")} className="small" >Entfernen</Button>;
        case "not_confirmed":
            return <Button onClick={() => onClick(props.eventId, props.userId, "not_confirmed")} className="small">Erinnerung erneut zuschicken</Button>;
        default:
            return "";
    }
};*/

const mapStateToProps = (state : RootState) => ({
    userData: state.user
})

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EventShow);
