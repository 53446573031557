import React, {FC, useEffect, useState} from 'react';
import Background from "../../assets/images/Background.png";
import NoLayout from "../../layouts/NoLayout/NoLayout";
import {useLocation} from "react-router-dom";
import instance from "../../util/api";
import {Button} from "react-bootstrap";
import {LOGIN} from "../../util/routes/routes";


const ProofUser : FC = (props) => {
    const [success, setSuccess] = useState(false);

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    useEffect(() => {
        instance.get("/user/proof", {
            params: {
                date: urlParams.get("date"),
                u: urlParams.get("u")
            }
        }).then(resp => {
            if (resp.data.error) {

            } else {
                setSuccess(true);
            }
        }).catch(err => {

        })

    }, [])

    return (
        <NoLayout className="Login" background={Background}>
            {!success ?
                <div className="login-container">
                    <p>Wir bestätigen deinen Account. Vielen Dank!</p>
                </div> :
                <div className="login-container text-center">
                    <p>Danke für die Bestätigung.<br/> Dein Account bleibt somit aktiv.</p>
                    <Button href={LOGIN} as={"a"}>Zurück zur Startseite</Button>
                </div>}
        </NoLayout>
    );
};

export default ProofUser;