import React, {useEffect, useState} from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, OverlayTrigger, Tooltip
} from "react-bootstrap";
import {faThList} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ITodoModalUser {
    todo: any
}

const TodoModalUser : React.FC<ITodoModalUser> = (props) => {

    const {todo} = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const TooltipCreator = (props : any) => (
        <OverlayTrigger overlay={<Tooltip id={props.id}>{props.text}</Tooltip>}>
            {props.children}
        </OverlayTrigger>
    )

    return (
        <span>
            <span className="ml-2" style={{color: "#000"}} onClick={toggle}>
                <TooltipCreator id={"eventInfoUserTodo_" + todo.id} text={"Dir wurden weitere Aufgaben zugeteilt. Clicke hier um mehr zu erfahren."}>
                    <span id={"eventInfoUserTodo_" + todo.id}>
                        Deine ToDos {' '}
                        <FontAwesomeIcon icon={faThList} />
                    </span>
                </TooltipCreator>
            </span>

            <Modal fade={false} show={modal} onHide={toggle} centered={true}>
                <ModalHeader closeButton>Deine ToDos</ModalHeader>
                <ModalBody>
                    <p>{todo.titel}</p>
                    <p>{todo.content}</p>
                </ModalBody>
                <ModalFooter>
                    <Button variant="primary" onClick={toggle}>Schließen</Button>{' '}
                </ModalFooter>
            </Modal>
        </span>
    );
};

export default TodoModalUser;