import React, {useState} from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    FormGroup,
    Modal,
    ModalBody,
    Form,
    ModalHeader,
    Row
} from "react-bootstrap";
import { Formik } from 'formik';
import instance from "../../../../util/api";

const initialForm = {
    type: "organisation",
    title: "",
    firstname: "",
    lastname: "",
    organisation: "",
    position: "",
    name_organisation: "",
    customer_number: -1,
    organisation_type: "customer",
    street: "",
    plz: "",
    city: "",
    country: "",
    address_type: "work"
}

interface INewCustomerModal {
    onSave(customer: any): void
}

const NewCustomerModal : React.FC<INewCustomerModal> = (props) => {

    const {
        onSave
    } = props;

    const [modal, setModal] = useState(false);
    const [date, setDate] = useState(new Date());

    const toggle = () => setModal(!modal);


    return (
        <div>
            <Button onClick={toggle}>+ Neuer Kunde</Button>

            <Modal fade={false} size="lg" show={modal} onHide={toggle} centered={true}>
                <ModalHeader closeButton>Neuen Kunden anlegen</ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={initialForm}
                        onSubmit={(values, {setSubmitting}) => {
                            instance.post("/admin/customer", values)
                                .then(resp => {
                                    if (onSave){
                                        onSave(resp.data.customer);
                                    }
                                    toggle();
                                    console.log(resp);
                                }).catch(err => {
                                    console.error(err);
                            })
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleSubmit,
                            isSubmitting
                        }) => (
                            <form className="form" onSubmit={handleSubmit}>
                                <Row className="mb-4">
                                    <Col xs={12}>
                                        <ButtonGroup>
                                            <Button id="type" name="type" active={values.type === "customer"} onClick={e => {
                                                e.currentTarget.value = "customer";
                                                handleChange(e);
                                            }}>Person</Button>
                                            <Button id="type" name="type" active={values.type === "organisation"} onClick={e => {
                                                e.currentTarget.value = "organisation";
                                                handleChange(e);
                                            }}>Organisation</Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                                <Row className="mb-4 border-bottom">
                                    <Col xs={12} md={6}>
                                        <Row>
                                            {values.type === "organisation" ?
                                                <Col xs={12} md={12}>
                                                    <FormGroup>
                                                        <Form.Label htmlFor="name_organisation">Name der Organisation</Form.Label>
                                                        <Form.Control name="name_organisation" required={true} type="text" value={values.name_organisation} onChange={handleChange} />
                                                    </FormGroup>
                                                </Col>
                                                :
                                                <>
                                                    <Col xs={12} md={12}>
                                                        <FormGroup>
                                                            <Form.Label htmlFor="title">Title</Form.Label>
                                                            <Form.Control name="title" required={false} type="text" value={values.title} onChange={handleChange} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <FormGroup>
                                                            <Form.Label htmlFor="firstname">Vorname</Form.Label>
                                                            <Form.Control name="firstname" required={false} type="text" value={values.firstname} onChange={handleChange} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <FormGroup>
                                                            <Form.Label htmlFor="lastname">Nachname</Form.Label>
                                                            <Form.Control name="lastname" required={true} type="text" value={values.lastname} onChange={handleChange} />
                                                        </FormGroup>
                                                    </Col>
                                                </>
                                            }
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <FormGroup>
                                                    <Form.Label htmlFor="customer_number">Kunden-Nummer</Form.Label>
                                                    <Form.Control name="customer_number" required={true} type="number" min={0} value={values.customer_number} onChange={handleChange} />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <FormGroup>
                                                    <Form.Label htmlFor="organisation_type">Typ</Form.Label>
                                                    <Form.Control name="organisation_type"
                                                           required={false}
                                                           type="select"
                                                           value={values.organisation_type}
                                                           onChange={handleChange}>
                                                        <option value="customer">Kunde</option>
                                                        <option value="supplier">Lieferant</option>
                                                        <option value="partner">Partner</option>
                                                        <option value="interessant">Interessent</option>
                                                    </Form.Control>
                                                </FormGroup>
                                            </Col>
                                            {values.type === "customer" ?
                                                <>
                                                    <Col xs={12} md={6}>
                                                        <FormGroup>
                                                            <Form.Label htmlFor="organisation">Organisation</Form.Label>
                                                            <Form.Control name="organisation" required={false} type="text"
                                                                   value={values.organisation} onChange={handleChange}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <FormGroup>
                                                            <Form.Label htmlFor="position">Position</Form.Label>
                                                            <Form.Control name="position" required={false} type="text"
                                                                   value={values.position} onChange={handleChange}/>
                                                        </FormGroup>
                                                    </Col>
                                                </> : <></>
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col xs={12} md={8}>
                                        <Row>
                                            <Col xs={12}>
                                                <FormGroup>
                                                    <Form.Label htmlFor="street">Straße</Form.Label>
                                                    <Form.Control name="street" required={false} type="text"
                                                           value={values.street} onChange={handleChange}/>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <FormGroup>
                                                    <Form.Label htmlFor="plz">PLZ</Form.Label>
                                                    <Form.Control name="plz" required={false} type="text"
                                                           value={values.plz} onChange={handleChange}/>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={9}>
                                                <FormGroup>
                                                    <Form.Label htmlFor="city">Stadt</Form.Label>
                                                    <Form.Control name="city" required={false} type="text"
                                                           value={values.city} onChange={handleChange}/>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <FormGroup>
                                                    <Form.Label htmlFor="country">Land</Form.Label>
                                                    <Form.Control name="country" required={false} type="text"
                                                           value={values.country} onChange={handleChange}/>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <FormGroup>
                                                    <Form.Label htmlFor="address_type">Type</Form.Label>
                                                    <Form.Control name="address_type" required={false} type="select"
                                                           value={values.address_type} onChange={handleChange}>
                                                        <option value="work">Arbeit</option>
                                                        <option value="private">Privat</option>
                                                        <option value="invoice">Rechnungsanschrift</option>
                                                        <option value="delivery">Lieferanschrift</option>
                                                        <option value="departure">Abholanschrift</option>
                                                    </Form.Control>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className="text-right">
                                        <ButtonGroup className="text-right">
                                            <Button variant="primary" onClick={toggle}>Abbrechen</Button>
                                            <Button variant="secondary" type={"submit"}>Speichern</Button>{' '}
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default NewCustomerModal;