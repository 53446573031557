import React, {useEffect, useState} from 'react';
import Search from "../../components/search/Search";
import List, {ListType} from "../../components/list/List";
import instance from "../../util/api";
import {EventModel} from "../../model/EventModel";
import {connect, ConnectedProps} from "react-redux";
import {eventInfoStatusRanking, EventStatus, EventUserStatus, statusRanking} from "../../util/helper/eventHelper";
import BaseLayoutUser from "../../layouts/BaseLayout/BaseLayoutUser";
import Loading from "../../components/loading/Loading";
import {USER_EVENT_LOGISTIC_DETAIL} from "../../util/routes/routes";
import {RootState} from "../../store";
import {RouteComponentProps} from "react-router-dom";
import moment from "moment";
import AccordionList from "../../components/list/AccordionList";
import {TStatusEvent} from "../../model/interfaces/event/IEvent";
import Moment from "react-moment";

const classNames = require('classnames');

interface MatchProps {
    eventType: string
}

interface IEventListUser extends PropsFromRedux, RouteComponentProps<MatchProps>{

}

const EventListUser : React.FC<IEventListUser> = (props) => {
    const [events, setEvents] = useState<any[]>([]);
    const [bkupEvents, setBkupEvents] = useState<any[]>([]);
    const [eventUser, setEventUsers] = useState({id: -1, status: ""});
    const [loading, setLoading] = useState<boolean>(false);
    const [done, setDone] = useState<boolean>(false);

    const {userData} = props;

    useEffect(() => {
        instance.get(`/user/event/all`, {
            params: {
                role: "USER",
                type: props.match.params.eventType.toUpperCase(),
                username: props.userData.username
            }
        })
            .then(resp => {
                const events = [] as any[];
                const currentDate = moment();
                const oneWeekBefore = currentDate.subtract(1, "week");
                resp.data.forEach((ev : any) => {
                    const userInEvent = ev.users.find((user: any) => user.username === props.userData.username);

                    const nextEventInfo = ev.eventSections.filter((i : any) => moment(i.date).isSameOrAfter(currentDate)).sort((a: any,b : any) => a.date - b.date);

                    //console.log("EVENT DATES", nextEventInfo, ev.eventSections.map((i: any) => [i.id, moment(i.date), moment(i.date).isSameOrAfter(currentDate)]), currentDate)

                    if (nextEventInfo.length > 0){
                        if (userInEvent){
                            const nextEventStatus = nextEventInfo.map((i : any) => i.eventInfoUsers.find((infoUser : any) => userInEvent.id === infoUser.user_id))
                                .filter((i : any) => i)
                                .sort((a : any, b : any) => statusRanking[a.status as keyof typeof statusRanking] - statusRanking[b.status as keyof typeof statusRanking]);

                            //console.log("NEXT EVENT STATUS", nextEventStatus)

                            const evModel = new EventModel();
                            evModel.createModel(ev, nextEventStatus.length > 0 ? nextEventStatus[0].status : "not_apply");
                            events.push(evModel);
                        } else {
                            const statusList = ev.eventSections.map((event : any) => eventInfoStatusRanking[event.status as keyof typeof eventInfoStatusRanking]);

                            if (statusList.find((s : number) => s < 2) !== undefined){
                                const evModel = new EventModel();
                                evModel.createModel(ev, "not_apply");
                                events.push(evModel);
                            }
                        }
                    }
                });

                //console.log(events);

                setEvents(events);
                setBkupEvents(events);
                setLoading(false);
                setTimeout(() => {
                    setDone(true);
                }, 1000);

                console.log("EVENT DATA");
            }).catch(err => {
                //console.log(err);
        })
    }, [props.match.params.eventType]);

    const onSearch = (values : any[], searchVal : string) => {
        if (searchVal !== "") {
            const foundUsers = bkupEvents.filter(user => values.find(val => val.id === user.id));
            setEvents(foundUsers)
        }  else {
            setEvents(bkupEvents);
        }
    }

    const onFilter = (values : any[], filterNr : number) => {
        if (filterNr > 0) {
            const foundUsers = bkupEvents.filter(user => values.find(val => val.id === user.id));
            setEvents(foundUsers)
        }  else {
            setEvents(bkupEvents);
        }
    }

    const headers = [
        {slug: "title", name: "Name"},
        {slug: "date", name: "Datum"},
        {slug: "status", name: ""},
    ];

    const currentDate = moment();
    const rowData = events.map(ev => {
        let nextEventStatus;
        let nextEventInfo;
        if (ev.status !== "completed" && ev.status !== "finished"){
            nextEventInfo = ev.infos
                .filter((info : any) => info.users.find((user : any) => user.user_id === userData._id))
                //.filter((info : any) => moment(info.date).diff(currentDate) >= 0)
                .sort((a : any,b : any) => moment(a.date).diff(currentDate) - moment(b.date).diff(currentDate));

            if (nextEventInfo.length > 0 && nextEventInfo[0].users.length > 0) {
                nextEventStatus = nextEventInfo[0].users
                    .find((user : any) => user.user_id === userData._id)
                    .status;
            } else {
                nextEventStatus = "not_apply";
            }


            //nextEventStatus = (nextEventInfo.length > 0 && nextEventInfo[0].users.length > 0) ? nextEventInfo[0].users[0].status : "not_apply";
            //console.log("NEXT EVENT STATUS", nextEventInfo, nextEventStatus, nextEventInfo.length > 0 ? moment(nextEventInfo[0].date).diff(currentDate) : "")

        } else {
            nextEventInfo = ev.infos;
            nextEventStatus = ev.status;
        }

        const dataClass = classNames("status", nextEventStatus);

         return {
             show: USER_EVENT_LOGISTIC_DETAIL.path.replace(":eventType", props.match.params.eventType).replace(":id", ev.id),
             id: ev.id,
             multiple: nextEventInfo.length > 1,
             data: [
                {val: ev.title, classes: [], type: ListType.STRING, slug: "title"},
                {val: [...new Set(ev.infos.map((info : any) => moment(info.date).format("DD.MM.YYYY")))].join(", "), classes: [], type: ListType.STRING, slug: "date"},
                {val: <div className={dataClass}>
                        <h5>{EventUserStatus[nextEventStatus as keyof typeof EventUserStatus]}</h5>
                    </div> , classes: [], type: ListType.HTML, slug: "status"},
            ],
             nextData: nextEventInfo.filter((n : any, i : number) => i > 0).map((info : any) => {
                 if (info.users.length > 0) {
                     const user = info.users
                         .find((user : any) => user.user_id === userData._id)
                     nextEventStatus = user ? user.status : "not_apply";
                 } else {
                     nextEventStatus = "not_apply";
                 }

                 const dataClass = classNames("status", nextEventStatus);

                 return [
                     {val: "", classes: [], type: ListType.STRING, slug: "title"},
                     {val: info.date, classes: [], type: ListType.DATE, slug: "date"},
                     {val: <div className={dataClass}>
                             <h5>{EventUserStatus[nextEventStatus as keyof typeof EventUserStatus]}</h5>
                         </div> , classes: [], type: ListType.HTML, slug: "status"},
                 ]})
         }
        });


    return (
        <BaseLayoutUser>
            <div className="row align-items-center justify-content-between pb-5">
                <div className="col-12 col-md-8 col-lg-5">
                    <Search onChange={onSearch} placeholder={props.match.params.eventType === "event" ? "Suche nach Events..." : "Suche nach Logistik Events..."}  searchType={props.match.params.eventType}/>
                </div>
            </div>
            {!done ?
                <Loading loading={loading} />
                :
                <AccordionList headers={headers} data={rowData} noEdit={false} noCopy={false}/>
                /*<List headers={headers} data={rowData} noEdit={true} type="user" />*/
            }
        </BaseLayoutUser>
    );
};

const mapStateToProps = (state : RootState) => ({
    userData: state.user
})

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(EventListUser);
