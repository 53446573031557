import {Action} from "@reduxjs/toolkit";
import {userLogin} from "../../../util/api/user/login";
import {Observable, filter, mergeMap} from "rxjs";
import {userLoginFailureAction, userLoginRequestAction, userLoginSuccessAction} from "../../action/user/userAction";
import {AxiosError} from "axios";


export const proceedUserLogin = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    filter(userLoginRequestAction.match),
    mergeMap((action) => {
        return userLogin(action.payload.username, action.payload.password)
            .then((resp) : Action => {
                console.log("LOGIN DATA", resp);
                return userLoginSuccessAction(resp.data.user, resp.data.token, resp.data.refreshToken);
            }).catch((error : AxiosError<any, any>) : Action => {
                console.log("ERRROR", error);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.error(error.response.data);
                    console.error(error.response.status);
                    console.error(error.response.headers);
                    return userLoginFailureAction(error.response.data.message);
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.error(error.request);
                    return userLoginFailureAction(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.error('Error', error.message);
                    return userLoginFailureAction(error.message);
                }
            })
    })
)
