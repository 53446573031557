import instance from "../index";
import {toast} from "react-toastify";
import FusionToast from "../../../components/toast/FusionToast";
import React from "react";


export const getMessagesApi = () => {
    return instance.get("/user/messages");
}

export const readMessageApi = (id : number) => {
    return instance.get("/user/messages/read", {params: {id: id}})
}

export const removeMessageApi = (id : number) => {
    return instance.delete("/user/messages", {params: {id: id}})
}

export const createMessageApi = (header: string, message : string, status: string, id?: number) => {
    return instance.post("/user/messages", {message: message, header: header, status: status, id: id})
}