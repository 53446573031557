import React, {useEffect, useState} from 'react';
import BaseLayoutUser from "../../layouts/BaseLayout/BaseLayoutUser";
import instance from "../../util/api";
import {connect, ConnectedProps} from "react-redux";
import Loading from "../../components/loading/Loading";
import {Accordion, Button, Col, ListGroup, Row, Tab} from "react-bootstrap";
import {USER_EVENT_LOGISTIC_DETAIL} from "../../util/routes/routes";
import {toast} from "react-toastify";
import FusionToast from "../../components/toast/FusionToast";
import {v4} from "uuid";
import {eventInfoStatusRanking, EventUserStatus, statusRanking} from "../../util/helper/eventHelper";
import {RootState} from "../../store";
import {IEvent} from "../../model/interfaces/event/IEvent";
import Moment from "react-moment";
import EventModalUser from "../../components/calendar/sub/EventModalUser";

import "./DashboardUser.scss";
import classNames from "classnames";
import moment from "moment";

interface IDashboardUser extends PropsFromRedux{

}

interface IEventFilter {
    confirmed: IEvent[],
    applied: IEvent[],
    open: IEvent[],
    old: IEvent[]
}

const AccordionHeader = {
    confirmed: "Bestätigte Events",
    applied: "Beworben",
    open: "Offene Events",
    old: "Vergangene Events"
}as {
    [key in keyof IEventFilter]: string
}

const DashboardUser : React.FC<IDashboardUser> = (props) => {
    const [modal, setModal] = useState(false);
    const [events, setEvents] = useState<IEventFilter>({
        confirmed: [],
        applied: [],
        open: [],
        old: []
    });
    const [event, setEvent] = useState<IEvent>();
    const [loading, setLoading] = useState<boolean>(false);
    const [done, setDone] = useState<boolean>(false);
    const [filterValues, selectFilter] = useState([
        {id: v4(), name: "Alle Events", value: "alle", selected: true},
        {id: v4(), name: "Beworben", value: 1, comparator: "=", selected: false},
        {id: v4(), name: "Zugeteilt", value: 3, comparator: ">=", selected: false},
        {id: v4(), name: "Offene Jobs", value: 0, comparator: "=", selected: false},
    ]);

    const {userData} = props;

    const openModal = (event: IEvent) => {
        setEvent(event);
        setModal(!modal);
    }

    const toggle = () => {
        setModal(!modal);
    }

    const changeEvent = (props: any) => {
        getEvents();
    }

    useEffect(() => {
        getEvents();
    }, []);

    const getEvents = () => {
        instance.get("/user/tasks", {
            params: {
                ended: true
            }
        })
            .then(resp => {
                if (!resp.data.error) {

                    const events = resp.data.events
                        .map((event : any) => {
                            event.eventSections = event.eventSections.filter((eventInfo : any) => {
                                const foundUser = eventInfo.eventInfoUsers.find((user : any) => user.user_id === userData._id);
                                if (foundUser) {
                                    return true;
                                } else {
                                    console.log(eventInfo.status, eventInfoStatusRanking[eventInfo.status as keyof typeof eventInfoStatusRanking])
                                    return eventInfoStatusRanking[eventInfo.status as keyof typeof eventInfoStatusRanking] < 2;
                                }
                            });
                            //return foundInfos.length > 0;
                            return event;
                        })
                        .map((event : any) => ({
                            ...event,
                            statusEvent: event.status !== "completed" ? event.eventSections.map((eventInfo : any) => {
                                const foundUser = eventInfo.eventInfoUsers.find((user : any) => user.user_id === userData._id);
                                if (foundUser){
                                    return foundUser.status;
                                } else {
                                    return "not_apply";
                                }
                            }).sort((a : keyof typeof statusRanking, b : keyof typeof statusRanking) => statusRanking[a] - statusRanking[b]).reverse()[0] : event.status,
                            link: USER_EVENT_LOGISTIC_DETAIL.path.replace(":eventType", "event").replace(":id", event.id),
                            show: true
                        }))
                        .map((event: any) => ({
                            ...event,
                            statusRanking: statusRanking[event.statusEvent as keyof typeof statusRanking]
                        }));

                    const eventFilters = {
                        confirmed: events.filter((event: any) => event.statusRanking >= 3 && event.statusRanking < 8),
                        applied: events.filter((event: any) => event.statusRanking === 1),
                        open: events.filter((event: any) => event.statusRanking === 0),
                        old: events.filter((event: any) => event.statusRanking >= 8)
                    }

                    //console.log("USER EVENTS", eventFilters);

                    const billableDates = resp.data.billableEvents.map((event : any) => ([
                        ...event.eventSections.map((eventInfo : any) => {
                            const foundUser = eventInfo.eventInfoUsers.find((user : any) => user.user_id === userData._id && !user.user_billed)
                            if (foundUser) {
                                return {
                                    user_billing_date: foundUser.user_billing_date,
                                    user_billed: foundUser.user_billed
                                }
                            } else {
                                return null;
                            }
                        })
                    ])).reduce((acc : any, curr : any) => [...acc, ...curr], []);

                    console.log("ALL EVENTS", events, billableDates, resp.data.billableEvents);

                    const uniqueBillableDates = [] as any[];
                    billableDates.map((x : any) => uniqueBillableDates.filter(a => a && x && a.user_billing_date && x.user_billing_date &&  a.user_billing_date === x.user_billing_date).length > 0 ? null : uniqueBillableDates.push(x));

                    setEvents(eventFilters);
                    setLoading(false);
                    setTimeout(() => {
                        setDone(true);
                    }, 1000);
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
            }).catch(err => {
            console.error(err);
            toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
        });
    }

    return (
        <BaseLayoutUser>
            {!done ?
                <Loading loading={loading} />
                :
                <>
                    <Accordion defaultActiveKey={"confirmed"} className="mt-5">
                        {Object.keys(events).map(key => {
                            const headerKey = key as keyof IEventFilter;
                            console.log("CURRENT EVENT", events[headerKey])

                            return (
                                <Accordion.Item className="dashboard-accordion" eventKey={key} key={key}>
                                    <Accordion.Header className={key}>{AccordionHeader[headerKey]}</Accordion.Header>
                                    <Accordion.Body>
                                        {headerKey === "confirmed" ?
                                            <div className="information small bold">
                                                <p>Solltest du deinen Einsatz aus etwaigen Gründen nicht wahrnehmen können, ruf bitte sofort <a href="tel:+43 664 88299422">+43 664 88299422</a> an! Wenn du dir bei etwas unsicher bist oder Fragen hast, kannst du uns gerne immer per WhatsApp erreichen.</p>
                                            </div> : <></>}

                                        {(headerKey === "open" && !props.userData.is_active) ?
                                            <div className="information small bold">
                                                <p>Dein Account ist noch nicht vollständig aktiviert, bitte fülle noch die fehlenden Daten aus. <a href='/profile'>Zu deinem Profil</a></p>
                                            </div> :
                                            <>
                                                {events[headerKey].length > 0 ?
                                                    <Tab.Container id={`event-${key}`} defaultActiveKey={events[headerKey][0].id}>
                                                        <Row>
                                                            <Col sm={4}>
                                                                <ListGroup>
                                                                    {events[headerKey].map((event : any) => {
                                                                        const eventDate = event.eventSections[0].date;
                                                                        const lastDate = event.eventSections[event.eventSections.length-1].date;
                                                                        let oneDay = eventDate === lastDate;

                                                                        return (
                                                                            <ListGroup.Item action href={"#" + event.id}>
                                                                                <h6 className="mb-0">{event.title}</h6>
                                                                                <small className="small">
                                                                                    {oneDay ? <Moment format={"DD.MM.YYYY"}>{event.eventSections[0].date}</Moment> :
                                                                                        <Moment format={"DD.MM.YYYY"}>{eventDate}</Moment> + " - " + <Moment format={"DD.MM.YYYY"}>{lastDate}</Moment>
                                                                                    }
                                                                                </small>
                                                                            </ListGroup.Item>
                                                                        )})}
                                                                </ListGroup>
                                                            </Col>
                                                            <Col sm={8}>
                                                                <Tab.Content>
                                                                    {events[headerKey].map((event: any) => (
                                                                        <Tab.Pane eventKey={"#"+event.id}>
                                                                            <div className="simple-task">
                                                                                <div className="row">
                                                                                    <div className="task-title col-7">
                                                                                        <h2>{event.title}</h2>
                                                                                    </div>
                                                                                    <div className="task-status col-5">
                                                                                        <div className={classNames("status", event.statusEvent)}>
                                                                                            <h3>{EventUserStatus[event.statusEvent as keyof typeof EventUserStatus]}</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="task-content col-12">
                                                                                        <p>{event.content}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="task-head">
                                                                                    <h3>Für folgende Einsätze kannst du dich bewerben</h3>
                                                                                </div>
                                                                                <div className="task-headline row">
                                                                                    {event.eventSections.map((eventInfo: any) => {
                                                                                        return (
                                                                                            <div className="task-info col-5">
                                                                                                <p className="mb-0 pb-0"><strong>Datum:</strong> <Moment
                                                                                                    format={"DD.MM.YYYY"}>{eventInfo.date}</Moment>
                                                                                                </p>
                                                                                                <p><strong>Von-Bis:</strong> <Moment
                                                                                                    format={"HH:mm"}>{eventInfo.starting_time}</Moment> - <Moment
                                                                                                    format={"HH:mm"}>{eventInfo.ending_time}</Moment>
                                                                                                </p>
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                                <div className="task-footer text-end">
                                                                                    <Button variant="primary" className="small" onClick={() => openModal(event)}>Details</Button>
                                                                                </div>
                                                                            </div>
                                                                        </Tab.Pane>
                                                                    ))}
                                                                </Tab.Content>
                                                            </Col>
                                                        </Row>
                                                    </Tab.Container>
                                                    : <p className="small bold">Derzeit sind in dieser Kategorie keine Events hinterlegt!</p>}
                                            </>
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })}


                        {/*<div className="row justify-content-end align-items-center">
                            <div className="col-lg-3 col-md-6 col-12 text-end">
                                <Dropdown show={dropdownOpen} onClick={toggle}>
                                    <Dropdown.Toggle className="btn-outline-primary">
                                        Filtern nach <strong>{filterValues.find(f => f.selected)?.name}</strong> <span><FontAwesomeIcon icon={faFilter} /></span>
                                    </Dropdown.Toggle>
                                    <DropdownMenu>
                                        {filterValues.length > 0 && filterValues.map(value => <DropdownItem key={value.id} active={value.selected} onClick={() => onSelectFilter(value)}>{value.name}</DropdownItem>)}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>*/}
                        {/*<Calendar events={events} billableEvents={billableEvents} type="USER" isUserCalendar={true} />*/}

                        {(modal && event) && <EventModalUser modal={modal} toggle={toggle} event={event} changeEvent={changeEvent}/>}
                    </Accordion>
                </>
            }
            {/* <TaskList/>*/}
        </BaseLayoutUser>
    );
};

const mapStateToProps = (state : RootState) => ({
    userData: state.user
})

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(DashboardUser);
