import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps} from "react-redux";
import BaseLayout from "../../../layouts/BaseLayout/BaseLayout";
import {RootState} from "../../../store";
import {Formik} from "formik";
import {Button, Col, Form, FormGroup, ListGroup} from "react-bootstrap";
import * as yup from 'yup';
import {toast} from "react-toastify";
import error = toast.error;
import instance from "../../../util/api";
import FusionToast from "../../../components/toast/FusionToast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {ListItem} from "@material-ui/core";

interface IDocumentation extends PropsFromRedux {

}

interface IFormValues {
    title: string,
    category: string,
    file: any
}

const initialValues = {
    title: "",
    category: "",
    file: ""
} as IFormValues

const MAX_FILE_SIZE = 10485760; //10MB

const validFileExtensions = ['jpg', 'gif', 'png', 'jpeg', 'pdf', 'docx', 'xlsx'] as string[];

function isValidFileType(fileName : string) : boolean {
    return fileName ? validFileExtensions.indexOf(fileName.split('.').pop() as string) > -1 : false;
}

const validationSchema = yup.object({
    title: yup.string().required("Titel ist erforderlich"),
    category: yup.string().required("Kategorie ist erforderlich"),
    file: yup.mixed().required("Datei ist erforderlich")
        .test('is-valid-type', 'Ist kein gültiges Dokument oder Bild',
            (value : any) => isValidFileType(value && value.name.toLowerCase()))
        .test('is-valid-size', 'Datei ist zu groß',
            (value : any) => value && value.size <= MAX_FILE_SIZE)
})

const AdminDocumentation : React.FC<IDocumentation> = (props) => {
    const [files, setFiles] = useState<any[]>([]);
    const {user} = props;

    useEffect(() => {
        instance.get('/admin/documentation')
            .then(response => {
                console.log(response.data);
                setFiles(response.data.documentation)
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    const onSubmit = (values : IFormValues) => {
        // Upload the values to server
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('category', values.category);
        formData.append('file', values.file);

        // Upload the file to server
        instance.post('/admin/documentation', formData)
            .then(response => {
                if (response.data.error) {
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message={response.data.message} />);
                } else {
                    setFiles((fileList : any[]) => [...fileList, response.data.documentation as any])
                    toast(<FusionToast className="success" header="Dokument erfolgreich gespeichert" message={""} />);
                }
            })
            .catch(err => {
                toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message={err.data.message} />);
            })
    }

    const deleteFile = (id: number) => {
        instance.delete('/admin/documentation/' + id)
            .then(response => {
                if (response.data.error) {
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message={response.data.message} />);
                } else {
                    setFiles((fileList : any[]) => fileList.filter(file => file.id !== id));
                    toast(<FusionToast className="success" header="Dokument erfolgreich gelöscht" message={""} />);
                }
            })
            .catch(err => {
                toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message={err.data.message} />);
            })
    }

    return (
        <BaseLayout className="Documentation">
            <h1>Dokumentations Dokumente für User</h1>

            <div className="container main-area-container">
                <div className="document-list">
                    <h3>Alle Dokumente</h3>
                    <ListGroup>
                        {files.map((file : any) => {
                            return (
                                <ListItem key={file.id} className="row">
                                    <Col xs={10}>
                                    {(file.type === "application/jpg" || file.type === "application/jpeg" || file.type === "application/png") ? <img src={"/api/file/images/" + file.name} alt={file.name} /> : <></>}
                                    {file.type === "application/pdf" ? <a href={"/api/file/pdfs/" + file.name} target="_blank">{file.title} - {file.category}</a> : <></>}
                                    </Col>
                                    <Col xs={2}>
                                        <Button variant="link" onClick={() => deleteFile(file.id)}>
                                            <FontAwesomeIcon icon={icon({name: 'trash'})} />
                                        </Button>
                                    </Col>
                                </ListItem>
                            )
                        })}
                    </ListGroup>
                </div>
                <div className="new-upload-section">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}>
                        {({handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue}) => {

                            return (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <h3>Neues Dokument hochladen</h3>
                                    <FormGroup className="mb-4">
                                        <Form.Label>Titel</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="title"
                                            value={values.title}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={!!errors.title && touched.title}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.title}
                                        </Form.Control.Feedback>
                                    </FormGroup>
                                    <FormGroup className="mb-4">
                                        <Form.Label>Kategorie</Form.Label>
                                        <Form.Select
                                            name="category"
                                            value={values.category}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={!!errors.category && touched.category} >
                                            <option value="">Bitte auswählen</option>
                                            <option value="default">Allgemeines</option>
                                            <option value="contract">Vertrag</option>
                                            <option value="about">Über Fusion</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.category}
                                        </Form.Control.Feedback>
                                    </FormGroup>
                                    <FormGroup className="mb-4">
                                        <Form.Label>File</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="file"
                                            onChange={(event : React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue("file", event.currentTarget.files && event.currentTarget.files[0]);
                                            }}
                                            onBlur={handleBlur}
                                            isInvalid={!!errors.file} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.file}
                                        </Form.Control.Feedback>
                                    </FormGroup>

                                    <Button type="submit">Hochladen</Button>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </BaseLayout>
    );
};

const mapStateToProps = (state : RootState) => ({
    user: state.user
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AdminDocumentation);