import React, {useEffect, useState} from 'react';
import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import BaseLayoutUser from "../../layouts/BaseLayout/BaseLayoutUser";
import instance from "../../util/api";

interface IDocumentation extends PropsFromRedux {

}

const categoryOptions = {
    "default": "Allgemeines",
    "contract": "Vertrag",
    "about": "Über Fusion"
};

const Documentation: React.FC<IDocumentation> = (props) => {
    const [files, setFiles] = useState<any>({});
    const {user} = props;

    useEffect(() => {
        instance.get('/admin/documentation')
            .then(response => {
                console.log(response.data);
                // Group by category
                const grouped = response.data.documentation.reduce((acc: any, file: any) => {
                    if (file.category !== "contract") {

                    }
                    if (!acc[file.category]) {
                        acc[file.category] = [];
                    }
                    acc[file.category].push(file);
                    return acc;
                }, {});

                setFiles(grouped);
            })
            .catch(err => {
                console.log(err);
            })
    }, []);


    return (
        <BaseLayoutUser className="Documentation">
            <div className="container main-area-container">

                <h1>Dokumentation</h1>

                <div className="documentation">
                    {Object.keys(files).map((category, index) => {
                        return (
                            <div key={index} className="category mb-4">
                                <h2>{categoryOptions[category as keyof typeof categoryOptions]}</h2>
                                <div className="files">
                                    {files[category].map((file: any, index: number) => {
                                        return (
                                            <div key={index} className="file">
                                                {(file.type === "application/jpg" || file.type === "application/jpeg" || file.type === "application/png") ? <img src={"/api/file/images/" + file.name} alt={file.name} /> : <></>}
                                                {file.type === "application/pdf" ? <a href={"/api/file/pdfs/" + file.name} target="_blank">{file.title}</a> : <></>}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </BaseLayoutUser>
    );
};

const mapStateToProps = (state: RootState) => ({
    user: state.user
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Documentation);