import React, {ChangeEvent, ChangeEventHandler, EventHandler, MouseEventHandler, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faSortDown, faSortUp, faTrash} from '@fortawesome/free-solid-svg-icons';
import "./list.scss";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Table} from "react-bootstrap";
import Moment from "react-moment";
import {NavLink, useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import {v4 as uuidv4} from 'uuid';
import {bindActionCreators} from "redux";
import {connect, ConnectedProps} from "react-redux";
import instance from "../../util/api";
import {AppDispatch} from "../../store";
import {select} from "../../state/reducer/admin/userSlice";

const classNames = require('classnames');

export const ListType = {
    STRING: "string",
    DATE: "date",
    HTML: "string",
    IMAGE: "image",
    IMAGE_TEXT: "image_text"
};

interface IListField {
    value: any,
    classes: any,
    type: string,
    onClick: MouseEventHandler<HTMLElement>
}
const ListField : React.FC<IListField> = ({value, classes, type, onClick}) => {
    switch(type){
        case ListType.STRING:
        case ListType.HTML:
            return <td className={classes} onClick={onClick}>{value}</td>
        case ListType.DATE:
            const date = new Date(value);
            return <td className={classes} onClick={onClick}><Moment format="DD.MM.YYYY" date={date}/></td>
        case ListType.IMAGE:
            return <td className={classes} onClick={onClick}><div className="img-holder">{value ? <img src={value} alt="Image" /> : ""}</div></td>
        case ListType.IMAGE_TEXT:
            return <td className={classes} onClick={onClick}>
                <div className="d-flex align-items-center">
                    <div className="img-holder">
                        {(value && value.img) ? <img src={value.img} alt="Image" /> : ""}
                    </div>
                    <p className="p-0 m-0 pl-1">{value.text}</p>
                </div>
            </td>
        default:
            return <td></td>
    }
};

interface IList extends PropsFromRedux{
    data: any[],
    headers: any[],
    history?: any,
    noEdit: boolean,
    type: string,
    callback?: Function
}

const List : React.FC<IList> = (props) => {
    const [selectedData, selectData] = useState<any[]>([]);
    const [sortRight, setSortRight] = useState<any[]>([]);
    const [initLoad, setInitLoad] = useState<boolean>(true);
    const [modal, setModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<any | null>(null);

    const history = useHistory();

    const {headers, data, noEdit, selectUserList, callback} = props;

    const classes = classNames("border-bottom", noEdit ? "clickable" : "");

    useEffect(() => {
        setSortRight(headers.map(() => 0));
    }, [])

    useEffect(() => {
        if (initLoad){
            const listData = data.map(d => {
                d.select = false;
                return d;
            })
            selectUserList([]);
            selectData(listData);
            setInitLoad(false);
        } else {
            selectData(data);
        }
    }, [data]);

    useEffect(() => {
        if (props.type === "user"){
            //console.log(selectedData);
            selectUserList(selectedData.filter(data => data.select).map(data => ({id: data.id, select: data.select, name: data.data[1].val})));
        }
        if(callback){
            callback(selectedData);
        }

    }, [selectedData])


    const onClick = (id : number, route : string) => {
        history.push({pathname: route,
        state: {
            id: id
        }});
    };

    const onSelectAll = (event : ChangeEvent<HTMLInputElement>) => {
        const status = event.target.checked;
        selectData(data => (
            data.map(d => {
                d.select = status
                return d;
            })
        ))
    }

    const onSelectOne = (event : ChangeEvent<HTMLInputElement>, id : number | string) => {
        const status = event.target.checked;
        selectData(data => (
            data.map(d => {
                if (id === d.id)
                    d.select = status;
                return d;
            })
        ))
    }

    const onSort = (name : string, i : number, sortDirOverride? : number) => {
        const sortDir = sortRight;

        selectData(selectedData => ([...selectedData.sort((d1, d2) => {
                    const slugFindD1 = d1.data.find((d : any) => d.slug === name);
                    const slugFindD2 = d2.data.find((d: any) => d.slug === name);

                    console.log(name,slugFindD1, slugFindD2);

                    if (slugFindD1 && slugFindD2) {
                        if (typeof slugFindD1.val === "string"){
                            slugFindD1.val = slugFindD1.val.toUpperCase();
                            slugFindD2.val = slugFindD2.val.toUpperCase();
                        }
                        if (slugFindD1.spec_val && slugFindD2.spec_val){
                            if (sortDir[i] === 1){
                                return slugFindD1.spec_val > slugFindD2.spec_val ? 1 : -1
                            } else if(sortDir[i] === -1){
                                return slugFindD1.spec_val < slugFindD2.spec_val ? 1 : -1
                            } else {
                                return 0;
                            }
                        }
                        if (sortDir[i] === 1){
                            return slugFindD1.val > slugFindD2.val ? 1 : -1
                        } else if(sortDir[i] === -1){
                            return slugFindD1.val < slugFindD2.val ? 1 : -1
                        } else {
                            return 0;
                        }
                    }
                    return 0;
                })
            ])
        );

        if (sortDir[i] === 1){
            sortDir[i] = -1;
        } else if(sortDir[i] === -1){
            sortDir[i] = 0;
        } else {
            sortDir[i] = 1;
        }

        setSortRight(sortDir);
    }

    const toggle = () => setModal(!modal);

    const onDeleteModal = (data : any) => {
        console.log(data);
        setModalData(data);
        toggle();
    }

    const onDelete = () => {
        const data = modalData;
        instance.get(`/admin/${data.delete}/delete`, {
            params: {
                id: data.id,
            }
        })
            .then(resp => {
                const newData = selectedData.filter(d => d.id !== data.id);
                selectData(newData);
                //console.log(resp);
            }).catch(err => {
              //console.log(err);
        })
        //console.log(data);
        toggle();
    }



    return (
        <div className="list-container">
            <div className="list-holder">
                <Table responsive className="table table-hover list-table">
                    <thead className="list-header">
                        <tr>
                            {headers.map((head, i) => {
                                let sortIcon = <></>;
                                if (sortRight[i] === 1)
                                    sortIcon = <FontAwesomeIcon icon={faSortDown} />

                                if (sortRight[i] === -1)
                                    sortIcon = <FontAwesomeIcon icon={faSortUp} />

                                return <th key={uuidv4()} className="sort-th" onClick={() => onSort(head.slug, i)}>
                                    {head.name} {sortIcon}
                                </th>
                            })}
                            {!noEdit && <th className="btn-th"></th>}
                            {!noEdit && <th className="btn-th"></th>}
                        </tr>
                    </thead>
                    <tbody className="list-body">
                        {selectedData.map(row => (
                            <tr className={classes} key={row.id}>
                                {row.data.map((d: any) => {
                                    const dataClass = classNames("align-middle", ...d.classes);
                                    return <ListField onClick={() => onClick(row.id, row.show)} key={uuidv4()} value={d.val} classes={dataClass} type={d.type}/>
                                })}
                                {!noEdit && <td className="align-middle btn-td"><NavLink className="btn btn-link" to={{pathname: row.edit, state: {id: row.id}}}><FontAwesomeIcon icon={faEdit} /></NavLink></td>}
                                {!noEdit && <td className="align-middle btn-td"><button className="btn btn-link" onClick={() => onDeleteModal(row)}><FontAwesomeIcon icon={faTrash} /></button></td>}
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Modal show={modal} centered={true} fade={false} onHide={toggle}>
                    <ModalHeader closeButton>{props.type === "user" ? "User" : "Event"} wirklich löschen?</ModalHeader>
                    <ModalBody>
                        Willst du {props.type === "user" ? "den User" : "das Event"} wirklich löschen?? Eine Wiederherstellung ist nicht wieder möglich!
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="primary" onClick={onDelete}>Ja</Button>{' '}
                        <Button variant="secondary" onClick={toggle}>Nein</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    );
};

const mapReducerToProp = (dispatch : AppDispatch) => {
    return bindActionCreators({
        selectUserList: select
    }, dispatch)
}


const connector = connect(null, mapReducerToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(List);
