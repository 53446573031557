import {TagModel} from "./TagModel";
import {getImage} from "../util/helper/images";

export class UserModel {
    _id?: number;
    _title?: string;
    _firstname? : string;
    _lastname? : string;
    _username? : string;
    _socialNumber? : number;
    _birthday? : string;
    _nationality? : string;
    _postcode? : number;
    _address? : string;
    _state? : string;
    _city? : string;
    _telephone? : string;
    _email? : string;
    _status? : string;
    _createdAt? : string;
    _masterId? : number;
    _images = [{name: "", url: "", type: ""}];
    _tags: any[] = [];
    _is_active? : boolean;
    _vertrag_unterschrieben: boolean = false;
    _userInfos: any[] = [];

    constructor() {
    }

    createModel(respData : any) {
        //console.log("CREATE USER", respData);
        this._id = respData.id;
        this._title = respData.title;
        this._firstname = respData.firstname;
        this._lastname = respData.lastname;
        this._username = respData.username;
        this._state = respData.state;
        this._city = respData.city;
        this._telephone = respData.telephone;
        this._email = respData.email;
        this._birthday = respData.birthday;
        this._status = respData.status;
        this._socialNumber = respData.socialNumber;
        this._postcode = respData.postcode;
        this._nationality = respData.nationality;
        this._address = respData.address;
        this._createdAt = respData.createdAt;
        this._masterId = respData.masterId;
        this._is_active = respData.is_active;
        this._vertrag_unterschrieben = respData.vertrag_unterschrieben;
        this._userInfos = respData.userInfos;

        this._images = respData.userInfos.filter((info : any) => {
            return (info.attribute === "fullscreen_image" || info.attribute === "profile_image" || info.attribute === "image");
        }).map((img : any) => {
            return {name: img.value, url: "", type: img.attribute}
        })
        this._tags = respData.tags.map((tag : any) => {
            return new TagModel(tag.id, tag.name, tag.type, tag.createdAt);
        })
    }

    createImageLink = async () => {
        for (const img of this._images) {
            img.url = await getImage(img.name);
        }
        return this;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get title() {
        return this._title;
    }

    set title(value) {
        this._title = value;
    }


    get firstname() {
        return this._firstname;
    }

    set firstname(value) {
        this._firstname = value;
    }

    get lastname() {
        return this._lastname;
    }

    set lastname(value) {
        this._lastname = value;
    }

    get username() {
        return this._username;
    }

    set username(value) {
        this._username = value;
    }

    get state() {
        return this._state;
    }

    set state(value) {
        this._state = value;
    }

    get city() {
        return this._city;
    }

    set city(value) {
        this._city = value;
    }

    get telephone() {
        return this._telephone;
    }

    set telephone(value) {
        this._telephone = value;
    }

    get email() {
        return this._email;
    }

    set email(value) {
        this._email = value;
    }


    get socialNumber() {
        return this._socialNumber;
    }

    set socialNumber(value) {
        this._socialNumber = value;
    }

    get birthday() {
        return this._birthday;
    }

    set birthday(value) {
        this._birthday = value;
    }

    get nationality() {
        return this._nationality;
    }

    set nationality(value) {
        this._nationality = value;
    }

    get postcode() {
        return this._postcode;
    }

    set postcode(value) {
        this._postcode = value;
    }

    get address() {
        return this._address;
    }

    set address(value) {
        this._address = value;
    }

    get createdAt() {
        return this._createdAt;
    }

    set createdAt(value) {
        this._createdAt = value;
    }

    get userInfos() {
        return this._userInfos;
    }

    set userInfos(value) {
        this._userInfos = value;
    }


    get masterId() {
        return this._masterId;
    }

    set masterId(value) {
        this._masterId = value;
    }

    get status() {
        return this._status;
    }

    set status(value) {
        this._status = value;
    }


    get tags() {
        return this._tags;
    }

    set tags(value) {
        this._tags = value;
    }

    get is_active() {
        return this._is_active;
    }

    set is_active(value) {
        this._is_active = value;
    }

    get vertrag_unterschrieben(): boolean {
        return this._vertrag_unterschrieben;
    }

    set vertrag_unterschrieben(value: boolean) {
        this._vertrag_unterschrieben = value;
    }

    get images() {
        return this._images;
    }

    set images(value) {
        this._images = value;
    }
}
