import React from "react";
import BaseLayoutUser from "../../layouts/BaseLayout/BaseLayoutUser";
import BackButton from "../../components/backButton/BackButton";
import EventUserShowView from "../../components/event/EventUserShowView";
import {RouteComponentProps} from "react-router-dom";

interface MatchProps {
    eventType: string,
    id: string
}

interface IEventUserShow extends RouteComponentProps<MatchProps>{

}

const EventUserShow : React.FC<IEventUserShow> = (props) => {

    return (
        <div>
            <BaseLayoutUser className="EventUserShow">
                <div className="button-header-row d-flex justify-content-between">
                    <BackButton />
                </div>
                <EventUserShowView id={props.match.params.id} eventType={props.match.params.eventType}  />
            </BaseLayoutUser>
        </div>
    );
};

export default EventUserShow;