import React, {ChangeEvent, useEffect, useState} from 'react';
import BaseLayout from "../../layouts/BaseLayout/BaseLayout";
import {connect, ConnectedProps} from "react-redux";
import {Button, Col, Container, FormGroup} from "react-bootstrap";
import {createError} from "../../util/helper/error";
import instance from "../../util/api";
import {toast} from "react-toastify";
import FusionToast from "../../components/toast/FusionToast";
import {useHistory} from "react-router-dom";
import {ADMIN_PROFILE, PROFILE} from "../../util/routes/routes";
import {RootState} from "../../store";
import {Field, Form, Formik} from "formik";

interface IUserPassword extends PropsFromRedux{

}
const UserPassword : React.FC<IUserPassword> = (props) => {
    const [form, setForm] = useState({
        password: "",
        confirm_password: ""
    });

    const [error, setError] = useState<any[]>([]);
    const [emailCheck, setEmailCheck] = useState({
        code: "",
        generateCode: null
    });

    const history = useHistory();
    const {user} = props;
    const type = user.roles.find(role => role === "ADMIN" || role === "AUTHOR") ? "ADMIN" : "USER";

    //console.log("USER DATA", user, type);

    const handleChange = (event : ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.id] : event.target.value
        })
    };

    const handleSubmit = () => {
        setError([]);
        let error = 0;

        /*if (form.password !== form.confirm_password){
            error += 1;
            setError((error : any[]) => [...error, createError("Passwörter stimmen nicht überein")])
        }*/

        if (error === 0){
            instance.post("/user/password/new", form)
                .then(resp => {
                    if (resp.data.error){
                        console.error(resp);
                        toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                    } else {
                        toast(<FusionToast className="success" header="Passwort erfolgreich geändert" message="" />);
                        history.push(type === "USER" ? PROFILE.path : ADMIN_PROFILE.path)
                    }
                }).catch(err => {
                    console.error(err);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
            })
        }
    };

    /*const handleInvalidSubmit = (event, errors, values) => {
        setError(error => [...error, createError("Einige Felder müssen noch ausgefüllt werden!")])
    }*/


    return (
        <BaseLayout className="EditUserPassword">
            <Container style={{maxWidth: 500}}>
                <h1>Passwort neu vergeben</h1>
                {error.map(error => (error.error ? <div className="alert alert-danger" role="alert">
                    {error.message}
                </div> : ""))}
                <Formik
                    initialValues={form}
                    id="RegisterForm"
                    onSubmit={handleSubmit}
                    //onInvalidSubmit={handleInvalidSubmit}
                >
                    <Form>
                        <FormGroup>
                            <Field className="form-control" type="password" name="password" required value={form.password} id="password" placeholder="Passwort" onChange={handleChange} />
                        </FormGroup>
                        {/*<FormGroup>
                            <Field className="form-control" type="password" name="confirm_password" required value={form.confirm_password} id="confirm_password" placeholder="Passwort wiederholen" onChange={handleChange} />
                        </FormGroup>*/}
                        <div className="text-center">
                            <Button variant="primary">Passwort ändern</Button>
                        </div>
                    </Form>
                </Formik>
            </Container>
        </BaseLayout>
    );
};

const mapStateToProps = (state : RootState) => ({
    user: state.user
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UserPassword);
