import React, {useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import instance from "../../util/api";
import {toast} from "react-toastify";
import FusionToast from "../../components/toast/FusionToast";

const ConfigurationMain = () => {


    const onStartMigration = () => {
        instance.get("/admin/start-migration")
            .then(resp => {
                if (!resp.data.error){
                    toast(<FusionToast className="success" header="Aktion war erfolgreich" message="Alle Daten übertragen" />);
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
            })
    }

    const onSendNewWelcomeEmail = () => {
        instance.get("/admin/email-migration")
            .then(resp => {
                if (!resp.data.error){
                    toast(<FusionToast className="success" header="Aktion war erfolgreich" message="E-Mails wurden versendet" />);
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
            })
    }

    const onSendProofEmail = () => {
        instance.get("/admin/user/email/proof")
            .then(resp => {
                if (!resp.data.error){
                    toast(<FusionToast className="success" header="Aktion war erfolgreich" message="E-Mails wurden versendet" />);
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
            })
    }

    return (
        <div>
            <p>Configuration Main</p>

            <Row>
                <Col md={3}>
                    <Button variant="primary" onClick={onStartMigration}>Übertrage Daten</Button>
                </Col>
                <Col md={3}>
                    <Button variant="primary" onClick={onSendNewWelcomeEmail}>Sende willkommens Email</Button>
                </Col>
                <Col md={3}>
                    <Button variant="primary" onClick={onSendProofEmail}>Sende überprüfungs Email</Button>
                </Col>
            </Row>

        </div>
    );
};

export default ConfigurationMain;
