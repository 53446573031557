import {createEpicMiddleware} from 'redux-observable';
import thunk from 'redux-thunk';
import localforage from 'localforage';
import rootReducer from './state';
import {persistReducer, persistStore} from 'redux-persist';
import {Middleware} from "redux";
import {authenticationMiddleware} from "./util/api/middleware/authentication";
import {messageMiddleware} from "./util/api/middleware/message";
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import {configureStore} from "@reduxjs/toolkit";
import {rootEpics} from "./state/epics";

const epicMiddleware = createEpicMiddleware();
const middleware = [];

middleware.push(epicMiddleware);

middleware.push(authenticationMiddleware);
middleware.push(messageMiddleware);
middleware.push(thunk);

/*const multiActionMiddelware = store => next => action => {
    if (!Array.isArray(action)) return next(action);
    return action.map(a => store.dispatch(a));
};*/

const multiActionMiddelware: Middleware<
    {},
    PersistRootState
    > = (store) => (next) => (action) => {
    if (!Array.isArray(action)) return next(action);
    return action.map(a => store.dispatch(a));
}

middleware.push(multiActionMiddelware);

const persistConfig = {
    key: 'root',
    storage: localforage,
    debug: true,
    stateReconciler: autoMergeLevel2
};

const pReduce = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer);


const newStore = configureStore({
    reducer: pReduce,
    middleware: [...middleware],
    devTools: process.env.NODE_ENV !== "production"
})

epicMiddleware.run(rootEpics);

export const store = newStore;
export const persistor = persistStore(store);
export type PersistRootState = ReturnType<typeof pReduce>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;