import React from 'react';
import Moment from "react-moment";
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../store";

interface IHeader extends PropsFromRedux{

}
const Header : React.FC<IHeader> = (props) => {
    return (
        <div className="fusion-header">
            <div className="fusion-user-header">
                <h5>Willkommen, <span className="username">{props.user.firstname} {props.user.lastname}</span></h5>
                <h6 className="date"><Moment date={new Date()} format="DD.MM.YYYY" /></h6>
            </div>
        </div>
    );
};


const mapStateToProps = (state : RootState) => ({
    user: state.user
})

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Header);
