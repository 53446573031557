import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'react-bootstrap';
import PropTypes from "prop-types";

interface IEmailPreviewModal {
    modal: any,
    toggle: () => void,
    className?: any,
    email: any,
    onSubmitToServer(eventToServer: any): void
}
const EmailPreviewModal : React.FC<IEmailPreviewModal> = (props) => {
    const {
        modal,
        toggle,
        className,
        email,
        onSubmitToServer
    } = props;

    const onSubmit = () => {
        toggle();
        onSubmitToServer(email.eventToServer)
    }

    return (
        <Modal show={modal} fade={false} size="lg" onHide={toggle} className={className} centered={true}>
            <ModalHeader closeButton>E-Mail Vorschau</ModalHeader>
            <ModalBody>
                <div className="email-content-header">
                    <h3 className="email-content-subject">{email.subject}</h3>
                    <div className="email-content-time">{}</div>
                    <div className="email-content-from">Von {email.from}</div>
                </div>
                <div className="email-content-message" dangerouslySetInnerHTML={{__html: email.html}} />
            </ModalBody>
            <ModalFooter>
                <Button variant="secondary" onClick={toggle}>Bearbeiten</Button>{' '}
                <Button variant="primary" onClick={onSubmit}>Absenden</Button>
            </ModalFooter>
        </Modal>
    );
};

export default EmailPreviewModal;

EmailPreviewModal.propTypes = {
  className: PropTypes.any,
  email: PropTypes.object.isRequired,
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
}
