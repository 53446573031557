import React, {FC, KeyboardEventHandler, MouseEventHandler} from "react";
import {useHistory} from "react-router-dom";
import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import classNames from "classnames";
import {EventUserStatus} from "../../../util/helper/eventHelper";
import {ICalendarEvent, IEvent} from "../../../model/interfaces/event/IEvent";
import {EventInput} from "@fullcalendar/core";
import {boolean} from "yup";
import EventDetailView from "../../event/EventDetailView";
import EventUserShowView from "../../event/EventUserShowView";

interface IEventModalUser {
    modal: boolean,
    toggle: () => void,
    event: IEvent,
    changeEvent?: (props: any) => void
}
const EventModalUser : FC<IEventModalUser> = (props) => {
    const {modal, toggle, event, changeEvent} = props;

    return (
        <Modal
            dialogClassName="modal-90w"
            show={modal} fade={false} onHide={toggle} className="modal-dialog-centered event-modal">
            <ModalHeader className="background-black" data-bs-theme="dark"
                closeButton>
            </ModalHeader>
            <ModalBody>
                <EventUserShowView eventType={event.type} id={event.id.toString()} changeEvent={changeEvent} />
            </ModalBody>
        </Modal>
    )
}

export default EventModalUser;