import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import {Button, ButtonGroup, Card, CardBody, CardHeader, Col, Collapse, Form, FormGroup, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import EventNewInsert from "../event_new/EventNewInsert";
import BaseLayout from "../../../../../layouts/BaseLayout/BaseLayout";
import {v4} from "uuid";
import {RouteComponentProps, useLocation} from "react-router-dom";
import instance from "../../../../../util/api";
import {number} from "prop-types";
import moment from "moment/moment";
import {toast} from "react-toastify";
import FusionToast from "../../../../../components/toast/FusionToast";
import {ADMIN_EVENT} from "../../../../../util/routes/routes";
import {AppDispatch, RootState} from "../../../../../store";
import {bindActionCreators} from "redux";
import userList from "../../../../../state/reducer/admin/userSlice";
import {connect, ConnectedProps} from "react-redux";
import EventNewEdit from "../event_new/EventNewEdit";


const eventInit = {
    "title": "",
    "content": "",
    "type": "",
    "events": []
};

const singleEventInit = {
    "id": "",
    "title": "Event Title",
    "content": "",
    "emailText": "",
    "sumEmail": false,
    "mailDate" : "",
    "type": "EVENT",
    "open": false,
    "customer": null,
    "infos": []
}


interface MatchProps {
    eventType: string,
    id: string
}
interface IEventEdit extends PropsFromRedux, RouteComponentProps<MatchProps> {

}
const SerialEventNewEdit : React.FC<IEventEdit> = (props) => {
    const [eventNew, setEventNew] = useState<any>(eventInit);
    const [type, setType] = useState<string>("event");
    const [customer, setCustomer] = useState<any | null>(null);
    const [events, setEvents] = useState<any>([{...singleEventInit, id: v4()}]);

    const location = useLocation();

    const loadEvent = (id : number) => {
        instance.get(`/admin/serial_event/one`, {
            params: {
                type: props.match.params.eventType.toUpperCase(),
                id: id
            }
        })
            .then(async resp => {
                //console.log(resp);
                if (!resp.data.error){
                    //console.log(resp.data);
                    const events = [] as any[];

                    resp.data.event.Events.forEach((event: any, id : number) => {
                        const eventSections = [] as any[];
                        event.eventSections.forEach((eventInfo : any, id : number) => {
                            const info = {
                                id: eventInfo.id,
                                timeStart: eventInfo.timeStart,
                                timeEnd: eventInfo.timeEnd,
                                eventTitle: eventInfo.title,
                                person: eventInfo.person,
                                gender: eventInfo.gender
                            }

                            const foundInfo = eventSections.find(ev => ev.date.isSame(eventInfo.date));

                            if (foundInfo){
                                foundInfo.times.push(info);
                            } else {
                                const main = {
                                    id: id,
                                    date: moment(eventInfo.date),
                                    place: eventInfo.place,
                                    meeting_time: eventInfo.meeting_time,
                                    starting_time: eventInfo.starting_time,
                                    times: [info]
                                }
                                eventSections.push(main);
                            }
                        })

                        event.infos = [...eventSections.map(ev => ({
                            ...ev,
                            date: ev.date.toDate()
                        }))];
                        events.push(event);
                    })

                    setEvents(events)

                    /*resp.data.event.eventSections.forEach((eventInfo, id) => {
                        const info = {
                            id: eventInfo.id,
                            timeStart: eventInfo.timeStart,
                            timeEnd: eventInfo.timeEnd,
                            eventTitle: eventInfo.title,
                            person: eventInfo.person,
                            gender: eventInfo.gender
                        }

                        const foundInfo = eventSections.find(ev => ev.date.isSame(eventInfo.date));

                        if (foundInfo){
                            foundInfo.times.push(info);
                        } else {
                            const main = {
                                id: id,
                                date: moment(eventInfo.date),
                                place: eventInfo.place,
                                times: [info]
                            }
                            eventSections.push(main);
                        }
                    })

                    setEventInfos([...eventSections.map(ev => ({
                        ...ev,
                        date: ev.date.toDate()
                    }))]);*/
                    const loadedEvent = {...resp.data.event};
                    setEventNew((event : any) => ({...event, ...loadedEvent}));
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
            }).catch((err) => {
            console.error(err);
        });
    }

    useEffect(() => {

        if (props.match.params.id) {
            loadEvent(parseInt(props.match.params.id));
        }

    }, [])

    const onUpdateEvent = (ev : ChangeEvent<HTMLInputElement>) => {
        if (ev instanceof Date){
            setEventNew({...eventNew, mailDate: ev})
        } else {
            if (ev.target.type === "checkbox"){
                setEventNew({...eventNew, [ev.target.name]: ev.target.checked})
            } else {
                setEventNew({...eventNew, [ev.target.name]: ev.target.value})
            }
        }
    };

    const onChange = (id : number, eventSections : any, eventNew : any) => {
        console.log("SOMETHING CHANGED", eventSections, eventNew);
        eventNew.infos = eventSections;
        eventNew.id = id;

        setEvents((events : any[]) => [...events.map(event => {
            if (event.id === id){
                return eventNew;
            } else {
                return event;
            }
        })])

    }

    const onSave = (e : SyntheticEvent<any>) => {
        e.preventDefault();

        const eventToSend = {...eventNew};
        eventToSend.events = [...events];

        if (customer) {
            eventToSend.customer = customer;
        }

        console.log(eventToSend);

        instance.put("/admin/serial_event", eventToSend)
            .then(resp => {
                console.log(resp);
                props.history.push(ADMIN_EVENT.path.replace(":eventType", type));
            }).catch(err => {
            console.error(err);
        })
    }

    const onAddNewDate = () => {
        const newEvent = {...singleEventInit};
        newEvent.id = v4();
        newEvent.title = "Neuer Event Title";
        setEvents((events : any[]) => [...events, newEvent]);
    }

    const onCopy = (e : SyntheticEvent<any>, id : number) => {
        e.stopPropagation();
        e.preventDefault();

        const newEvent = events.filter((event : any) => event.id === id);
        if (newEvent.length > 0){
            const event = newEvent[0];
            setEvents((events: any[]) => [...events, {...event, title: "Copy: " + event.title, id: v4()}]);

        }
    }

    const onDelete = (e : SyntheticEvent<any>, id : number) => {
        e.stopPropagation();
        e.preventDefault();

        setEvents((events : any[]) => [...events.filter(event => event.id !== id)])
    }

    const toggle = (id : number) => setEvents((events : any[]) => [...events.map(event => {
        if (event.id === id){
            event.open = !event.open;
        } else {
            event.open = false;
        };
        return event;
    })])


    return (
        <BaseLayout className="EventNew white">
            <div className="event-create-container">
                <header>
                    <h3>{type === "event" ? "Neues Serienevent" : "Neue Serienlogistik"} anlegen</h3>
                </header>
                <Form className="event-form">
                    <div className="event-main-content">
                        <FormGroup>
                            <Form.Control type="text" name="title" id="title" required value={eventNew.title} onChange={onUpdateEvent} placeholder="Titel" />
                        </FormGroup>
                        <FormGroup>
                            <Form.Control type="textarea" name="content" id="content" value={eventNew.content} onChange={onUpdateEvent} placeholder="Beschreibung" />
                        </FormGroup>
                    </div>

                    <div className="text-right mb-5">
                        <Button type="submit" onClick={onSave}>Speichern</Button>
                    </div>

                    {events.map((event : any) => (
                        <Card key={"event_card_" + event.id}>
                            <CardHeader className="clickable" onClick={() => toggle(event.id)}>
                                <Row>
                                    <Col xs={10}>
                                        Titel: {event.title}
                                    </Col>
                                    <Col xs={2}>
                                        <ButtonGroup>
                                            <button className="btn btn-link" onClick={(e) => onCopy(e, event.id)}><FontAwesomeIcon icon={faCopy} /></button>
                                            <button className="btn btn-link" onClick={(e) => onDelete(e, event.id)}><FontAwesomeIcon icon={faTrash} /></button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Collapse in={event.open}>
                                <CardBody>
                                    <EventNewEdit event={event} onChange={onChange} match={props.match} location={location} history={props.history} />
                                </CardBody>
                            </Collapse>
                        </Card>
                    ))}

                    {/*<div className="event-main-content">
                            <Select
                                defaultValue={[]}
                                isMulti
                                name="events"
                                options={events}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(d) => setEventNew(event => ({
                                    ...event,
                                    events: d
                                }))}
                            />
                        </div>*/}

                    <div className="text-right mt-5">
                        <ButtonGroup>
                            <Button variant="primary" className="event-date-add" onClick={onAddNewDate}><FontAwesomeIcon icon={faPlus}/> Neues Event</Button>
                            <Button type="submit" onClick={onSave}>Speichern</Button>
                        </ButtonGroup>
                    </div>
                </Form>
            </div>
        </BaseLayout>
    );
};

const mapDispatchToPorps = (dispatch: AppDispatch) => {
    return bindActionCreators({
        selectUserList: userList
    }, dispatch)
}

const mapStateToProps = (state : RootState) => ({
    userData: state.user,
    userList: state.userList
})



const connector = connect(mapStateToProps, mapDispatchToPorps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SerialEventNewEdit);