export type TRoutes = {
    path: string,
    name: string,
    sympath?: string
}

// ADMIN ROUTES
export const ADMIN = {path: "/admin", name: "Dashboard"};
export const ADMIN_LOGIN = {path: ADMIN.path + "/login", name: "Login"};
export const ADMIN_PROFILE = {path: ADMIN.path + "/profile", name: "Profile"};

export const ADMIN_PASSWORD = {path: ADMIN.path + "/profile/password", name: "Passwort ändern"}

export const ADMIN_PROMOTER = {path: ADMIN.path + "/user/:type", sympath: ADMIN.path + "/user/promoters", name: "Promoter Liste"};
export const ADMIN_LOGISTIC = {path: ADMIN.path + "/user/:type", sympath: ADMIN.path + "/user/logistics", name: "Logistiker Liste"};
export const ADMIN_REGISTERED = {path: ADMIN.path + "/user/:type", sympath: ADMIN.path + "/user/registries", name: "Neue Registrierungen"};

export const ADMIN_NEW_PROMOTER = {path: ADMIN.path + "/promoters/new", name: "Neuer Promoter"};
export const ADMIN_EDIT_PROMOTER = {path: ADMIN.path + "/promoters/edit/:id", name: "Promoter bearbeiten"}
export const ADMIN_NEW_LOGISTIC = {path: ADMIN.path + "/logistics/new", name: "Neuer Logistiker"};
export const ADMIN_EDIT_LOGISTIC = {path: ADMIN.path + "/logistics/edit/:id", name: "Logistiker bearbeiten"};

export const ADMIN_EVENT = {path: ADMIN.path + "/event/:eventType", sympath: ADMIN.path + "/event/event", name: "Event Liste"};
export const ADMIN_EVENT_ARCHIVE = {path: ADMIN.path + "/event/:eventType/archive/:archiveType", sympath: ADMIN.path + "/event/event/archive", name: "Event Archiv"}
export const ADMIN_EVENT_FINISHED = {path: ADMIN.path + "/event/:eventType/archive/:archiveType", sympath: ADMIN.path + "/event/event/ended", name: "Event beendet"}
export const ADMIN_EVENT_DELETED = {path: ADMIN.path + "/event/:eventType/deleted", sympath: ADMIN.path + "/event/event/deleted", name: "Gelöschte Events"}

export const ADMIN_LOGISTIC_EVENT = {path: ADMIN.path + "/event/:eventType", sympath: ADMIN.path + "/event/logistic", name: "Logistik Event Liste"};
export const ADMIN_LOGISTIC_EVENT_ARCHIVE = {path: ADMIN.path + "/event/:eventType/archive/:archiveType", sympath: ADMIN.path + "/event/logistic/archive", name: "Logistik Event Archiv"}
export const ADMIN_LOGISTIC_EVENT_FINISHED = {path: ADMIN.path + "/event/:eventType/archive/:archiveType", sympath: ADMIN.path + "/event/logistic/ended", name: "Logistik Event beendet"}
export const ADMIN_LOGISTIC_EVENT_DELETED = {path: ADMIN.path + "/event/:eventType/deleted", sympath: ADMIN.path + "/event/event/deleted", name: "Gelöschte Logistik"}

export const ADMIN_EVENT_LOGISTIC_CREATE = {path: ADMIN.path + "/event/editor/:eventType/new", sympath: ADMIN.path + "/event", name: "Erstellen"};
export const ADMIN_SERIAL_EVENT_LOGISTIC_CREATE = {path: ADMIN.path + "/serialevent/editor/:eventType/new", sympath: ADMIN.path + "/serialevent", name: "Erstellen"};
export const ADMIN_EVENT_LOGISTIC_CREATE_EDIT = {path: ADMIN.path + "/editor/:eventType/new", sympath: ADMIN.path + "/event", name: "Erstellen"};


export const ADMIN_EVENT_LOGISTIC_SHOW = {path: ADMIN.path + "/event/editor/:eventType/show/:id", name: "Bearbeiten"};
export const ADMIN_EVENT_LOGISTIC_EDIT  = {path: ADMIN.path + "/event/editor/:eventType/edit/:id", name: "Bearbeiten"};
export const ADMIN_SERIAL_EVENT_LOGISTIC_SHOW = {path: ADMIN.path + "/serialevent/editor/:eventType/show/:id", name: "Bearbeiten"};
export const ADMIN_SERIAL_EVENT_LOGISTIC_EDIT = {path: ADMIN.path + "/serialevent/editor/:eventType/edit/:id", name: "Bearbeiten"};

export const ADMIN_MAIL = {path: ADMIN.path + "/mailing", name: "E-Mail System"};
export const ADMIN_CONFIGURATION = {path: ADMIN.path + "/config", name: "E-Mail System"};
export const ADMIN_CONFIGURATION_USER_ATTR = {path: ADMIN.path + "/config/user/attr", name: "E-Mail System"};

export const ADMIN_CUSTOMER = {path: ADMIN.path + "/customers", name: "Kunden"};
export const ADMIN_CUSTOMER_SHOW_EVENTS = {path: ADMIN.path + "/customers/:customer", name: "Kunden Events"};

export const ADMIN_DOCUMENTATION = {path: ADMIN.path + "/documentation", name: "Dokumentation"};


// USER ROUTES
export const HOME = {path: "/", name: "Dashboard"};
export const CALENDAR = {path: "/calendar", name: "Mein Kalender"};
export const LOGIN = "/login";
export const REGISTER = "/register";
export const REGISTER_STEP_TWO = "/register/second";
export const REGISTER_COMPLETE = "/register/complete";

export const PROFILE = {path: "/profile", name: "Profile"};
export const EVENT_LIST = {path: "/list/:eventType", sympath: "/list/event", name: "Event Liste"};
export const EVENT_ARCHIVE = {path: "/list/:eventType/archive", sympath: "/list/event/archive", name: "Event Archiv"};

export const LOGISTIC_LIST = {path: "/list/:eventType", sympath: "/list/logistics", name: "Logistik Liste"};
export const LOGISTIC_ARCHIVE = {path: "/list/:eventType/archive", sympath: "/list/logistics/archive", name: "Logistik Archiv"};

export const USER_EVENT_LOGISTIC_DETAIL = {path: "/event/:eventType/detail/:id", sympath: "/event/detail", name: "Details"};
export const USER_DOCUMENTATION = {path: "/documentation", sympath: "/documentation", name: "Dokumentation"};

export const USER_PASSWORD = {path: "/profile/password", name: "Passwort ändern"}
export const RESET_PASSWORD = {path: "/password/reset", name: "Passwort ändern"}
export const RESET_PASSWORD_TOKEN = {path: "/password/reset/:token", name: "Passwort ändern"}
export const USER_PROOF_ACCOUNT = "/user/proof"
export default [
    ADMIN,
    ADMIN_LOGIN,
    ADMIN_PROFILE,
    ADMIN_PROMOTER,
    ADMIN_LOGISTIC,
    ADMIN_REGISTERED,
    ADMIN_EVENT,
    ADMIN_LOGISTIC_EVENT,
    ADMIN_EVENT_ARCHIVE,
    ADMIN_LOGISTIC_EVENT_ARCHIVE,
    ADMIN_EDIT_LOGISTIC,
    ADMIN_EDIT_PROMOTER,
    ADMIN_NEW_PROMOTER,
    ADMIN_NEW_LOGISTIC,
    ADMIN_MAIL,
    ADMIN_CONFIGURATION,
    ADMIN_CONFIGURATION_USER_ATTR,
    HOME,
    PROFILE,
    EVENT_LIST,
    EVENT_ARCHIVE,
    LOGISTIC_LIST,
    LOGISTIC_ARCHIVE
] as TRoutes[]
