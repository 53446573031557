import React, {ChangeEvent, FormEvent, FormEventHandler, SyntheticEvent, useEffect, useState} from 'react';
import NoLayout from "../../../layouts/NoLayout/NoLayout";
import {connect, ConnectedProps} from "react-redux";
import {RouterProps, useHistory} from "react-router-dom";
import * as ROUTES from "../../../util/routes/routes";

import "./AdminLogin.scss";
import Background from "../../../assets/images/loginRegisterAdminBg.png"
import Logo from "../../../assets/images/logo_fusion_white.png";
import {ADMIN_AUTH, AUTHOR_AUTH, hasRole} from "../../../util/auth/auth";
import {RESET_PASSWORD} from "../../../util/routes/routes";
import {AppDispatch, RootState} from "../../../store";
import {bindActionCreators} from "redux";
import {userLoginRequestAction, userLogoutAction} from "../../../state/action/user/userAction";
import {Button, Form, FormGroup} from "react-bootstrap";
import TwoColumnLayout from "../../../layouts/TwoColumnLayout/TwoColumnLayout";

interface IAdminLogin extends PropsFromRedux {

}

type TForm = {
    email: string,
    password: string
}

const AdminLogin : React.FC<IAdminLogin> = (props) => {
    const [form, setForm] = useState<TForm>({
        email: "",
        password: ""
    });
    const [formInvalid, setFormInvalid] = useState({
        email: false,
        password: false
    })
    const [error, setError] = useState({error: false, message: ""});

    const history = useHistory();

    const handleChange = (event : ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.id] : event.target.value
        })
    };

    const handleSubmit = (event : FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log("FORM...", event.currentTarget)
        setFormInvalid(f => ({...f, email: (form.email === null || form.email === "")}));
        setFormInvalid(f => ({...f, password: (form.password === null || form.password === "")}));

        if (form.email && form.password){
            setFormInvalid({email: false, password: false})
            props.userLoginRequest(form.email, form.password)
        }
    };

    useEffect(() => {
        if (props.status.isAuthenticated) {
            if (hasRole(props.status, [ADMIN_AUTH, AUTHOR_AUTH])){
                history.push(ROUTES.ADMIN.path);
            } else {
                props.userLogout();
            }
        }
        if (props.status.error.error){
            setError(props.status.error);
        }
    }, [props.status]);

    return (
        <TwoColumnLayout className="Login AdminLogin" background={Background} logo={Logo}>
            <div className="login-main pb-5">
                <h1 className="text-center">Willkommen beim <br/> Fusion Promotion Dashboard (Admin Area)</h1>
                <h4></h4>
            </div>
            <div className="login-container">
                <div className={"login-header"}>
                    <h4>Login</h4>
                </div>
                <p>Nur mit Administrator-Rechten möglich!</p>
                <Form onSubmit={handleSubmit}>
                    {error.error ? <div className="alert alert-danger" role="alert">
                        {error.message}
                    </div> : ""}
                    <FormGroup className="mb-2">
                        <Form.Control type="text" name="email" value={form.email} id="email" placeholder="Email / Username" onChange={handleChange} isInvalid={formInvalid.email} />
                        <Form.Control.Feedback>Bitte gib einen Username oder Email an!</Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup className="mb-4">
                        <Form.Control type="password" name="password" value={form.password} id="password" placeholder="Passwort" onChange={handleChange} isInvalid={formInvalid.password} />
                        <Form.Control.Feedback>Bitte trage ein Password ein!</Form.Control.Feedback>
                    </FormGroup>
                    <Button className="w-100" type="submit" variant="primary">Login</Button>
                </Form>
                <Button className="small" onClick={() => history.push(RESET_PASSWORD.path)} variant="link">Passwort vergessen?</Button>
            </div>
        </TwoColumnLayout>
    );
};

const mapDispatchToPorps = (dispatch : AppDispatch) => {
    return bindActionCreators({
        userLoginRequest: userLoginRequestAction,
        userLogout: userLogoutAction
    }, dispatch)
}

const mapStateToProps = (state : RootState) => {
    return {
        status: state.user
    }
};


const connector = connect(mapStateToProps, mapDispatchToPorps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AdminLogin);