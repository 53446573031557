import {createAction} from "@reduxjs/toolkit";

const userLoginRequest = (username: string, password: string) => ({
    payload: {
        username: username,
        password: password
    }
});
const userLoginSuccess = (user: any, access_token: string, refresh_token: string) => ({
    payload: {
        user: user,
        access_token: access_token,
        refresh_token: refresh_token
    }
});
const userLoginByRefreshToken = (user: any, access_token: string, refresh_token: string) => ({
    payload: {
        user: user,
        access_token: access_token,
        refresh_token: refresh_token
    }
});

const userActivation = (type: string, succeess: boolean) => ({
    payload: {
        type: type,
        success: succeess
    }
});


export const userLoginInitAction = createAction("USER_LOGIN_INIT");
export const userLoginRequestAction = createAction("USER_LOGIN_REQUEST", userLoginRequest);
export const userLoginSuccessAction = createAction("USER_LOGIN_SUCCESS", userLoginSuccess);
export const userRefreshTokenAction = createAction("USER_REFRESH_TOKEN", userLoginByRefreshToken);
export const userLoginFailureAction = createAction<string>("USER_LOGIN_FAILURE");
export const userLogoutAction = createAction("USER_LOGOUT");

export const userActivationAction = createAction("USER_ACTIVATION", userActivation);