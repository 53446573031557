import {getReloginProcess, setInReloginProcess} from "../index";
import {refreshToken} from "../user/login";
import {store} from "../../../store";
import {AxiosInstance} from "axios";
import {userLogoutAction, userRefreshTokenAction} from "../../../state/action/user/userAction";

let subscribers: any[] = []
function onAccessTokenFetched(access_token: string) {
    subscribers = subscribers.filter(callback => callback(access_token))
}

type TAddSubscriberFunction = {
    (access_token: string) : void
}

function addSubscriber(callback: TAddSubscriberFunction) {
    subscribers.push(callback)
}

export const autoLoginInterceptor = (instance : AxiosInstance) => {
    instance.interceptors.response.use(response =>{
        return response
    }, error => {
        const { config, response } = error
        const originalRequest = config

        console.log("RESPONSE ERROR", response, getReloginProcess(), error);

        if (response && response.status === 401) {
            if (!getReloginProcess()) {
                setInReloginProcess(true);

                if (error.config.url === '/auth/refreshToken' || error.response.message === "Account is disabled.") {
                    return new Promise((resolve, reject) => {
                        reject(error);
                    })
                }

                const state = store.getState();
                console.log("STATE", state);
                if (state.user && state.user.refreshToken) {
                    console.log("START REFRESH", state.user.refreshToken);
                    refreshToken(state.user.refreshToken).then(resp => {
                        console.log("REFRESH SUCCESS", resp.data);
                        onAccessTokenFetched(resp.data.token)
                        store.dispatch(userRefreshTokenAction(resp.data.user, resp.data.token, resp.data.refreshToken));
                        setInReloginProcess(false);

                    }).catch(err => {
                        console.log("LOGOUT and NO RELOGIN", err);
                        setInReloginProcess(false);
                        store.dispatch(userLogoutAction());
                    })
                } else {
                    setInReloginProcess(false);
                    store.dispatch(userLogoutAction());
                }
            }

            return new Promise((resolve) => {
                addSubscriber(access_token => {
                    originalRequest.headers.Authorization = 'Bearer ' + access_token
                    resolve(instance(originalRequest))
                })
            })
        }

        return new Promise((resolve, reject) => {
            reject(error);
        });

    })
}
