import React, {FC, ReactElement, ReactNode, useEffect, useState} from 'react';
import {Button, Collapse, Container, Dropdown, Nav, NavItem, NavLink as BNavLink} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect, ConnectedProps} from "react-redux";
import ROUTES, {
    ADMIN,
    ADMIN_CONFIGURATION, ADMIN_CUSTOMER, ADMIN_DOCUMENTATION,
    ADMIN_EVENT,
    ADMIN_EVENT_ARCHIVE, ADMIN_EVENT_DELETED, ADMIN_EVENT_FINISHED,
    ADMIN_EVENT_LOGISTIC_CREATE, ADMIN_EVENT_LOGISTIC_CREATE_EDIT,
    ADMIN_LOGISTIC,
    ADMIN_LOGISTIC_EVENT, ADMIN_LOGISTIC_EVENT_ARCHIVE, ADMIN_LOGISTIC_EVENT_DELETED, ADMIN_LOGISTIC_EVENT_FINISHED,
    ADMIN_MAIL,
    ADMIN_PROMOTER,
} from "../../util/routes/routes";
import "./BaseLayout.scss";

import {NavLink, useLocation} from "react-router-dom";
import TopMenu from "../../components/topMenu/TopMenu";
import {RootState} from "../../store";
import {userLogoutAction} from "../../state/action/user/userAction";
import LOGO from "../../assets/images/logo_fusion_white.png";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const classNames = require('classnames');

interface IBaseLayout extends PropsFromRedux {
    className?: any,
    header?: React.ReactNode,
    sidebar?: ReactNode
}

const BaseLayout : FC<IBaseLayout> = (props) => {
    const [navLinkOpen, setNavLinkOpen] = useState({promoter: false, delivery: false, event: false, delEvent: false})
    const [isEventOpen, setEventIsOpen] = useState(false);
    const [isLogisticOpen, setLogisticIsOpen] = useState(false);
    const [isPromotorOpen, setPromotorOpen] = useState(false);

    const toggleEvent = () => setEventIsOpen(!isEventOpen);
    const toggleLogistic = () => setLogisticIsOpen(!isLogisticOpen);
    const togglePromotor = () => setPromotorOpen(!isPromotorOpen);


    const location = useLocation();
    console.log(ROUTES, location.pathname);

    useEffect(() => {
        if (location.pathname.includes("/admin/event/event")) {
            setEventIsOpen(true);
            setLogisticIsOpen(false)
            setPromotorOpen(false);
        } else if (location.pathname.includes("/admin/event/logistic")) {
            setEventIsOpen(false);
            setLogisticIsOpen(true);
            setPromotorOpen(false);
        } else if (location.pathname.includes("/admin/user") || location.pathname.includes("/admin/documentation")) {
            setPromotorOpen(true);
            setEventIsOpen(false);
            setLogisticIsOpen(false);
        }
    }, [location.pathname]);


    const baseLayoutClassName = classNames('BaseLayout', props.className);
    const foundPath = ROUTES.find(route => (route.path === location.pathname || route.sympath === location.pathname))
    return (
        <div className={baseLayoutClassName}>
            <header className="header">
                <TopMenu type=""/>
            </header>
            <div className="main-area">
                <div className="sidebar-holder">
                    <div className="sidebar">
                        {/*<div className="icon-bar">
                            <Nav vertical>
                                <NavItem>
                                </NavItem>
                            </Nav>
                            <Nav vertical>
                                <NavItem>
                                    <NavLink activeClassName="active" className="nav-link fusion-nav-icon-link" to={ADMIN_CONFIGURATION.path}><FontAwesomeIcon icon={faCog} /></NavLink>
                                </NavItem>
                            </Nav>
                        </div>*/}
                        <div className="logo-holder">
                            <img src={LOGO} alt="LOGO" />
                        </div>
                        <div className="nav-holder">
                            {/*<h5 className="nav-bread-header"> {foundPath ? foundPath.name : ""}</h5>*/}
                            <Nav className="flex-column">
                                <NavItem>
                                    <NavLink exact activeClassName="active" className="nav-link fusion-nav-link" to={ADMIN.path}>
                                        <span className="icon">
                                            <FontAwesomeIcon icon={icon({name: 'house'})}/>
                                        </span>
                                        <span className="title">
                                            {ADMIN.name}
                                        </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <Button variant="link" className={
                                        classNames("nav-link", "fusion-nav-link", {"active": isPromotorOpen})}
                                            onClick={togglePromotor}>
                                        <span className="icon">
                                            <FontAwesomeIcon icon={icon({name: 'calendar-days'})}/>
                                        </span>
                                        <span className="title">
                                            Promotor Area
                                        </span>
                                    </Button>
                                    <Collapse in={isPromotorOpen}>
                                        <div id="event-collapse">
                                            <NavLink activeClassName="active" className="dropdown-item fusion-dropdown-link" to={ADMIN_PROMOTER.path.replace(":type", "promoters")}>
                                            <span className="icon">
                                                <FontAwesomeIcon icon={icon({name: 'arrow-turn-up'})} rotation={90}/>
                                            </span>
                                                <span className="title">
                                                {ADMIN_PROMOTER.name}
                                            </span>
                                            </NavLink>
                                            <NavLink activeClassName="active" className="dropdown-item fusion-dropdown-link" to={ADMIN_DOCUMENTATION.path}>
                                            <span className="icon">
                                                <FontAwesomeIcon icon={icon({name: 'book'})}/>
                                            </span>
                                                <span className="title">
                                                {ADMIN_DOCUMENTATION.name}
                                            </span>
                                            </NavLink>
                                        </div>
                                    </Collapse>
                                </NavItem>


                                <NavItem>
                                    <Button variant="link" className={
                                        classNames("nav-link", "fusion-nav-link", {"active": isEventOpen})}
                                            onClick={toggleEvent}>
                                        <span className="icon">
                                            <FontAwesomeIcon icon={icon({name: 'calendar-days'})}/>
                                        </span>
                                        <span className="title">
                                            Event Area
                                        </span>
                                    </Button>
                                    <Collapse in={isEventOpen}>
                                        <div id="event-collapse">
                                            <NavLink activeClassName="active" className="dropdown-item fusion-dropdown-link" to={ADMIN_EVENT.path.replace(":eventType", "event")}>
                                            <span className="icon">
                                                <FontAwesomeIcon icon={icon({name: 'arrow-turn-up'})} rotation={90}/>
                                            </span>
                                                <span className="title">
                                                {ADMIN_EVENT.name}
                                            </span>
                                            </NavLink>
                                            <NavLink activeClassName="active" className="dropdown-item fusion-dropdown-link" to={ADMIN_EVENT_LOGISTIC_CREATE_EDIT.path.replace(":eventType", "event")}>
                                            <span className="icon">
                                                <FontAwesomeIcon icon={icon({name: 'arrow-turn-up'})} rotation={90}/>
                                            </span>
                                                <span className="title">Neues Event</span>
                                            </NavLink>
                                            <NavLink activeClassName="active" className="dropdown-item fusion-dropdown-link" to={ADMIN_EVENT_FINISHED.path.replace(":eventType", "event").replace(":archiveType", "ended")}>
                                            <span className="icon">
                                                <FontAwesomeIcon icon={icon({name: 'arrow-turn-up'})} rotation={90}/>
                                            </span>
                                                <span className="title">{ADMIN_EVENT_FINISHED.name}</span>
                                            </NavLink>
                                            <NavLink activeClassName="active" className="dropdown-item fusion-dropdown-link" to={ADMIN_EVENT_ARCHIVE.path.replace(":eventType", "event").replace(":archiveType", "archive")}>
                                            <span className="icon">
                                                <FontAwesomeIcon icon={icon({name: 'arrow-turn-up'})} rotation={90}/>
                                            </span>
                                                <span className="title">{ADMIN_EVENT_ARCHIVE.name}</span>
                                            </NavLink>
                                            <NavLink activeClassName="active" className="dropdown-item fusion-dropdown-link" to={ADMIN_EVENT_DELETED.path.replace(":eventType", "event")}>
                                            <span className="icon">
                                                <FontAwesomeIcon icon={icon({name: 'arrow-turn-up'})} rotation={90}/>
                                            </span>
                                                <span className="title">{ADMIN_EVENT_DELETED.name}</span>
                                            </NavLink>
                                        </div>
                                    </Collapse>
                                </NavItem>
                                <NavItem>
                                    <NavLink activeClassName="active" className="nav-link fusion-nav-link" to={ADMIN_LOGISTIC.path.replace(":type", "logistics")}>
                                        <span className="icon">
                                                <FontAwesomeIcon icon={icon({name: 'truck'})}/>
                                            </span>
                                        <span className="title">{ADMIN_LOGISTIC.name}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <Button variant="link" className={
                                        classNames("nav-link", "fusion-nav-link", {"active": isLogisticOpen})} onClick={toggleLogistic}>
                                        <span className="icon">
                                            <FontAwesomeIcon icon={icon({name: 'warehouse'})}/>
                                        </span>
                                        <span className="title">
                                            Logistic Area
                                        </span>
                                    </Button>
                                    <Collapse in={isLogisticOpen}>
                                        <div id="logistic-collapse">
                                            <NavLink activeClassName="active" className="dropdown-item fusion-dropdown-link" to={ADMIN_LOGISTIC_EVENT.path.replace(":eventType", "logistic")}>
                                            <span className="icon">
                                                <FontAwesomeIcon icon={icon({name: 'arrow-turn-up'})} rotation={90}/>
                                            </span>
                                                <span className="title">
                                                {ADMIN_LOGISTIC_EVENT.name}
                                            </span>
                                            </NavLink>
                                            <NavLink activeClassName="active" className="dropdown-item fusion-dropdown-link" to={ADMIN_EVENT_LOGISTIC_CREATE.path.replace(":eventType", "logistic")}>
                                            <span className="icon">
                                                <FontAwesomeIcon icon={icon({name: 'arrow-turn-up'})} rotation={90}/>
                                            </span>
                                                <span className="title">
                                                Neue Logistik
                                            </span>

                                            </NavLink>
                                            <NavLink activeClassName="active" className="dropdown-item fusion-dropdown-link" to={ADMIN_EVENT_FINISHED.path.replace(":eventType", "logistic").replace(":archiveType", "ended")}>
                                            <span className="icon">
                                                <FontAwesomeIcon icon={icon({name: 'arrow-turn-up'})} rotation={90}/>
                                            </span>
                                                <span className="title">
                                                {ADMIN_LOGISTIC_EVENT_FINISHED.name}
                                            </span>
                                            </NavLink>
                                            <NavLink activeClassName="active" className="dropdown-item fusion-dropdown-link" to={ADMIN_EVENT_ARCHIVE.path.replace(":eventType", "logistic").replace(":archiveType", "archive")}>
                                            <span className="icon">
                                                <FontAwesomeIcon icon={icon({name: 'arrow-turn-up'})} rotation={90}/>
                                            </span>
                                                <span className="title">
                                                {ADMIN_LOGISTIC_EVENT_ARCHIVE.name}
                                            </span>
                                            </NavLink>
                                            <NavLink activeClassName="active" className="dropdown-item fusion-dropdown-link" to={ADMIN_LOGISTIC_EVENT_DELETED.path.replace(":eventType", "event")}>
                                            <span className="icon">
                                                <FontAwesomeIcon icon={icon({name: 'arrow-turn-up'})} rotation={90}/>
                                            </span>
                                                <span className="title">
                                                {ADMIN_LOGISTIC_EVENT_DELETED.name}
                                            </span>
                                            </NavLink>
                                        </div>
                                    </Collapse>
                                </NavItem>

                                <NavItem>
                                    <NavLink activeClassName="active" className="nav-link fusion-nav-link" to={ADMIN_CUSTOMER.path}>
                                        <span className="icon">
                                            <FontAwesomeIcon icon={icon({name: 'people-arrows'})}/>
                                        </span>
                                        <span className="title">
                                            {ADMIN_CUSTOMER.name}
                                        </span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink activeClassName="active" className="nav-link fusion-nav-link" to={ADMIN_MAIL.path}>
                                        <span className="icon">
                                            <FontAwesomeIcon icon={icon({name: 'envelope'})}/>
                                        </span>
                                        <span className="title">
                                            {ADMIN_MAIL.name}
                                        </span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>
                </div>
                <div className="main-holder">
                    {props.header ? props.header : <></>}
                    <Container className="fusion-container mt-3">
                        <div className="row">
                            {props.sidebar ? <div className="col-12 col-lg-2">
                                {props.sidebar}
                            </div> : <></>
                            }
                            <div className={classNames("col-12",{ "col-lg-10": props.sidebar})}>
                                {props.children}
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    );
};

const mapReducerToProp = {
    logout : userLogoutAction
};

const mapStateToProps = (state : RootState) =>  {
    return {user: state.user}
};



const connector = connect(mapStateToProps, mapReducerToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(BaseLayout);
