import React, {useEffect, useState} from 'react';
import {Button, Col, Container, FormGroup} from "react-bootstrap";
import {createError} from "../../util/helper/error";
import instance from "../../util/api";
import {toast} from "react-toastify";
import FusionToast from "../../components/toast/FusionToast";
import {ADMIN_PROFILE, LOGIN, PROFILE} from "../../util/routes/routes";
import NoLayout from "../../layouts/NoLayout/NoLayout";
import Background from "../../assets/images/loginRegisterBg.png";
import {useHistory, useParams} from "react-router-dom";
import {Field, Formik, Form} from "formik";
import TwoColumnLayout from "../../layouts/TwoColumnLayout/TwoColumnLayout";

const ResetPasswordToken = () => {
    const [form, setForm] = useState({
        token: "",
        password: "",
        confirm_password: ""
    });

    const [error, setError] = useState<any[]>([]);
    /*const [emailCheck, setEmailCheck] = useState({
        code: "",
        generateCode: null
    });*/

    const history = useHistory();
    const {token} = useParams() as any;

    useEffect(() => {
        setForm(form => ({...form, token: token}))
    }, [token])

    const handleChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.id] : event.target.value
        })
    };

    const handleSubmit = () => {
        setError([]);
        let error = 0;

        /*if (form.password !== form.confirm_password){
            error += 1;
            setError(error => [...error, createError("Passwörter stimmen nicht überein")])
        }*/

        if (error === 0){
            instance.post("/user/password/reset/token", form)
                .then(resp => {
                    if (resp.data.error){
                        console.error(resp);
                        toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                    } else {
                        toast(<FusionToast className="success" header="Passwort erfolgreich geändert" message="" />);
                        history.push(LOGIN)
                    }
                }).catch(err => {
                console.error(err);
                toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
            })
        }
    };

    /*const handleInvalidSubmit = (event, errors, values) => {
        setError(error => [...error, createError("Einige Felder müssen noch ausgefüllt werden!")])
    }*/


    return (
        <TwoColumnLayout className="Login" background={Background}>
            <div className="login-main pb-5">
                <h1 className="text-center">Passwort neu vergeben</h1>
            </div>
            <div className="login-container">
                {error.map(error => (error.error ? <div className="alert alert-danger" role="alert">
                    {error.message}
                </div> : ""))}
                <Formik id="RegisterForm"
                      initialValues={form}
                      onSubmit={handleSubmit}
                      //onInvalidSubmit={handleInvalidSubmit}
                >
                    <Form>
                        <FormGroup className={"mb-4"}>
                            <Field className="form-control" type="password" name="password" required value={form.password} id="password" placeholder="Passwort" onChange={handleChange} />
                        </FormGroup>
                        {/*<FormGroup>
                            <Field className="form-control" type="password" name="confirm_password" required value={form.confirm_password} id="confirm_password" placeholder="Passwort wiederholen" onChange={handleChange} />
                        </FormGroup>*/}
                        <div className="text-center">
                            <Button type="submit" variant="primary" className="w-100">Passwort ändern</Button>
                        </div>
                    </Form>
                </Formik>
            </div>
        </TwoColumnLayout>
    );
};

export default ResetPasswordToken;
