import React, {SyntheticEvent, useEffect, useState} from 'react';
import {Button, Col, FormGroup, Form, Row} from "react-bootstrap";
import instance from "../../../util/api";
import InputType from "../../../components/inputType/InputType";
import Dropzone from "../../../components/dropzone/Dropzone";
import BaseLayout from "../../../layouts/BaseLayout/BaseLayout";
import {useHistory} from "react-router-dom";
import {ADMIN_PROMOTER} from "../../../util/routes/routes";
import {IRegisterState, registerInitState} from "../../../state/reducer/user/userRegisterSlice";
import {bool} from "prop-types";
import {Formik} from "formik";

interface INewUser {

}

type TImage = {
    content: string | null,
    preview: string | null
}

type TImageState = {
    fullscreen: TImage,
    profile: TImage,
    unset: TImage[]
}

const initImages = {
    fullscreen: {
        content: null,
        preview: null
    },
    profile: {
        content: null,
        preview: null
    },
    unset: [{
        content: null,
        preview: null
    }]
} as TImageState;

type FormControlElement = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
const NewUser : React.FC<INewUser> = () => {
    const [form, setForm] = useState<IRegisterState>(registerInitState);
    //const formValid = Object.keys(registerInitState).map(key => ({[key]: false}));
    const formValid = {} as {[key : string] : boolean};
    (Object.keys(registerInitState) as (keyof IRegisterState)[]).forEach((key) => {
        formValid[key] = false;
    })

    const [attrForm, setAttrForm] = useState<any>({});
    const [attributes, setAttributes] = useState<any[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();

    const [images, setImages] = useState<TImageState>(initImages);

    const [error, setError] = useState<any[]>([]);


    useEffect(() => {
        instance.get("admin/user/attributes")
            .then(resp => {
                if (!resp.data.error){
                    setAttributes(resp.data.attr);
                    const formData = resp.data.attr.reduce((obj: any, item : any) => ({...obj, [item.slug]:""}), {});
                    setAttrForm(formData);
                    setLoaded(true);
                }
            }).catch(err => {
            //console.log(err);
        });
    }, []);

    const handleChange = (event : React.ChangeEvent<FormControlElement>) => {
        setForm({
            ...form,
            [event.target.name] : event.target.value
        })
    };

    const handleChangeAttr = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.type === "checkbox"){
            if (event.target.checked){
                setAttrForm({
                    ...attrForm,
                    [event.target.name]: [...attrForm[event.target.name], event.target.value]
                })
            } else {
                setAttrForm({
                    ...attrForm,
                    [event.target.name]: [...attrForm[event.target.name].filter((name : string) => name !== event.target.value)]
                })
            }
        } else {
            setAttrForm({
                ...attrForm,
                [event.target.name]: event.target.value
            })
        }
    };

    //console.log(attrForm);

    const handleSubmit = () => {
        setError([]);

        const formData = new FormData();
        const files = []
        Object.keys(images).forEach((key: string) => {
            if (Array.isArray(images[key as keyof TImageState])){
                (images[key as keyof TImageState] as TImage[]).forEach(image => {
                    formData.append("file", image.content || "");
                    files.push(image.content);
                })
            }
        })

        formData.append("fullscreen", images.fullscreen.content || "");
        formData.append("profile", images.profile.content || "");

        for (let [key, value] of Object.entries(form)){
            formData.append(key, value);
        }

        for (let [key, value] of Object.entries(attrForm)) {
            const keyArray = key.split(/[\[*\]*]/g)
            let attrKey = "attr";

            keyArray.forEach(k => attrKey += `[${k}]`);

            formData.append(attrKey, value as string);
        }


        instance.post('/admin/user/new', formData)
            .then(res => {
                //console.log(res);
                if (!res.data.error){
                    history.push(ADMIN_PROMOTER.path.replace(":type", "promoters"))
                }
            })
            .catch(err => console.error(err));
    };

    return (
        <BaseLayout className="white">
            <div className="layout-header">
                <h1>Neuen User anlegen</h1>
                <p>Du kannst hier einen individuellen User erstellen. Bitte beachte die Pflichtfelder!</p>
            </div>
            {loaded ?
                <Formik
                    initialValues={form}
                    onSubmit={(values, formikHelpers) => handleSubmit()}>
                    <FormGroup>
                        <Form.Control type="email" name="email" value={form.email} id="email" placeholder="E-Mail" onChange={handleChange} />
                    </FormGroup>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Form.Select name="title" value={form.title} id="title" placeholder="Title"
                                       onChange={handleChange} required isInvalid={formValid.title}>
                                    <option>Title</option>
                                    <option>Herr</option>
                                    <option>Frau</option>
                                    <option>Anderes</option>
                                </Form.Select>
                            </FormGroup>
                            <FormGroup>
                                <Form.Control type="text" name="firstname" value={form.firstname} id="firstname"
                                       placeholder="Vorname" onChange={handleChange} required isInvalid={formValid.firstname}/>
                                <Form.Control.Feedback>Bitte trage einen Vornamen ein!</Form.Control.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <Form.Control type="date" name="birthday" value={form.birthday} id="birthday"
                                       placeholder="Geburtsdatum" onChange={handleChange} required isInvalid={formValid.birthday}/>
                                <Form.Control.Feedback>Bitte trage ein Geburtsdatum ein!</Form.Control.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <Form.Control type="text" name="address" value={form.address} id="address" placeholder="Straße"
                                       onChange={handleChange} required isInvalid={formValid.address}/>
                                <Form.Control.Feedback>Bitte trage eine Adresse ein!</Form.Control.Feedback>
                            </FormGroup>
                            <FormGroup as={Row}>
                                <Col xs={4}>
                                    <Form.Control type="text" name="postcode" value={form.postcode} id="postcode" placeholder="PLZ"
                                           onChange={handleChange} required isInvalid={formValid.postcode}/>
                                    <Form.Control.Feedback>Bitte trage einen PLZ ein!</Form.Control.Feedback>

                                </Col>
                                <Col xs={8}>
                                    <Form.Control type="text" name="city" value={form.city} id="city" placeholder="Stadt"
                                           onChange={handleChange} required isInvalid={formValid.city}/>
                                    <Form.Control.Feedback>Bitte trage eine Stadt ein!</Form.Control.Feedback>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Form.Control type="text" name="nationality" value={form.nationality} id="nationality"
                                       placeholder="Staatsangehörigkeit" onChange={handleChange} required isInvalid={formValid.nationality}/>
                                <Form.Control.Feedback>Bitte trage eine Nationalität ein!</Form.Control.Feedback>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            {/*<FormGroup>
                                <Form.Control type="text" name="username" value={form.username} id="username"
                                       placeholder="Username" onChange={handleChange} required isInvalid={formValid.username}/>
                                <Form.Control.Feedback>Bitte trage einen Usernamen ein!</Form.Control.Feedback>
                            </FormGroup>*/}
                            <FormGroup>
                                <Form.Control type="text" name="lastname" value={form.lastname} id="lastname"
                                       placeholder="Nachname" onChange={handleChange} required isInvalid={formValid.lastname}/>
                                <Form.Control.Feedback>Bitte trage einen Nachnamen ein!</Form.Control.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <Form.Control type="text" name="socialNumber" value={form.socialNumber} id="socialNumber"
                                       placeholder="Sozialversicherung" onChange={handleChange} required isInvalid={formValid.socialNumber}/>
                                <Form.Control.Feedback>Bitte trage eine Sozialversicherung ein!</Form.Control.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <Form.Control type="text" name="telephone" value={form.telephone} id="telephone"
                                       placeholder="Telefonnummer" onChange={handleChange} required isInvalid={formValid.telephone}/>
                                <Form.Control.Feedback>Bitte trage eine Telefonnummer ein!</Form.Control.Feedback>
                            </FormGroup>
                            <Row>
                                <Col xs={5}><strong>User soll Zugriff haben als:</strong></Col>
                                <Col xs={7}>
                                    <Form.Check
                                        type="radio"
                                        label="Logistiker"
                                        name="registerAs"
                                        id="registerAs"
                                        value="SUPPLIER"
                                        required
                                        isInvalid={formValid.registerAs}
                                        onChange={handleChange}
                                        />
                                    <Form.Check
                                        type="radio"
                                        label="Promoter"
                                        name="registerAs"
                                        id="registerAs"
                                        value="PROMOTER"
                                        required
                                        isInvalid={formValid.registerAs}
                                        onChange={handleChange}
                                        />
                                    <Form.Check
                                        type="radio"
                                        label="Beides"
                                        name="registerAs"
                                        id="registerAs"
                                        value="SUPPLIER,PROMOTER"
                                        required
                                        isInvalid={formValid.registerAs}
                                        onChange={handleChange}
                                        />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            {attributes.map(attr => <InputType type={attr.type} data={attr} handleChange={handleChangeAttr} value={attrForm[attr.slug]} form={attrForm}  name={attr.slug} />)}
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={6}>
                                    <p><strong>Ganzkörper Foto</strong></p>
                                    <Dropzone files={images} name="fullscreen" setFiles={setImages} deleteAble={true} acceptedFiles={""}/>
                                </Col>
                                <Col md={6}>
                                    <p><strong>Profil Foto</strong></p>
                                    <Dropzone files={images} name="profile" setFiles={setImages} deleteAble={true} acceptedFiles={""}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <p><strong>Weitere Fotos hinzufügen? (Mehrere per Drag&Drop möglich!)</strong></p>
                                    <Dropzone files={images} name="unset" setFiles={setImages} deleteAble={true} acceptedFiles={""} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Button variant="primary">User anlegen</Button>
                </Formik> : <div>Loading Data</div>
            }
        </BaseLayout>
    );
};

export default NewUser;
