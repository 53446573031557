import React, {useEffect, useReducer} from 'react';
import "./Dashboard.scss";
import instance from "../../../util/api"
import {
    ADMIN_EVENT_LOGISTIC_EDIT,
    ADMIN_EVENT_LOGISTIC_SHOW
} from "../../../util/routes/routes";
import BaseLayout from "../../../layouts/BaseLayout/BaseLayout";
import Loading from "../../../components/loading/Loading";
import Calendar from "../../../components/calendar/Calendar";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import FusionToast from "../../../components/toast/FusionToast";
import {statusRanking} from "../../../util/helper/eventHelper";

const LOAD_EVENTS = "LOAD_EVENTS"
const SET_BILLABLE_EVENTS = "SET_BILLABLE_EVENTS"

const initialState = {
    done: true,
    loading: true,
    events: [],
    billableEvents: []
}


type State = {
    done: boolean,
    loading: boolean,
    events: any[],
    billableEvents: any[]
}

type Action =
    | { type: "SET_BILLABLE_EVENTS", billableEvents: any[]}
    | { type: "LOAD_EVENTS", events: any[]}

const reducer = (state : State, action : Action) => {
    switch (action.type){
        case SET_BILLABLE_EVENTS:
            return {
                ...state,
                billableEvents: action.billableEvents
            }
        case LOAD_EVENTS:
            return {
                ...state,
                loading: false,
                done: true,
                events: action.events
            }
        default:
            return state
    }
}
const Dashboard = () => {
    const history = useHistory();

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        instance.get("admin/tasks/event").then(resp => {
            const events = []
            if (!resp.data.error) {

                console.log("EVENTS", resp.data.events)
                events.push(...resp.data.events.map((event: any) => ({
                    ...event,
                    show: true,
                    statusEvent: (event.status !== "completed" && event.eventSections) ? event.eventSections.map((info: any) => info.status).sort((a: any, b: any) => statusRanking[a as keyof typeof statusRanking] - statusRanking[b as keyof typeof statusRanking]).reverse()[0] : event.status,
                    link: ADMIN_EVENT_LOGISTIC_SHOW.path.replace(":id", event.id).replace(":eventType", event.type.toLowerCase()),
                    editLink: ADMIN_EVENT_LOGISTIC_EDIT.path.replace(":id", event.id).replace(":eventType", event.type.toLowerCase())
                })));

                //console.log("ALL EVENTS", events)

                //console.log(resp.data.billableEvents);
                if (resp.data.billableEvents && resp.data.billableEvents.length > 0){
                    //setBillableEvents(resp.data.billableEvents);
                    dispatch({
                        type: SET_BILLABLE_EVENTS,
                        billableEvents: resp.data.billableEvents,
                    })
                }
            } else {
                console.error(resp);
                toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
            }

            dispatch({
                type: LOAD_EVENTS,
                events: events,
            })

            /*setEvents(events);

            setLoading(false);
            setTimeout(() => {
                setDone(true);
            }, 1000);*/
        }).catch(err => {
            console.error(err);
        })
    }, []);

    console.log("LOADED EVENTS", state.events)

    return (
        <BaseLayout>
            {!state.done ?
                <Loading loading={state.loading} />
                :
                <div>
                    {/*<div className="row justify-content-between align-items-center">
                        <div className="col-md-7 col-12">
                            <h1 className="text-uppercase mt-5 txt-sb">Kalender</h1>
                            <p>Du hast {state.events.length} Event(s) zu betreuen!</p>
                        </div>
                    </div>*/}
                    <Calendar events={state.events} billableEvents={state.billableEvents} type={''}
                              isUserCalendar={false} />
                </div>
            }
        </BaseLayout>
    );
};


export default Dashboard;
