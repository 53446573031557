import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from "react";
import instance from "../../../util/api";
import BaseLayout from "../../../layouts/BaseLayout/BaseLayout";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import {connect, ConnectedProps} from "react-redux";
import {eventInfoStatusRanking, EventStatus, eventStatusRanking} from "../../../util/helper/eventHelper";
import moment from "moment";
import Moment from "react-moment";
import {faBookOpen, faTrash, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EventLogisticUserInfoModal from "../../../components/modal/EventLogisticUserModal";
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {ADMIN_EVENT} from "../../../util/routes/routes";
import Dropzone from "../../../components/dropzone/Dropzone";
import BackButton from "../../../components/backButton/BackButton";
import {getImage} from "../../../util/helper/images";
import ImageLightbox from "../../../components/imageLightbox/ImageLightbox";
import EventIcons from "../../../components/icons/EventIcons";
import {useWindowSize} from "../../../util/helper/screen";
import {toast} from "react-toastify";
import FusionToast from "../../../components/toast/FusionToast";
import EventUserFeedback from "./components/EventUserFeedback";
import BriefingModal from "./components/BriefingModal";
import TodoModal from "./components/TodoModal";
import StornoModal from "./components/StornoModal";
import {RootState} from "../../../store";
import className from "classnames";
import {EVENT_SECTION_STATUS, IEvent, IEventSection} from "../../../model/interfaces/event/IEvent";
import {AxiosResponse} from "axios";
import EventDetailView from "../../../components/event/EventDetailView";

const eventTimeInit = {
    "id": 0,
    "timeStart": null,
    "timeEnd": null,
    "eventTitle": "",
    "person": ""
}

const eventInfoInit = {
    id: -1,
    date: new Date(),
    locationId: "",
    locationValue: "",
    locationLabel: "",
    locationLat: 0,
    locationLng: 0,
    starting_time: new Date(),
    ending_time: new Date(),
    title: "",
    gender: "",
    status: EVENT_SECTION_STATUS.SECTION_CREATED,
    eventInfoUsers: [],
    all_users_confirmed: false,
    EventId: -1
};

const eventInit = {
    "id": -1,
    "title": "",
    "content": "",
    "emailText": "",
    "additional_info": "",
    "type": "EVENT",
    "status": "created",
    "author": 0,
    "deleted": false,
    "eventSections": [],
    "users": [],
    "mailInfos": "",
    "is_serial": false
} as IEvent;

const feedbackTextInit = {
    "id": null,
    "title": "",
    "description": ""
}

const feedbackInit = {
    "photo" : false,
    "photoNumber" : 10,
    "text" : false
}


interface MatchProps {
    eventType: string,
    id: string
}
type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

interface EventResponse {
    event: IEvent,
    error: any
}

interface IEventShow extends PropsFromRedux, RouteComponentProps<MatchProps> {

}

const EventShow : React.FC<IEventShow> = (props) => {

    return (
        <BaseLayout>
            <EventDetailView
                id={parseInt(props.match.params.id)}
                eventType={props.match.params.eventType}
                isModal={false}
            />
        </BaseLayout>
    )
}


const mapStateToProps = (state : RootState) => ({
    userData: state.user
})

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EventShow);
