import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from "react";
import {Button, Form, InputGroup} from "react-bootstrap";
import {faBan, faCheckCircle, faPen, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IEditableField {
    value?: string | number,
    name: string,
    setValue : (name: string, value: string | number) => void
}
const EditableField : React.FC<IEditableField> = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [newValue, setNewValue] = useState<string | number>("");

    useEffect(() => {
        setNewValue(props.value || "");
    }, [props.value])

    const toggleMode = () => setEditMode(mode => !mode);

    const onChange = (e : ChangeEvent<HTMLInputElement>) => {
        setNewValue(e.target.value);
    }

    const onSave = (e : SyntheticEvent) => {
        e.preventDefault();
        props.setValue(props.name, newValue)
        toggleMode();
    }

    const onCancel = (e : SyntheticEvent) => {
        e.preventDefault();
        setNewValue(props.value || "");
        toggleMode();
    }

    return (
        <>
            {editMode ?
                <InputGroup className="editable">
                    <Form.Control name={props.name} onChange={onChange} value={newValue}  />
                    <Button variant="success">
                        <FontAwesomeIcon icon={faCheckCircle} onClick={onSave}/>
                    </Button>
                    <Button variant="danger">
                        <FontAwesomeIcon icon={faBan} onClick={onCancel}/>
                    </Button>
                </InputGroup>
            : <div className="editable">{props.children}
                    <div className="edit-button-holder">
                        <button onClick={toggleMode} className="edit-button"><FontAwesomeIcon icon={faPen}/></button>
                    </div>
            </div>}
        </>
    )
}


export default EditableField;