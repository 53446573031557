import React from 'react';

import "./NoLayout.scss";

const classNames = require('classnames');

interface INoLayout {
    className: any,
    background?: string
}
const NoLayout : React.FC<INoLayout> = (props) => {
    const noLayoutClassNames = classNames('NoLayout', props.className);

    return (
        <div className={noLayoutClassNames}>
            <div className="background-holder" style={{background: `url(${props.background})`}}>
            </div>
            <div className="fusion-container">
                {props.children}
            </div>
        </div>
    );
};

export default NoLayout;
