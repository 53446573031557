import React, {MouseEvent} from 'react';
import {ContentBlock, Editor, EditorState, RichUtils} from "draft-js";

const BLOCK_TYPES = [
    {label: 'Überschift 1', style: 'header-one'},
    {label: 'Überschift 2', style: 'header-two'},
    {label: 'Überschift 3', style: 'header-three'},
    {label: 'Überschift 4', style: 'header-four'},
    {label: 'Überschift 5', style: 'header-five'},
    {label: 'Überschift 6', style: 'header-six'},
    {label: 'Liste', style: 'unordered-list-item'},
    {label: 'Nummerrierte Liste', style: 'ordered-list-item'}
];

const INLINE_STYLES = [
    {label: 'Fett', style: 'BOLD'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Unterstrichen', style: 'UNDERLINE'}
];

interface IHtmlEditor {
    text: EditorState,
    setText(editorState: EditorState): void,
    name: string,
    placeholder: string
}

interface IBlockStyleControls {
    editorState: EditorState,
    onToggle: Function
}

interface IInlineStyleControls {
    editorState: EditorState,
    onToggle: Function
}
interface IStyleButton {
    key: string,
    active: boolean,
    label: string,
    onToggle: Function
    style: any
}
const StyleButton : React.FC<IStyleButton> = (props) => {


    const onToggle = (e : MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        props.onToggle(props.style)
    }

    let className = 'RichEditor-styleButton';
    if (props.active) {
        className += ' RichEditor-activeButton';
    }
    return (
        <span className={className} onMouseDown={onToggle}>
              {props.label}
            </span>
    )
}

const HtmlEditor : React.FC<IHtmlEditor> = (props) => {
    const {text, setText, name, placeholder} = props;

    if (typeof text === "object") {

        const BlockStyleControls : React.FC<IBlockStyleControls> = (props) => {
            const {editorState} = props;
            if (!editorState || typeof editorState.getSelection !== 'function') {
                return <></>;
            }
            const selection = editorState.getSelection();
            const blockType = editorState
                .getCurrentContent()
                .getBlockForKey(selection.getStartKey())
                .getType();

            return (
                <div className="RichEditor-controls">
                    {BLOCK_TYPES.map((type) =>
                        <StyleButton
                            key={type.label}
                            active={type.style === blockType}
                            label={type.label}
                            onToggle={props.onToggle}
                            style={type.style}
                        />
                    )}
                </div>
            );
        };

        const InlineStyleControls : React.FC<IInlineStyleControls> = (props) => {
            if (!props.editorState || typeof props.editorState.getCurrentInlineStyle !== 'function') {
                return <></>;
            }
            const currentStyle = props.editorState.getCurrentInlineStyle();
            return (
                <div className="RichEditor-controls">
                    {INLINE_STYLES.map(type =>
                        <StyleButton
                            key={type.label}
                            active={currentStyle.has(type.style)}
                            label={type.label}
                            onToggle={props.onToggle}
                            style={type.style}
                        />
                    )}
                </div>
            );
        };

        const getBlockStyle = (block : ContentBlock) => {
            switch (block.getType()) {
                case 'blockquote':
                    return 'RichEditor-blockquote';
                default:
                    return "";
            }
        }

        const onChange = (editorState : EditorState) => setText(editorState)

        const toggleBlockType = (blockType : any) => {
            onChange(
                RichUtils.toggleBlockType(
                    text,
                    blockType
                )
            );
        }

        const toggleInlineStyle = (inlineStyle : any) => {
            onChange(
                RichUtils.toggleInlineStyle(
                    text,
                    inlineStyle
                )
            );
        }

        return (
            <div className="RichEditor-root">
                <BlockStyleControls
                    editorState={text}
                    onToggle={toggleBlockType}
                />
                <InlineStyleControls
                    editorState={text}
                    onToggle={toggleInlineStyle}
                />
                <Editor
                    blockStyleFn={getBlockStyle}
                    editorState={text}
                    onChange={setText}
                    placeholder={placeholder}
                    spellCheck={true}
                />
            </div>
        );
    } else {
        return <></>;
    }
};

export default HtmlEditor;
