import React, {ChangeEvent, useEffect, useState} from 'react';
import instance from "../../util/api";
import chroma from 'chroma-js';
import {toast} from "react-toastify";
import FusionToast from "../toast/FusionToast";
import { Form } from 'react-bootstrap';

interface IFilter {
    onChange(proposals: any[], length: number): void
}

const Filter : React.FC<IFilter> = (props) => {
    const [filterValues, setValues] = useState<any[]>([]);
    const [tagValues, setTagValues] = useState<any[]>([]);
    const [dropdownOpen, setOpen] = useState<boolean>(false);
    const [dropdownTagOpen, setTagOpen] = useState<boolean>(false);
    const [selectedFilters, selectFilter] = useState<any[]>([]);
    const [selectedTags, selectTag] = useState<any[]>([]);
    const [proposals, setProposals] = useState<any[]>([]);

    const toggle = () => setOpen(!dropdownOpen);
    const toggleTag = () => setTagOpen(!dropdownTagOpen);

    useEffect(() => {
        if (props.onChange && selectedTags){
            props.onChange(proposals, selectedFilters.length + selectedTags.length)
        }
    }, [proposals]);

    useEffect(() => {
        instance.get("/admin/filter", {
            params: {
                type: "user"
            }
        })
            .then(resp => {
                console.log("FILTER VALUES", resp.data);
                if (!resp.data.error) {
                    const values = resp.data.userAttr.map((attr : any) => ({
                        id: attr.id,
                        slug: attr.slug,
                        name: attr.name,
                        value: attr.value
                    }));

                    values.push({
                        id: 98,
                        slug: "vertrag_unterschrieben",
                        name: "Vertrag unterschrieben",
                        value: JSON.stringify([{id: 0, attrId: 0, value: 1, label: "Ja"}, {id: 0, attrId: 0, value: 0, label: "Nein"}])
                    })
                    values.push({
                        id: 99,
                        slug: "geschlecht",
                        name: "Geschlecht",
                        value: JSON.stringify([{id: 0, attrId: 0, value: "male", label: "Männlich"}, {id: 0, attrId: 0, value: "female", label: "Weiblich"}])
                    })
                    setValues(values);
                    selectFilter(values.map((val: any) => ({...val, selectedValues: ""})))
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
            }).catch(err => {
                //console.log(err);
        })

        instance.get("/admin/tags", {
            params: {
                type: "user"
            }
        }).then(resp => {
            if (!resp.data.error) {
                const tagOptions = resp.data.tags.map((tag : any) => ({value: tag.id, label: tag.name}))
                setTagValues(tagOptions);
            } else {
                console.error(resp);
                toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
            }
        }).catch(err => {
            //console.log(err);
        })

    }, []);

    const filterForValues = () => {
        const data = selectedFilters.filter(filter => filter.selectedValue).map(filter => ({name: filter.slug, value: filter.selectedValue}));
        if (selectedTags){
            selectedTags.forEach(tag => {
                if (tag.value) {
                    data.push({name: "tag", value: tag.value})
                }
            })
        }
        data.push({name: "type", value: "user"});

        console.log(selectedFilters, data);

        instance.post("/admin/filter", data)
            .then(resp => {
                if (!resp.data.error){
                    console.log(resp.data);
                    setProposals(resp.data.proposals)
                } else {
                    setProposals([])
                }
            }).catch(err => {
                //console.log(err);
        })
    };

    const onSelectFilter = (filter : any) => {
        selectFilter(f => ([...f, {...filter, selectedValue:  JSON.parse(filter.value)[0].value}]))
    };

    const onSelectTag = (tag : any) => {
        selectTag(tag)
    }

    const onRemoveFilter = (id : number) => {
        selectFilter(filter => filter.filter(f => f.id !== id));
    };

    const onRemoveTag = (id : number) => {
        selectTag(tag => tag.filter(t => t.id !== id));
    }

    const selectFilterValue = (e : ChangeEvent<HTMLSelectElement>) => {
        e.persist();
        selectFilter(filter => (
            filter.map((f) => {
                if (f.slug === e.target.name){
                    f.selectedValue = e.target.value;
                }
                return f;
            })
        ))

        filterForValues();
    };

    const customStyles = {
        container: () => ({
            backgroundColor: "#474038",
        }),
        control: () => ({
            // none of react-select's styles are passed to <Control />
            display: "flex",
            backgroundColor: '#fff'
        }),
        indicatorsContainer: () => ({
           //color: "#fff"
        }),
        indicatorSeparator: () => ({
            //color: '#fff'
        }),
        option: (styles : any, { data, isDisabled, isFocused, isSelected } : any) => {
            const color = chroma.random()
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.1).css()
                            : null,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
                },
            };
        },
        multiValue: (provided : any, state : any) => {
            const color = chroma.random()
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
            const backgroundColor = color.alpha(0.1).css();

            return { ...provided, opacity, transition, backgroundColor };
        }
    }


    return (
        <div className="mt-5">
            <h3>Filtern</h3>
            {filterValues.map((filterValue) => {
                const filter = selectedFilters.find(select => select.slug === filterValue.slug);
                return (
                    <div className="filter-row mb-4">
                        <div className="filter-header">
                            <p>{filterValue.name}</p>
                        </div>
                        <div className="filter-values">
                            <Form.Select name={filterValue.slug} value={filter ? filter.selectedValue : ""} onChange={selectFilterValue}>
                                <option value="">Bitte wählen</option>
                                {JSON.parse(filterValue.value).map((v : any) => <option value={v.value}>{v.label ? v.label : v.value}</option>)}
                            </Form.Select>
                        </div>
                    </div>
                )
            })}

            {/*<Row className="align-items-center">
                <Col xs={12} md={6}>
                    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle className="btn-outline-primary" caret>
                            Filtern nach... <span><FontAwesomeIcon icon={faFilter} /></span>
                        </DropdownToggle>
                        <DropdownMenu>
                            {filterValues.length > 0 && filterValues.map(value => <DropdownItem key={value.id} onClick={() => onSelectFilter(value)}>{value.name}</DropdownItem>)}
                        </DropdownMenu>
                    </ButtonDropdown>
                </Col>
                <Col xs={12} md={6}>
                    <Select
                        className="btn-select"
                        isMulti
                        isSearchable
                        value={selectedTags}
                        onChange={onSelectTag}
                        styles={customStyles}
                        options={tagValues} />
                </Col>
            </Row>*/}
            {/*{selectedFilters.length > 0 ?
                <div className="filter-objects">
                    <ListGroup>
                    {selectedFilters.map(filter => <ListGroupItem key={filter.id}>
                        <FormGroup row>
                            <Col sm={2}><Button variant="danger" size="sm" className="filter-delete" onClick={() => onRemoveFilter(filter.id)}><FontAwesomeIcon icon={faMinus}/></Button></Col>
                            <Label htmlFor="code" sm={6}>{filter.name}</Label>
                            <Col sm={4}>
                                <Input type="select" name={filter.slug} value={filter.selectedValue} onChange={selectFilterValue}>
                                    {JSON.parse(filter.value).map((v : any) => <option value={v.value}>{v.label ? v.label : v.value}</option>)}
                                </Input>
                                </Col>
                            </FormGroup>
                        </ListGroupItem>)}
                    </ListGroup>
                </div> : <div></div>}*/}
        </div>
    );
};

export default Filter;
