import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const uuid = require("uuid");

export interface IRegisterState {
    id: string,
    email: string,
    password: string,
    title: string,
    firstname: string,
    lastname: string,
    username: string,
    birthday: string,
    socialNumber: string,
    nationality: string,
    address: string,
    city: string,
    postcode: string,
    telephone: string,
    registerAs: string,
    info: string,
    checkAGB: boolean,
    how_did_you_find_us?: string
}

const initialState = {
    id: "",
    email: "",
    password: "",
    title: "",
    firstname: "",
    lastname: "",
    username: uuid.v4(),
    birthday: "",
    socialNumber: "",
    nationality: "",
    address: "",
    city: "",
    postcode: "",
    telephone: "",
    registerAs: "",
    checkAGB: false,
    info: "",
    how_did_you_find_us: ""
} as IRegisterState;

export const userRegisterSlice = createSlice({
    name: "userData",
    initialState: initialState,
    reducers: {
        stepOne(state, action: PayloadAction<any>) {
            console.log("STEP ONE", action)
            return {
                ...state,
                ...action.payload
            }
        },
        stepTwo(state, action: PayloadAction<any>) {
            return{
                ...state,
                ...action.payload
            }
        },
        complete(state, action: PayloadAction<any>) {
            return initialState;
        }
    }
})

export const { stepOne, stepTwo, complete } = userRegisterSlice.actions;
export default userRegisterSlice.reducer;

export const registerInitState = initialState;
