import React, {useEffect, useReducer, useState} from 'react';
import {RouteComponentProps} from "react-router-dom";
import {AppDispatch, RootState} from "../../../../../store";
import {bindActionCreators} from "redux";
import {connect, ConnectedProps} from "react-redux";
import {Button, Col, Container, FloatingLabel, Form, FormGroup, Row} from "react-bootstrap";
import BaseLayout from "../../../../../layouts/BaseLayout/BaseLayout";
import eventNewEditReducer, {initialState} from "./eventNewEditReducer";
import {FieldArray, Formik, FormikErrors, useFormikContext} from "formik";
import {EditorState} from "draft-js";
import * as yup from 'yup';
import DatePicker from "react-datepicker";
import GooglePlacesAutocomplete, {geocodeByPlaceId, getLatLng} from "react-google-places-autocomplete";
import SimpleCollapse from "../../../../../components/collapse/SimpleCollapse";

import "react-datepicker/dist/react-datepicker.css";
import "./EventNewEdit.scss";
import instance from "../../../../../util/api";
import {AxiosResponse} from "axios";
import {IEvent} from "../../../../../model/interfaces/event/IEvent";
import {ADMIN_EVENT_LOGISTIC_SHOW} from "../../../../../util/routes/routes";
import {useHistory} from "react-router";
import {StylesConfig} from "react-select";
import classNames from "classnames";

interface MatchProps {
    eventType: string,
    id: string
}

interface IEventNewEdit extends PropsFromRedux, RouteComponentProps<MatchProps> {
    event?: IEventNewEditFormValues,
    onChange?(id: number, eventSections: any[], eventNew: any): void,
    serialEvent?: boolean
}

export interface IEventInfoFormVales {
    id?: number,
    date: Date,
    location: {
        label: string,
        lat: number,
        lng: number
        id: string
        value: any
    }
    meeting_time?: Date,
    general_starting_time?: Date,
    starting_time: Date,
    ending_time: Date,
    title: string,
    person?: number,
    backup_person?: number
    gender: string
}

export interface IEventNewEditFormValues {
    id?: number,
    title?: string,
    content?: string,
    emailText?: EditorState,
    sumEmail?: boolean,
    mailDate?: Date,
    type: string,
    customer?: any,
    infos: IEventInfoFormVales[]
}

const initEventInfoFormValues : IEventInfoFormVales = {
    "date": new Date(),
    "location": {
        "label": "",
        "lat": 0,
        "lng": 0,
        "id": "",
        "value": ""
    },
    "starting_time": new Date(),
    "ending_time": new Date(),
    "title": "",
    "gender": ""
}

const initialFormValues : IEventNewEditFormValues = {
    "type": "EVENT",
    "infos": []
}

const formValuesSchema = yup.object({
    title: yup.string().required('Bitte einen Titel angeben'),
    content: yup.string().required('Bitte einen Inhalt angeben'),
    sumEmail: yup.boolean(),
    infos: yup.array().of(yup.object().shape({
        date: yup.date().required('Bitte ein Datum angeben'),
        location: yup.object().shape({
            label: yup.string().required('Bitte eine Location angeben'),
            id: yup.string().required('Bitte eine Location angeben'),
            value: yup.object().required('Bitte eine Location angeben')
        }),
        starting_time: yup.date().required('Bitte eine Startzeit angeben'),
        ending_time: yup.date().required('Bitte eine Endzeit angeben'),
        title: yup.string().required('Bitte eine Beschreibung angeben'),
        gender: yup.string().required('Bitte ein Geschlecht angeben')
    })).min(1, 'Bitte mindestens eine Aktivität angeben')
})

const selectStyles: StylesConfig = {
    control: (styles) => ({
        ...styles,
        border: 'none',
        borderBottom: '1px solid #ced4da',
        borderRadius: 0,
        color: '#000000'
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#000000'
    }),
    menu: (styles) => {
        return {
            ...styles,
            zIndex: 9999
        };
    }
}

interface EventResponse {
    event: IEvent,
    error: any
}

const EventNewEdit : React.FC<IEventNewEdit> = (props) => {
    const [initialValues, setInitialValues] = useState<IEventNewEditFormValues>(initialFormValues);

    const history = useHistory();

    const type = props.match.params.eventType.toUpperCase();
    const id = props.match.params.id;

    useEffect(() => {
        if (id) {
            instance.get(`/admin/event/one`, {
                params: {
                    type: type,
                    id: id
                }
            }).then((resp: AxiosResponse<EventResponse>) => {
                console.log(resp);

                if (!resp.data.error) {
                    const event = resp.data.event;

                    const preData =  {
                        id: event.id,
                        title: event.title,
                        content: event.content,
                        emailText: event.emailText,
                        type: event.type,
                        infos: event.eventSections.map((section) => {
                            let locationValue = null;

                            try {
                                locationValue = JSON.parse(section.locationValue);
                            } catch (e) {
                                //locationValue = section.locationValue;
                            }

                            return {
                                id: section.id,
                                date: new Date(section.date),
                                location: {
                                    label: section.locationLabel,
                                    lat: section.locationLat,
                                    lng: section.locationLng,
                                    id: section.locationId,
                                    value: locationValue
                                },
                                meeting_time: section.meeting_time ? new Date(section.meeting_time) : undefined,
                                general_starting_time: section.general_starting_time ? new Date(section.general_starting_time) : undefined,
                                starting_time: section.starting_time ? new Date(section.starting_time) : undefined,
                                ending_time: section.ending_time ? new Date(section.ending_time) : undefined,
                                title: section.title,
                                person: section.person,
                                backup_person: section.backup_person,
                                gender: section.gender
                            }
                        })
                    } as IEventNewEditFormValues;

                    setInitialValues(preData);
                }

            }).catch((error) => {
                console.log("ERROR", error)
            })
        }
    }, []);


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={formValuesSchema}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                console.log("IS SUBMITTING");
                instance.post("/admin/event", values).then((resp: AxiosResponse<any, any>) => {
                    const id = resp.data.id;
                    history.push({pathname: ADMIN_EVENT_LOGISTIC_SHOW.path.replace(":eventType", props.match.params.eventType).replace(":id", id), state: {id: id}});
                }).catch((error) => {
                    console.log("ERROR", error)
                });
            }}
        >
            {({ handleSubmit, handleChange, setFieldValue, values, touched, errors, isSubmitting }) => {
                console.log("ALL ERRORS",values)
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <BaseLayout className="EventNew white"
                                    header={
                                        <div className="event-title-content">
                                            <Container className="standard-container">
                                                <Form.Control type="text" name="title" id="title" required
                                                              value={values.title}
                                                              onChange={handleChange} placeholder="Titel"
                                                              isInvalid={!!errors.title}/>
                                                <Form.Control.Feedback
                                                    type="invalid">{errors.title}</Form.Control.Feedback>
                                            </Container>
                                        </div>
                                    }
                        >
                            <div className="event-create-container">
                                <div className={"event-form"}>
                                    <div className="event-main-content">
                                        <FormGroup>
                                            <Form.Control as="textarea" name="content" id="content" rows={5}
                                                          value={values.content} onChange={handleChange}
                                                          isInvalid={!!errors.content}
                                                          placeholder="Beschreibung"/>
                                            <Form.Control.Feedback
                                                type="invalid">{errors.content}</Form.Control.Feedback>
                                        </FormGroup>
                                    </div>

                                    <div className="event-info-content mb-4">
                                        <FieldArray name={"infos"}>
                                            {({insert, remove, push}) => (
                                                <div>
                                                    {values.infos.map((info, index) => {
                                                        const infoErrors = (errors.infos && errors.infos[index] ? errors.infos[index] : {}) as FormikErrors<IEventInfoFormVales>;
                                                        console.log("INFO ERRORS", infoErrors)
                                                        return (
                                                            <div className="event-info" key={index}>
                                                                <Row className={"event-info-header mt-3"}>
                                                                    <Col md={8}>
                                                                        <h3>Aktivität {index + 1} {info.title ? info.title : ""}</h3>
                                                                    </Col>
                                                                    <Col md={4} className="text-end">
                                                                        <Button variant="danger" type="button" size="sm"
                                                                                onClick={() => remove(index)}>
                                                                            X
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <FloatingLabel
                                                                            controlId={`infos.${index}.title`}
                                                                            label="Aktivitätsbeschreibung">
                                                                            <Form.Control type="text" value={info.title}
                                                                                          isInvalid={!!infoErrors.title}
                                                                                          name={`infos.${index}.title`}
                                                                                          onChange={handleChange}
                                                                                          placeholder="Aktivitätsbeschreibung"/>
                                                                            <Form.Control.Feedback
                                                                                type="invalid">{infoErrors.title}</Form.Control.Feedback>
                                                                        </FloatingLabel>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <Form.Group className="mt-3"
                                                                                    controlId={`infos.${index}.title`}>
                                                                            <Form.Label>Event Datum</Form.Label>
                                                                            <DatePicker
                                                                                name={`infos.${index}.date`}
                                                                                selected={info.date}
                                                                                className="form-control"
                                                                                dateFormat={"dd.MM.yyyy"}
                                                                                onChange={(date, event) => {
                                                                                    setFieldValue(`infos.${index}.date`, date)
                                                                                }}
                                                                            />
                                                                            <Form.Control.Feedback
                                                                                type="invalid">{infoErrors.date}</Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <FormGroup className="mt-3"
                                                                                   controlId={`infos.${index}.p`}>
                                                                            <GooglePlacesAutocomplete
                                                                                selectProps={{
                                                                                    placeholder: "Event Location",
                                                                                    value: info.location.value,
                                                                                    onChange: (e) => {
                                                                                        console.log("E", e);
                                                                                        geocodeByPlaceId(e.value.place_id).then(result => {
                                                                                            getLatLng(result[0]).then((latLng) => {
                                                                                                setFieldValue(`infos.${index}.location.label`, e.label)
                                                                                                setFieldValue(`infos.${index}.location.lat`, latLng.lat)
                                                                                                setFieldValue(`infos.${index}.location.lng`, latLng.lng)
                                                                                                setFieldValue(`infos.${index}.location.id`, e.value.place_id)
                                                                                                setFieldValue(`infos.${index}.location.value`, e)
                                                                                            }).catch(error => {
                                                                                                setFieldValue(`infos.${index}.location.label`, e.label)
                                                                                                setFieldValue(`infos.${index}.location.id`, e.value.place_id)
                                                                                                setFieldValue(`infos.${index}.location.value`, e)
                                                                                            })
                                                                                        }).catch(error => {
                                                                                            setFieldValue(`infos.${index}.location.label`, e.label)
                                                                                            setFieldValue(`infos.${index}.location.id`, e.value.place_id)
                                                                                            setFieldValue(`infos.${index}.location.value`, e)
                                                                                        })
                                                                                    },
                                                                                    styles: selectStyles,
                                                                                    className: classNames({
                                                                                        "is-invalid": !!infoErrors.location
                                                                                    })
                                                                                }}
                                                                                apiKey="AIzaSyDCCe9OCww_E3C1QrSutASm3CKvTiZ1Cjo"
                                                                            />
                                                                            <Form.Control.Feedback
                                                                                type="invalid">{infoErrors.location?.id}</Form.Control.Feedback>
                                                                            <Form.Control type="hidden" name="longitude"
                                                                                          value=""/>
                                                                            <Form.Control type="hidden" name="latitude"
                                                                                          value=""/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={6}></Col>
                                                                    <Col md={6}>
                                                                        <Form.Group className="mt-3"
                                                                                    controlId={`infos.${index}.starting_time`}>
                                                                            <Form.Label>Startzeit</Form.Label>
                                                                            <DatePicker
                                                                                name={`infos.${index}.starting_time`}
                                                                                selected={info.starting_time}
                                                                                className="form-control"
                                                                                dateFormat={"HH:mm"}
                                                                                showTimeSelect
                                                                                showTimeSelectOnly
                                                                                timeIntervals={15}
                                                                                timeCaption="Time"
                                                                                onChange={(date, event) => {
                                                                                    setFieldValue(`infos.${index}.starting_time`, date)
                                                                                }}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <Form.Group className="mt-3"
                                                                                    controlId={`infos.${index}.ending_time`}>
                                                                            <Form.Label>Endzeit</Form.Label>
                                                                            <DatePicker
                                                                                name={`infos.${index}.ending_time`}
                                                                                selected={info.ending_time}
                                                                                className="form-control"
                                                                                dateFormat={"HH:mm"}
                                                                                showTimeSelect
                                                                                showTimeSelectOnly
                                                                                timeIntervals={15}
                                                                                timeCaption="Time"
                                                                                onChange={(date, event) => {
                                                                                    setFieldValue(`infos.${index}.ending_time`, date)
                                                                                }}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <FloatingLabel className="mt-3"
                                                                                       controlId={`infos.${index}.gender`}
                                                                                       label="Gewünschtes Geschlecht">
                                                                            <Form.Select name={`infos.${index}.gender`}
                                                                                         isInvalid={!!infoErrors.gender}
                                                                                         onChange={handleChange}>
                                                                                <option value="">Gewünschtes Geschlecht
                                                                                </option>
                                                                                <option value="equal">Egal</option>
                                                                                <option value="female">Weiblich</option>
                                                                                <option value="male">Männlich</option>
                                                                            </Form.Select>
                                                                            <Form.Control.Feedback
                                                                                type="invalid">{infoErrors.gender}</Form.Control.Feedback>
                                                                        </FloatingLabel>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <FloatingLabel className="mt-3"
                                                                                       controlId={`infos.${index}.person`}
                                                                                       label="Anzahl Personen (Optional)">
                                                                            <Form.Control type="number"
                                                                                          value={info.person}
                                                                                          name={`infos.${index}.person`}
                                                                                          onChange={handleChange}
                                                                                          placeholder="Anzahl Personen (Optional)"/>
                                                                        </FloatingLabel>
                                                                    </Col>
                                                                </Row>
                                                                <SimpleCollapse
                                                                    buttonText={"Weitere Informationen"}
                                                                    id={`infos.${index}.additional`}
                                                                >
                                                                    <Row>
                                                                        <Col md={6}>
                                                                            <FloatingLabel className="mt-3"
                                                                                           controlId={`infos.${index}.backup_person`}
                                                                                           label="Backup (Standard: 0)">
                                                                                <Form.Control type="number"
                                                                                              name={`infos.${index}.backup_person`}
                                                                                              onChange={handleChange}
                                                                                              placeholder="Backup (Standard: 0)"/>
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <Form.Group className="mt-3"
                                                                                        controlId={`infos.${index}.meeting_time`}>
                                                                                <Form.Label>Treffzeitpunkt</Form.Label>
                                                                                <DatePicker
                                                                                    name={`infos.${index}.meeting_time`}
                                                                                    selected={info.meeting_time}
                                                                                    className="form-control"
                                                                                    dateFormat={"HH:mm"}
                                                                                    showTimeSelect
                                                                                    showTimeSelectOnly
                                                                                    timeIntervals={15}
                                                                                    timeCaption="Time"
                                                                                    onChange={(date, event) => {
                                                                                        setFieldValue(`infos.${index}.meeting_time`, date)
                                                                                    }}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <Form.Group className="mt-3"
                                                                                        controlId={`infos.${index}.general_starting_time`}>
                                                                                <Form.Label>Endzeit</Form.Label>
                                                                                <DatePicker
                                                                                    name={`infos.${index}.general_starting_time`}
                                                                                    selected={info.general_starting_time}
                                                                                    className="form-control"
                                                                                    dateFormat={"HH:mm"}
                                                                                    showTimeSelect
                                                                                    showTimeSelectOnly
                                                                                    timeIntervals={15}
                                                                                    timeCaption="Time"
                                                                                    onChange={(date, event) => {
                                                                                        setFieldValue(`infos.${index}.general_starting_time`, date)
                                                                                    }}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>
                                                                </SimpleCollapse>
                                                            </div>
                                                        )
                                                    })}
                                                    <Button className="mt-4" type="button"
                                                            onClick={() => push(initEventInfoFormValues)}>
                                                        + Neue Aktivität
                                                    </Button>
                                                </div>
                                            )}
                                        </FieldArray>
                                    </div>
                                    {(!Array.isArray(errors.infos) && !!errors.infos) ?
                                        <div className="invalid-feedback d-block">{errors.infos}</div> : <></>}
                                </div>

                                <Row>
                                    <Col md={6}>
                                        <Button variant="danger" onClick={() => {
                                            history.goBack();
                                        }}>Abbruch</Button>
                                    </Col>
                                    <Col md={6} className="text-end">
                                        <Button type="submit" variant="secondary">Speichern</Button>
                                    </Col>
                                </Row>

                            </div>
                        </BaseLayout>
                    </Form>
                )
            }}
        </Formik>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return bindActionCreators({}, dispatch)
}

const mapStateToProps = (state: RootState) => ({
    userData: state.user,
    userList: state.userList
})


const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EventNewEdit);