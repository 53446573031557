import React, {FC, useEffect, useState} from "react";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import deLocale from '@fullcalendar/core/locales/de';
import "./Calendar.scss";
import BillingNextModal from "./sub/BillingNextModal";
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../store";
import {IBillableEvent, ICalendarEvent, IEvent} from "../../model/interfaces/event/IEvent";
import {DatesSetArg, EventClickArg, EventContentArg, EventInput} from "@fullcalendar/core";
import EventModalUser from "./sub/EventModalUser";
import EventModal from "./sub/EventModal";
import {v4} from "uuid";
import {Identity} from "@fullcalendar/core/internal";

const classNames = require('classnames');

interface ICalendar extends PropsFromRedux {
    events: ICalendarEvent[],
    type: string,
    billableEvents: IBillableEvent[],
    isUserCalendar: boolean,
    onDateSet?: (arg: DatesSetArg) => void
}

interface ICalendarEventObject {
    id: string,
    title?: string,
    date: Date,
    allDay: boolean,
    groupId: number,
    classNames: string[]
}

const Calendar : FC<ICalendar> = (props) => {
    const {events, type, billableEvents, isUserCalendar, userData, onDateSet} = props;
    const [modal, setModal] = useState(false);
    const [billingModal, setBillingModal] = useState(false);
    const [event, setEvent] = useState<ICalendarEvent | null>(null);
    const [billingEvent, setBillingEvent] = useState<IBillableEvent[] | null>(null);
    const [eventList, setEventList] = useState<EventInput[]>([]);

    const toggle = () => setModal(!modal);

    const toggleBilling = () => setBillingModal(!billingModal);

    useEffect(() => {
        const eventList = [] as EventInput[];

        if (events) {
            events.forEach(event => {
                const dateList = []
                if (event.eventSections){
                     dateList.push(...Array.from(new Set(event.eventSections.map(info => info.date))));
                }


                const groupId = v4();
                dateList.forEach(date => {
                    let startTime = null;
                    if (event.eventSections){
                        startTime = event.eventSections.find(info => info.date === date);
                    }
                    const ownEvent = event.own ? "own-event" : "";
                    if (startTime){
                        const dateMoment = moment(date);
                        const dateTime = moment(startTime.starting_time);
                        let status = event.statusEvent.toString();
                        let confirmedFromAllUsers = false;
                        if (isUserCalendar && event.status !== "completed" && startTime.eventInfoUsers){

                            const foundUser = startTime.eventInfoUsers.find((user: any) => user.user_id === userData._id);

                            if (foundUser){
                                status = foundUser.status;

                                if (foundUser.user_assigned_backup){
                                    status += " backup"
                                }
                            } else {
                                status = "not_apply";
                            }
                        } else {
                            confirmedFromAllUsers = startTime.all_users_confirmed;
                        }

                        dateTime.year(dateMoment.get("year")).month(dateMoment.get("month")).date(dateMoment.get("date"));
                        if (event.show) {
                            eventList.push({
                                id: event.id.toString(),
                                title: event.title,
                                date: dateTime.toDate(),
                                allDay: false,
                                groupId: groupId,
                                classNames: ["fusion-cal-event", event.type.toLowerCase(), status, ownEvent, confirmedFromAllUsers ? "confirmed" : ""],
                                extendedProps: {
                                    own: event.own
                                }
                            })
                        }
                    }
                })
            })
        }

        /*if (type === "USER"){
            if (billableEvents && billableEvents.length > 0){
                billableEvents.forEach(billing => {
                    const groupId = v4();
                    if (billing && billing.user_billing_date){
                        eventList.push({
                            id: "billing_" + billing.event_id,
                            title: "Abrechnungstermin",
                            date: billing.user_billing_date,
                            allDay: false,
                            groupId: groupId,
                            classNames: ["fusion-cal-event", "billing-cal-event"]
                        })
                    }
                })
            }
        } else {
            if (billableEvents && billableEvents.length > 0){
                billableEvents.forEach(billing => {
                    if (!eventList.find(ev => ev.date === billing.user_billing_date)){
                        const groupId = v4();
                        eventList.push({
                            id: "billing_" + billing.event_id,
                            title: "Abrechnungstermin",
                            date: billing.user_billing_date,
                            allDay: false,
                            groupId: groupId,
                            classNames: ["fusion-cal-event", "billing-cal-event"]})
                    }
                })
            }
        }*/

        setEventList(eventList);
    }, [events, billableEvents])

    const onClickEvent = (info : EventClickArg) => {
        const startDate = moment(info.event.start).format("YYYY-MM-DD");

        const foundEvent = events.find(ev => ev.id === parseInt(info.event.id));
        const foundBillableEvent = billableEvents.filter(ev => ev && ev.user_billing_date &&  moment(ev.user_billing_date).format("YYYY-MM-DD") === startDate);

        console.log(foundEvent, foundBillableEvent, startDate);


        if (foundBillableEvent.length > 0){
            setBillingEvent(foundBillableEvent)
            setEvent(null);
            toggleBilling();
        } else if (foundEvent){
            setEvent(foundEvent)
            setBillingEvent(null);
            toggle();
        }
    }

    return (
        <div className="calendar">
            <FullCalendar
                aspectRatio={1.6}
                locale={deLocale}
                themeSystem='bootstrap'
                initialView="dayGridMonth"
                eventClick={onClickEvent}
                displayEventTime={false}
                headerToolbar={{
                    left: 'prev title next',
                    center: '',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                titleFormat={{year: 'numeric', month: 'long'}}
                eventContent={renderEventContent} // custom render function
                events={eventList}
                datesSet={onDateSet}
                dayHeaderFormat={{
                    weekday: "short"
                }}
                plugins={[dayGridPlugin, timeGridPlugin]} />

            {event ?
                type !== "USER" ?
                    <EventModal modal={modal} toggle={toggle} event={event} />
                    :  <EventModalUser modal={modal} toggle={toggle} event={event} />
                : <div></div>}

            {(billingEvent) ? <BillingNextModal type={type} modal={billingModal} toggle={toggleBilling} events={billingEvent} /> : <div></div>}
        </div>
    )
}


function renderEventContent(eventInfo : EventContentArg) {
    //console.log(eventInfo);
    const own = eventInfo.event.extendedProps.own as boolean;
    const classes = classNames({
        "own": own
    })
    return (
        <>
            <span className={classes}>{eventInfo.event.title}</span>
        </>
    )
}


const mapStateToProps = (state : RootState) => ({
    userData: state.user
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Calendar);
