import React, {ChangeEvent, EventHandler, FormEvent, FormEventHandler, useEffect, useState} from 'react';
import {Button, ListGroup, Modal, ModalBody, ModalHeader} from "react-bootstrap";
import Search from "../search/Search";
import instance from "../../util/api";
import {useHistory} from 'react-router'
import {toast} from "react-toastify";
import FusionToast from "../toast/FusionToast";

interface IUserListModal {
    className: any,
    refId: any
}
const UserListModal : React.FC<IUserListModal> = (props) => {
    const {
        className,
        refId
    } = props;

    const history = useHistory();
    const [modal, setModal] = useState<boolean>(false);
    const [userList, setUserList] = useState<any[]>([]);
    const [loading, isLoading] = useState<boolean>(false);
    const [value, setValue] = useState<any[]>([]);

    const toggle = () => {
        setModal(!modal);
    }

    useEffect(() => {
        isLoading(true);
        instance.get("/admin/user/all")
            .then(resp => {
                setUserList(resp.data);
                isLoading(false);
                //console.log(resp);
            }).catch(err => {
                //console.log(err);
        })
    }, []);

    const onSearch = (values : any[], searchVal: string) => {
       instance.get("/admin/user/search", {
           params: {
               s: searchVal
           }
       }).then(resp => {
           if (!resp.data.error){
               setUserList(resp.data.proposals);
           } else {
               console.error(resp);
               toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
           }
       })
    }

    const onChange = (e : ChangeEvent<HTMLInputElement>) => {
        e.persist();
        if (e.target.checked){
            setValue((v) => [...v, e.target.value])
        } else {
            setValue((v) => v.filter(id => id !== e.target.value))
        }
    }

    const onSubmit = (e : FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = {
            eventId: refId,
            userIds: value
        }
        instance.post("/admin/event/addUser", data)
            .then(resp => {
                console.log("FINISH", resp.data);
                if (!resp.data.error){
                    history.go(0);
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
            }).catch(err => {
                //console.log(err);
        })
    }

    return (
        <div>
            <Button variant="primary" className="btn btn-primary w-100" onClick={toggle}>User hinzufügen?</Button>
            <Modal fade={false} centered={true} show={modal} onHide={toggle} className={className}>
                <ModalHeader closeButton>User Liste</ModalHeader>
                <ModalBody>

                    <div className="search-modal">
                        <Search searchType="user" onChange={onSearch} placeholder="Suche nach User..."/>
                    </div>
                    {/*<div className="user-list-container">
                        <ListGroup className="list-group">
                            {!loading ? userList.map((user) =>
                                <ListGroup.Item key={"listModal_" + user.id} className="list-group-item d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">{user.firstname} {user.lastname}</div>
                                        <div>{user.email}</div>
                                    </div>
                                    <Button variant="primary">User annehmen</Button>
                                </ListGroup.Item>
                            ): <div></div>}
                        </ListGroup>
                    </div>*/}
                    <form onSubmit={onSubmit}>
                        <div className="user-list-container">
                            <ul className="list-group">
                                {!loading ? userList.map((user) =>
                                    <li  key={"listModal_" + user.id} className="list-group-item">
                                        <label className="row" style={{"cursor": "pointer"}}>
                                            <div className="col-10">{user.firstname} {user.lastname}</div>
                                            <div className="col-2">
                                                <input type="checkbox" id={"user_"+user.id} onChange={onChange} name="user[]" value={user.id} />
                                            </div>
                                        </label>
                                    </li>
                                ): <div></div>}
                            </ul>
                        </div>

                        <button className="btn btn-primary" type="submit">User hinzufügen</button>

                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default UserListModal;
