import instance from "../api";


export const getFiles = async (userImageAttr : any[]) => {
    const files = [];
    for (const attr of userImageAttr) {
        const foundFile = await instance.get("/file/pdfs/" + attr.value, {
            responseType: 'arraybuffer'
        })
        if (foundFile.data){
            const url = URL.createObjectURL(new Blob([foundFile.data], {type: foundFile.headers["content-type"]}));
            files.push({type: attr.attribute, url: url})
        }
    }

    return files;
}

export const getFile = async(name : string, fileName = "download.pdf") => {
    const foundFile = await instance.get("/file/pdfs/" + name, {
        responseType: 'arraybuffer'
    })
    console.log("FOUND FILE", foundFile.data, foundFile.headers["content-type"])
    if (foundFile.data) {
        const url = URL.createObjectURL(new Blob([foundFile.data], {type: foundFile.headers["content-type"]}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    return "";
}

export const checkFile = (src : string, good : (this: GlobalEventHandlers, ev: Event) => any, bad : (this: OnErrorEventHandler, ev: string | Event) => any) => {
    const img = new Image();
    img.onload = good;
    img.onerror = bad;
    img.src = src;
}
