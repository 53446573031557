import React from 'react';
import BaseLayout from "../../layouts/BaseLayout/BaseLayout";
import {Col, Nav, NavItem, Row} from "react-bootstrap";
import {Link, Route, Switch} from "react-router-dom";
import {ADMIN_CONFIGURATION, ADMIN_CONFIGURATION_USER_ATTR} from "../../util/routes/routes";
import ConfigurationMain from "./ConfigurationMain";
import ConfigurationUserAttr from "./UserAttribute/ConfigurationUserAttr";

const Configuration = () => {
    return (
        <BaseLayout>
            <Row>
                <Col md={2}>
                    <Nav className="flex-column">
                        <NavItem>
                            <Link className="nav-link" to={ADMIN_CONFIGURATION.path}>All</Link>
                            <Link className="nav-link" to={ADMIN_CONFIGURATION_USER_ATTR.path}>User Attributes</Link>
                        </NavItem>
                    </Nav>
                </Col>
                <Col md={10}>
                    <Switch>
                        <Route exact path={ADMIN_CONFIGURATION.path} component={ConfigurationMain} />
                        <Route exact path={ADMIN_CONFIGURATION_USER_ATTR.path} component={ConfigurationUserAttr} />

                    </Switch>
                </Col>
            </Row>
        </BaseLayout>
    );
};

export default Configuration;
