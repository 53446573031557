import React, {ReactNode, useState} from 'react';
import {Col, Container, Nav, NavItem} from "react-bootstrap";
import {connect, ConnectedProps} from "react-redux";
import "./BaseLayout.scss";

import {NavLink, useLocation} from "react-router-dom";
import {SYSTEM_VARS} from "../../util/api";
import TopMenu from "../../components/topMenu/TopMenu";
import ROUTES, {CALENDAR, EVENT_LIST, HOME, LOGISTIC_LIST, USER_DOCUMENTATION} from "../../util/routes/routes";
import {hasRole, PROMOTER_AUTH, SUPPLIER_AUTH} from "../../util/auth/auth";
import {RootState} from "../../store";
import {userLogoutAction} from "../../state/action/user/userAction";
import LOGO from "../../assets/images/logo_fusion_white.png";

const classNames = require('classnames');

declare var process : {
    env: {
        REACT_APP_DEV_LEVEL: string
    }
}
interface IBaseLayoutUser extends PropsFromRedux {
    className?: any,
    header?: React.ReactNode,
    sidebar?: ReactNode
}

const BaseLayoutUser : React.FC<IBaseLayoutUser> = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const location = useLocation();

    const messages = [];

    if (props.user) {
        if (!props.user.is_active) {
            if (props.user.activation_request_sent) {
                if (!props.user.vertrag_unterschrieben) {
                    messages.push("Dein Account wurde noch nicht vollständig aktiviert, bitte downloade und uploade ihn in deinem Profil. <a href='/profile'>Zu deinem Profil</a>")
                } else {
                    messages.push("Dein Account wurde noch nicht kontrolliert und freigeschaltet, bitte gedulde dich noch ein wenig. <a href='/profile'>Zu deinem Profil</a>")
                }
            } else {
                messages.push("Dein Account ist noch nicht vollständig aktiviert, bitte fülle noch die fehlenden Daten aus. <a href='/profile'>Zu deinem Profil</a>")
            }
        }
    }


    const baseLayoutClassName = classNames('BaseLayout', 'BaseLayoutUser', props.className);
    const foundPath = ROUTES.find(route => (route.path === location.pathname || route.sympath === location.pathname))

    const endpoint = SYSTEM_VARS[process.env.REACT_APP_DEV_LEVEL].socket_endpoint;

    return (
        <div className={baseLayoutClassName}>
            <header className="header">
                <TopMenu type="USER"/>
            </header>
            <div className="main-area">
                <div className="sidebar-holder">
                    <div className="sidebar">
                        <div className="logo-holder">
                            <img src={LOGO} alt="LOGO" />
                        </div>
                        <div className="nav-holder">
                            <Nav className="flex-column">
                                <NavItem>
                                    <NavLink exact activeClassName="active" className="nav-link fusion-nav-link" to={HOME.path}>{HOME.name}</NavLink>
                                </NavItem>
                                {props.user.is_active ?
                                    <>
                                    <NavItem>
                                        <NavLink exact activeClassName="active" className="nav-link fusion-nav-link" to={CALENDAR.path}>{CALENDAR.name}</NavLink>
                                    </NavItem>
                                    {hasRole(props.user, [PROMOTER_AUTH]) &&
                                        <NavItem>
                                            <NavLink activeClassName="active" className="nav-link fusion-nav-link" to={EVENT_LIST.path.replace(":eventType", "event")}>{EVENT_LIST.name}</NavLink>
                                        </NavItem>
                                    }
                                    {hasRole(props.user, [SUPPLIER_AUTH]) &&
                                        <NavItem>
                                            <NavLink activeClassName="active" className="nav-link fusion-nav-link" to={LOGISTIC_LIST.path.replace(":eventType", "logistic")}>{LOGISTIC_LIST.name}</NavLink>
                                        </NavItem>
                                    }
                                    </>
                                    : <>
                                    </>
                                }
                                <NavItem>
                                    <NavLink exact activeClassName="active" className="nav-link fusion-nav-link" to={USER_DOCUMENTATION.path}>{USER_DOCUMENTATION.name}</NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>
                </div>
                <div className="main-holder">
                    {messages.length > 0 ?
                        <div className="message-holder">
                            <div className="container">
                                {messages.map((message, index) => {
                                    return (
                                        <div className="row" key={index}>
                                            <div className="col-12">
                                                <div className="" role="alert"
                                                     dangerouslySetInnerHTML={{__html: message}}></div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        : <></>}
                    {props.header ? props.header : <></>}
                    <Container className="fusion-container">
                        {props.sidebar ? <div className="col-12 col-lg-2">
                            {props.sidebar}
                        </div> : <></>
                        }
                        <div className="row">
                            <div className="col-12">
                                {props.children}
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    );
};

const mapReducerToProp = {
    logout: userLogoutAction
};

const mapStateToProps = (state : RootState) =>  {
    return {user: state.user}
};



const connector = connect(mapStateToProps, mapReducerToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(BaseLayoutUser);
