import React, {ChangeEvent, FC, KeyboardEventHandler, MouseEventHandler} from 'react';
import {Col, Form, FormGroup, Modal, ModalBody, ModalHeader, Row} from "react-bootstrap";
import moment from "moment";
import instance from "../../../util/api";
import {toast} from "react-toastify";
import FusionToast from "../../toast/FusionToast";
import {v4} from "uuid";
import {IBillableEvent, ICalendarEvent} from "../../../model/interfaces/event/IEvent";
import {IUser} from "../../../model/interfaces/user/IUser";

interface IBillingNextModal {
    modal: boolean,
    toggle: () => void,
    events: IBillableEvent[],
    type: string
}

const BillingNextModal : FC<IBillingNextModal>= (props) => {
    const {modal, toggle, events, type} = props;

    //console.log(events);

    const onChecked = (event : ChangeEvent<HTMLInputElement>, eventId : number[], userId : number) => {
        //console.log("CHECKED", event.target.checked, eventId, userId);

        const data = {
            eventId: eventId,
            userId: userId,
            checked: event.target.checked
        }

        instance.post("/admin/event/user/billed", data)
            .then(resp => {
                if (!resp.data.error){
                    toast(<FusionToast className="success" header="User erfolgreich bezahlt" message="Der User wurde erfolgreich bezahlt" />);
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
            }).catch(err => {
                //console.log(err);
        })
    }

    if (events && events.length > 0) {
        const eventSortUser = [] as {
            event : IBillableEvent[],
            user : IUser
        }[];

        events.forEach(event => {
            console.log(eventSortUser, event);
            const found = eventSortUser.findIndex(ev => ev.user && event.user && ev.user.id === event.user.id);
            if (found !== -1) {
                eventSortUser[found].event.push(event)
            } else {
                if (event.user) {
                    eventSortUser.push({
                        event: [event],
                        user: event.user
                    })
                }
            }
        })

        //console.log(eventSortUser);

        if (type === "USER") {
            return (
                <Modal show={modal} fade={false} onHide={toggle} size="lg" className="modal-dialog-centered">
                    <ModalHeader closeButton>Abrechnungstermin
                        Datum: {moment(events[0].user_billing_date).format("DD.MM.YYYY")}</ModalHeader>
                    <ModalBody>
                        <p>Du kannst zwischen 12:00 und 14:00 Uhr dein Geld im Büro abholen!</p>
                    </ModalBody>
                </Modal>
            )
        } else {
            return (
                <Modal show={modal} fade={false} onHide={toggle} size="lg" className="modal-dialog-centered">
                    <ModalHeader closeButton>User wurde erfolgreich abgerechnet?
                        Datum: {moment(events[0].user_billing_date).format("DD.MM.YYYY")}</ModalHeader>
                    <ModalBody>
                        <div className="user-list-container">
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <Row>
                                        <Col md={4}>Event Titel</Col>
                                        <Col md={4}>Promoter/Logistiker</Col>
                                        <Col md={4}>Bezahlstatus</Col>
                                    </Row>
                                </li>
                                {eventSortUser.map((eventUser) =>
                                    <li key={"listModal_" + v4()} className="list-group-item">
                                        <Row>
                                            <Col md={4}>{eventUser.event.map(ev => <>{ev.title},<br/></>)}</Col>
                                            <Col md={4}>{eventUser.user.firstname} {eventUser.user.lastname}</Col>
                                            <Col md={4}>
                                                <Form.Check
                                                    type={"checkbox"}
                                                    label={"Bezahlt?"}
                                                    onChange={(e) => onChecked(e, eventUser.event.map(ev => ev.event_id), eventUser.user.id)}
                                                />
                                            </Col>
                                        </Row>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </ModalBody>
                </Modal>
            )
        }
    }

    return <div></div>
};

export default BillingNextModal;
