import React, {useEffect, useState} from 'react';
import BaseLayoutUser from "../../layouts/BaseLayout/BaseLayoutUser";
import instance from "../../util/api";
import {connect, ConnectedProps} from "react-redux";
import Loading from "../../components/loading/Loading";
import Calendar from "../../components/calendar/Calendar";
import {USER_EVENT_LOGISTIC_DETAIL} from "../../util/routes/routes";
import {toast} from "react-toastify";
import FusionToast from "../../components/toast/FusionToast";
import {v4} from "uuid";
import {eventInfoStatusRanking, statusRanking} from "../../util/helper/eventHelper";
import {RootState} from "../../store";
import Moment from "react-moment";
import EventModalUser from "../../components/calendar/sub/EventModalUser";

import "./CalendarUser.scss";
import {IEvent} from "../../model/interfaces/event/IEvent";

interface ICalendarUser extends PropsFromRedux{

}

const CalendarUser : React.FC<ICalendarUser> = (props) => {
    const [modal, setModal] = useState(false);
    const [events, setEvents] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [done, setDone] = useState<boolean>(false);
    const [filterValues, selectFilter] = useState([
        {id: v4(), name: "Alle Events", value: "alle", selected: true},
        {id: v4(), name: "Beworben", value: 1, comparator: "=", selected: false},
        {id: v4(), name: "Zugeteilt", value: 3, comparator: ">=", selected: false},
        {id: v4(), name: "Offene Jobs", value: 0, comparator: "=", selected: false},
    ]);

    const {userData} = props;

    const toggle = () => setModal(!modal);

    useEffect(() => {
        instance.get("/user/tasks", {
            params: {
                calendar: true
            }
        })
            .then(resp => {
                if (!resp.data.error) {

                    const events = resp.data.events
                        .map((event : any) => {
                            event.eventSections = event.eventSections.filter((eventInfo : any) => {
                                const foundUser = eventInfo.eventInfoUsers.find((user : any) => user.user_id === userData._id);
                                if (foundUser) {
                                    return true;
                                } else {
                                    console.log(eventInfo.status, eventInfoStatusRanking[eventInfo.status as keyof typeof eventInfoStatusRanking])
                                    return eventInfoStatusRanking[eventInfo.status as keyof typeof eventInfoStatusRanking] < 2;
                                }
                            });
                            //return foundInfos.length > 0;
                            return event;
                        })
                        .map((event : any) => ({
                            ...event,
                            statusEvent: event.status !== "completed" ? event.eventSections.map((eventInfo : any) => {
                                const foundUser = eventInfo.eventInfoUsers.find((user : any) => user.user_id === userData._id);
                                if (foundUser){
                                    return foundUser.status;
                                } else {
                                    return "not_apply";
                                }
                            }) : event.status,
                            link: USER_EVENT_LOGISTIC_DETAIL.path.replace(":eventType", "event").replace(":id", event.id),
                            show: true
                        }));

                    //console.log("USER EVENTS", eventFilters);
                    setEvents(events);
                    setLoading(false);
                    setTimeout(() => {
                        setDone(true);
                    }, 1000);
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
            }).catch(err => {
                console.error(err);
                toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
        });

    }, []);


    return (
        <BaseLayoutUser>
            {!done ?
                <Loading loading={loading} />
                :
                <Calendar events={events} billableEvents={[]} type="USER" isUserCalendar={true} />
            }
            {/* <TaskList/>*/}
        </BaseLayoutUser>
    );
};

const mapStateToProps = (state : RootState) => ({
    userData: state.user
})

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(CalendarUser);
