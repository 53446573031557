import React from 'react';
import 'photoswipe/dist/photoswipe.css'
import {Gallery, Item} from "react-photoswipe-gallery";

interface IImageLightbox {
    images: any[],
    isSingle: boolean,
    alt: string
}
const ImageLightbox : React.FC<IImageLightbox> = (props) => {
    const {images, isSingle, alt} = props;


    if ((isSingle && images[0] && images[0].url) || (!isSingle && images.length > 0)) {
        console.log("URL", images)

        return (
            <Gallery>
                <div className="row">
                    {(isSingle && images[0]) ?
                        <Item
                            original={images[0].url}
                            thumbnail={images[0].url}
                            width="1024"
                            height="768"
                        >
                            {({ ref, open }) => (
                                <img onClick={open} className="img col-12" src={images[0].url} alt={alt}/>
                            )}
                        </Item> : ""}

                    {(!isSingle && images.length > 0) ? images.map((image, key) => {
                        if (image.url) {
                            return (<Item
                                original={image.url}
                                thumbnail={image.url}
                                width="1024"
                                height="768"
                            >
                                {({ ref, open }) => (
                                    <div className="img-holder col-4" key={`img_${key}`}>
                                        <img onClick={open} className="img" src={image.url} alt={alt}/>
                                    </div>
                                )}
                            </Item>)
                        }
                        return <></>
                    }) : ""}
                </div>
            </Gallery>
        );
    } else {
        return <></>
    }
};

export default ImageLightbox;
