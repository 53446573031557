import {createReducer, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    addAllMessagesAction,
    addMessageAction, errorMessageAction,
    readAllMessagesAction,
    readMessageAction
} from "../../action/message/messageAction";
import {IMessage} from "../../../model/interfaces/message/IMessage";

interface IMessageSlice {
    messages: IMessage[]
}

const initialState = {
    messages: []
} as IMessageSlice


const messageData = createReducer(initialState, {
    [addAllMessagesAction.type]: (state, action) => {
        return {
            messages: (action.payload && action.payload.length > 0) ? action.payload : []
        }
    },
    [addMessageAction.type]: (state, action) => {
        return {
            messages: [...state.messages, action.payload]
        }
    },
    [readAllMessagesAction.type]: (state, action) => {
        return {
            messages: []
        }
    },
    [errorMessageAction.type]: (state, action) => {
        return {
            messages: [...state.messages, action.payload]
        }
    }
})

export default messageData;