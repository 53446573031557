import React from 'react';
import * as loadingData from "./15563-hiring-isometric-animation.json";
import * as loadingDataDone from "./lf30_editor_pXY8AL.json";
import FadeIn from "react-fade-in";
import Lottie from "lottie-react";

interface ILoading {
    loading: boolean
}

const Loading : React.FC<ILoading> = (props) => {
    const {loading} = props;

    return (
        <FadeIn>
            <div className="d-flex justify-content-center align-items-center">
                <div className="text-center">
                    <h1>Bitte warten...</h1>
                    <p>Laden zusätzliche Daten!</p>
                    {loading ?
                        <Lottie animationData={loadingData} loop={true} autoplay={true} rendererSettings={{
                            preserveAspectRatio: 'xMidYMid slice'
                        }} height={120} width={120} /> :
                        <Lottie animationData={loadingDataDone} loop={true} autoplay={true} rendererSettings={{
                            preserveAspectRatio: 'xMidYMid slice'
                        }}  height={120} width={120} />
                    }
                </div>
            </div>
        </FadeIn>
    );
};

export default Loading;
