import {REHYDRATE} from "redux-persist";
import {Middleware} from "redux";
import {PersistRootState} from "../../../store";
import {userLoginSuccessAction, userRefreshTokenAction} from "../../../state/action/user/userAction";
import {addMessageAction} from "../../../state/action/message/messageAction";

export const messageMiddleware: Middleware<
{},
    PersistRootState
    > = ({dispatch, getState}) => next => action => {
    if (action.payload && action.type === REHYDRATE){
        //console.log("REHYDRATE", action.payload.userData);
        dispatch(addMessageAction);
    }
    if (action.user && (action.type === userLoginSuccessAction.type || action.type === userRefreshTokenAction.type)){
        //console.log("USER_LOGIN_SUCCESS", action.user);
        dispatch(addMessageAction);
    }
    return next(action);
}
