import instance, {authInstance, OAUTH_CLIENT_ID, OAUTH_CLIENT_KEY} from "../index";
import qs from 'qs';

const headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
}

declare var process : {
    env: {
        REACT_APP_DEV_LEVEL: string
    }
}


export const userLogin = (email : string, password : string) => {
    return instance({
        method: 'POST',
        url: '/auth/login',
        data: qs.stringify({
            email: email,
            password: password
        }),
        headers: headers
    })
}
//https://auth.hofmann.solutions/realms/fusionpromotion/protocol/openid-connect/token
/*export const userLogin = (username: string, password: string) => {
    return authInstance({
            method: 'POST',
            url: `/realms/fusionpromotion/protocol/openid-connect/token`,
            data: qs.stringify({
                username: username,
                password: password,
                grant_type: 'password'
            }),
            headers,
            auth: {
                username: OAUTH_CLIENT_ID,
                password: OAUTH_CLIENT_KEY
            },
            withCredentials: true,
        }
    )
};*/

export const getUserData = (username: string, access_token: string) => {
    instance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
    return instance("/user/v1/username/" + username);
};

export const refreshToken = (token : string) => {
    return instance({
        method: 'POST',
        url: "/auth/refreshToken",
        data: qs.stringify({
            refreshToken: token
        }),
        headers
    })
}