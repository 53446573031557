import React, {SyntheticEvent, useEffect} from 'react';
import {useDropzone} from "react-dropzone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import pdf from "../../assets/images/pdf.png";

interface IDropzone {
    files: any,
    name: string,
    setFiles: Function,
    deleteAble: boolean,
    acceptedFiles: string,
    onDeleteCb?: (e : SyntheticEvent<any>, url : string, id : number) => void
}

const Dropzone : React.FC<IDropzone> = (props) => {
    const {files, name, setFiles, deleteAble = false, acceptedFiles = '', onDeleteCb = null} = props;

    const acceptFileString = acceptedFiles;

    const {
        getRootProps,
        getInputProps,
    } = useDropzone({
        //acceptedFiles: acceptedFiles,
        noClick: false,
        noKeyboard: true,

        onDrop: (acceptedFiles) => {
            if (name !== "unset"){
                setFiles({...files, [name]: {preview: URL.createObjectURL(acceptedFiles[0]), content: acceptedFiles[0]}})
            } else {
                if (acceptFileString === "pdf") {
                    setFiles({
                        ...files,
                        [name]: [...files[name], ...acceptedFiles.map(file => ({
                            preview: pdf,
                            content: file
                        }))]
                    })
                } else {
                    setFiles({
                        ...files,
                        [name]: [...files[name], ...acceptedFiles.map(file => ({
                            preview: URL.createObjectURL(file),
                            content: file
                        }))]
                    })
                }
                }
        }
    });

    /*useEffect(() => {
        setFiles({...files});
    }, [])*/


    const onDelete = (e : SyntheticEvent<any>, url : string, id : number) => {
        e.preventDefault();
        e.stopPropagation();

        if (onDeleteCb){
            onDeleteCb(e, url, id)
        }

        setFiles({...files, [name]: [...files[name].filter((file : any) => file.preview !== url)]})
    }

    return (
        <div className="dropzone-holder">
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                {renderPreview(files[name], deleteAble, onDelete)}
            </div>
        </div>
    )
}

const renderPreview = (files: any, deleteAble : boolean = false, onDelete : Function) => {
    if (files){
        if (Array.isArray(files)) {
            if (files.length === 1 && !files[0].preview){
                return <p>Drag file or click to upload file</p>
            }
            return (
                <div className="row dropzone-row">
                    {files.map(file => (
                        file.preview ?
                            <div className="col-12 col-md-6">
                                <div className="img-holder">
                                    {deleteAble ? <div className="delete-holder">
                                        <Button variant="link" onClick={(e) => onDelete(e, file.preview, file.id)}><FontAwesomeIcon icon={faTrash} /></Button>
                                    </div> : ""}
                                    <img className="img-fluid" src={file.preview} />
                                </div>
                                {/*<p>{file.content.name}</p>*/}
                            </div>
                            : ""
                    ))}
                </div>
            )
        } else {
            return files.preview
                ?
                <img className="img-fluid" src={files.preview} />
                :
                <p>Drag file or click to upload file</p>
        }
    }
}

export default Dropzone
