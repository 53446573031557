import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from "react";
import instance from "../../util/api";
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "react-bootstrap";
import {connect, ConnectedProps} from "react-redux";
import Moment from "react-moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import Dropzone from "../../components/dropzone/Dropzone";
import moment from "moment";
import {toast} from "react-toastify";
import FusionToast from "../../components/toast/FusionToast";
import {useHistory, useLocation} from "react-router-dom";
import {eventInfoStatusRanking, statusRanking} from "../../util/helper/eventHelper";
import {KeyboardTimePicker} from "@material-ui/pickers";
import "../../pages/event/EventUserShow.scss";
import {RootState} from "../../store";
import TodoModalUser from "../../pages/event/components/TodoModalUser";
import {EVENT_SECTION_STATUS, IEvent, IEventSection} from "../../model/interfaces/event/IEvent";
import {AxiosResponse} from "axios";
import {IDirectUserEvent} from "../../model/interfaces/user/IUser";

const classNames = require('classnames');

const eventTimeInit = {
    "id": 0,
    "timeStart": null,
    "timeEnd": null,
    "eventTitle": "",
    "person": ""
}

const eventInfoInit = {
    id: -1,
    date: new Date(),
    locationId: "",
    locationValue: "",
    locationLabel: "",
    locationLat: 0,
    locationLng: 0,
    starting_time: new Date(),
    ending_time: new Date(),
    title: "",
    gender: "",
    status: EVENT_SECTION_STATUS.SECTION_CREATED,
    eventInfoUsers: [],
    all_users_confirmed: false,
    EventId: -1
};

const eventInit = {
    "title": "",
    "content": "",
    "emailText": "",
    "infos": []
};

const eventFeedbackInit = {
    "id": -1,
    "title": "",
    "description": {},
}

interface EventResponse {
    event: IEvent,
    error: any
}

interface IEventUserShow extends PropsFromRedux {
    id: string,
    eventType: string,
    changeEvent?: (props: any) => void
}

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;
const EventUserShow : React.FC<IEventUserShow> = (props) => {
    const [eventSections, setEventInfos] = useState<IEventSection[]>([eventInfoInit]);
    const [eventFeedbackAttr, setEventFeedbackAttr] = useState<any[]>([eventFeedbackInit]);
    const [eventUser, setEventUsers] = useState<IDirectUserEvent>({} as IDirectUserEvent);
    const [event, setEvent] = useState<any>(eventInit);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [id, setId] = useState<number>(-1);

    const {userData, changeEvent} = props;

    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        setId(parseInt(props.id));

        instance.get("/admin/event/one", {
            params: {
                id: props.id
            }
        })
            .then((resp: AxiosResponse<EventResponse>) => {
                if (!resp.data.error){
                    const userInEvent = resp.data.event.users.find((user : any) => user.username === userData.username) as IDirectUserEvent | undefined;

                    console.log("EVENT DATA", resp.data.event);

                    if (userInEvent){
                        userInEvent.preference = [];
                        userInEvent.user_status = [];
                    }

                    const eventFeedbackRespAttr = resp.data.event.eventFeedbackAttrs;

                    if (eventFeedbackRespAttr) {
                        const feedbackAttributes = eventFeedbackRespAttr.map((feedback : any) => {
                            try {
                                feedback.description = JSON.parse(feedback.description);
                            } catch (e) {

                            }
                            return feedback;
                        })

                        let countFeedback = 0;
                        resp.data.event.eventSections.forEach((eventInfo : any) => {
                            eventInfo.eventInfoUsers.forEach((user : any) => {
                                if (user.user_content !== null || user.user_images !== null){
                                    countFeedback++;
                                }
                            })
                        })

                        resp.data.event.eventSections.forEach((event : any) => {
                            if (userInEvent) {
                                const feedbackRequired = feedbackAttributes.find((feedback : any) => (feedback.title === "photo" && feedback.description.value === true) || (feedback.title === "text" && feedback.description.value === true));

                                //console.log(feedbackAttributes, feedbackRequired, countFeedback);

                                const userToEvent = event.eventInfoUsers.filter((info : any) => info.user_id === userInEvent.id);

                                console.log("USER TO EVENT", userToEvent);

                                if (!userInEvent.preference) {
                                    userInEvent.preference = [];
                                }
                                if (!userInEvent.user_status) {
                                    userInEvent.user_status = [];
                                }
                                userInEvent.preference.push(...userToEvent.filter((info : any)  => info.user_preference === true || info.user_preference === "true" || parseInt(info.user_preference)).map((info : any)  => info.EventInfoId));
                                userInEvent.user_status.push(...userToEvent.map((info : any)  => ({
                                    status: info.status,
                                    confirm_accepted: info.confirm_accepted,
                                    eventInfoId: info.EventSectionId,
                                    feedbackRequired: info.user_must_feedback ?
                                        info.user_must_feedback :
                                        (countFeedback === 0 && feedbackRequired !== undefined)
                                })));
                                console.log("USER IN EVENT", userInEvent);
                            }
                        });

                        setEventFeedbackAttr(feedbackAttributes);
                    }

                    let id = 0;
                    setEvent(resp.data.event);
                    setEventInfos(resp.data.event.eventSections);
                    setEventUsers(userInEvent ? {...userInEvent}: {} as IDirectUserEvent);
                    setLoaded(true);
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
                //console.log(resp.data.event);
            }).catch(err => {
            //console.log(err);
        });

        const search = new URLSearchParams(location.search);
        console.log(location);
        //console.log("USER EVENT LOCATION",search.get("user"));
        if (search.get("briefing")){
            instance.get("/user/event/briefing", {
                params: {
                    user: search.get("user"),
                    time: search.get("time")
                }
            }).then((resp) => {
                history.push(location.pathname)
                //console.log("RESPONSE FROM BRIEFING", resp.data);
            }).catch(err => {
                console.error(err);
            })
        }

    }, [props.id]);

    //console.log(eventUser);

    let userPreferences = {};
    /*if (eventUser.status !== "not_apply" && eventUser.status !== "reject") {
        userPreferences = eventUser.preference ? JSON.parse(eventUser.preference) : {};
    }*/

    //userPreferences = eventUser.preference



    //console.log(userPreferences);

    const getGlobalEventUserStatus = (eventSections: any[]) => {
        //console.log(eventUser, eventSections);
        if (eventUser.user_status && eventUser.user_status.length > 0 && eventSections) {

            console.log("GLOBAL_EVENT_STATUS", eventUser.user_status, eventSections);
            const userStati = eventSections.map(eventInfo => {
                console.log("EVENT INFO", eventInfo, eventUser);
                return (eventUser.user_status && eventUser.user_status.find((i : any) => i.eventInfoId && eventInfo.id === i.eventInfoId)) ?
                    eventUser.user_status.filter((i : any) => i.eventInfoId && eventInfo.id === i.eventInfoId) :
                    [{status: "not_apply"}];
            }).reduce((acc, curr) => [...acc, ...curr], []).map((i : any) => i.status);

            console.log("USER STATI", userStati);

            return userStati
                .sort((a, b) => statusRanking[a as keyof typeof statusRanking] - statusRanking[b as keyof typeof statusRanking]).reverse()[0];
        }

        return "not_apply";

    }

    const getCurrentUserStatus = (eventInfo : any) => {
        /*if (eventUser.status){
            const found = eventUser.status.filter(s => eventInfo.times.some(e => e.id === s.eventInfoId))
            if (found.length > 0){
                return found.sort((a,b) => statusRanking[a.status] - statusRanking[b])[0].status;
            }
        }*/

        if (eventUser.user_status && eventInfo){
            const found = eventUser.user_status.find((i : any) => i.eventInfoId === eventInfo.id);
            console.log("GET CURRENT USER STATUS", eventInfo, eventUser.status, found)
            if (found){
                return found;
            }
        }

        return "not_apply";
    }

    const logoClass = classNames({
        "big-logo-info": true,
        "big-logo-info-event": props.eventType.toLowerCase() === "event",
        "big-logo-info-logistic": props.eventType.toLowerCase() === "logistic"
    })

    let userHasEvent = getGlobalEventUserStatus(eventSections) !== "not_apply"

    console.log("USER HAS EVENT", event, props);

    return (
        <div>
            <div className="event-create-container">
                <div className="event-main-content row align-items-center">
                    <div className="col-4 col-md-2">
                        <div className={logoClass}>
                            <p className="text">
                                {props.eventType.toLowerCase() === "event" ? "E" : "L"}
                            </p>
                        </div>
                    </div>
                    <div className="col-8 col-md-10">
                        <header>
                            <h3>{event.title}</h3>
                        </header>
                        <p>{event.content}</p>
                    </div>
                </div>
                {eventSections.filter(event => (event.gender === "equal" || event.gender === userData.gender)).map((event, index, array) => {

                    const userInEvent = event.eventInfoUsers ? event.eventInfoUsers.find((eve : any) => eve.user_id === userData._id) : false;
                    const userTodo = userInEvent ? userInEvent.eventUserToDos : null;

                    return (
                        <Card key={"Event_" + event.id}>
                            <CardHeader>
                                <strong>Eventdatum:</strong> <Moment className="bold" format="DD.MM.YYYY">{event.date}</Moment>
                            </CardHeader>
                            <CardBody>
                                <div key={event.id} className="event-row-container mb-4">
                                    <Row className="align-items-center col-mb-2 mb-3">
                                        <Col xs={12} lg={12} xl={12}><h4><strong>Tätigkeit:</strong> {event.title}</h4></Col>
                                        <Col xs={6} lg={3} xl={2} className="bold">Zeit:</Col>
                                        <Col xs={6} lg={3} xl={2}><Moment
                                            format="HH:mm">{event.starting_time}</Moment> - <Moment
                                            format="HH:mm">{event.ending_time}</Moment></Col>
                                        <Col xs={6} lg={3} xl={2} className="bold">Anzahl an Personen:</Col>
                                        <Col xs={6} lg={3} xl={2}>{event.person}</Col>
                                        <Col xs={6} lg={3} xl={2} className="bold">Deine Wunschzeiten:</Col>
                                        <Col xs={6} lg={3}
                                             xl={2}>{eventUser.preference && eventUser.preference.find(i => i === event.id) ?
                                            <FontAwesomeIcon icon={faCheck}/> : ""}</Col>

                                        {event.meeting_time ? <>
                                            <Col xs={6} lg={3} xl={2} className="bold">Treffzeitpunkt:</Col>
                                            <Col xs={6} lg={3} xl={2}><Moment
                                                format="HH:mm">{event.meeting_time}</Moment></Col>
                                        </> : <></>}

                                        {event.starting_time ? <>
                                            <Col xs={6} lg={3} xl={2} className="bold">Einsatzzeit:</Col>
                                            <Col xs={6} lg={3} xl={2}><Moment
                                                format="HH:mm">{event.starting_time}</Moment></Col>
                                        </> : <></>}

                                    </Row>
                                    <Row className="align-items-center col-mb-2 ">
                                        <Col xs={6} lg={3} xl={2} className="bold">Dein Status:</Col>
                                        <Col xs={12} lg={3} xl={2} className="text-center">
                                            {event.status === EVENT_SECTION_STATUS.SECTION_STORNO ? <span>EVENT STORNIERT</span> :
                                                <>
                                                    {(userInEvent && userInEvent.user_assigned_backup === "true") ?
                                                        <p><strong>BACKUP</strong></p> : <></>}
                                                    {userTodo ? <TodoModalUser todo={userTodo}/> : <></>}
                                                    {userHasEvent ?
                                                        <UserActionButton status={getCurrentUserStatus(event)}
                                                                          eventUser={eventUser}
                                                                          currentInfo={event}
                                                                          eventSections={eventSections}
                                                                          userData={userData}
                                                                          eventFeedbackAttr={eventFeedbackAttr}
                                                                          eventId={id}
                                                                          changeEvent={changeEvent}
                                                        />
                                                        : <></>}
                                                </>
                                            }
                                        </Col>
                                        {userInEvent ?
                                            <>
                                                <Col xs={12} md={12}>
                                                    {userInEvent.status !== "finish" && userInEvent.briefing_read &&
                                                        <p>Du hast das Briefing gelesen und verstanden!</p>}
                                                    {userInEvent.status !== "finish" && userInEvent.check_in &&
                                                        <p>Du hast dich erfolgreich eingecheckt!</p>}
                                                    {userInEvent.status !== "finish" && userInEvent.check_out &&
                                                        <p>Du hast dich erfolgreich ausgecheckt!</p>}
                                                </Col>
                                            </>
                                            : <></>
                                        }
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    )
                })}
                {!userHasEvent ?
                    <div className="mt-4 text-right">
                        <UserActionButton
                            status={getGlobalEventUserStatus(eventSections)}
                            currentInfo={event}
                            eventSections={eventSections}
                            userData={userData}
                            eventFeedbackAttr={eventFeedbackAttr}
                            eventId={id}
                            changeEvent={changeEvent}
                        />
                    </div>
                   : <></>}
            </div>
        </div>
    );
};



type TImage = {
    content: string | null,
    preview: string | null
}

type TImageState = {
    unset: TImage[]
}

const initImages = {
    unset: [{
        content: null,
        preview: null
    }]
} as TImageState;
interface IUserActionButton {
    status: string,
    eventSections: IEventSection[],
    eventUser?: any,
    currentInfo: IEventSection | IEvent,
    userData: any,
    eventFeedbackAttr: any,
    eventId: number,
    changeEvent?: (props: any) => void
}

const UserActionButton : React.FC<IUserActionButton> = (props) => {
    const [form, setForm] = useState({
        content: "",
        selectEvent: [],
        checkIn: moment().second(0).toDate(),
        checkOut: moment().second(0).toDate()
    });
    const [userStatus, setUserStatus] = useState("not_apply");
    const [userConfirmedEvent, setUserConfirmedEvent] = useState(false);
    const [userImages, setImages] = useState<TImageState>(initImages);
    const [error, setError] = useState("");

    const {status, eventSections, eventUser, currentInfo, changeEvent, userData} = props;

    console.log("PROPS", status, eventSections, eventUser, currentInfo);
    let userIsInEvent = null

    useEffect(() => {
        let currentUserStatus = status
        let userConfirmedEvent = false;
        if (currentInfo && "eventInfoUsers" in currentInfo && currentInfo.eventInfoUsers){
            const foundUser = currentInfo.eventInfoUsers.find((user : any) => user.user_id === eventUser.id);

            console.log("USER TO EVENT", currentInfo.eventInfoUsers, foundUser);

            if (foundUser){
                currentUserStatus = foundUser.status || "not_apply";
                userConfirmedEvent = foundUser.confirm_accepted || false;
            } else {
                currentUserStatus = "not_apply"
            }
        }
        console.log("CURRENT USER STATUS", currentUserStatus);

        setUserStatus(currentUserStatus);
        setUserConfirmedEvent(userConfirmedEvent);
    }, [currentInfo, status, eventUser])

    useEffect(() => {
        setInterval(() => {
            if (userStatus){
                switch (userStatus){
                    case "not_confirmed":
                        setForm(form => ({...form, checkIn: moment().second(0).toDate(), checkOut: moment().second(0).toDate()}));
                        break;
                    case "confirm":
                        console.log("INTERVAL", userStatus, form)
                        setForm(form => ({...form, checkOut: moment().second(0).toDate()}));
                        break;
                    default:
                        break;
                }
            }
        }, 60000)
    }, [userStatus]);

    const onClickButton = (action : string, id : number | null = null) => {

        if (action === "reject_confirmed_event"){
            toggleRejectModal();
        } else {
            //console.log(action, form)
            instance.post("/user/event/action", form, {
                params: {
                    action: action,
                    eventId: props.eventId,
                    eventInfoId: id ? id : null,
                }
            }).then(resp => {
                //console.log(resp.data);
                if (!resp.data.error){
                    if (action === "apply"){
                        toggle();
                        toggleSucceess();
                    }
                    if (changeEvent) {
                        changeEvent({id: props.eventId, action: action, eventInfoId: id, resp: resp.data});
                    }

                    toast(<FusionToast className="success" header="Status für Event/Logistik geändert" message="Dein Status für das Event/Logistik wurde erfolgreich geändert!" />)
                    setUserStatus(resp.data.newStatus);

                } else{
                    console.error(resp);
                    toast(<FusionToast className="error" header="Status konnte nicht geändert werden" message="Dein Status für das Event/Logistik konnte nicht geändert!" />)
                }
            }).catch(err => {
                //console.log(err);
            })
        }
    };

    const onChange = (event : ChangeEvent<FormControlElement>, i : number | null = null) => {
        event.persist();
        //console.log(event.target.name, i, event.target.value);
        if (i !== null && i >= 0){
            setForm((form : any) => (
                {...form, [event.target.name]: {...form[event.target.name], [i]: event.target.value}}
            ))
        } else {
            setForm((form : any) => (
                {...form, [event.target.name]: event.target.value}
            ))
        }
    };

    const onChangeCheckbox = (event : any) => {
        event.persist();
        //console.log(event.target.name, event.target.value, event.target.checked);
        setForm((form : any) => (
            {...form, [event.target.name]: {...form[event.target.name], [event.target.value]: event.target.checked}}
        ))
    }

    const onUpdateTime = (event : any, fieldName : string) => {
        console.log(event, fieldName)
        setForm(data => ({...data, [fieldName]: event}))
    }

    const onFinish = (event : SyntheticEvent, action : string, id : number | null = null, feedbackRequired : boolean = false) => {
        event.preventDefault();
        const photo = props.eventFeedbackAttr.find((attr : any) => attr.title === "photo");
        const photoNumber = props.eventFeedbackAttr.find((attr : any) => attr.title === "photoNumber");
        const additionalText = props.eventFeedbackAttr.find((attr : any) => attr.title === "text");

        const formData = new FormData();
        const files = []

        const addedImages = userImages.unset.filter(img => img.content && img.preview);

        if (feedbackRequired && addedImages.length < photoNumber.description.value){
            setError("Du musst mindestens " + photoNumber.description.value + " Bilder abgeben!");
        } else {
            setError("");
            Object.keys(userImages).forEach(key => {
                if (Array.isArray(userImages[key as keyof TImageState])){
                    (userImages[key as keyof TImageState] as TImage[]).forEach(image => {
                        formData.append("file", image.content || "");
                        files.push(image.content);
                    })
                }
            })

            for (let [key, value] of Object.entries(form)){
                if (typeof value === "object"){
                    for (let [k, val] of Object.entries(value)) {
                        formData.append(key + "[]", val);
                    }
                } else {
                    formData.append(key, value);
                }
            }

            console.log(form, userImages, photoNumber, photo, additionalText)

            //console.log(form);
            instance.post("/user/event/action", formData, {
                params: {
                    action: action,
                    eventId: props.eventId,
                    eventInfoId: id ? id : null,
                }
            }).then(resp => {
                //console.log(resp.data);
                if (!resp.data.error){
                    toast(<FusionToast className="success" header="Status für Event/Logistik geändert" message="Dein Status für das Event/Logistik wurde erfolgreich geändert!" />)
                    setUserStatus(resp.data.newStatus);
                } else{
                    console.error(resp);
                    toast(<FusionToast className="error" header="Status konnte nicht geändert werden" message="Dein Status für das Event/Logistik konnte nicht geändert!" />)
                }
            }).catch(err => {
                console.error(err);
                toast(<FusionToast className="error" header="Status konnte nicht geändert werden" message="Dein Status für das Event/Logistik konnte nicht geändert!" />)
            })
        }
    };

    const onPossibleFinish = (event : SyntheticEvent, action : string, id : number | null = null) => {
        console.log(eventUser.status, currentInfo, eventUser.user_status.find((s : any) => (s.eventInfoId === currentInfo.id && !s.feedbackRequired)))
        const currentStatus = eventUser.user_status.find((s : any) => (s.eventInfoId === currentInfo.id));

        if (currentStatus && currentStatus.feedbackRequired) {
            toggle();
        } else {
            onFinish(event, action, id, false);
        }


        /*if ((currentStatus && !currentStatus.feedbackRequired) || currentInfo.status === "protocol_send"){
            onFinish(event, action, id, false);
        } else {
            toggle();
        }*/
    }

    const [modal, setModal] = useState(false);
    const [modalSuccess, setModalSuccess] = useState(false);

    const toggle = () => setModal(!modal);
    const toggleSucceess = () => setModalSuccess(!modalSuccess);

    const [rejectModal, setRejectModal] = useState(false);

    const toggleRejectModal = () => setRejectModal(!rejectModal);


    let wunschterminIsRequired = false;
    let counter = 0;
    if (eventSections) {
        eventSections.forEach(event => {
                console.log("COUNTER", event)
                if (event.gender === props.userData.gender || event.gender === "equal"){
                    counter++;
                    if (counter > 1){
                        wunschterminIsRequired = true;
                    }
                }
        })
    }

    console.log("USER STATUS", currentInfo, userStatus, eventSections, eventUser);
    console.log("FORM", form);

    switch(userStatus) {
        case "not_apply":
            let buttonDisabled = false;


            eventSections.map((event, index, array) => {
                    let show = true
                    if (eventInfoStatusRanking[event.status as keyof typeof eventInfoStatusRanking] >= 2) {
                        show = false;
                        console.log(eventUser)
                        if (currentInfo && eventUser) {
                            const userEventStatus = eventUser.user_status.find((u : any) => u.eventInfoId === event.id);
                            if (userEventStatus && statusRanking[userEventStatus.status as keyof typeof statusRanking] >= 3) {
                                show = false;
                            }
                        }
                    }
                    if (show) {
                        buttonDisabled = true;
                    }
            });

            const eventSelected = Object.keys(form.selectEvent).filter(key => {
                return form.selectEvent[key as any]
            });

            console.log("EVENT SELECTED", form.selectEvent, eventSelected)

            return (
                <div>
                    {buttonDisabled ? <Button variant="secondary" onClick={toggle}>Für Event bewerben</Button> : <p>Keine Bewerbung mehr möglich</p> }
                    <Modal show={modal} fade={false} centered={true} size="lg" toggle={toggle}>
                        <ModalHeader closeButton className="bold background-black" data-bs-theme="dark">Für Event bewerben</ModalHeader>
                        <ModalBody>
                            <p>Vielen Dank, dass du dich für dieses Event interessierst!
                            <br/>Möchtest du uns noch etwas mitteilen?</p>
                            <FormGroup>
                                <Form.Control as="textarea" rows={3} name="content" id="content" value={form.content} onChange={onChange} placeholder="Deine Mitteilung" />
                            </FormGroup>

                            <p className="small mt-4">Die Zeiten welche du wählst, solltest du unbedingt frei sein, da wir dich bei Annahme fix diesem Termin zuteilen.</p>

                            {eventSections.filter(event => (event.gender === "equal" || event.gender === userData.gender)).map((event, index, array) => {
                                console.log(event);

                                if (moment(event.date).isSameOrAfter(moment(), 'day')) {
                                    let show = true
                                    if (eventInfoStatusRanking[event.status as keyof typeof eventInfoStatusRanking] >= 2){
                                        show = false;

                                        if (currentInfo && eventUser) {
                                            const userEventStatus = eventUser.user_status.find((u : any)  => u.eventInfoId === event.id);
                                            if (userEventStatus && statusRanking[userEventStatus.status as keyof typeof statusRanking] >= 3){
                                                show = false;
                                            }
                                        }
                                    }
                                    if (show){
                                        return (
                                            <>
                                            <Card key={"Event_" +event.id}>
                                                <CardHeader><strong>Eventdatum:</strong> <Moment
                                                    format="DD.MM.YYYY">{event.date}</Moment></CardHeader>
                                                <CardBody>
                                                    <Row className="event-date-info-header justify-content-center">
                                                        <Col md={4}>
                                                            Bitte wähle deine Zeiten
                                                        </Col>
                                                        <Col md={2}>
                                                            Startzeit
                                                        </Col>
                                                        <Col md={2}>
                                                            Endzeit
                                                        </Col>
                                                        <Col md={2}>
                                                            Aktion
                                                        </Col>
                                                        <Col md={2}>
                                                            Anzahl an Personen
                                                        </Col>
                                                    </Row>
                                                {event.gender === props.userData.gender || event.gender === "equal" ?
                                                    <Row className="justify-content-center">
                                                        <Col md={4}>
                                                            <Form.Check
                                                                type="switch"
                                                                name="selectEvent"
                                                                value={event.id}
                                                                onChange={onChangeCheckbox} />
                                                        </Col>
                                                        <Col md={2}>
                                                            <Moment format="HH:mm">{event.starting_time}</Moment>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Moment format="HH:mm">{event.ending_time}</Moment>
                                                        </Col>
                                                        <Col md={2}>
                                                            {event.title}
                                                        </Col>
                                                        <Col md={2}>
                                                            {event.person}
                                                        </Col>
                                                    </Row>
                                                    :<></>}
                                                </CardBody>
                                            </Card>
                                            </>
                                        )}
                                } else {
                                    return <></>
                                }
                            })}
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="primary" onClick={toggle}>Abbrechen</Button>{' '}
                            {buttonDisabled ? <Button variant="secondary" disabled={(eventSelected.length === 0) ? true : false} onClick={() => onClickButton("apply")}>Für Event bewerben</Button> : <p>Keine Bewerbung mehr möglich</p> }
                        </ModalFooter>
                    </Modal>
                    <Modal show={modalSuccess} fade={false} centered>
                        <ModalHeader>
                            Vielen Dank für deine Bewerbung
                        </ModalHeader>
                        <ModalBody>
                            Wir werden so bald wie möglich eine
                            Entscheidung treffen. Wenn du nach 48 Stunden noch keine Bestätigung von
                            uns erhalten hast, wurde dieser Einsatz jemand anderem zugeteilt.
                        </ModalBody>
                    </Modal>
                </div>
            )
        case "apply": return <Button onClick={() => onClickButton("reject")}>Bewerbung zurückziehen</Button>;
        case "confirm_for_event": return (
            <>
                <p className="bold mb-0 pb-0">Du wurdest erfolgreich angenommen.</p>
                {!userConfirmedEvent ?
                    <p className="small">Bitte bestätige deinen Einsatz.</p> : <></>}
                <Row>
                    {!userConfirmedEvent ?
                        <Col xs={12} className="mb-2">
                            <Button className="w-100" color={"success"} onClick={() => onClickButton("confirm_accepted", props.currentInfo.id)}>Einsatz bestätigen</Button>
                        </Col> : <></>
                    }
                    {/*<Col xs={12}>
                        <Button className="w-100"  color={"danger"} onClick={() => onClickButton("reject_confirmed_event")}>Wieder Abmelden</Button>
                    </Col>*/}
                </Row>
                <ConfirmRejectModal id={currentInfo.id} eventId={props.eventId} toggle={toggleRejectModal} open={rejectModal} />
            </>
        )
        case "not_confirmed":
            return (
                <>
                    <FormGroup>
                        <Form.Label htmlFor="checkIn" className="bold">Check-In Zeit</Form.Label>
                        <KeyboardTimePicker
                            label="Start-Zeit (HH:mm)"
                            placeholder="08:00"
                            format="HH:mm"
                            value={form.checkIn}
                            onChange={e => onUpdateTime(e, "checkIn")}
                        />
                            {/*<DatePicker
                                placeholder="Check-In Zeit"
                                ranges={[]}
                                format="HH:mm" value={form.checkIn}
                                disabledMinutes={minute => minute % 15 !== 0}
                                onChange={e => onUpdateTime(e, "checkIn")}
                                style={{minWidth: "100%"}} />*/}
                    </FormGroup>
                    <Button className="w-100"  onClick={() => onClickButton("confirm", props.currentInfo.id)}>Check-In</Button>
                </>
                /*<Row>
                    <Col className="text-right" md={10}>
                        <FormGroup row className="justify-content-end">
                            <Form.Label htmlFor="checkIn" sm={2}>Check-In Zeit</Form.Label>
                            <Col sm={3}>
                                <DatePicker
                                    placeholder="Check-In Zeit"
                                    ranges={[]}
                                    format="HH:mm" value={form.checkIn}
                                    disabledMinutes={minute => minute % 15 !== 0}
                                    onChange={e => onUpdateTime(e, "checkIn")}
                                    style={{minWidth: "100%"}} />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className="text-right" md={2}>
                        <Button onClick={() => onClickButton("confirm")}>Check-In</Button>
                    </Col>
                </Row>*/
            );
        case "confirm":
            return (
                <>
                    <FormGroup>
                        <Form.Label htmlFor="checkOut" className="bold">Check-Out Zeit</Form.Label>
                        <KeyboardTimePicker
                            label="Start-Zeit (HH:mm)"
                            placeholder="08:00"
                            format="HH:mm"
                            value={form.checkOut}
                            onChange={e => onUpdateTime(e, "checkOut")} />
                        {/*<DatePicker
                            placeholder="Check-Out Zeit"
                            ranges={[]}
                            format="HH:mm" value={form.checkOut}
                            disabledMinutes={minute => minute % 15 !== 0}
                            onChange={e => onUpdateTime(e, "checkOut")}
                            style={{minWidth: "100%"}} />*/}
                    </FormGroup>
                    <Button className="w-100"  onClick={() => onClickButton("ended", props.currentInfo.id)}>Check-Out</Button>
                </>

               /* <Row>
                    <Col className="text-right" md={10}>
                        <FormGroup row className="justify-content-end">
                            <Form.Label htmlFor="checkIn" sm={2}>Check-Out Zeit</Form.Label>
                            <Col sm={3}>
                                <DatePicker
                                    placeholder="Check-In Zeit"
                                    ranges={[]}
                                    format="HH:mm" value={form.checkOut}
                                    disabledMinutes={minute => minute % 15 !== 0}
                                    onChange={e => onUpdateTime(e, "checkOut")}
                                    style={{minWidth: "100%"}} />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className="text-right" md={2}>
                        <Button onClick={() => onClickButton("ended")}>Check-Out</Button>
                    </Col>
                </Row>*/
            );
        case "ended":
            const photo = props.eventFeedbackAttr.find((attr : any)  => attr.title === "photo");
            const photoNumber = props.eventFeedbackAttr.find((attr : any) => attr.title === "photoNumber");
            const additionalText = props.eventFeedbackAttr.find((attr : any) => attr.title === "text");

            //console.log("FEEDBACK", props.eventFeedbackAttr);
            return (
                <>
                    <Button onClick={(ev) => onPossibleFinish(ev, "finish", props.currentInfo.id)}>Einsatz beenden</Button>
                    <Modal show={modal} fade={false} centered={true} size="lg" onHide={toggle}>
                        <Form onSubmit={(ev) => onFinish(ev, "finish", props.currentInfo.id, true)}>
                        <ModalHeader closeButton>Einsatz beenden?</ModalHeader>
                        <ModalBody>
                            {error !== "" &&
                            <Row>
                                <Col xs={12}>
                                    <Alert variant="danger">{error}</Alert>
                                </Col>
                            </Row>}
                            <Row>
                                {(photo && photo.description.value && parseInt(photoNumber.description.value) > 0) ?
                                    <Col md={6}>
                                        <Card>
                                            <CardHeader>Upload Fotos von deinem Einsatz!</CardHeader>
                                            <CardBody>
                                                <Dropzone files={userImages} deleteAble={true} name="unset" setFiles={setImages} acceptedFiles={""} />
                                            </CardBody>
                                        </Card>
                                    </Col> : ""
                                }
                                {(additionalText && additionalText.description.value) ? props.eventFeedbackAttr.map((attr : any, i : number) => {
                                    if (attr.description.type === "TEXT") {
                                        //console.log(attr, i)
                                        return (
                                            <Col md={6}>
                                                <Card>
                                                    <CardBody>
                                                        <CardHeader>
                                                            {attr.title}
                                                        </CardHeader>
                                                        <FormGroup>
                                                            <Form.Control type="textarea" required name="content" id="content" value={form.content[i]} onChange={(e) => onChange(e, i)} placeholder={attr.description.value} />
                                                        </FormGroup>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        )
                                    }
                                }) : ""}
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="submit">Einsatz beenden</Button>
                        </ModalFooter>
                        </Form>
                    </Modal>
                </>
        )
        case "finish":
            return <p>Du hast diese Aufgabe beendet!</p>
        default: return <></>
    }
}

interface IConfirmRejectModal {
    open: boolean,
    toggle: () => void,
    id: number,
    eventId: number
}

const ConfirmRejectModal : React.FC<IConfirmRejectModal> = (props) => {
    const {open, toggle, id} = props;

    const sendRejectRequest = (event : ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();

        const formData = new FormData(event.target);

        /*for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }*/

        instance.post("/user/event/action", formData, {
            params: {
                action: "reject_confirmed_event",
                eventId: props.eventId,
                eventInfoId: id ? id : null,
            }
        }).then(resp => {
            //console.log(resp.data);
            if (!resp.data.error){
                toast(<FusionToast className="success" header="Status für Event/Logistik geändert" message="Dein Status für das Event/Logistik wurde erfolgreich angefragt!" />)
                toggle();
            } else{
                console.error(resp);
                toast(<FusionToast className="error" header="Status konnte nicht geändert werden" message="Dein Status für das Event/Logistik konnte nicht geändert!" />)
                toggle();
            }
        }).catch(err => {
            //console.log(err);
        })
    }

    return (
        <Modal show={open} centered={true} fade={false} onHide={toggle}>
            <Form onSubmit={sendRejectRequest}>
                <ModalHeader closeButton>Von Event abmelden</ModalHeader>
                <ModalBody>
                    <p>Damit wir dich vom Event abmelden können. Bitte teile uns den Grund für die Abmeldung mit?</p>
                        <FormGroup>
                            <Form.Label htmlFor="reasonOfRejection">Grund der Abmedlung</Form.Label>
                            <Form.Control type="textarea" name="reason_rejection" id="reasonOfRejection" />
                        </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button variant="primary" onClick={toggle}>Abbrechen</Button>{' '}
                    <Button variant="secondary" type={"submit"}>Abschicken</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = (state : RootState) => ({
    userData: state.user
})

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EventUserShow);