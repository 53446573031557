import React from 'react';
import {Toast, ToastBody, ToastHeader} from "react-bootstrap";
import "./FusionToast.scss"

const classNames = require("classnames");

interface IFusionToast {
    className: any,
    header: string,
    message: string
}
const FusionToast : React.FC<IFusionToast> = (props) => {

    const classes = classNames("q1-toast", props.className);

    return (
        <Toast className={classes}>
            <Toast.Header>
                <strong className="me-auto">{props.header}</strong>
                </Toast.Header>
            <Toast.Body>{props.message}</Toast.Body>
        </Toast>
    );
};

export default FusionToast;
