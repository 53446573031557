import React, {ChangeEvent, useEffect, useState} from 'react';
import NoLayout from "../../layouts/NoLayout/NoLayout";
import Logo from "../../assets/images/logo_fusion.png";
import qs from 'qs';
import instance from "../../util/api";
import {Button, Col, Row} from "react-bootstrap";
import InputType from "../../components/inputType/InputType";
import Dropzone from "../../components/dropzone/Dropzone";
import {LOGIN} from "../../util/routes/routes";
import {toast} from "react-toastify";
import FusionToast from "../../components/toast/FusionToast";
import {registerInitState} from "../../state/reducer/user/userRegisterSlice";
import {useHistory, useLocation} from "react-router-dom";
import {Formik, Form} from "formik";
import TwoColumnLayout from "../../layouts/TwoColumnLayout/TwoColumnLayout";
import Background from "../../assets/images/loginRegisterBg.png";

const FormData = require('form-data');

const formInit = registerInitState;

interface IRegisterComplete {

}


type TImage = {
    content: string | null,
    preview: string | null
}

type TImageState = {
    fullscreen: TImage,
    profile: TImage,
    unset: TImage[]
}

const initImages = {
    fullscreen: {
        content: null,
        preview: null
    },
    profile: {
        content: null,
        preview: null
    },
    unset: [{
        content: null,
        preview: null
    }]
} as TImageState;

const RegisterComplete : React.FC<IRegisterComplete> = (props) => {
    const [form, setForm] = useState(formInit);
    const [attrForm, setAttrForm] = useState<any>({});
    const [attributes, setAttributes] = useState<any[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [validate, setValidate] = useState<boolean>(false);
    const [fileError, setFileError] = useState({fullscreen: false, profile: false})

    const [images, setImages] = useState(initImages);

    const location = useLocation();
    const history = useHistory();

    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    useEffect(() => {
        instance.get("user/register/proofToken", {
            params: {
                id: query.id,
                token: query.token
            }
        })
            .then(resp => {
                if (!resp.data.error){
                    setForm(resp.data.user);
                    setAttributes(resp.data.attr);
                    const formData = resp.data.attr.reduce((obj : any, item : any) => ({...obj, [item.slug]:""}), {});
                    //console.log(formData);
                    setAttrForm(formData);
                    setLoaded(true);
                    //console.log(resp.data);
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
            }).catch(err => {
            //console.log(err);
        });
    }, []);

    const handleChange = (event : ChangeEvent<HTMLInputElement | HTMLAreaElement>) => {
        //console.log(event, attribute);
        if (event.target) {
            const target = event.target as HTMLInputElement;
            if (target.type === "checkbox") {
                if (target.checked) {
                    setAttrForm({
                        ...attrForm,
                        [target.name]: [...attrForm[target.name], target.value]
                    })
                } else {
                    setAttrForm({
                        ...attrForm,
                        [target.name]: [...attrForm[target.name].filter((name : string) => name !== target.value)]
                    })
                }
            } else {
                setAttrForm({
                    ...attrForm,
                    [target.name]: target.value
                })
            }
        }
    };

    //console.log(attrForm);

    const handleSubmit = async () => {
        //console.log("Send Data");

        let error = 0;

        form.info = attrForm;

        const formData = new FormData();

        const files = []
        Object.keys(images).forEach(key => {
            if (Array.isArray(images[key as keyof TImageState])){
                (images[key as keyof TImageState] as TImage[]).forEach(image => {
                    formData.append("file", image.content || "");
                    files.push(image.content);
                })
            }
        })

        if (images.fullscreen.content){
            formData.append("fullscreen", images.fullscreen.content);
            setFileError(error => ({...error, fullscreen: false}))
        } else {
            setFileError(error => ({...error, fullscreen: true}))
            error++;
        }

        if (images.profile.content){
            formData.append("profile", images.profile.content);
            setFileError(error => ({...error, profile: false}))
        } else {
            setFileError(error => ({...error, profile: true}))
            error++;
        }

        //console.log("SEND DATA", error);

        if (error > 0){
            window.scrollTo({top: 0, behavior: 'smooth'});
        } else {
            formData.append("id", query.id);
            for (let [key, value] of Object.entries(attrForm)) {
                formData.append(key, value);
            }

            form.id = query.id as string;

             instance.post('/user/register/complete', formData)
                 .then(res => {
                     //console.log(res.data);
                     if (!res.data.error){
                         history.push(LOGIN);
                     }
                 })
                 .catch(err => console.error(err));
        }

    };

    const handleInvalidSubmit = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    console.log("ATTRIBUTEFORM", attrForm)

    return (
        <TwoColumnLayout className="Register" background={Background}>
            <div className="login-container">
                <div className="login-main mb-5">
                    <h1 className="text-center mb-2">Herzlich Willkommen, bitte fülle d</h1>
                </div>
                {loaded ?
                    <Formik
                        initialValues={attrForm}
                        onSubmit={handleSubmit}
                        validateOnChange={false}
                    validateOnBlur={false}
                    //onInvalidSubmit={handleInvalidSubmit}
                >
                    {({ handleSubmit, handleChange, setFieldValue, values, errors }) => {

                        return (
                            <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6}>
                                    {attributes.map((attr, index) => index <= 7 &&
                                        <div className="mb-3">
                                            <InputType type={attr.type} data={attr}
                                                       handleChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
                                                       value={attrForm[attr.slug]} form={attrForm} key={attr.slug} name={attr.slug}/>
                                        </div>
                                    )}
                                </Col>
                                <Col md={6}>
                                    {attributes.map((attr, index) => index > 7 &&
                                        <div className="mb-3">
                                            <InputType type={attr.type} data={attr}
                                                       handleChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
                                                       value={attrForm[attr.slug]} form={attrForm} key={attr.slug} name={attr.slug} />
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={8}>
                                    <Row>
                                        <Col md={12} lg={6}>
                                            <label className="required"><strong>Ganzkörper Foto</strong></label>
                                            <Dropzone files={images} name="fullscreen" setFiles={setImages}
                                                      acceptedFiles={""} deleteAble={false}/>
                                            {fileError.fullscreen &&
                                                <p className="alert alert-danger">Bitte lade ein Ganzkörperfoto
                                                    hoch!</p>}
                                        </Col>
                                        <Col md={12} lg={6}>
                                            <label className="required"><strong>Profil Foto</strong></label>
                                            <Dropzone files={images} name="profile" setFiles={setImages}
                                                      acceptedFiles={""} deleteAble={false}/>
                                            {fileError.profile &&
                                                <p className="alert alert-danger">Bitte lade ein Profilfoto hoch!</p>}
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col md={12}>
                                            <p><strong>Weitere Fotos hinzufügen? (Mehrere per Drag&Drop
                                                möglich!)</strong></p>
                                            <Dropzone files={images} deleteAble={true} name="unset" setFiles={setImages}
                                                      acceptedFiles={""}/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={12} className="text-right mt-4">
                                    <Button type="submit" variant="primary">Registrierung vervollständigen</Button>
                                </Col>
                            </Row>
                        </Form>
                    )
                    }}
                </Formik>
                : <div>Loading your Data</div>
            }
            </div>
        </TwoColumnLayout>
    );
};

export default RegisterComplete;
