import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {Button, Col, Container, Form, FormGroup, Row} from "react-bootstrap";
import BaseLayout from "../../../layouts/BaseLayout/BaseLayout";
import {DatePicker} from "rsuite";
import {connect, ConnectedProps} from "react-redux";
import 'rsuite/dist/rsuite.min.css';
import "./EventNew.scss";
import instance from "../../../util/api";
import {ADMIN_EVENT} from "../../../util/routes/routes";
import GooglePlacesAutocomplete, {geocodeByPlaceId, getLatLng} from "react-google-places-autocomplete";
import {bindActionCreators} from "redux";
import EmailPreviewModal from "../../../components/modal/EmailPreviewModal";
import moment from "moment";
import {KeyboardTimePicker} from "@material-ui/pickers";
import HtmlEditor from "../../../components/editor/HtmlEditor";
import {EditorState} from "draft-js";
import {convertToHTML} from "draft-convert";
import {RouteComponentProps, useLocation} from "react-router-dom";
import {Divider} from "@material-ui/core";
import {AppDispatch, RootState} from "../../../store";
import className from "classnames";


interface MatchProps {
    eventType: string,
    id: string
}
interface IEventNew extends PropsFromRedux, RouteComponentProps<MatchProps> {

}

const eventTimeInit = {
    "id": 0,
    "timeStart": null,
    "timeEnd": null,
    "eventTitle": "",
    "person": "",
    "backupPerson": ""
}

const eventInfoInit = {
    "id": 0,
    "date": null,
    "meeting_time": null,
    "starting_time": null,
    "times": [eventTimeInit]
};

const eventInit = {
    "title": "",
    "content": "",
    "emailText": EditorState.createEmpty(),
    "sumEmail": true,
    "mailDate" : "",
    "type": "EVENT",
    "customer": null,
    "infos": []
};

const EventNew : React.FC<IEventNew> = (props) => {
    const [eventSections, setEventInfos] = useState<any[]>([eventInfoInit]);
    const [eventNew, setEventNew] = useState<any>(eventInit);
    const [eventNewInvalid, setEventNewInvalid] = useState<any>(Object.keys(eventInit).map(key => ({[key]: false})))
    const [type, setType] = useState<string>("event");
    const {userList} = props;
    const selectedUser = userList.selectUserList;
    const [modal, setModal] = useState<boolean>(false);
    const [email, setEmail] = useState<any>({
        subject: "Wöchentliche Eventliste",
        from: "noreply@fusion.at",
        html: "",
        eventToServer: {}
    })

    const location = useLocation<any>();

    const toggle = () => setModal(!modal);

    useEffect(() => {
        let customer = null as any;

        if (location && location.state && location.state.customer) {
            customer = location.state.customer;
        }

        setType(props.match.params.eventType);
        setEventNew((event : any) => ({...event, type: props.match.params.eventType.toUpperCase(), customer: customer}))
    }, [props.match.params.eventType]);

    const onAddNewDate = () => {
        setEventInfos(events => ([...events, {...eventInfoInit, id: eventSections[eventSections.length-1].id + 1}]))
    };

    const onAddNewRow = (dateIndex : number) => {
        setEventInfos(events => (
            events.map(event => {
                if (event.id === dateIndex){
                    event.times = [...event.times, {...eventTimeInit, id: event.times[event.times.length-1].id+1}]
                }
                return event;
            })
        ))
    };

    const onRemoveRow = (dateIndex : number, id : number) => {
        setEventInfos(events => (
            events.map(event => {
                if (event.id === dateIndex){
                    event.times = event.times.filter((time: any) => time.id !== id);
                }
                return event;
            })
        ))
        //setEventInfos(events => (events.slice(0, id).concat(events.slice(id + 1, events.length))))
    };

    const onRemoveDate = (dateIndex : number) => {
        setEventInfos(events => (events.filter(event => event.id !== dateIndex)));
    };

    const onUpdateEvent = (ev : ChangeEvent<HTMLInputElement>) => {
        if (ev instanceof Date){
            setEventNew({...eventNew, mailDate: ev})
        } else {
            if (ev.target.type === "checkbox"){
                setEventNew({...eventNew, [ev.target.name]: ev.target.checked})
            } else {
                setEventNew({...eventNew, [ev.target.name]: ev.target.value})
            }
        }
    };

    const onUpdateEmailText = (value : EditorState) => {
        setEventNew({...eventNew, emailText: value})
    }

    const onUpdateEventInfos = (event : any, eventId : number, id : number | null, fieldName? : string) => {
        //console.log(event);
        if (event instanceof Date && id === null){
            let name = "date";
            if (fieldName) {
                name = fieldName;
            }
            setEventInfos(events => (
                events.map((ev, i) => {
                    if (ev.id === eventId){
                        return {...ev, [name]: event, id: eventId}
                    }
                    return ev;
                })
            ));
        } else if (event && event.value && event.value.place_id){
            geocodeByPlaceId(event.value.place_id).then(result => {
                getLatLng(result[0]).then((latLng) => {
                    setEventInfos(events => (
                        events.map((ev, i) => {
                            if (ev.id === eventId){
                                return {...ev, place: event.value.description, lat: latLng.lat, lang: latLng.lng, id: eventId, placeValue: event}
                            }
                            return ev;
                        })
                    ));
                }).catch(err => {
                    //console.log(err);
                    setEventInfos(events => (
                        events.map((ev, i) => {
                            if (ev.id === eventId){
                                return {...ev, place: event.description, id: eventId}
                            }
                            return ev;
                        })
                    ));
                })
            }).catch(err => {
                //console.log(err);
                setEventInfos(events => (
                    events.map((ev, i) => {
                        if (ev.id === eventId){
                            return {...ev, place: event.description, id: eventId}
                        }
                        return ev;
                    })
                ));
            })
        } else {
            setEventInfos(events => (
                events.map((ev, i) => {
                    if (ev.id === eventId){
                        ev.times = ev.times.map((time : any) => {
                            if (time.id === id && !fieldName){
                                return {...time, [event.name]: event.value, id: id}
                            } else if (time.id === id && fieldName){
                                return {...time, [fieldName]: event, id: id}
                            }
                            return time;
                        })
                    }
                    return ev;
                })
            ));
        }
    };


    const sendToAPI = (eventToServer : any) => {
        instance.post("/admin/event", eventToServer)
            .then((resp) => {
                const respData = resp.data;

                if (!respData.error){
                    //selectUserList([]);
                    props.history.push(ADMIN_EVENT.path.replace(":eventType", type));
                }

                //console.log(resp);
            }).catch((err) => {
            //console.log(err);
        })
    }

    const onSend = (e : SyntheticEvent<any>) => {
        e.preventDefault();

        let error = 0;
        const eventToServer = eventNew;
        if (eventToServer.emailText && eventToServer.emailText.getCurrentContent){
            eventToServer.emailText = convertToHTML(eventToServer.emailText.getCurrentContent());
        }
        eventToServer.infos = eventSections;

        if (userList && userList.selectUserList.length > 0){
            eventToServer.userList = userList.selectUserList;
        }

        setEventNewInvalid((f : any) => ({...f, title: (eventToServer.title === null || eventToServer.title === "")}))
        //setEventNewInvalid(f => ({...f, content: (eventToServer.content === null || eventToServer.content === "")}))

        error += (eventToServer.title === null || eventToServer.title === "") ? 1 : 0;
        //error += (eventToServer.content === null || eventToServer.content === "")

        //console.log(eventToServer);

        if (error === 0){
            if (eventToServer.sumEmail ){
                const eventText = eventNew.infos.map((info : any) =>
                    `<table>
                        <tr>
                            <td>Personen</td>
                            ${info.times.map((i : any) => `<td>${i.person}</td>`)}
                        </tr>
                        <tr>
                            <td>Aufgabe</td>
                            ${info.times.map((i : any) => `<td>${i.eventTitle}</td>`)}
                        </tr>
                        <tr>
                            <td>Zeiten</td>
                            ${info.times.map((i : any) => `<td>${moment(i.timeStart).format("HH:mm")} - ${moment(i.timeEnd).format("HH:mm")}</td>`)}
                        </tr>
                    </table>`
                );

                //console.log(eventText);

                const emailHTML =  `<p>Hallo Vorname Nachname</p>
               <p>wir habe neue Jobs für dich:</p>
                <p style="font-weight: 900">${eventNew.title} ${moment(eventNew.mailDate, "DD.MM.YYYY HH:mm").format("DD.MM.YYYY")} </p>
                ${eventText}
                <p>Fall du dich für einen dieser Jobs anmelden möchtest,  <a href="#">KLICK HIER</a><br/>
                Auf der Plattform kannst du die gewünschten Dienstzeiten auswählen. <strong>(ACHTUNG: Nur wenn es unterschiedliche Dienstzeiten gibt!)</strong></p>
                <p>Du kannst dich auch ganz einfach in deinen Account einloggen und dich dort bewerben!</p>
                <p>Sobald du dich für einen Einsatz gemeldet hast, halte dir den Termin bitte frei. 
                Ob du für einen Job eingeteilt wurdest, erfährst du im Laufe der nächsten Tage via E-Mail</p>
                <p>Falls du weitere Fragen hast kannst du dich auch gerne jederzeit an <a href="mailto:jobs@fusionpromotion.at">jobs@fusionpromotion.at</a> wenden oder uns auf dem Promotoren Handy unter <strong>0664 88299422</strong> erreichen.</p>' +
                <p>Liebe Grüße,
                <br/>Dein Fusion Team</p>
                `;
                setEmail((mail : any) => ({...mail, html: emailHTML, eventToServer: eventToServer}));
                setModal(true);

            } else {
                sendToAPI(eventToServer)
            }
        }
    };

    //console.log(eventSections);

    return (
            <BaseLayout className="EventNew white"
                        header={
                                <div className="event-title-content">
                                    <Container className="standard-container">
                                        <Form.Control type="text" name="title" id="title" required value={eventNew.title} onChange={onUpdateEvent} placeholder="Titel" isInvalid={eventNewInvalid.title} />
                                        <Form.Control.Feedback>Bitte definiere einen Titel!</Form.Control.Feedback>
                                    </Container>
                                </div>
                        }
            >
                <div className="event-create-container">
                    {/*<header>
                        <h3>{type === "event" ? "Neues Event" : "Neue Logistik"} anlegen</h3>
                    </header>*/}
                    <Form className="event-form">
                        <div className="event-main-content">
                            <FormGroup>
                                <Form.Control type="textarea" name="content" id="content" value={eventNew.content} onChange={onUpdateEvent} placeholder="Beschreibung" />
                            </FormGroup>
                        </div>
                        <div className="event-info-content">
                            {eventSections.map((eventInfo) => (
                                <div className="event-date">
                                    <Row>
                                        <Col md={5}>
                                            <FormGroup as={Row} className="align-items-end">
                                                <Form.Label htmlFor="date" className="bold col-md-5">Event Datum</Form.Label>
                                                <div className="col-md-7">
                                                    <DatePicker placeholder="Datum" format="dd.MM.yyyy" oneTap name="date" value={eventInfo.date} onChange={e => onUpdateEventInfos(e, eventInfo.id, null)} />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={5}>
                                        </Col>
                                        <Col md={1}>
                                            <Button className="event-delete event-date-delete" variant="danger" onClick={() => onRemoveDate(eventInfo.id)}><FontAwesomeIcon icon={faMinus}/></Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={5}>
                                            <FormGroup as={Row} className="align-items-end">
                                                <Form.Label htmlFor="date" className="bold col-md-5">Event Location</Form.Label>
                                                <div className="col-md-7">
                                                    <GooglePlacesAutocomplete
                                                        selectProps={{
                                                            value: eventInfo.placeValue,
                                                            className: "form-control form-date-control",
                                                            onChange: (e) => onUpdateEventInfos(e, eventInfo.id, null),
                                                        }}
                                                        apiKey="AIzaSyDCCe9OCww_E3C1QrSutASm3CKvTiZ1Cjo"
                                                        /*renderSuggestions={(active, suggestions, onSelectSuggestion) => (
                                                            <div className="suggestions-container">
                                                                {suggestions.map((suggestion, key) => (
                                                                    <div
                                                                        key={key}
                                                                        className="suggestion"
                                                                        onClick={(event) => onSelectSuggestion(suggestion, event)}
                                                                    >
                                                                        {suggestion.description}
                                                                    </div>
                                                                ))
                                                                }
                                                            </div>
                                                        )}*/
                                                    />
                                                    <Form.Control type="hidden" name="longitude" value="" />
                                                    <Form.Control type="hidden" name="latitude" value="" />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={5}>
                                        </Col>
                                    </Row>
                                    {eventInfo.times.map((event: any,i : number, array: any[]) => (
                                        <>
                                            <Row className={className(array.length - 1 === i ? "event-infos event-infos-empty" : "event-infos", "align-items-center")} key={event.id}>
                                                <Col md={2}>
                                                    <Form.Label htmlFor="date" className="bold">Zeitraum</Form.Label>
                                                </Col>
                                                <Col md={2}>
                                                    <FormGroup>
                                                        <KeyboardTimePicker
                                                            label="Start-Zeit (HH:mm)"
                                                            placeholder="08:00"
                                                            format="HH:mm"
                                                            value={event.starting_time}
                                                            onChange={e => onUpdateEventInfos(e, eventInfo.id, event.id, "timeStart")}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={2}>
                                                    <FormGroup>
                                                        <KeyboardTimePicker
                                                            label="End-Zeit (HH:mm)"
                                                            placeholder="08:00"
                                                            format="HH:mm"
                                                            value={event.ending_time}
                                                            onChange={e => onUpdateEventInfos(e, eventInfo.id, event.id, "timeEnd")}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}></Col>
                                                <Col md={1}>
                                                    <Button className="event-delete" variant="danger" onClick={() => onRemoveRow(eventInfo.id, event.id)}><FontAwesomeIcon icon={faMinus}/></Button>
                                                </Col>
                                                <Col md={1}>
                                                    <Button className="event-add" variant="success" onClick={() => onAddNewRow(eventInfo.id)}><FontAwesomeIcon icon={faPlus}/></Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}>
                                                    <Form.Label htmlFor="date" className="bold">Aktivitäten / Geschlecht / Personen</Form.Label>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Form.Control type="text" name="eventTitle" onChange={e => onUpdateEventInfos(e.target, eventInfo.id, event.id)} placeholder="Aktivität" />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Form.Select name="gender" onChange={e => onUpdateEventInfos(e.target, eventInfo.id, event.id)} >
                                                            <option value="">Gewünschtes Geschlecht</option>
                                                            <option value="equal">Egal</option>
                                                            <option value="female">Weiblich</option>
                                                            <option value="male">Männlich</option>
                                                        </Form.Select>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Form.Control type="number" name="person" onChange={e => onUpdateEventInfos(e.target, eventInfo.id, event.id)}  placeholder="Anzahl Personen (Optional)" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}>
                                                    <Form.Label htmlFor="date" className="bold">Backup notwendig?<br/><span className="small">Wenn leer, dann wird kein Backup hinterlegt</span></Form.Label>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Form.Control type="number" name="backupPerson" onChange={e => onUpdateEventInfos(e.target, eventInfo.id, event.id)} value={event.backupPerson}  placeholder="Backup (Standard: 0)" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={5}>
                                                    <FormGroup as={Row} className="align-items-end">
                                                        <Form.Label htmlFor="meeting_time" className="bold col-md-5">Treffzeitpunkt</Form.Label>
                                                        <div className="col-md-7">
                                                            <DatePicker placeholder="Treffzeitpunkt" format="HH:mm" name="meeting_time" onChange={e => onUpdateEventInfos(e, eventInfo.id, event.id, "meeting_time")} />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={5}>
                                                    <FormGroup as={Row} className="align-items-end">
                                                        <Form.Label htmlFor="meeting_time" className="bold col-md-5">Einsatzzeit</Form.Label>
                                                        <div className="col-md-7">
                                                            <DatePicker placeholder="Einsatzzeit" format="HH:mm" name="starting_time" onChange={e => onUpdateEventInfos(e, eventInfo.id, event.id, "starting_time")} />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12}>
                                                    <Divider className="divider-event" />
                                                </Col>
                                            </Row>
                                        </>
                                    ))}
                                </div>
                            ))}
                            <div className="event-button-container justify-content-end row">
                                <Col md={2}><Button variant="primary" className="event-date-add w-100" onClick={onAddNewDate}><FontAwesomeIcon icon={faPlus}/> Neues Datum</Button></Col>
                            </div>
                        </div>
                        <div className="event-info-content mt-4 mb-3">
                            {(selectedUser && selectedUser.length > 0) ? <Row>
                                <Col md={8}>
                                    <FormGroup>
                                        <Form.Control type="textarea" name="emailText" id="emailText" value={eventNew.emailText} onChange={onUpdateEvent} placeholder="Zusätzliche Informationen für Email" />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <Form.Label className="bold">User List <span className="small">(An folgende User wird die Email versendet)</span></Form.Label>
                                    <ul className="list list-max-height">
                                        {selectedUser.map(user => <li>{user.name}</li>)}
                                    </ul>
                                </Col>
                            </Row> :
                                <FormGroup>
                                    <Form.Label className="bold">Weitere Informationen für die Email?</Form.Label>
                                    <HtmlEditor setText={onUpdateEmailText} name="emailText" placeholder="Email Text" text={eventNew.emailText} />

{/*
                                    <Form.Control type="textarea" name="emailText" id="emailText" value={eventNew.emailText} onChange={onUpdateEvent} placeholder="Email Text" />
*/}
                                </FormGroup>
                            }
                        </div>
                        <div className="event-send-container row justify-content-end align-items-center">
                            {/*<Col md={4} className="text-right">
                                <FormGroup check>
                                    <Form.Label check>
                                        <Form.Control type="checkbox" name="sumEmail" checked={eventNew.sumEmail} onChange={onUpdateEvent} />{''}
                                        In Sammelmail aufnehmen. Bitte Datum wählen <br/><span className="small">(Für sofort aussenden, bitte Sammelmail weglicken)</span>:
                                    </Form.Label>
                                </FormGroup>
                            </Col>
                            <Col md={3} className="text-right">
                                <FormGroup>
                                    <Form.Label htmlFor="mailDate"></Form.Label>
                                    <DatePicker className="w-100" placeholder="Datum" format="DD.MM.YYYY HH:mm" oneTap name="mailDate" value={eventNew.mailDate} onChange={onUpdateEvent} />
                                </FormGroup>
                            </Col>*/}
                            <Col md={2} className="text-right">
                                <Button type="submit" onClick={onSend}>Absenden</Button>
                                <EmailPreviewModal email={email} onSubmitToServer={sendToAPI} modal={modal} toggle={toggle}/>
                            </Col>
                        </div>
                    </Form>
                </div>
            </BaseLayout>
    );
};

const mapDispatchToProps = (dispatch : AppDispatch) => {
    return bindActionCreators({

    }, dispatch)
}

const mapStateToProps = (state: RootState) => ({
    userData: state.user,
    userList: state.userList
})


const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EventNew);