import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import Logo from "../../assets/images/logo_fusion.png";
import {Button, Col, FormGroup, Form, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {LOGIN} from "../../util/routes/routes";
import NoLayout from "../../layouts/NoLayout/NoLayout";
import instance from "../../util/api";
import {connect, ConnectedProps} from "react-redux";
import {complete, registerInitState} from "../../state/reducer/user/userRegisterSlice";
import {AppDispatch, RootState} from "../../store";
import {bindActionCreators} from "redux";
import {Formik, FormikValues} from "formik";
import Background from "../../assets/images/loginRegisterBg.png";
import TwoColumnLayout from "../../layouts/TwoColumnLayout/TwoColumnLayout";
import * as yup from "yup";


const uuid = require("uuid");

export interface IRegisterState {
    id: number,
    email: string,
    title: string,
    firstname: string,
    lastname: string,
    birthday: string,
    socialNumber: string,
    nationality: string,
    address: string,
    city: string,
    postcode: string,
    telephone: string,
    registerAs: string,
    checkAGB: boolean,
    how_did_you_find_us?: string
}

const initialState = {
    id: 0,
    email: "",
    title: "",
    firstname: "",
    lastname: "",
    birthday: "",
    socialNumber: "",
    nationality: "",
    address: "",
    city: "",
    postcode: "",
    telephone: "",
    registerAs: "",
    checkAGB: false,
    how_did_you_find_us: ""
} as IRegisterState;

const formValuesSchema = yup.object({
    id: yup.number().required("ID is required"),
    email: yup.string().email("Bitte trage eine gültige E-Mail Adresse ein!").required("Bitte trage deine E-Mail Adresse ein!"),
    title: yup.string().required("Bitte wähle einen Titel aus!"),
    firstname: yup.string().required("Bitte trage deinen Vornamen ein!"),
    lastname: yup.string().required("Bitte trage deinen Nachnamen ein!"),
    birthday: yup.string().required("Bitte trage dein Geburtsdatum ein!"),
    socialNumber: yup.number().required("Bitte trage deine Sozialversicherung ein!"),
    nationality: yup.string().required("Bitte trage deine Nationalität ein!"),
    address: yup.string().required("Bitte trage deine Adresse ein!"),
    city: yup.string().required("Bitte trage deine Stadt ein!"),
    postcode: yup.number().required("Bitte trage deine PLZ ein!"),
    telephone: yup.string().required("Bitte trage deine Telefonnummer ein!"),
    registerAs: yup.string().required("Bitte wähle aus, ob du dich als Logistiker, Promoter oder beides registrieren möchtest!").oneOf(["SUPPLIER", "PROMOTER", "SUPPLIER,PROMOTER"]),
    how_did_you_find_us: yup.string(),
    checkAGB: yup.boolean().required("Bitte bestätige die Datenschutzrichtlinien!").oneOf([true], "Bitte bestätige die Datenschutzrichtlinien!")
});

interface IRegisterStepTwo extends PropsFromRedux{

}

type FormControlElement = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;


const formInitValid = {} as {[key : string] : boolean};
(Object.keys(registerInitState) as (keyof IRegisterState)[]).forEach((key) => {
    formInitValid[key] = false;
})

const RegisterStepTwo : React.FC<IRegisterStepTwo> = (props) => {
    const [form, setForm] = useState<any>(initialState);
    const [formValid, setFormValid] = useState(formInitValid);

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<any[]>([]);


    useEffect(() => {
        console.log("CURRENT USER DATA ", props.userData);
        setForm({
            ...props.userData,
            username: uuid.v4()
        })
    }, []);

    const handleSubmit = (values: IRegisterState) => {
        console.log("USER DATA", values);

        return instance({
            method: 'POST',
            url: '/user/register',
            data: values
        }).then(resp => {
            //console.log(resp);
            if (!resp.data.error){
                setSuccess(true);
                props.registerComplete({});
            } else {
                setError((error : any[]) => [...error, resp.data]);
            }
        }).catch(err => {
            //console.log(err);
        })
    };

    /*const handleInvalidSubmit = (event, errors, values) => {
        setError(error => [...error, createError("Einige Felder müssen noch ausgefüllt werden!")])
    };*/

    return (
        <TwoColumnLayout className="Register" background={Background}>
            <div className="login-container">
                <div className="login-main mb-5">
                    <h1 className="text-center mb-2">Wir freuen uns auf deine Bewerbung.</h1>
                </div>
            {error.map(error => (error.error ? <div className="alert alert-danger" role="alert">
                {error.message}
            </div> : ""))}
            {!success ?
                <Formik
                    validationSchema={formValuesSchema}
                    onSubmit={(values) => handleSubmit(values)}
                    validateOnChange={false}
                    validateOnBlur={false}
                              //onInvalidSubmit={handleInvalidSubmit}
                    initialValues={{
                        ...initialState,
                        email: props.userData.email,
                        id: parseInt(props.userData.id)
                    }}
                >
                    {({ handleSubmit, handleChange, setFieldValue, values, errors }) => {
                        return (
                            <Form noValidate onSubmit={handleSubmit}>
                                <input value={values.email}/>
                                <input value={values.id}/>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup className="mb-3">
                                            <Form.Select name="title" required value={values.title} id="title"
                                                         placeholder="Title"
                                                         onChange={handleChange}
                                                         isInvalid={!!errors.title}>
                                                <option>Title</option>
                                                <option>Herr</option>
                                                <option>Frau</option>
                                                <option>Anderes</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={8}></Col>
                                    <Col md={6}>
                                        <FormGroup className="mb-3">
                                            <Form.Control type="text" name="firstname" value={values.firstname}
                                                          id="firstname"
                                                          placeholder="Vorname" onChange={handleChange} required
                                                          isInvalid={!!errors.firstname}/>

                                            <Form.Control.Feedback
                                                type="invalid">{errors.firstname}</Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup className="mb-3">
                                            <Form.Control type="text" name="lastname" value={values.lastname}
                                                          id="lastname"
                                                          placeholder="Nachname" onChange={handleChange} required
                                                          isInvalid={!!errors.lastname}/>
                                            <Form.Control.Feedback
                                                type="invalid">{errors.lastname}</Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup className="mb-3">
                                            <Form.Control type="date" name="birthday" value={values.birthday}
                                                          id="birthday"
                                                          placeholder="Geburtsdatum" onChange={handleChange} required
                                                          isInvalid={!!errors.birthday}/>
                                            <Form.Control.Feedback
                                                type="invalid">{errors.birthday}</Form.Control.Feedback>
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <Form.Control type="text" name="address" value={values.address} id="address"
                                                          placeholder="Straße"
                                                          onChange={handleChange} required
                                                          isInvalid={!!errors.address}/>
                                            <Form.Control.Feedback
                                                type="invalid">{errors.address}</Form.Control.Feedback>
                                        </FormGroup>
                                        <FormGroup as={Row} className="mb-3">
                                            <Col xs={4}>
                                                <Form.Control type="text" name="postcode" value={values.postcode}
                                                              id="postcode" placeholder="PLZ"
                                                              onChange={handleChange} required
                                                              isInvalid={!!errors.postcode}/>
                                                <Form.Control.Feedback
                                                    type="invalid">{errors.postcode}</Form.Control.Feedback>

                                            </Col>
                                            <Col xs={8}>
                                                <Form.Control type="text" name="city" value={values.city} id="city"
                                                              placeholder="Stadt"
                                                              onChange={handleChange} required
                                                              isInvalid={!!errors.city}/>
                                                <Form.Control.Feedback
                                                    type="invalid">{errors.city}</Form.Control.Feedback>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <Form.Control type="text" name="nationality" value={values.nationality}
                                                          id="nationality"
                                                          placeholder="Staatsangehörigkeit" onChange={handleChange}
                                                          required
                                                          isInvalid={!!errors.nationality}/>
                                            <Form.Control.Feedback
                                                type="invalid">{errors.nationality}</Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Control type="hidden" name="username" value={uuid.v4()} id="username"
                                                      required/>
                                        <FormGroup className="mb-3">
                                            <Form.Control type="text" name="socialNumber" value={values.socialNumber}
                                                          id="socialNumber"
                                                          placeholder="Sozialversicherung" onChange={handleChange}
                                                          required
                                                          isInvalid={!!errors.socialNumber}/>
                                            <Form.Control.Feedback
                                                type="invalid">{errors.socialNumber}</Form.Control.Feedback>
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <Form.Control type="text" name="telephone" value={values.telephone}
                                                          id="telephone"
                                                          placeholder="Telefonnummer" onChange={handleChange} required
                                                          isInvalid={!!errors.telephone}/>
                                            <Form.Control.Feedback
                                                type="invalid">{errors.socialNumber}</Form.Control.Feedback>
                                        </FormGroup>
                                        <Row>
                                            <Col xs={5}><strong>Registrieren als:</strong></Col>
                                            <Col xs={7}>
                                                <Form.Group className="position-relative mb-3">
                                                    <Form.Check
                                                        required
                                                        type="radio"
                                                        label="Logistiker"
                                                        name="registerAs"
                                                        id="registerAs"
                                                        value="SUPPLIER"
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.registerAs}
                                                    />
                                                    <Form.Check
                                                        required
                                                        type="radio"
                                                        label="Promoter"
                                                        name="registerAs"
                                                        id="registerAs"
                                                        value="PROMOTER"
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.registerAs}
                                                    />
                                                    <Form.Check
                                                        required
                                                        type="radio"
                                                        label="Beides"
                                                        name="registerAs"
                                                        id="registerAs"
                                                        value="SUPPLIER,PROMOTER"
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.registerAs}
                                                        feedback={errors.registerAs}
                                                        feedbackType="invalid"
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Form.Control type="text" name="how_did_you_find_us"
                                                      value={values.how_did_you_find_us} id="how_did_you_find_us"
                                                      placeholder="Wie bist du auf uns aufmerksam geworden?"
                                                      onChange={handleChange}/>
                                    </Col>
                                </Row>
                                <FormGroup className="mb-3 mt-5">
                                    <Form.Check type="checkbox">
                                        <Form.Check.Input
                                            required
                                            type="checkbox"
                                            //label={"Hiermit bestätige ich die Verarbietung meiner Daten und habe die <a href=\"https://www.fusionpromotion.at/privacy-policy\">Datenschutzrichtlinien</a> gelesen und verstanden."}
                                            name="checkAGB"
                                            id="checkAGB"
                                            onChange={handleChange}
                                            isInvalid={!!errors.checkAGB}
                                        />
                                        <Form.Check.Label htmlFor="checkAGB">Hiermit bestätige ich die Verarbietung
                                            meiner Daten und habe die <a target="_blank"
                                                                         href="https://www.fusionpromotion.at/privacy-policy">Datenschutzrichtlinien</a> gelesen
                                            und verstanden.</Form.Check.Label>
                                        <Form.Control.Feedback type="invalid">{errors.checkAGB}</Form.Control.Feedback>
                                    </Form.Check>
                                </FormGroup>
                                <div className="text-center">
                                    <Button variant="primary" type="submit">Abschicken</Button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik> :
                <div id="registerSuccessMessage">
                    <h3 className="text-right mb-5">Dein Account wurde erfolgreich erstellt. <br/> In Kürze melden wir uns bei dir!</h3>
                    <div className="text-center">
                        <NavLink to={LOGIN} className="btn btn-primary">Zurück zum Login</NavLink>
                    </div>
                </div>
            }
            </div>
        </TwoColumnLayout>
    );
};

const mapDispatchToProps = (dispatch : AppDispatch) => {
    return bindActionCreators({
        registerComplete : complete
    }, dispatch)
}

const mapStateToProps = (state : RootState) => ({
    userData: state.userRegister
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RegisterStepTwo);
