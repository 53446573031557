import React, {ChangeEvent, useEffect, useState} from 'react';
import {Button, Col, FormGroup, Form, Row} from "react-bootstrap";
import {NavLink, RouteComponentProps, useLocation} from "react-router-dom";
import {LOGIN, REGISTER_STEP_TWO} from "../../util/routes/routes";
import NoLayout from "../../layouts/NoLayout/NoLayout";
import "./Register.scss";
import instance from "../../util/api";
import {connect, ConnectedProps} from "react-redux";
import {createError} from "../../util/helper/error";
import Background from "../../assets/images/loginRegisterBg.png";
import {complete, stepOne} from "../../state/reducer/user/userRegisterSlice";
import {AppDispatch} from "../../store";
import {bindActionCreators} from "redux";
import {Field, Formik, Form as FormFormik} from "formik";
import TwoColumnLayout from "../../layouts/TwoColumnLayout/TwoColumnLayout";
import * as yup from 'yup';

const initForm = {
    email: "",
    password: "",
} as IInitForm

interface IInitForm {
    email: string,
    password: string,
}

const formValuesSchema = yup.object({
    email: yup.string().email("Das ist keine gültige E-Mail Adresse").required("Bitte eine E-Mail Adresse eingeben"),
    password: yup.string().min(8, "Das Passwort ist zu kurz! Mindestens 8 Zeichen lang").required("Bitte definiere ein Passwort")
});

const formCodeValuesSchemar = yup.object({
    code: yup.string().required("Es sieht so aus, als das der Referenzcode fehlt. Bitte melde dich beim Support"),
    generateCode: yup.string().required("Bitte gib den erhaltenen Code ein")
})

interface IMailCheck {
    code: string,
    generateCode?: string
}

interface IRegister extends PropsFromRedux, RouteComponentProps{

}
const Register : React.FC<IRegister> = (props) => {
    const [form, setForm] = useState(initForm);

    const [error, setError] = useState<any[]>([]);
    const [emailCheck, setEmailCheck] = useState<IMailCheck>({
        code: "",
        generateCode: undefined
    });

    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const auth = params.get("auth");
        const user = params.get("u");

        if(auth && user) {
            setForm(form => ({
                ...form,
                email: atob(user)
            }))
            setEmailCheck({
                code: atob(auth),
                generateCode: atob(auth)
            })
        }

    }, []);

    const handleSubmit = (values: IInitForm) => {
        console.log(values);
        setError([]);
        instance({
            method: 'POST',
            url: '/user/code',
            data: {
                email: values.email,
                password: values.password
            }
        }).then(resp => {
            if (!resp.data.error){
                setEmailCheck({...emailCheck, generateCode: resp.data.code});
                setForm(values);
            } else {
                setError(error => [...error, resp.data]);
            }
        }).catch(err => {
            setError(error => [...error, createError("Es gab ein Problem bei der Code erstellung")]);
            //console.log(err);
        })

    };

    const handleCodeRegeneration = () => {
        setError([]);

        instance({
            method: 'POST',
            url: '/user/code',
            data: {
                email: form.email,
                password: form.password
            }
        }).then(resp => {
            if (!resp.data.error){
                setEmailCheck({...emailCheck, generateCode: resp.data.code});
            } else {
                setError(error => [...error, resp.data]);
            }
        }).catch(err => {
            setError(error => [...error, createError("Es gab ein Problem bei der Code erstellung")]);
            //console.log(err);
        })
    }

    const handleSubmitEmailCheck = (values: IMailCheck) => {
        let error = 0;

        if (!values.generateCode) {
            error += 1;
        } else {
            if (!emailCheck.generateCode || parseInt(values.code) !== parseInt(values.generateCode)){
                error += 1;
            }
        }

        if (error === 0){
            instance.post('/user/register/init', {
                email: form.email,
                password: form.password
            }).then(resp => {
                console.log(resp);
                if (resp.data.error){
                    setError(error => [...error, createError(resp.data.message)]);
                } else {
                    props.setRegisterData({
                        email: form.email,
                        id: resp.data.id
                    });
                    props.history.push(REGISTER_STEP_TWO);
                }
            }).catch(err => {
                console.log(err);
                setError(error => [...error, createError("User konnte nicht gespeichert werden")]);
            });

        } else {
            setError(error => [...error, createError("Der Code ist nicht richtig!")])
        }
    };

    return (
        <TwoColumnLayout className="Register" background={Background}>
            {!emailCheck.generateCode ?
                <div className="login-main mb-5">
                    <h1 className="text-center mb-2">Wir freuen uns auf deine Bewerbung.</h1>
                    <p className="text-center">Bitte lege dazu, ein Konto an.
                        <br/>
                        Im Anschluss erhälst du eine E-Mail mit einem Aktivierungscode.
                    </p>
                </div> :
                <div className="login-main mb-5">
                    <h1 className="text-center mb-2">Vielen Dank für die Registrierung</h1>
                    <p className="text-center">Dein Aktivierungscode wurde erfolgreich zugesendet.<br/>
                        Bitte bestätige deine E-Mail Adresse durch Eingabe des Codes im unteren Feld.
                    </p>
                    <p className="text-center">Bitte schaue auch im Spam-Ordner nach, ob du die E-Mail bekommen
                        hast!</p>
                </div>}
            <div className="login-container register-form">
                {error.map(error => (error.error ? <div className="alert alert-danger" role="alert">
                    {error.message}
                </div> : ""))}
                {!emailCheck.generateCode ?
                    <>
                        <Formik
                            initialValues={initForm}
                            validationSchema={formValuesSchema}
                            validateOnChange={false}
                            validateOnBlur={false}
                            //onInvalidSubmit={handleInvalidSubmit}
                            onSubmit={(values) => handleSubmit(values)}>
                            {({ handleSubmit, handleChange, setFieldValue, values, errors }) => {
                                return (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <FormGroup className="mb-3">
                                            <Form.Control type="email" name="email" required
                                                   value={values.email} id="email" placeholder="E-Mail" onChange={handleChange}
                                                  isInvalid={!!errors.email}/>
                                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <Form.Control type="password" name="password" required
                                                   value={values.password} id="password" placeholder="Passwort"
                                                   onChange={handleChange}
                                                  isInvalid={!!errors.password}/>
                                            <Form.Control.Feedback
                                                type="invalid">{errors.password}</Form.Control.Feedback>
                                        </FormGroup>
                                        {/*<FormGroup className="mb-5">
                                    <Field type="password" name="confirm_password" className="form-control" required value={form.confirm_password} id="confirm_password" placeholder="Passwort wiederholen" onChange={handleChange} />
                                </FormGroup>*/}
                                        <div className="text-center">
                                            <Button type="submit" className="w-75" variant="primary">Registrieren</Button>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </> : ""
                }
                {emailCheck.generateCode ?
                    <>
                        <Formik
                            id="EmailCheck"
                            initialValues={emailCheck}
                            onSubmit={handleSubmitEmailCheck}
                            validationSchema={formCodeValuesSchemar}
                            validateOnChange={false}
                            validateOnBlur={false}
                            //onInvalidSubmit={handleInvalidSubmit}
                        >
                            {({ handleSubmit, handleChange, setFieldValue, values, errors }) => {
                                return (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <FormGroup as={Row} className="mb-4">
                                            <Form.Label htmlFor="code" column sm={8}>E-Mail Bestätigungscode eingeben</Form.Label>
                                            <Col sm={4}>
                                                <Form.Control type="text" required name="code"
                                                       value={values.code} id="code" onChange={handleChange}
                                                       placeholder="Code"
                                                      isInvalid={!!errors.code}/>
                                                <Form.Control.Feedback
                                                    type="invalid">{errors.code}</Form.Control.Feedback>
                                            </Col>
                                        </FormGroup>
                                        <div className="d-flex justify-content-between">
                                            <Button variant="outline" onClick={handleCodeRegeneration}>Resend Code</Button>
                                            <Button variant="primary" disabled={values.code === ""} type="submit">Zum nächsten Schritt</Button>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </>
                    : ""
                }
                <div className="register-link text-center">
                    <NavLink to={LOGIN} className="btn btn-link float-right small">Bereits ein Konto? Zurück zum
                        Login</NavLink>
                </div>
            </div>
        </TwoColumnLayout>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return bindActionCreators({
        setRegisterData: stepOne
    }, dispatch)
}

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Register);
