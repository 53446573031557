import React from 'react';
import {
    ADMIN,
    ADMIN_CONFIGURATION,
    ADMIN_CONFIGURATION_USER_ATTR, ADMIN_CUSTOMER, ADMIN_CUSTOMER_SHOW_EVENTS, ADMIN_DOCUMENTATION,
    ADMIN_EDIT_LOGISTIC,
    ADMIN_EDIT_PROMOTER,
    ADMIN_EVENT,
    ADMIN_EVENT_ARCHIVE, ADMIN_EVENT_DELETED, ADMIN_EVENT_FINISHED,
    ADMIN_EVENT_LOGISTIC_CREATE, ADMIN_EVENT_LOGISTIC_CREATE_EDIT,
    ADMIN_EVENT_LOGISTIC_EDIT,
    ADMIN_EVENT_LOGISTIC_SHOW,
    ADMIN_LOGIN,
    ADMIN_LOGISTIC,
    ADMIN_LOGISTIC_EVENT_ARCHIVE, ADMIN_LOGISTIC_EVENT_DELETED, ADMIN_LOGISTIC_EVENT_FINISHED,
    ADMIN_MAIL,
    ADMIN_NEW_LOGISTIC,
    ADMIN_NEW_PROMOTER, ADMIN_PASSWORD,
    ADMIN_PROFILE,
    ADMIN_PROMOTER,
    ADMIN_REGISTERED, ADMIN_SERIAL_EVENT_LOGISTIC_CREATE, ADMIN_SERIAL_EVENT_LOGISTIC_EDIT, CALENDAR,
    EVENT_LIST,
    HOME,
    LOGIN,
    PROFILE,
    REGISTER,
    REGISTER_COMPLETE,
    REGISTER_STEP_TWO, RESET_PASSWORD, RESET_PASSWORD_TOKEN, USER_DOCUMENTATION,
    USER_EVENT_LOGISTIC_DETAIL, USER_PASSWORD, USER_PROOF_ACCOUNT,
} from "./routes";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import PrivateRoute, {PrivateAdminRoute} from "./PrivateRoute";
import Dashboard from "../../pages/admin/dashboard/Dashboard";
import Login from "../../pages/login/Login";
import EventNew from "../../pages/admin/event/EventNew";
import Configuration from "../../pages/configuration/Configuration";
import Register from "../../pages/register/Register";
import RegisterStepTwo from "../../pages/register/RegisterStepTwo";
import EditUser from "../../pages/admin/user/EditUser";
import RegisterComplete from "../../pages/register/RegisterComplete";
import {ADMIN_AUTH, AUTHOR_AUTH, hasRole, PROMOTER_AUTH, SUPPLIER_AUTH} from "../auth/auth";
import DashboardUser from "../../pages/dashboard/DashboardUser";
import EventUserShow from "../../pages/event/EventUserShow";
import NewUser from "../../pages/admin/user/NewUser";
import Profile from "../../pages/user/Profile";
import AdminLogin from "../../pages/admin/login/AdminLogin";
import UserList from "../../pages/admin/userlist/UserList";
import EventList from "../../pages/admin/event/EventList";
import EventListUser from "../../pages/event/EventListUser";
import MailPage from "../../pages/admin/mail/Mail";
import EventArchive from "../../pages/admin/event/EventArchive";
import EventShow from "../../pages/admin/event/EventShow";
import EventEdit from "../../pages/admin/event/EventEdit";
import UserPassword from "../../pages/user/UserPassword";
import ResetPassword from "../../pages/user/ResetPassword";
import ResetPasswordToken from "../../pages/user/ResetPasswordToken";
import EventDeleted from "../../pages/admin/event/EventDeleted";
import SerialEventNew from "../../pages/admin/event/SerialEventNew";
import CustomerList from "../../pages/admin/customer/CustomerList";
import CustomerDetail from "../../pages/admin/customer/CustomerDetail";
import SerialEventEdit from "../../pages/admin/event/SerialEventEdit";
import ProofUser from "../../pages/user/ProofUser";
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../store";
import EventNewEdit from "../../pages/admin/event/components/event_new/EventNewEdit";
import CalendarUser from "../../pages/calendar/CalendarUser";
import Documentation from "../../pages/user/Documentation";
import AdminDocumentation from "../../pages/admin/user/Documentation";
import SerialEventNewEdit from '../../pages/admin/event/components/serial_event_new/SerialEventNewEdit';


interface INavigationLinks extends PropsFromRedux {

}

const NavigationLinks : React.FC<INavigationLinks> = (props) => {
    //console.log(props);

    const location = useLocation();

    return (
        <div>
            <Switch>
                //ADMIN SECTIONS
                <Route exact path={ADMIN_LOGIN.path} component={AdminLogin} />

                <Route exact path={RESET_PASSWORD.path} component={ResetPassword} />
                <Route exact path={RESET_PASSWORD_TOKEN.path} component={ResetPasswordToken} />

                <PrivateAdminRoute exact path={ADMIN.path} component={Dashboard} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_PROFILE.path} component={Profile} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_PASSWORD.path} component={UserPassword} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_PROMOTER.path} component={UserList} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_LOGISTIC.path} component={UserList} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]}/>

                <PrivateAdminRoute exact path={ADMIN_MAIL.path} component={MailPage} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />

                <PrivateAdminRoute exact path={ADMIN_PROMOTER.path} component={UserList} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_LOGISTIC.path} component={UserList} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]}/>
                <PrivateAdminRoute exact path={ADMIN_REGISTERED.path} component={UserList} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]}/>

                <PrivateAdminRoute exact path={ADMIN_NEW_PROMOTER.path} component={NewUser} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_NEW_LOGISTIC.path} component={NewUser} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_EDIT_PROMOTER.path} component={EditUser} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_EDIT_LOGISTIC.path} component={EditUser} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />


                <PrivateAdminRoute exact path={ADMIN_EVENT.path} component={EventList} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_EVENT_ARCHIVE.path} component={EventArchive} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_EVENT_FINISHED.path} component={EventArchive} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_EVENT_DELETED.path} component={EventDeleted} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_EVENT_LOGISTIC_CREATE.path} component={EventNew} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_EVENT_LOGISTIC_CREATE_EDIT.path} component={EventNewEdit} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />


                <PrivateAdminRoute exact path={ADMIN_SERIAL_EVENT_LOGISTIC_CREATE.path} component={SerialEventNewEdit} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_EVENT_LOGISTIC_SHOW.path} component={EventShow} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_EVENT_LOGISTIC_EDIT.path} component={EventNewEdit} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_SERIAL_EVENT_LOGISTIC_EDIT.path} component={SerialEventNewEdit} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_LOGISTIC_EVENT_ARCHIVE.path} component={EventArchive} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_LOGISTIC_EVENT_FINISHED.path} component={EventArchive} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_LOGISTIC_EVENT_DELETED.path} component={EventDeleted} user={props.user} roles={[ADMIN_AUTH, AUTHOR_AUTH]} />

                <PrivateAdminRoute exact path={ADMIN_CONFIGURATION.path} component={Configuration} user={props.user} roles={[ADMIN_AUTH]}/>
                <PrivateAdminRoute exact path={ADMIN_CONFIGURATION_USER_ATTR.path} component={Configuration} user={props.user} roles={[ADMIN_AUTH]} />

                <PrivateAdminRoute exact path={ADMIN_CUSTOMER.path} component={CustomerList} user={props.user} roles={[ADMIN_AUTH]} />
                <PrivateAdminRoute exact path={ADMIN_CUSTOMER_SHOW_EVENTS.path} component={CustomerDetail} user={props.user} roles={[ADMIN_AUTH]} />

                <PrivateAdminRoute exact path={ADMIN_DOCUMENTATION.path} component={AdminDocumentation} user={props.user} roles={[ADMIN_AUTH]} />

                //USER SECTIONS
                <Route exact path={LOGIN} component={Login} />
                <Route exact path={REGISTER} component={Register} />
                <Route exact path={REGISTER_STEP_TWO} component={RegisterStepTwo} />
                <Route path={REGISTER_COMPLETE} component={RegisterComplete} />
                <Route path={USER_PROOF_ACCOUNT} component={ProofUser} />

                <PrivateRoute exact path={PROFILE.path} component={Profile} user={props.user} roles={[PROMOTER_AUTH, SUPPLIER_AUTH]} />


                <PrivateRoute exact path={HOME.path} component={DashboardUser} user={props.user} roles={[PROMOTER_AUTH, SUPPLIER_AUTH]} />
                <PrivateRoute exact path={CALENDAR.path} component={CalendarUser} user={props.user} roles={[PROMOTER_AUTH, SUPPLIER_AUTH]} />
                <PrivateRoute exact path={EVENT_LIST.path} component={EventListUser} user={props.user} roles={[PROMOTER_AUTH, SUPPLIER_AUTH]} />

                <PrivateRoute exact path={USER_EVENT_LOGISTIC_DETAIL.path} component={EventUserShow} user={props.user} roles={[PROMOTER_AUTH, SUPPLIER_AUTH]} />
                <PrivateRoute exact path={USER_DOCUMENTATION.path} component={Documentation} user={props.user} roles={[PROMOTER_AUTH, SUPPLIER_AUTH]} />

                <PrivateRoute exact path={USER_PASSWORD.path} component={UserPassword} user={props.user} roles={[PROMOTER_AUTH, SUPPLIER_AUTH]} />

                <>
                    {hasRole(props.user, [ADMIN_AUTH, AUTHOR_AUTH]) ? <Redirect to={{
                        pathname: ADMIN.path,
                        state: { referrer: location }
                    }} /> : <Redirect to={{
                        pathname: HOME.path,
                        state: {referrer: location}
                    }} /> }
                </>
            </Switch>
        </div>
    );
};


const mapStateToProps = (state : RootState) => ({
    user: state.user
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NavigationLinks);
