import instance from "../api";


export const getImages = async (userImageAttr : any[]) => {
    const images = [];
    for (const attr of userImageAttr) {
        const foundImage = await instance.get("/file/images/" + attr.value, {
            responseType: 'arraybuffer'
        })
        if (foundImage && foundImage.data){
            const url = URL.createObjectURL(new Blob([foundImage.data], {type: foundImage.headers["content-type"]}));
            images.push({type: attr.attribute, url: url, id: attr.id})
        }
    }

    return images;
}

export const getImage = async(name : string) => {
    try {
        const foundImage = await instance.get("/file/images/" + name, {
            responseType: 'arraybuffer'
        })

        if (foundImage && foundImage.data) {
            console.log(name, foundImage, foundImage.data);
            return URL.createObjectURL(new Blob([foundImage.data], {type: foundImage.headers["content-type"]}));
        }

    } catch (e){
        console.error(e);
    }
    return "";
}

export const checkImage = (src : string, good : (this: GlobalEventHandlers, ev: Event) => any, bad : (this: OnErrorEventHandler, ev: string | Event) => any) => {
    const img = new Image();
    img.onload = good;
    img.onerror = bad;
    img.src = src;
}
