import React, {useEffect, useState} from 'react';
import BaseLayout from "../../../layouts/BaseLayout/BaseLayout";
import instance from "../../../util/api";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Form,
    ListGroup,
    ListGroupItem,
    Row,
    Table
} from "react-bootstrap";
import Moment from "react-moment";
import {connect, ConnectedProps} from "react-redux";
import "./EditUser.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import TagFinder from "../../../components/tagfinder/TagFinder";
import ReactStars from 'react-stars'
import ImageLightbox from "../../../components/imageLightbox/ImageLightbox";
import 'draft-js/dist/Draft.css';
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import moment from "moment";
import {RouteComponentProps, useHistory, useParams} from "react-router-dom";
import {ADMIN_PROMOTER} from "../../../util/routes/routes";
import {getImage, getImages} from "../../../util/helper/images";
import HtmlEditor from "../../../components/editor/HtmlEditor";
import {toast} from "react-toastify";
import FusionToast from "../../../components/toast/FusionToast";
import {UserModel} from "../../../model/UserModel";
import 'photoswipe/dist/photoswipe.css'
import {Gallery, Item} from "react-photoswipe-gallery";
import {RootState} from "../../../store";
import EditableField from "../../../components/inputType/EditableField";
import {getFile} from "../../../util/helper/files";

interface MatchProps {
    id: string
}
interface IEditUser extends PropsFromRedux, RouteComponentProps<MatchProps> {
}

type TImage = {
    content: string | null,
    preview: string | null
}

type TImageState = {
    fullscreen: TImage,
    profile: TImage,
    unset: TImage[]
}

const initImages = {
    fullscreen: {
        content: null,
        preview: null
    },
    profile: {
        content: null,
        preview: null
    },
    unset: [{
        content: null,
        preview: null
    }]
} as TImageState;

const EditUser : React.FC<IEditUser> = (props) => {
    const [user, setUser] = useState<any>({});
    const [masterName, setMasterName] = useState<string>("");
    const [loaded, setLoaded] = useState<boolean>(false);
    const [userId, setUserId] = useState<number>(-1);
    const [tags, setTags] = useState<any[]>([]);
    const [erfahrungen, setErfahrungen] = useState<any[]>([]);
    const [schulungen, setSchulungen] = useState<any[]>([]);
    const [kundenmeinungen, setKundenmeinungen] = useState<any[]>([]);
    const [notizen, setNotizen] = useState<EditorState>(EditorState.createEmpty());
    const [rating, setRating] = useState<number>(0);
    const [beauty, setBeauty] = useState<string>("");
    const [contract, setContract] = useState("");

    const [userImages, setImages] = useState<TImageState>(initImages);

    const history = useHistory();

    const {userList} = props;
    const {id} = useParams() as any;

    let avatar;
    let fullscreenImage;
    let additionalImage;

    useEffect(() => {
        let user = null;

        if (userList){
            user = userList.userList.find(user => user._id === parseInt(props.match.params.id));
            //console.log("USER FROM LIST", user);
        }

        if (!user){
            instance.get("/admin/user/one", {
                params: {
                    id: id
                }
            }).then(async resp => {
                if (!resp.data.error) {
                    const userModel = new UserModel();
                    await userModel.createModel(resp.data.user);
                    setUserData(userModel)
                } else {
                    console.error(resp.data);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
            }).catch(err => {
                console.error(err);
                toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
            })
        } else {
            setUserData(user);
        }
    }, []);

    const setUserData = (user : any) => {
        //console.log("SET USER DATA", user)
        if (user) {
            instance.get("/user/attribute", {
                params: {
                    id: user.masterId ? user.masterId : user._masterId,
                    attributes: ["firstname", "lastname", "fullname"]
                }
            }).then(resp => {
                //console.log("USER DATA SET", resp);
                if (resp.data && !resp.data.error){
                    setMasterName(resp.data.user.fullname);
                }
            }).catch(err => {
                console.error(err);
            })

            //console.log("USER MODEL", user);
            setUserId(parseInt(props.match.params.id));
            setUser(user);

            const tags = user._tags.filter((tag : any) => tag._type === "tag").map((tag : any) => tag._name);
            const schulungen = user._tags.filter((tag : any) => tag._type === "schulung").map((tag : any) => tag._name);
            const erfahrungen = user._tags.filter((tag : any) => tag._type === "erfahrung").map((tag : any) => tag._name);
            const rating = user._userInfos.find((info : any) => info.attribute === "rating")
            const beauty = user._userInfos.find((info : any) => info.attribute === "beauty")
            const kundenmeinungen = user._userInfos.filter((info : any) => info.attribute === "kundenmeinungen").map((meinung : any) => JSON.parse(meinung.value));
            const notizen = user._userInfos.find((info : any) => info.attribute === "notizen")
            const contract = user._userInfos.find((attr : any) => attr.attribute === "contract");

            //console.log(kundenmeinungen);

            setTags(tags);
            setSchulungen(schulungen);
            setErfahrungen(erfahrungen);
            setContract(contract ? contract.value : "")
            if (rating){
                setRating(rating.value)
            }
            if(beauty){
                setBeauty(beauty.value)
            }
            if (kundenmeinungen.length > 0){
                setKundenmeinungen(kundenmeinungen[0]);
            }

            if (notizen){
                const contentFromStore = convertFromRaw(JSON.parse(notizen.value))
                setNotizen(EditorState.createWithContent(contentFromStore));
            }

            const images = user._userInfos.filter((attr : any) => attr.attribute === "image");
            const fullscreenImage = user._userInfos.find((attr : any) => attr.attribute === "fullscreen_image");
            const profileImage = user._userInfos.find((attr : any) => attr.attribute === "profile_image");

            getImages(images).then(imagesObjects => {
                setImages(images => ({
                    ...images,
                    unset: imagesObjects.map(img => ({content: '', preview: img.url}))
                }))
            })
            if (fullscreenImage){
                getImage(fullscreenImage.value).then(fullscreenImageObject => {
                    setImages(images => ({
                        ...images,
                        fullscreen: {content: '', preview: fullscreenImageObject},
                    }))
                })
            }
            if (fullscreenImage){
                getImage(profileImage.value).then(profileImageObject => {
                    setImages(images => ({
                        ...images,
                        profile: {content: '', preview: profileImageObject},
                    }))
                })
            }

            setLoaded(true);
        }
    }

    const removeUserInternalInfo = (info : any, name : string) => {
        //console.log(info, name);
        const data = {
            refId: userId,
            tagType: info,
            action: "deleteFromUser",
            tag: name
        };

        instance.post('/admin/tag', data).then(resp => {
            //console.log(resp.data);
            if (!resp.data.error){
                switch(info){
                    case "tag":
                        setTags(tags => tags.filter(tag => tag !== name));
                        break;
                    case "schulung":
                        setSchulungen(schulungen => schulungen.filter(schulung => schulung !== name));
                        break;
                    case "erfahrung":
                        setErfahrungen(erfahrungen => erfahrungen.filter(erfahrung => erfahrung != name));
                        break;
                    default:
                        break;
                }
            } else {
                console.error(resp);
                toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
            }
        })
    }


    const getUserDetails = (name : string) => {
        const attr = user._userInfos.find((info : any) => info.attribute === name);

        return attr ? attr.value : "Keine Information gefunden";
    }

    const getUserDetailFromJson = (data : string) => {
        //console.log("PARSE JSON", data);
        try {
            const parsedData = JSON.parse(data);
            return Object.keys(parsedData).map(key => <ListGroupItem>{key}: {parsedData[key]}</ListGroupItem>)
        } catch (e){
            return "Keine Information gefunden";
        }
    }

    const activateUser = (status = "active") => {

        //console.log("NEW STATUS", status, "USERID", userId);

        instance.get("/admin/user/changeStatus", {
            params: {
                id: userId,
                status: status
            }
        })
            .then(resp => {
                //console.log(resp);
                if (!resp.data.error){
                    history.push(ADMIN_PROMOTER.path.replace(":type", "promoters"))
                } else {
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message={resp.data.message} />);
                }
                //history.push(ADMIN_PROMOTER.path.replace(":type", "promoters"))
            }).catch(err => {
            console.error(err);
        });
    }

    const activateLoginUser = () => {
        instance.put("/admin/user/activateLogin",
            {
                id: userId
            }
        ).then(resp => {
            setUser((user : any) => ({
                ...user,
                _is_active: true
            }))
        }).catch(err => {
            console.error(err);
        })
    }

    const saveUser = () => {
        const notizenText = convertToRaw(notizen.getCurrentContent());
        const data = {
            id: userId,
            rating,
            beauty,
            kundenmeinungen: JSON.stringify(kundenmeinungen),
            notizen: JSON.stringify(notizenText)
        }

        //console.log(data);

        instance.post("/admin/user/save", data).then(resp => {
            if (!resp.data.error){
                history.push(ADMIN_PROMOTER.path.replace(":type", "promoters"))
            }
        }).catch(err => {
            //console.log(err);
        })
    }

    const onUpdateValue = (name: string, value: string | number) => {
        console.log(name, value);
        setUser((user : any) => ({
            ...user,
            [name] : value
        }))

        const data = {
            name: name.substring(1),
            value: value,
            id: id
        }

        instance.post("/admin/user/update", data)
            .then(resp => {
                console.log("SAVED...", resp.data);
            })
            .catch(err => {

            })
    }


    if (loaded) {
        avatar = user._images.find((image : any) => image.type === "profile_image");
        fullscreenImage = user._images.find((image : any)  => image.type === "fullscreen_image");
        additionalImage = user._images.filter((image : any)  => image.type === "image");
    }

    //console.log("USER MODEL CURRENT", user, userImages)

    return (
        <BaseLayout className="EditUser">
            {loaded ?
                <div className="user-info">
                    <div className="header-info">
                        <div className="container header-container">
                            <div className="row align-items-center">
                                <Col md={2} className="row justify-content-center">
                                    <div className="img-holder avatar-cubed" style={{backgroundImage: `url(${userImages.profile.preview ? userImages.profile.preview : ""})`}}></div>
                                </Col>
                                <Col md={5}>
                                    <div className="main-info-detail">
                                        <h3 className="main-info-header">{user._firstname} {user._lastname} (<Moment fromNow ago>{user._birthday}</Moment>)</h3>
                                        <p className="main-info-calendar">Seit <Moment format="D.M.Y">{user._createdAt}</Moment></p>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </div>
                    <div className="container main-area-container">
                        <Row>
                            <Col md={9}>
                                <div className="main-info">
                                    <Row className="main-info-row">
                                        <Col md={6}>
                                            <Card>
                                                <CardHeader>Über {user._firstname}</CardHeader>
                                                <CardBody>
                                                    <div className="main-info-detail">
                                                        <Table className="info-table" borderless>
                                                            <tbody>
                                                            <tr>
                                                                <td className="table-header">Geburtstag:</td>
                                                                <td><Moment format="D.M.Y">{user._birthday}</Moment></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-header">Soz.:</td>
                                                                <td>{user._socialNumber}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-header">Telefon:</td>
                                                                <td><EditableField
                                                                    name="_telephone"
                                                                    value={user._telephone}
                                                                    setValue={onUpdateValue}
                                                                >{user._telephone}</EditableField></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-header">E-Mail:</td>
                                                                <td><EditableField
                                                                    name="_email"
                                                                    value={user._email}
                                                                    setValue={onUpdateValue}
                                                                >{user._email}</EditableField></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-header">Adresse:</td>
                                                                <td>{user._address}, {user._postcode} {user._city}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-header">Nationalität:</td>
                                                                <td><EditableField
                                                                    name="_nationality"
                                                                    value={user._nationality}
                                                                    setValue={onUpdateValue}
                                                                >{user._nationality}</EditableField></td>
                                                            </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={6}>
                                            <Card className="user-detail-base">
                                                <CardHeader>Mehr</CardHeader>
                                                <CardBody>
                                                    <div className="user-detail-detail">
                                                        <Table className="info-table" borderless>
                                                            <tbody>
                                                            <tr>
                                                                <td className="table-header">Führerschein:</td>
                                                                <td>{getUserDetails("fuhrerschein")}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-header">Eigenes Auto:</td>
                                                                <td>{getUserDetails("eigenes_auto")}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-header">Haarfarbe:</td>
                                                                <td>{getUserDetails("haarfarbe")}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-header">Schuhgröße:</td>
                                                                <td>{getUserDetails("schuhroe")}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-header">Konvektionsgröße:</td>
                                                                <td>{getUserDetails("konfektionsgroe")}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-header">Körpergröße:</td>
                                                                <td>{getUserDetails("korpergroe")}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-header">Brille:</td>
                                                                <td>{getUserDetails("brille")}</td>
                                                            </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="user-detail-container">
                                    <Card>
                                        <CardHeader>Weitere Informationen</CardHeader>
                                        <CardBody>
                                            <Table className="info-table" borderless>
                                                <tbody>
                                                <tr>
                                                    <td className="table-header">Zeiteinteilung:</td>
                                                    <td>
                                                        <ListGroup>
                                                            {getUserDetails("zeiteinteilung").split(",").map((zeit : string) => (<ListGroupItem>{zeit}</ListGroupItem>))}
                                                        </ListGroup>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table-header">Tattoos sichtbar:</td>
                                                    <td>{getUserDetails("tattoos_sichtbar")}</td>
                                                </tr>
                                                <tr>
                                                    <td className="table-header">Piercings sichtbar:</td>
                                                    <td>{getUserDetails("piercing_sichtbar")}</td>
                                                </tr>
                                                <tr>
                                                    <td className="table-header">Ausbildungen:</td>
                                                    <td>{getUserDetails("ausbildungen")}</td>
                                                </tr>
                                                <tr>
                                                    <td className="table-header">Qualifikationen:</td>
                                                    <td>
                                                        <ListGroup>
                                                            {getUserDetails("qualifikationen").split(",").map((qual : string) => (<ListGroupItem>{qual}</ListGroupItem>))}
                                                        </ListGroup>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table-header">Sonstige Qualifikationen:</td>
                                                    <td>{getUserDetails("sonstige_qualifikationen")}</td>
                                                </tr>
                                                <tr>
                                                    <td className="table-header">Übernachtungsmöglichkeiten:</td>
                                                    <td>
                                                        <ListGroup>
                                                            {getUserDetailFromJson(getUserDetails("ubernachtungsmoglichkeit_value"))}
                                                        </ListGroup>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                    <Card className="mt-2">
                                        <CardHeader className="with-action">
                                            <div className="title">
                                                Kundenmeinungen
                                            </div>
                                            <div className="action">
                                                <button className="btn btn-add-tag" onClick={() => setKundenmeinungen(meinung => [...meinung, ""])}><FontAwesomeIcon icon={faPlus} /></button>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            {kundenmeinungen.map((meinung, key) => {
                                                //console.log(meinung);
                                                return (
                                                    <div>
                                                        <h6>Datum: <Moment format={"DD.MM.YYYY"}>{meinung.date}</Moment></h6>
                                                        <Form.Control type="textarea" className="mb-2" onChange={(e) => {
                                                            const target = e.target;
                                                            setKundenmeinungen(kundenmeinungen => {
                                                                kundenmeinungen = kundenmeinungen.map((meinung, i) => i === key ? ({value: target.value, date: moment()}) : meinung);
                                                                return kundenmeinungen
                                                            })
                                                        }}
                                                               value={meinung.value} key={key} name={`meinung[${key}]`}/>
                                                    </div>
                                                )})}
                                        </CardBody>
                                    </Card>

                                    <Card className="mt-2">
                                        <CardHeader>Interne Notizen</CardHeader>
                                        <CardBody className="p-0">
                                            <HtmlEditor setText={setNotizen} text={notizen} placeholder="Füge eine Notitz hinzu" name={""}/>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="user-sidebar main-info">
                                    <Button onClick={saveUser} variant="secondary" className="mb-5 w-100">Speichern</Button>

                                    {(user._status === "inactive" || user._status === "deleted") &&
                                        <Card className="mb-2">
                                            <CardHeader>User ist noch nicht aktiviert!</CardHeader>
                                            <CardBody>
                                                <div className="user-inactive">
                                                    <p>Möchtest du ihn aktivieren?</p>
                                                    <p>Nach der Aktivierung erhält der User einen Aktivierungslink für die
                                                        Vervollständigung seiner Daten!</p>
                                                    <Button variant="primary" onClick={() => activateUser()}>Aktivieren</Button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    }

                                    {user._status === "active" &&
                                        <Card className="mb-2">
                                            <CardHeader>User ist aktiv!</CardHeader>
                                            <CardBody>
                                                <div className="user-detail">
                                                    <p>User wurde aktiviert von: <strong>{masterName}</strong></p>
                                                </div>
                                                <div className="user-inactive">
                                                    <p>Möchtest du ihn deaktivieren?</p>
                                                    <p>Nach der Deaktivierung, kann der User nicht mehr aktiviert werden!</p>
                                                    <Button variant="primary" onClick={() => activateUser("deactivate")}>Deaktivieren</Button>
                                                </div>


                                                {!user._is_active ?
                                                    <>
                                                        <div className="user-detail">
                                                            <p>User ist noch nicht für Login freigeschaltet</p>
                                                        </div>
                                                        <div className="user-inactive">
                                                            <p>Bitte alles überprüfen und ihn dann aktivieren</p>
                                                            <Button variant="secondary" onClick={() => activateLoginUser()}>Freischalten</Button>
                                                        </div>
                                                    </>
                                                    : <></>}
                                                </CardBody>
                                        </Card>
                                    }

                                    <Card className="mb-2">
                                        <CardHeader>Vertrags-Informationen</CardHeader>
                                        <CardBody>
                                            <div className="user-detail">
                                                {user._vertrag_unterschrieben ? <p>User hat den Vertrag unterschrieben</p> : <p></p>}
                                            </div>
                                            {!user._vertrag_unterschrieben ?
                                            <div className="user-inactive">
                                                <p>Bitte klicke hier, wenn der User den Vetrag unterschrieben hat.</p>
                                                <Button variant="primary" onClick={() => activateUser("sign_contract")}>Unterschrift erfolgreich</Button>
                                            </div>
                                            : <div className="user-detail">
                                                    {user._vertrag_unterschrieben ?
                                                        <Button variant="primary" onClick={() => getFile(`/secured/${id}/${contract}`, `Vertrag_MA_${user._firstname}_${user._lastname}`)}>Download Vertrag</Button> : <></>}
                                                </div>}


                                            {!user._is_active ?
                                                <>
                                                    <div className="user-detail">
                                                    <p>User ist noch nicht für Login freigeschaltet</p>
                                                    </div>
                                                    <div className="user-inactive">
                                                        <p>Bitte alles überprüfen und ihn dann aktivieren</p>
                                                        <Button variant="secondary" onClick={() => activateLoginUser()}>Freischalten</Button>
                                                    </div>
                                                </>
                                                : <></>}
                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardHeader>User Bilder</CardHeader>
                                        <CardBody>
                                            <Gallery>
                                                <div className="user-detail-images">
                                                    {userImages.fullscreen.preview ?
                                                        <Item
                                                            original={userImages.fullscreen.preview}
                                                            thumbnail={userImages.fullscreen.preview}
                                                            width="1024"
                                                            height="768"
                                                        >
                                                            {({ ref, open }) => (
                                                                <div className="img-holder">
                                                                    <p><strong>Ganzkörper Foto</strong></p>
                                                                    <img onClick={open} className="img" src={userImages.fullscreen.preview || ""} alt="User Fullscreen Image" />
                                                                </div>
                                                            )}
                                                        </Item>
                                                        : <></>}
                                                    {userImages.profile.preview ?
                                                        <Item
                                                            original={userImages.profile.preview}
                                                            thumbnail={userImages.profile.preview}
                                                            width="1024"
                                                            height="768"
                                                        >
                                                            {({ ref, open }) => (
                                                                <div className="img-holder pt-4">
                                                                    <p><strong>Profil Foto</strong></p>
                                                                    <img onClick={open} className="img" src={userImages.profile.preview || ""} alt="User Profile Image" />
                                                                </div>
                                                            )}
                                                        </Item>
                                                        : <></>}
                                                </div>
                                            </Gallery>
                                            <p className="pt-4"><strong>Weitere Bilder von {user._firstname}</strong></p>
                                            <div className="user-detail-images-additional row">
                                                <ImageLightbox alt="Additinal Image" images={userImages.unset.map(img => ({url: img.preview}))} isSingle={false} />
                                            </div>
                                        </CardBody>
                                    </Card>

                                    <Card className="mt-2">
                                        <CardHeader className="with-action">
                                            <div className="title">
                                                SCHULUNGEN
                                            </div>
                                            <div className="action">
                                                <TagFinder modal={{header: "SCHULUNGEN eingeben", button: "Schulung hinzufügen"}} tagType="schulung" setTags={setSchulungen} refId={userId} />
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <ul className="user-detail-tag-list">
                                                {schulungen.map(schulung => (
                                                    <li className="user-detail-tag-list-item row align-items-center" key={schulung}>
                                                        <Col md={8}>{schulung}</Col>
                                                        <Col md={4}><button
                                                            onClick={() => removeUserInternalInfo("schulung", schulung)}
                                                            className="btn btn-remove"><FontAwesomeIcon icon={faMinus} /></button></Col>
                                                    </li>
                                                ))}
                                            </ul>
                                        </CardBody>
                                    </Card>
                                    <Card className="mt-2">
                                        <CardHeader className="with-action">
                                            <div className="title">
                                                TAGS
                                            </div>
                                            <div className="action">
                                                <TagFinder modal={{header: "TAGS eingeben", button: "Tags hinzufügen"}} tagType="tag" setTags={setTags} refId={userId} />
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <ul className="user-detail-tag-list">
                                                {tags.map(tag => (
                                                    <li className="user-detail-tag-list-item row align-items-center" key={tag}>
                                                        <Col md={8}>{tag}</Col>
                                                        <Col md={4}><button
                                                            onClick={() => removeUserInternalInfo("tag", tag)}
                                                            className="btn btn-remove"><FontAwesomeIcon icon={faMinus} /></button></Col>
                                                    </li>
                                                ))}
                                            </ul>
                                        </CardBody>
                                    </Card>

                                    <Card className="mt-2">
                                        <CardHeader className="with-action">
                                            <div className="title">
                                                ERFAHRUNGSBERICHTE
                                            </div>
                                            <div className="action">
                                                <TagFinder modal={{header: "ERFAHRUNG eingeben", button: "Erfahrung hinzufügen"}} tagType="erfahrung" setTags={setErfahrungen} refId={userId}/>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <ul className="user-detail-tag-list">
                                                {erfahrungen.map(erfahrung => (
                                                    <li className="user-detail-tag-list-item row align-items-center" key={erfahrung}>
                                                        <Col md={8}>{erfahrung}</Col>
                                                        <Col md={4}><button
                                                            onClick={() => removeUserInternalInfo("erfahrung", erfahrung)}
                                                            className="btn btn-remove"><FontAwesomeIcon icon={faMinus} /></button></Col>
                                                    </li>
                                                ))}
                                            </ul>
                                        </CardBody>
                                    </Card>
                                    <Card className="mt-2">
                                        <CardHeader>
                                            User Bewertungen
                                        </CardHeader>
                                        <CardBody>
                                            <p className="pt-3"><strong>Bewertung</strong></p>
                                            <ReactStars
                                                count={5}
                                                size={24}
                                                half={false}
                                                onChange={(newRating) => setRating(newRating)}
                                                value={rating}
                                            />

                                            <p className="pt-3"><strong>Schönheitsgrad</strong></p>
                                            <Form.Check
                                                label={"Nicht hübsch"}
                                                type={"radio"}
                                                name={"beauty"}
                                                checked={beauty === "Nicht hübsch"}
                                                value={"Nicht hübsch"}
                                                onChange={(e) => setBeauty('Nicht hübsch')}
                                            />
                                            <Form.Check
                                                label={"Hübsch"}
                                                type={"radio"}
                                                name={"beauty"}
                                                checked={beauty === "Hübsch"}
                                                value={"Hübsch"}
                                                onChange={(e) => setBeauty('Hübsch')}
                                            />
                                            <Form.Check
                                                label={"Sehr hübsch"}
                                                type={"radio"}
                                                name={"beauty"}
                                                checked={beauty === "Sehr hübsch"}
                                                value={"Sehr hübsch"}
                                                onChange={(e) => setBeauty('Sehr hübsch')}
                                            />
                                        </CardBody>
                                    </Card>

                                    <Button onClick={saveUser} variant="secondary"  className="mt-5 w-100">Speichern</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>:
                <div>Load User Datail!!</div>
            }
        </BaseLayout>
    );
};

const mapStateToProps = (state : RootState) => ({
    userList: state.userList,
    state
})

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditUser);
