import React, {createContext, FC, useEffect} from 'react';
import {io, Socket} from "socket.io-client";
import {SYSTEM_VARS} from "../api";
import {AppDispatch, RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import {ADMIN_AUTH, AUTHOR_AUTH, hasRole, PROMOTER_AUTH, SUPPLIER_AUTH} from "../auth/auth";
import {toast} from "react-toastify";
import FusionToast from "../../components/toast/FusionToast";
import {bindActionCreators} from "redux";
import {addAllMessagesAction, addMessageAction} from "../../state/action/message/messageAction";

declare var process : {
    env: {
        REACT_APP_DEV_LEVEL: string
    }
}
export interface IWebSocket extends PropsFromRedux{
}

export interface IWebSocketContext {
    socket?: Socket
    deleteSocket: Function
}

interface ServerToClientEvents {
    connectToServer: (data : any) => void;
    actualMessageToUser: (data : any) => void;
    actualMessageToAdmin: (data: any) => void;
    test: (data: any) => void;
    admin_new_registration: (data : any) => void;
    admin_new_event_action: (data : any) => void;
}

interface ClientToServerEvents {
    hello: () => void;
}

const initSocket = {
    socket: undefined,
    deleteSocket: () => {}
} as IWebSocketContext

export const WebSocketContext = createContext<IWebSocketContext>(initSocket);
const WebSocket : FC<IWebSocket> = (props) => {
    const [socket, setSocket] = React.useState<Socket<ServerToClientEvents, ClientToServerEvents> | undefined>(undefined);

    let ws = initSocket;

    useEffect(() => {
        console.log("INIT WEBSOCKET...")

        // @ts-ignore
        const ENDPOINT = SYSTEM_VARS[process.env.REACT_APP_DEV_LEVEL].socket_endpoint;

        const user = props.user
        let newSocket: Socket<ServerToClientEvents, ClientToServerEvents> = io(ENDPOINT, {
            reconnectionDelayMax: 10000,
            auth: {
                token: user.token
            },
            query: {
                username: user.socketId,
                roomId: user.socketId
            }
        });

        const actions = props.actions

        if (user && user.isAuthenticated) {
            newSocket.on("connect", () => {
                console.log("Connect to Socket successfully");
            })

            newSocket.on("connect_error", (data) => {
                console.error("Connect to Socket failed");
            });

            newSocket.on("disconnect", (reason, details : any) => {
                // the reason of the disconnection, for example "transport error"
                console.log(reason);

                // the low-level reason of the disconnection, for example "xhr post error"
                console.log(details?.message);

                // some additional description, for example the status code of the HTTP response
                console.log(details?.description);

                // some additional context, for example the XMLHttpRequest object
                console.log(details?.context);
            });

            newSocket.on("connectToServer", (data) => {
                console.info("Connection to Socket was successfull and works...");
            })

            if (hasRole(user, [ADMIN_AUTH, AUTHOR_AUTH])){
                newSocket.on("admin_new_registration", data => {
                    //console.log(data);
                    actions.addMessage(data);
                    toast(<FusionToast className="info" header="Neue Registrierung" message="Eben ist eine Neue Registrierun eingetroffen" />);
                })

                newSocket.on("admin_new_event_action", data => {
                    console.log("NEW ADMIN MESSAGE FORM USER");
                    actions.addMessage(data);
                    toast(<FusionToast className="info" header="Es gab eine Änderung beim Event" message={data.subject} />);
                });
            }


            /*if (hasRole(user, [PROMOTER_AUTH, SUPPLIER_AUTH])){
                //console.log("SOCKET_IO, IS USER", newSocket);
                newSocket.on("apply", data => {
                    actions.addMessage(data);
                    toast(<FusionToast className="info" header="Es gab eine Änderung beim Event" message={data.subject} />);
                })

                newSocket.on("remove_user_to_event", data => {
                    actions.addMessage(data);
                    toast(<FusionToast className="info" header="Es gab eine Änderung beim Event" message={data.subject} />);
                })

                newSocket.on("add_user_to_event", data => {
                    actions.addMessage(data);
                    toast(<FusionToast className="info" header="Es gab eine Änderung beim Event" message={data.subject} />);
                })

                newSocket.on("confirm", data => {
                    actions.addMessage(data);
                    toast(<FusionToast className="info" header="Es gab eine Änderung beim Event" message={data.subject} />);
                });

                newSocket.on("ended", data => {
                    actions.addMessage(data);
                    toast(<FusionToast className="info" header="Es gab eine Änderung beim Event" message={data.subject} />);
                })

                newSocket.on("confirm_for_event", data => {
                    actions.addMessage(data);
                    toast(<FusionToast className="info" header="Es gab eine Änderung beim Event" message={data.subject} />);
                })

                newSocket.on("reject", data => {
                    actions.addMessage(data);
                    toast(<FusionToast className="info" header="Es gab eine Änderung beim Event" message={data.subject} />);
                })

                newSocket.on("finish", data => {
                    actions.addMessage(data);
                    toast(<FusionToast className="info" header="Es gab eine Änderung beim Event" message={data.subject} />);
                })
            }*/

            newSocket.on("actualMessageToAdmin", data => {
                //console.log(data);
                if (Array.isArray(data)) {
                    actions.addAllMessages(data);
                } else {
                    actions.addMessage(data);
                }
                
                if (data.length > 0){
                    toast(<FusionToast className="info" header="Neue Nachrichten" message="Du hast einige Nachrichten" />);
                }
            })

            newSocket.on("actualMessageToUser", data => {
                console.log("actualMessageToUser", data);

                if (Array.isArray(data)) {
                    actions.addAllMessages(data);
                } else {
                    actions.addMessage(data);
                }

            })
        }

        setSocket(newSocket);

        const closeSocket = () => {
            newSocket.close();
        };

        // Add 'beforeunload' event listener to close the WebSocket connection when the page is being unloaded
        window.addEventListener('beforeunload', closeSocket);

        return () => {
            window.removeEventListener('beforeunload', closeSocket);
            closeSocket();
        }
    }, [props.user]);

    ws = {
        socket: socket,
        deleteSocket: () => {}
    }

    return (
        <WebSocketContext.Provider value={ws}>
            {props.children}
        </WebSocketContext.Provider>
    );
};

const mapDispatchToProps = (dispatch : AppDispatch) => {
    const actions = {
        addMessage : addMessageAction,
        addAllMessages : addAllMessagesAction
    };
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};

const mapStateToProps = (state : RootState) => ({
    user: state.user
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WebSocket);