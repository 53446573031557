import React, {ReactNode} from 'react';
import {ADMIN_LOGIN, LOGIN} from "./routes";
import {Redirect, Route, useLocation, useParams} from "react-router-dom";
import {hasRole, isAuthenticated} from "../auth/auth";
import qs from "qs";
import {refreshToken} from "../api/user/login";
import {store} from "../../store";
import {userLogoutAction, userRefreshTokenAction} from "../../state/action/user/userAction";
import {setInReloginProcess} from "../api";

interface IPrivateRoute {
    component: any,
    user: any,
    roles?: any[],
    privileges?: any[],
    path: string,
    [rest: string | number | symbol]: unknown;
}

const PrivateRoute : React.FC<IPrivateRoute> = ({component: Comp, user, roles, privileges, path, ...rest}) => {
    //console.log("PRIVATE ROUTE")
    const location = useLocation();

    return (
        <Route path={path}
            {...rest}
            render={props => {
            if (!isAuthenticated(user)) return <Redirect to={{
                pathname: LOGIN,
                state: {referrer: location}
            }} />;
            if (!Array.isArray(user.roles)) return <Redirect to={{
                pathname: LOGIN,
                state: {referrer: location}
            }} />;
            if (roles && !hasRole(user, roles)) return <Redirect to={{
                pathname: LOGIN,
                state: {referrer: location}
            }} />;
            return <Comp {...props} />
            }}
        />
    );
};

interface IPrivateAdminRoute {
    component: any,
    user: any,
    roles?: any[],
    privileges?: any[],
    path: string,

    [rest: string | number | symbol]: unknown;
}


export const PrivateAdminRoute : React.FC<IPrivateAdminRoute> = ({component: Comp, user, roles, privileges, path, ...rest}) => {
    //console.log("ADMIN ROUTE")
    const location = useLocation();
    return (
        <Route path={path}
               {...rest}
               render={props => {
                   if (!isAuthenticated(user)) return <Redirect to={{
                       pathname: ADMIN_LOGIN.path,
                       state: { referrer: location }
                   }}  />;
                   if (!Array.isArray(user.roles)) return <Redirect to={{
                       pathname: ADMIN_LOGIN.path,
                       state: { referrer: location }
                   }}  />;
                   if (roles && !hasRole(user, roles)) return <Redirect to={{
                       pathname: ADMIN_LOGIN.path,
                       state: { referrer: location }
                   }}  />;
                   return <Comp {...props} />
               }}
        />
    );
};

export default PrivateRoute;
