import React, {forwardRef, Fragment, Ref, useContext, useEffect, useState} from 'react';
import {
    Button,
    ButtonGroup,
    Dropdown, DropdownDivider,
    DropdownHeader,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
} from "react-bootstrap";
import {NavLink, useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBars,
    faCalendar,
    faCalendarAlt,
    faCalendarPlus,
    faEnvelope,
    faTruck,
    faTruckLoading,
    faUser,
    faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import testImg from "../../layouts/BaseLayout/avatar.png";
import {connect, ConnectedProps} from "react-redux";
import "./topMenu.scss";
import Moment from "react-moment";
import {getImage} from "../../util/helper/images";
import HomeIcon from '@material-ui/icons/Home';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import DnsIcon from '@material-ui/icons/Dns';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ArchiveIcon from '@material-ui/icons/Archive';
import {
    ADMIN,
    ADMIN_EDIT_PROMOTER,
    ADMIN_EVENT,
    ADMIN_EVENT_ARCHIVE,
    ADMIN_EVENT_LOGISTIC_CREATE, ADMIN_EVENT_LOGISTIC_CREATE_EDIT,
    ADMIN_EVENT_LOGISTIC_SHOW,
    ADMIN_LOGISTIC,
    ADMIN_LOGISTIC_EVENT,
    ADMIN_MAIL, ADMIN_NEW_LOGISTIC, ADMIN_NEW_PROMOTER, ADMIN_PASSWORD,
    ADMIN_PROFILE,
    ADMIN_PROMOTER,
    ADMIN_REGISTERED, ADMIN_SERIAL_EVENT_LOGISTIC_CREATE, CALENDAR,
    EVENT_LIST,
    HOME,
    LOGISTIC_LIST,
    PROFILE,
    USER_EVENT_LOGISTIC_DETAIL, USER_PASSWORD,
} from "../../util/routes/routes";
import classNames from "classnames";
import instance from "../../util/api";
import Search from "../search/Search";
import {EventStatus} from "../../util/helper/eventHelper";
import {useViewport} from "../../util/ViewportProvider";
import SideMessages from "../messages/SideMessages";
import {ADMIN_AUTH, AUTHOR_AUTH, hasRole, PROMOTER_AUTH, SUPPLIER_AUTH} from "../../util/auth/auth";
import {
    Avatar, createStyles,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    useTheme
} from "@material-ui/core";
import {ChevronLeft, ChevronRight, MailRounded} from "@material-ui/icons";
import {toast} from "react-toastify";
import FusionToast from "../toast/FusionToast";
import {WebSocketContext} from "../../util/socket/WebSocket";
import {AppDispatch, RootState} from "../../store";
import {bindActionCreators} from "redux";
import {userLogoutAction} from "../../state/action/user/userAction";
import {readMessageAction} from "../../state/action/message/messageAction";
import "moment/locale/de";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import Header from "../header/Header";

const drawerWidth = 340;

interface ITopMenu extends PropsFromRedux {
    type: any
}

const useStyles = makeStyles((theme) => createStyles({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerRoot: {
        zIndex: "999999!important" as any
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    avatarRoot: {
        alignItems: 'center',
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    avatarLarge: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

const TopMenu : React.FC<ITopMenu> = (props) => {
    const history = useHistory();
    const { width } = useViewport();
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [dropdownCreateOpen, setDropdownCreate] = useState<boolean>(false);
    const [messageDropdownOpen, setMessageDropdownOpen] = useState<boolean>(false);
    const [image, setImage] = useState<any>(testImg);
    const [userRegisterCount, setUserRegisterCount] = useState<number>(0);
    const [search, setSearch] = useState<any[]>([]);
    const [searching, isSearching] = useState<boolean>(false);
    const [drawer, setDrawer] = useState<boolean>(false);
    const [backdrop] = useState<any>(() => {
        const el = document.createElement('div')
        el.id = "SearchbarBack";
        el.classList.add("searchbar-backdrop");
        return el;
    });
    const {deleteSocket, socket} = useContext(WebSocketContext);

    const classes = useStyles();
    const theme = useTheme();

    const toggle = () => setDropdownOpen(prevState => !prevState);
    const toggleCreate = () => setDropdownCreate(prevState => !prevState);

    const toggleMessage = () => setMessageDropdownOpen(prevState => !prevState);

    const {user, type} = props;

    //console.log("USER", user);

    const taskPreTypes = {
        "user": user.roles.find(role => role === "ADMIN") ? "User" : null,
        "event": "Events",
        "logistic": "Logistik"
    }

    useEffect(() => {
        async function fetchImg() {
            if (user.thumbnail && user.thumbnail.value){
                const imgUrl = await getImage(user.thumbnail.value);
                if (imgUrl) {
                    setImage(imgUrl);
                }
            }
        };
        fetchImg().then(r => {

        });
    },[user])

    useEffect(() => {
        //console.log("INIT SOCKET", props.dispatch);
        instance.get("/admin/user/register/count")
            .then(resp => {
                if (!resp.data.error){
                    setUserRegisterCount(resp.data.userCount);
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
            }).catch(err => {
            //console.log(err);
        });
    }, []);

    const logout = () => {
        deleteSocket();

        return props.logout();
    }

    const onSearch = (values : any, searchVal : string) => {
        //console.log(values);
        setSearch(values);
        if (searchVal !== "" && (values.event || values.logistic)){
            document.body.appendChild(backdrop);
            isSearching(true);
        } else {
            if (document.getElementById(backdrop.id) && document.body.contains(backdrop)){
                document.body.removeChild(backdrop);
            }
            //document.body.removeChild(backdrop);
            isSearching(false);
        }
    }

    const onClick = (link : string) => {
        if (document.getElementById(backdrop.id) && document.body.contains(backdrop)) {
            document.body.removeChild(backdrop);
        }
        setSearch([])
        isSearching(false);
        history.push(link);
    }

    const backdropElement = document.getElementById(backdrop.id);
    if (backdropElement) {
        backdropElement.addEventListener("click", () => {
            if (document.getElementById(backdrop.id) && document.body.contains(backdrop)) {
                document.body.removeChild(backdrop);
            }
            setSearch([])
            isSearching(false);
        })
    }

    const searchBarListClass = classNames({
        "searchbar-calendar-list": true,
        "row": true,
        "searchbar-show": searching,
        "fade-height": true,
        "fade-in": searching
    })

    const messageBoxClasses = classNames(
        "nav-link",
        "message-link",
        {"active": messageDropdownOpen}
    )

    const toggleDrawer = (open : boolean) => (event : any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawer(open);
    };

    const LinkBehavior = forwardRef((props: any, ref: Ref<HTMLAnchorElement>) => (
        <NavLink exact ref={ref} activeClassName="active" className="nav-link fusion-nav-link" to={props.to} {...props} />
    ))

    const navListHeader = [
        {path: ADMIN.path, text: ADMIN.name, icon: <HomeIcon />},
    ]

    const navListPromoter = [
        {path: ADMIN_PROMOTER.path.replace(":type", "promoters"), text: ADMIN_PROMOTER.name, icon: <AccountTreeIcon />},
        {path: ADMIN_EVENT.path.replace(":eventType", "event"), text: ADMIN_EVENT.name, icon: <DnsIcon />},
        {path: ADMIN_EVENT_LOGISTIC_CREATE.path.replace(":eventType", "event"), text: ADMIN_EVENT_LOGISTIC_CREATE.name, icon: <AddBoxIcon />},
        {path: ADMIN_EVENT_ARCHIVE.path.replace(":eventType", "event"), text: ADMIN_EVENT_ARCHIVE.name, icon: <ArchiveIcon />},
    ]

    const navListLogistic = [
        {path: ADMIN_LOGISTIC.path.replace(":type", "logistics"), text: ADMIN_LOGISTIC.name, icon: <AccountTreeIcon />},
        {path: ADMIN_LOGISTIC_EVENT.path.replace(":eventType", "logistic"), text: ADMIN_LOGISTIC_EVENT.name, icon: <DnsIcon />},
        {path: ADMIN_EVENT_LOGISTIC_CREATE.path.replace(":eventType", "logistic"), text: ADMIN_EVENT_LOGISTIC_CREATE.name, icon: <AddBoxIcon />},
        {path: ADMIN_EVENT_ARCHIVE.path.replace(":eventType", "logistic"), text: ADMIN_EVENT_ARCHIVE.name, icon: <ArchiveIcon />},
    ]

    const navListBottom = [
        {path: ADMIN_MAIL.path, text: ADMIN_MAIL.name, icon: <MailRounded />},
    ]


    const userList = [
        {path: HOME.path, text: HOME.name, icon: <HomeIcon />},
        {path: CALENDAR.path, text: CALENDAR.name, icon: <FontAwesomeIcon icon={faCalendar} />}
    ]

    if (hasRole(user, [PROMOTER_AUTH])){
        userList.push({path: EVENT_LIST.path.replace(":eventType", "event"), text: EVENT_LIST.name, icon: <DnsIcon />})
    }

    if (hasRole(user, [SUPPLIER_AUTH])){
        userList.push({path: LOGISTIC_LIST.path.replace(":eventType", "logistic"), text: LOGISTIC_LIST.name, icon: <DnsIcon />})
    }

    return (
        <div className="nav-holder fusion-nav-holder">
            <div className="d-flex">
                <button className="d-md-none btn btn-link" onClick={toggleDrawer(true)}><FontAwesomeIcon icon={faBars}/></button>
                <Drawer
                    anchor="left"
                    open={drawer}
                    className={classes.drawer}
                    classes={{
                        root: classes.drawerRoot,
                        paper: classes.drawerPaper
                    }}
                    onClose={toggleDrawer(false)}>
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={toggleDrawer(false)}>
                            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                        </IconButton>
                    </div>
                    <Divider />
                    <div className={classes.avatarRoot}>
                        <Avatar alt="avatar" src={image} className={classes.avatarLarge} />
                        <div>
                            <NavLink activeClassName="active" className="btn btn-link" to={type === "USER" ? PROFILE.path : ADMIN_PROFILE.path}>{(props.user.firstname + " " + props.user.lastname)}</NavLink>
                            <button className="btn btn-link btn-sm" onClick={logout}>Logout</button>
                        </div>
                    </div>
                    <Divider />
                    {hasRole(user, [ADMIN_AUTH]) ?
                        <>
                            <List>
                                {navListHeader.map(nav => (
                                    <ListItem button component={LinkBehavior} to={nav.path}>
                                        <ListItemIcon>{nav.icon}</ListItemIcon>
                                        <ListItemText primary={nav.text} />
                                    </ListItem>
                                ))}
                            </List>
                            <Divider />
                            <List>
                                {navListPromoter.map(nav => (
                                    <ListItem button component={LinkBehavior} to={nav.path}>
                                        <ListItemIcon>{nav.icon}</ListItemIcon>
                                        <ListItemText primary={nav.text} />
                                    </ListItem>
                                ))}
                            </List>
                            <Divider />
                            <List>
                                {navListLogistic.map(nav => (
                                    <ListItem button component={LinkBehavior} to={nav.path}>
                                        <ListItemIcon>{nav.icon}</ListItemIcon>
                                        <ListItemText primary={nav.text} />
                                    </ListItem>
                                ))}
                            </List>
                            <Divider />
                            <List>
                                {navListBottom.map(nav => (
                                    <ListItem button component={LinkBehavior} to={nav.path}>
                                        <ListItemIcon>{nav.icon}</ListItemIcon>
                                        <ListItemText primary={nav.text} />
                                    </ListItem>
                                ))}
                            </List>
                        </> :
                        <>
                            <List>
                                {userList.map(nav => (
                                    <ListItem button component={LinkBehavior} to={nav.path}>
                                        <ListItemIcon>{nav.icon}</ListItemIcon>
                                        <ListItemText primary={nav.text} />
                                    </ListItem>
                                ))}
                            </List>
                        </>}
                </Drawer>
                <div className="header-bar">
                    <div className="container">
                        <div className="row align-items-center justify-content-end">
                            <div className="d-flex justify-content-start col-lg-9 col-md-8 d-none d-md-block">
                                <Header />
                            </div>
                            <div className="d-flex justify-content-end col-lg-3 col-md-4 col-12">
                                <Nav style={{paddingRight: "1em"}}>
                                    {!type ? <NavItem className="d-flex align-items-center">
                                        <div className="button-holder">
                                            <Dropdown show={dropdownCreateOpen} onToggle={toggleCreate}>
                                                <DropdownToggle
                                                    className="add-button"
                                                    >
                                                    <FontAwesomeIcon icon={icon({name: "plus"})}/> Anlegen
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownHeader>Event/Logistik</DropdownHeader>
                                                    <DropdownItem className="fusion-basic-link-icon"
                                                                  onClick={() => history.push(ADMIN_EVENT_LOGISTIC_CREATE_EDIT.path.replace(":eventType", "event"))}>
                                                        <FontAwesomeIcon icon={faCalendarPlus}/> Neues Event
                                                    </DropdownItem>
                                                    <DropdownItem className="fusion-basic-link-icon"
                                                                  onClick={() => history.push(ADMIN_SERIAL_EVENT_LOGISTIC_CREATE.path.replace(":eventType", "event"))}>
                                                        <FontAwesomeIcon icon={faCalendarPlus}/> Neues Serienevent
                                                    </DropdownItem>

                                                    <DropdownDivider/>

                                                    <DropdownItem className="fusion-basic-link-icon"
                                                                  onClick={() => history.push(ADMIN_EVENT_LOGISTIC_CREATE.path.replace(":eventType", "logistic"))}>
                                                        <FontAwesomeIcon icon={faTruckLoading}/> Neues Logisitik
                                                    </DropdownItem>
                                                    <DropdownItem className="fusion-basic-link-icon"
                                                                  onClick={() => history.push(ADMIN_SERIAL_EVENT_LOGISTIC_CREATE.path.replace(":eventType", "logistic"))}>
                                                        <FontAwesomeIcon icon={faTruckLoading}/> Neue Serien-Logisitik
                                                    </DropdownItem>

                                                    <DropdownDivider />

                                                    <DropdownItem className="fusion-basic-link-icon"
                                                                  onClick={() => history.push(ADMIN_NEW_PROMOTER.path)}>
                                                        <FontAwesomeIcon icon={faUserPlus}/> Neuer Promoter
                                                    </DropdownItem>
                                                    <DropdownItem className="fusion-basic-link-icon"
                                                                  onClick={() => history.push(ADMIN_NEW_LOGISTIC.path)}>
                                                        <FontAwesomeIcon icon={faUserPlus}/> Neuer Logistiker
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>

                                        </div>
                                    </NavItem> : <></>}
                                    <NavItem>
                                        <div className={messageBoxClasses}>
                                        <span className="clickable overlay-container" onClick={toggleMessage}>
                                            <FontAwesomeIcon icon={icon({name: "bell"})} />
                                            <div className="nav-icon-overlay">
                                                <div className="number-message">
                                                    {props.messages && props.messages.filter(message => message.status === "unread").length}
                                                </div>
                                            </div>
                                        </span>
                                        </div>
                                        <div className="message-menuholder">
                                            <div className={classNames("message-menu", messageDropdownOpen ? "open" : "")}>
                                                <div className="message-header">
                                                    <div className="icon-day">
                                                        <h6>
                                                            <span><FontAwesomeIcon icon={icon({name: "bell"})} /></span><span className="pl-2"> TODAY</span>
                                                        </h6>
                                                        <h2><Moment locale="de" format="dddd, D MMMM" /></h2>
                                                    </div>
                                                </div>
                                                {!type ?
                                                    <div className="message-info text-center mb-4">
                                                        <Button variant="outline-primary" onClick={() => history.push(ADMIN_REGISTERED.path.replace(":type", "registries"))}><FontAwesomeIcon icon={faUserPlus} /> Neue Registrierungen {userRegisterCount}</Button>
                                                    </div> : <></>
                                                }
                                                <SideMessages />
                                            </div>
                                        </div>
                                    </NavItem>
                                    <NavItem className="nav-user-details d-none d-md-block">
                                        <Dropdown show={dropdownOpen} onToggle={toggle}>
                                            <DropdownToggle
                                                as={"div"}
                                                data-toggle="dropdown"
                                                className="avatar-holder"
                                                aria-expanded={dropdownOpen}
                                            >
                                                <div className="avatar" style={{"backgroundImage": "url("+ image +")" }}></div>
                                            </DropdownToggle>
                                            <DropdownMenu
                                                align={"end"}>
                                                <DropdownItem>
                                                    <NavLink activeClassName="active" className="nav-link fusion-basic-link-icon" to={type === "USER" ? PROFILE.path : ADMIN_PROFILE.path}>
                                                <span className="icon">
                                                    <FontAwesomeIcon icon={icon({name: 'user'})}/>
                                                </span>
                                                        <span className="title">
                                                    Mein Account
                                                </span>
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink activeClassName="active" className="nav-link fusion-basic-link-icon" to={type === "USER" ? USER_PASSWORD.path : ADMIN_PASSWORD.path}>
                                                    <span className="icon">
                                                        <FontAwesomeIcon icon={icon({name: 'key'})}/>
                                                    </span>
                                                        <span className="title">
                                                        Passwort ändern
                                                    </span>
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem onClick={logout}>
                                                    <div className="nav-link fusion-basic-link-icon">
                                                <span className="icon">
                                                    <FontAwesomeIcon icon={icon({name: 'arrow-right-from-bracket'})}/>
                                                </span>
                                                        <span className="title">
                                                    Logout
                                                </span>
                                                    </div>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </NavItem>
                                </Nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface ISidebarTask {
    value: any,
    userData: any,
    onClick(link : string): void
}

const SidebarTask : React.FC<ISidebarTask> = (props) => {
    const history = useHistory();

    const [type, data] = props.value;

    //console.log(props.userData)

    const taskClass = classNames("task", type)

    const searchData = data.map((d : any) => {
        if (hasRole(props.userData, [ADMIN_AUTH, AUTHOR_AUTH])) {
            switch (type) {
                case "user":
                    d.link = ADMIN_EDIT_PROMOTER.path.replace(":id", d.id)
                    break;
                case "event":
                case "logistic":
                    d.link = ADMIN_EVENT_LOGISTIC_SHOW.path.replace(":eventType", type).replace(":id", d.id);
                    break;
                default:
                    break;
            }
        } else {
            switch (type) {
                case "event":
                case "logistic":
                    d.link = USER_EVENT_LOGISTIC_DETAIL.path.replace(":eventType", type).replace(":id", d.id);
                    break;
                default:
                    break;
            }
        }
        return d;
    })

    /*const onClick = (link) => {
        document.body.removeChild(props.backdrop);
        history.push(link);
    }*/

    return searchData.slice(0, 6).map((d : any) => (
        <div className={taskClass} onClick={() => props.onClick(d.link ? d.link : "")}>
            <div className="row">
                <div className="row">
                    {type !== "user" ?
                        <Fragment>
                            <div className="task-title col-12">
                                <h2>{d.title}</h2>
                            </div>
                            <div className="task-content col-12">
                                <p>{d.content}</p>
                            </div>
                        </Fragment>
                        :
                        <Fragment>
                            <div className="task-title col-6">
                                <h2>{d.firstname} {d.lastname}</h2>
                                <p>{d.email}</p>
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
        </div>
    ))
}

interface ISidebarTaskIcon {
    type: string
}
const SidebarTaskIcon : React.FC<ISidebarTaskIcon> = (props) => {
    const type = props.type;

    switch(type) {
        case "events":
            return <FontAwesomeIcon icon={faCalendar} />
        case "logistics":
            return <FontAwesomeIcon icon={faTruck} />
        case "users":
            return <FontAwesomeIcon icon={faUser} />
        default:
            return <div></div>
    }
}
interface ISidebarDateFields {
    type: {
        type: string,
        value: any
    }
}
const SidebarDateFields : React.FC<ISidebarDateFields> = (props) => {
    const {type, value} = props.type;

    switch(type) {
        case "events":
            return (
                <Fragment>
                    <h3><Moment format={"DD.MM.YYYY"}>{value.eventSections[0].date}</Moment></h3>
                    <h5><Moment format={"HH:mm"}>{value.eventSections[0].timeStart}</Moment> - <Moment format={"HH:mm"}>{value.eventSections[value.eventSections.length-1].timeEnd}</Moment></h5>
                </Fragment>
            )
        case "logistics":
            return (
                <Fragment>
                    <h3><Moment format={"DD.MM.YYYY"}>{value.LogisticInfos[0].date}</Moment></h3>
                    <h5><Moment format={"HH:mm"}>{value.LogisticInfos[0].timeStart}</Moment> - <Moment format={"HH:mm"}>{value.LogisticInfos[value.LogisticInfos.length-1].timeEnd}</Moment></h5>
                </Fragment>
            )
        case "users":
            return (
                <Fragment>
                    <h3>In Datenbank seit</h3>
                    <h5><Moment format={"DD.MM.YYYY"}>{value.createdAt}</Moment></h5>
                </Fragment>
            )
        default:
            return <div></div>
    }
}


const mapReducerToProp = (dispatch : AppDispatch) => {
    return bindActionCreators({
        logout: userLogoutAction,
        readMessage : readMessageAction
    }, dispatch)
};

const mapStateToProps = (state : RootState) => ({
    messages: state.message.messages,
    user: state.user
});


const connector = connect(mapStateToProps, mapReducerToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;


export default connector(TopMenu);
