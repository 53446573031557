import React, {SyntheticEvent, useState} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "react-bootstrap";
import {faBan, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DatePicker} from "rsuite";

interface IStornoModal {
    className?: any,
    event: any,
    onClick(event: any, id: number, date?: any): void
}
const StornoModal : React.FC<IStornoModal> = (props) => {

    const {
        onClick,
        event,
        className
    } = props;

    const [modal, setModal] = useState(false);
    const [date, setDate] = useState(new Date());

    const toggle = () => setModal(!modal);

    const sendImmediately = (e : SyntheticEvent<any>) => {
        if (onClick){
            onClick(e, event.id)
        }
        toggle();
    }



    return (
        <div>
            <Button variant="outline-danger" onClick={toggle}><FontAwesomeIcon icon={faBan} /> Event stornieren</Button>
            <Modal fade={false} show={modal} onHide={toggle} centered={true}>
                <ModalHeader closeButton>Event stornieren...</ModalHeader>
                <ModalBody>
                    <p>Soll dieser Eintrag wirklich storniert werden? Es werden alle Teilnehmer benachrichtigt, das dieser Einsatz nicht stattfinden wird!</p>
                </ModalBody>
                <ModalFooter>
                    <Button variant="primary" onClick={sendImmediately}>Stornieren</Button>{' '}
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default StornoModal;