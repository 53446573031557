import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faCirclePlay, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {faStar, faStickyNote} from "@fortawesome/free-regular-svg-icons";
import {Overlay, OverlayTrigger, Tooltip} from "react-bootstrap";

interface IEventIcons {
    info: any,
    user: any
}

const EventIcons : React.FC<IEventIcons> = (props) => {
    const {info, user} = props;

    const TooltipCreator = (props : any) => (
        <OverlayTrigger overlay={<Tooltip id={props.id}>{props.text}</Tooltip>}>
            {props.children}
        </OverlayTrigger>
    )

    switch(info.status){
        case "confirm_for_event":
            if (info.confirm_accepted) {
                return (
                    <span style={{color: "#5bb71d"}}>
                    <TooltipCreator id={"eventInfoUser_" + info.id + "_" + user.id} text="User hat seinen Einsatz bestätigt und wartet auf den Beginn">
                        <FontAwesomeIcon icon={faCirclePlay} id={"eventInfoUser_" + info.id + "_" + user.id} />
                    </TooltipCreator>
                </span>
                );
            } else {
                return (
                    <span style={{color: "#d29b0f"}}>
                    <TooltipCreator id={"eventInfoUser_" + info.id + "_" + user.id} text="User wurde angenommen hat Einsatz aber noch nicht bestätigt">
                        <FontAwesomeIcon icon={faTimesCircle} id={"eventInfoUser_" + info.id + "_" + user.id} />
                    </TooltipCreator>
                </span>
                );
            }
        case "not_confirmed":
            return (
                <span style={{color: "#d20f20"}}>
                    <TooltipCreator id={"eventInfoUser_" + info.id + "_" + user.id} text="User ist noch nicht eingecheckt">
                        <FontAwesomeIcon icon={faTimesCircle} id={"eventInfoUser_" + info.id + "_" + user.id} />
                    </TooltipCreator>
                </span>
            );
        case "confirm":
            return (
                <span style={{color: "#5bb71d"}}>
                    <TooltipCreator id={"eventInfoUser_" + info.id + "_" + user.id} text="User ist eingecheckt">
                        <FontAwesomeIcon icon={faCheckCircle} id={"eventInfoUser_" + info.id + "_" + user.id} />
                    </TooltipCreator>
                </span>
            );
        case "ended":
            return (
                <span>
                    <TooltipCreator id={"eventInfoUser_" + info.id + "_" + user.id} text="User hat den Einsatz beendet">
                        <FontAwesomeIcon icon={faStickyNote} id={"eventInfoUser_" + info.id + "_" + user.id} />
                    </TooltipCreator>
                </span>
            );
        case "finish":
            return (
                <span style={{color: "#ffd11c"}}>
                    <TooltipCreator id={"eventInfoUser_" + info.id + "_" + user.id} text="User hat sein Feedback abgegeben">
                        <FontAwesomeIcon icon={faStar} id={"eventInfoUser_" + info.id + "_" + user.id} />
                    </TooltipCreator>
                </span>
            );
        default:
            return <></>;
    }
};

export default EventIcons;
