import {EVENT_SECTION_STATUS, TStatusEvent} from "../../model/interfaces/event/IEvent";

export const EventStatus = {
    "SECTION_CREATED": "Angelegt",
    "SECTION_OPENED": "Angelegt",
    "SECTION_CANDIDATE_AVAILABLE": 'Bewerber vorhanden',
    "SECTION_OPERATIONAL": 'Bereit für Einsatz',
    "SECTION_NOT_ALL_CONFIRMED": 'Nicht bestätigt',
    "SECTION_ALL_CONFIRMED": 'Alle bestätigt',
    "SECTION_ENDED": 'Event abgeschlossen (Bericht fehlt)',
    "SECTION_PROTOCOL_SEND": 'Bericht gesendet',
    /*"completed": 'Abrechnung geöffnet',*/
    "SECTION_FINISHED": 'Beendet',
    "SECTION_STORNO": "STORNIERT"
} as {
    [k in EVENT_SECTION_STATUS] : string
}

export const EventUserStatus = {
    not_apply: "Nicht beworben",
    apply: "Beworben",
    reject: "Einsatz zurückgezogen",
    confirm_for_event: "Für Event eingetragen",
    not_confirmed: "Nicht bestätigt",
    confirm: 'Bestätigt',
    ended: 'Einsatz beended (Bericht fehlt)',
    finish: 'Einsatz abgeschlossen',
    completed: 'Abrechnung geöffnet',
    finished: 'Beendet'
} as {
    [k in keyof TStatusEvent] : string
}

export const statusRanking = {
    not_apply: 0,
    apply: 1,
    reject: 2,
    confirm_for_event: 3,
    candidature: 3,
    not_confirmed: 4,
    confirm: 5,
    ended: 6,
    finish: 7,
    completed: 8,
    finished: 9
}

export const eventStatusRanking = {
    SECTION_CREATED: 0,
    SECTION_OPENED: 1,
    SECTION_CANDIDATE_AVAILABLE: 2,
    SECTION_NOT_ALL_CONFIRMED: 3,
    SECTION_ALL_CONFIRMED: 4,
    SECTION_OPERATIONAL: 5,
    SECTION_STARTED: 6,
    SECTION_ENDED: 7,
    SECTION_PROTOCOL_SEND: 8,
    SECTION_FINISHED: 9,
    SECTION_STORNO: 10
}

export const eventInfoStatusRanking = {
    SECTION_CREATED: 0,
    SECTION_OPENED: 1,
    SECTION_CANDIDATE_AVAILABLE: 2,
    SECTION_NOT_ALL_CONFIRMED: 3,
    SECTION_ALL_CONFIRMED: 4,
    SECTION_OPERATIONAL: 5,
    SECTION_STARTED: 6,
    SECTION_ENDED: 7,
    SECTION_PROTOCOL_SEND: 8,
    SECTION_FINISHED: 9,
    SECTION_STORNO: 10
}
