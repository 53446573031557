import React, {useEffect, useState} from 'react';
import {Button, Col, FormGroup, Form, Modal, ModalBody, ModalHeader, Row, Table, FormCheck} from "react-bootstrap";
import PropTypes from "prop-types";
import Moment from "react-moment";
import instance from "../../util/api";
import UserListModal from "./UserListModal";
import {useHistory} from 'react-router'
import {toast} from "react-toastify";
import FusionToast from "../toast/FusionToast";
import {ADMIN_EDIT_PROMOTER} from "../../util/routes/routes";
import {Link} from 'react-router-dom';
import {IEventSection} from "../../model/interfaces/event/IEvent";
import moment from "moment";

interface IEventLogisticUserInfoModal {
    eventUser: any,
    eventSections: IEventSection[]
    buttonLabel: any,
    className?: any,
    eventId: number
    saveChanges?: () => void
}

type TForm = {
    selectEvent: any,
    selectEventBackup: any
}
const EventLogisticUserInfoModal: React.FC<IEventLogisticUserInfoModal> = (props) => {
    const {
        eventUser,
        eventSections,
        buttonLabel,
        className,
        eventId
    } = props;

    const history = useHistory();
    const [selectedData, selectData] = useState<any[]>([]);
    const [form, setForm] = useState<TForm>({selectEvent:{}, selectEventBackup: {}});
    const [modal, setModal] = useState<boolean>(false);
    useEffect(() => {
        const selectedEvent = {} as any;
        const selectEventBackup = {} as any;
        //eventUser.map(user => {
            //const userHasAssignement = user.UserEvent.user_assigned !== "" ? JSON.parse(user.UserEvent.user_assigned) : null;

            console.log(eventSections);

            eventSections.forEach((event) => {

                console.log("EVENT LOGISTIC USER MODAL", event.eventInfoUsers);
                //const userIsChecked = userHasAssignement ? Object.keys(userHasAssignement).find(key => parseInt(key) === event.id) : undefined;

                if (event.eventInfoUsers) {

                    event.eventInfoUsers.forEach((infoUser : any) => {
                        if(selectedEvent[event.id]){
                            selectedEvent[event.id].push({
                                userId: infoUser.user_id,
                                checked: infoUser.user_assigned === "1" || infoUser.user_assigned
                            })
                        } else {
                            selectedEvent[event.id] = [
                                {
                                    userId: infoUser.user_id,
                                    checked: infoUser.user_assigned === "1" || infoUser.user_assigned
                                }
                            ]
                        }

                        if(selectEventBackup[event.id]){
                            selectEventBackup[event.id] = [...selectEventBackup[event.id], {
                                userId: infoUser.user_id,
                                checked: infoUser.user_assigned_backup === "1" || infoUser.user_assigned_backup
                            }]
                        } else {
                            selectEventBackup[event.id] = [
                                {
                                    userId: infoUser.user_id,
                                    checked: infoUser.user_assigned_backup === "1" || infoUser.user_assigned_backup
                                }
                            ]
                        }
                    })

                }
            })
        //});
        setForm(form => {
            form.selectEvent = selectedEvent;
            form.selectEventBackup = selectEventBackup;
            return form;
        })

    }, [eventUser])

    const toggle = () => {
        setModal(!modal);
    }

    const onChange = (event : any) => {
        event.persist();

        const values = event.target.value.split(",");
        const id = values[0];
        const user = parseInt(values[1]);

        //console.log(event.target.name, values, event.target.checked);

        setForm((form) => {
            if (form[event.target.name as keyof typeof form][id]){
                const foundInd = form[event.target.name as keyof typeof form][id].findIndex((n: any) => n.userId === user);
                if (foundInd !== -1){
                    form[event.target.name as keyof typeof form][id][foundInd].checked = event.target.checked
                } else {
                    form[event.target.name as keyof typeof form][id] = [
                        ...form[event.target.name as keyof typeof form][id],
                        {
                            userId: user,
                            checked: event.target.checked
                        }
                    ]
                }
            } else {
                form[event.target.name as keyof typeof form][id] = [
                    {
                        userId: user,
                        checked: event.target.checked
                    }
                ];

            }
            return form;
        });
    }

    const onClick = (eventId: number, userId: number, isBackup: boolean, deleteFrom = false) => {
        instance.get(`/admin/event/${props.eventId}/${eventId}/status/${userId}`,
            {
                params: {
                    action: "confirm_for_event",
                    admin: true,
                    delete: deleteFrom,
                    isBackup: isBackup
            }}).then(resp => {
                //console.log(resp.data);
                if (!resp.data.error){
                    setForm(currentForm => {
                        let updatedForm = {
                            ...currentForm,
                            selectEvent: {
                                ...currentForm.selectEvent
                            },
                            selectEventBackup: {
                                ...currentForm.selectEventBackup
                            }
                        };

                        if (updatedForm.selectEvent[eventId]){
                            const foundInd = updatedForm.selectEvent[eventId].findIndex((n: any) => n.userId === userId);
                            if (foundInd !== -1){
                                updatedForm.selectEvent[eventId][foundInd].checked = !deleteFrom
                            }
                        }
                        if (updatedForm.selectEventBackup[eventId] && isBackup){
                            const foundInd = updatedForm.selectEventBackup[eventId].findIndex((n: any) => n.userId === userId);
                            if (foundInd !== -1){
                                updatedForm.selectEventBackup[eventId][foundInd].checked = !deleteFrom
                            }
                        }

                        console.log("SET FORM", updatedForm);

                        return updatedForm;
                    })
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
        }).catch(err => {
            //console.log(err);
        })
    };

    const deleteUserFromList = () => {
        const data = {
            eventId: props.eventId,
            userIds: selectedData
        }
        instance.post("/admin/event/removeUser", data)
            .then(resp => {
                if (!resp.data.error){
                    history.go(0);
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
            }).catch(err => {
            //console.log(err);
        })
    }
    //console.log("FORM", form);


    return (
        <div>
            <Button variant="secondary" className="w-100" onClick={toggle}>{buttonLabel}</Button>
            <Modal fade={false} centered={true} size="xl" show={modal} onHide={toggle} className={className}>
                <ModalHeader className="background-black" data-bs-theme="dark" closeButton>Bewerber für Event</ModalHeader>
                <ModalBody>
                    <h3>Promoter</h3>
                    <p>Hier hast du die Möglichkeit Bewerber anzusehen und diese anschließend für das Event einzutragen.<br />
                    Bitte speichere deine Änderungen damit diese aktiv werden können</p>
                    <div className="event-main-promoter">
                        <div className="list-container">
                            <div className="list-holder">
                                <Table bordered className="table table-hover list-table">
                                    <thead className="list-header">
                                    <tr>
                                        <th>Bewerber</th>
                                        {/*<th>Wunsch</th>*/}
                                        { eventSections.map((eventInfo, index : number) => {
                                            const date = moment(eventInfo.date);
                                            console.log("SECTION INFOS", eventInfo, date)

                                            const startTime = moment(eventInfo.starting_time)
                                            startTime.set("date", date.get("date"))
                                            startTime.set("month", date.get("month"))
                                            startTime.set("year", date.get("year"))

                                            const endTime = moment(eventInfo.ending_time)
                                            endTime.set("date", date.get("date"))
                                            endTime.set("month", date.get("month"))
                                            endTime.set("year", date.get("year"))


                                            return (
                                                <th key={index + "_" + eventInfo.id}>
                                                    <Row className="text-center">
                                                        <div className="col-5"><Moment
                                                            format={"DD.MM.YYYY HH:mm"}>{startTime}</Moment>
                                                        </div>
                                                        <div className="col-2">-</div>
                                                        <div className="col-5"><Moment
                                                            format={"DD.MM.YYYY HH:mm"}>{endTime}</Moment>
                                                        </div>
                                                    </Row>
                                                </th>
                                            )}
                                        ) }
                                    </tr>
                                    </thead>
                                    <tbody className="list-body">
                                        {eventUser.map((user : any) => {
                                                /*const dataClass = classNames("status", user.UserEvent.status);
                                                const userPreference = (user.UserEvent.user_preference) ? JSON.parse(user.UserEvent.user_preference) : null;
                                                const userEventPref = [];
                                                if (userPreference){
                                                    eventSections.forEach(info => {
                                                        userEventPref.push(...info.times.filter(event => Object.keys(userPreference).find(key => parseInt(key) === event.id)))
                                                    });
                                                    //console.log(userPreference, userEventPref);
                                                }*/

                                                return (
                                                    <tr key={user.id}>
                                                        <td>
                                                            <div className="d-flex">
                                                                <Col md={8}>
                                                                    <Link to={ADMIN_EDIT_PROMOTER.path.replace(":id", user.id)}>{user.firstname + " " + user.lastname}</Link>
                                                                </Col>
                                                                {/*<Col md={8}>
                                                                    <div className={dataClass}>
                                                                        <h5>{EventUserStatus[user.UserEvent.status]}</h5>
                                                                    </div>
                                                                </Col>*/}
                                                            </div>
                                                        </td>
                                                        {/*<td>{userEventPref.length > 0 ? userEventPref.map(event => (
                                                                <div className="small" key={event.id}>
                                                                    <p>{event.eventTitle}</p>
                                                                    <Moment format="DD.MM.YYYY HH:mm">{event.starting_time}</Moment> - <Moment format="DD.MM.YYYY HH:mm">{event.ending_time}</Moment>
                                                                </div>
                                                            )) : "Keine"}</td>*/}
                                                        {/*<td>
                                                        {eventSections.map((eventInfo : any) => eventInfo.times.filter((event : any) => {
                                                            console.log("EVENT INFOS", event.eventInfoUsers);
                                                            return event.eventInfoUsers.find((i: any) => i.user_id === user.id && i.user_preference === "true")
                                                        }).map((event : any) => {
                                                            //console.log(event);
                                                            return (
                                                                <div className="small" key={event.id}>
                                                                    <p>{event.eventTitle}</p>
                                                                    <Moment format="DD.MM.YYYY HH:mm">{event.starting_time}</Moment> - <Moment format="DD.MM.YYYY HH:mm">{event.ending_time}</Moment>
                                                                </div>
                                                            )
                                                        }))}
                                                        </td>*/}
                                                        { eventSections.map((event) => {
                                                            let isChecked = false;
                                                            let isBackupChecked = false;

                                                            const promoted = event.eventInfoUsers && event.eventInfoUsers.find((i: any) => i.user_id === user.id);
                                                            let isPreference = event.eventInfoUsers && event.eventInfoUsers.find((i: any) => i.user_id === user.id && i.user_preference);

                                                            console.log("IS PREFERENCE", isPreference, event.eventInfoUsers)

                                                            console.log("IS CHECKED", form.selectEvent, form.selectEvent[event.id], user.id)

                                                            if (form.selectEvent[event.id]){
                                                                const found = form.selectEvent[event.id].find((ev : any) => ev.userId === user.id);
                                                                //console.log("FOUND", event.id, found);
                                                                isChecked = found ? found.checked : false;
                                                            }

                                                            if (form.selectEventBackup[event.id]){
                                                                const foundBackup = form.selectEventBackup[event.id].find((ev : any) => ev.userId === user.id);
                                                                //console.log(found);
                                                                isBackupChecked = foundBackup ? foundBackup.checked : false;
                                                            }

                                                            if (isPreference) {
                                                                return (
                                                                    <td key={event.id}>
                                                                        {promoted ? <>
                                                                            {isChecked ? <Button className="small" onClick={() => onClick(event.id, user.id, isBackupChecked, true)}>Austragen</Button> : <Button className="small" onClick={() => onClick(event.id, user.id, isBackupChecked)}>Annehmen</Button>}
                                                                            {(event.backup_person && event.backup_person > 0) ?
                                                                                <FormCheck
                                                                                    type={"checkbox"}
                                                                                    name="selectEventBackup"
                                                                                    checked={isBackupChecked}
                                                                                    value={[event.id, user.id]}
                                                                                    onChange={onChange}
                                                                                    label={"Als Backup eintragen"} />
                                                                                : <></>}
                                                                        </> : <></>}
                                                                    </td>
                                                                )
                                                            } else {
                                                                return (
                                                                    <td key={event.id}></td>
                                                                )
                                                            }
                                                        }) }
                                                    </tr>
                                                )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-4 col-md-3">
                                    <UserListModal className="UserListModal" refId={props.eventId} />
                                </div>
                                <div className="col-4 col-md-3">
                                    <button className="btn btn-secondary w-100" onClick={() => {
                                        setModal(false)
                                        if (props.saveChanges) {
                                            props.saveChanges();
                                        }
                                    }} >Speichern und Schließen</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default EventLogisticUserInfoModal;
