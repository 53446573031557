import React, {useEffect, useState} from 'react';
import {Button} from "react-bootstrap";
import {RouteComponentProps} from "react-router-dom";
import {connect, ConnectedProps} from "react-redux";
import {EventModel} from "../../../model/EventModel";
import {ADMIN_EVENT_LOGISTIC_EDIT, ADMIN_EVENT_LOGISTIC_SHOW} from "../../../util/routes/routes";
import {EventStatus} from "../../../util/helper/eventHelper";
import BaseLayout from "../../../layouts/BaseLayout/BaseLayout";
import Search from "../../../components/search/Search";
import Loading from "../../../components/loading/Loading";
import List, {ListType} from "../../../components/list/List";
import instance from "../../../util/api";
import moment from "moment";
import {toast} from "react-toastify";
import FusionToast from "../../../components/toast/FusionToast";
import {RootState} from "../../../store";
import {EVENT_SECTION_STATUS, IEvent} from "../../../model/interfaces/event/IEvent";
import {IEventResponse} from "./EventList";

const classNames = require('classnames');

interface MatchProps {
    eventType: string
}
interface IEventDeleted extends PropsFromRedux, RouteComponentProps<MatchProps> {

}

const EventDeleted : React.FC<IEventDeleted> = (props) => {
    const [events, setEvents] = useState<IEventResponse[]>([]);
    const [bkupEvents, setBkupEvents] = useState<IEventResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [done, setDone] = useState<boolean>(false);

    //let selectedData = [];

    useEffect(() => {
        setLoading(true);
        instance.get(`/admin/event/all`, {
            params: {
                deleted: true,
                type: props.match.params.eventType.toUpperCase(),
                username: props.userData.username
            }
        })
            .then(resp => {
                //console.log(resp.data);
                const events = resp.data;
                setEvents(events);
                setBkupEvents(events);
                setLoading(false);
                setTimeout(() => {
                    setDone(true);
                }, 1000);
            }).catch(err => {
                //console.log(err);
        })
    }, [props.match.params.eventType]);

    const onSearch = (values : any[], searchVal: string) => {
        if (searchVal !== "") {
            const foundUsers = bkupEvents.filter(user => values.find(val => val.id === user.id));
            setEvents(foundUsers)
        }  else {
            setEvents(bkupEvents);
        }
    }

    const onFilter = (values : any[], filterNr : number) => {
        if (filterNr > 0) {
            const foundUsers = bkupEvents.filter(user => values.find(val => val.id === user.id));
            setEvents(foundUsers)
        }  else {
            setEvents(bkupEvents);
        }
    }

    const onDownloadExcelFile = () => {
        instance.get("/admin/event/excel", {
            params: {
                type: props.match.params.eventType.toUpperCase()
            }
        })
            .then(async resp => {
                //console.log(resp);
                if (!resp.data.error){
                    const foundFile = await instance.get("/file/files/" + resp.data.file, {
                        responseType: 'blob',
                    })
                    //console.log(foundFile);
                    if (foundFile.data) {
                        const url = URL.createObjectURL(new Blob([foundFile.data], {type: foundFile.headers["content-type"]}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', resp.data.file); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }

            }).catch(err => {
                //console.log(err);
        })
    }

    const headers = [
        {slug: "title", name: "Name"},
        {slug: "date", name: "Datum"},
        {slug: "personen", name: "Personen"},
        {slug: "place", name: "Ort"},
        {slug: "status", name: "Status"},
    ];

    const currentDate = moment();
    const rowData = events.filter(ev => (!ev.is_serial && ev.eventSections.length > 0)).map(ev => {
        let nextEventStatus;
        if (ev.status !== "completed" && ev.status !== "finished"){
            const nextEventInfo = ev.eventSections.sort((a: any,b: any) => moment(a.date).diff(currentDate) - moment(b.date).diff(currentDate));
            //console.log("NEXT EVENT INFO", nextEventInfo);
            nextEventStatus = nextEventInfo.length > 0 ? nextEventInfo[0].status : EVENT_SECTION_STATUS.SECTION_CREATED;
        } else {
            nextEventStatus = EVENT_SECTION_STATUS.SECTION_FINISHED;
        }



        const dataClass = classNames("status", nextEventStatus);
        return {
            show: ADMIN_EVENT_LOGISTIC_SHOW.path.replace(":eventType", props.match.params.eventType).replace(":id", ev.id.toString()),
            edit: ADMIN_EVENT_LOGISTIC_EDIT.path.replace(":eventType", props.match.params.eventType).replace(":id", ev.id.toString()),
            delete: 'event',
            id: ev.id,
            data: [
                {val: ev.title, classes: [], type: ListType.STRING, slug: "title"},
                {val: ev.eventSections[0].date, classes: [], type: ListType.DATE, slug: "date"},
                {val: ev.eventSections[0].locationLabel, classes: [], type: ListType.STRING, slug: "locationLabel"},
                {val: <div className={dataClass}>
                        <h5>{EventStatus[nextEventStatus]}</h5>
                    </div> , classes: [], type: ListType.HTML, slug: "status"},
            ]}
    });


    return (
        <BaseLayout>
            <div className="row align-items-center justify-content-between pb-5">
                <div className="col-12 col-md-4 col-lg-7">
                    <h1>{props.match.params.eventType === "event" ? "Gelöschte Events" : "Gelöschte Logistik Einsätze"}</h1>
                </div>
                <div className="col-5">
                    <Search onChange={onSearch} placeholder={props.match.params.eventType === "event" ? "Suche nach Events..." : "Suche nach Logistik Events..."}  searchType={props.match.params.eventType}/>
                </div>
                <div className="col-2">
                    <Button className="btn-secondaryDark btn-small" onClick={onDownloadExcelFile}>Download as Excel</Button>
                </div>
            </div>
            {!done ?
                <Loading loading={loading} />
                :
                <List headers={headers} data={rowData} type={''} noEdit={false}/>
            }
        </BaseLayout>
    );
};

const mapStateToProps = (state : RootState) => ({
    userData: state.user
})


const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EventDeleted);