import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import App from './App/App';
import * as serviceWorker from './serviceWorker';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/style.scss'
import {BrowserRouter as Router} from "react-router-dom";
import {persistor, store} from "./store";
import {PersistGate} from 'redux-persist/integration/react';
import WebSocket from "./util/socket/WebSocket";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import de from 'date-fns/locale/de';

registerLocale('de', de)
setDefaultLocale('de');

ReactDOM.render(
    <Router>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <WebSocket>
                    <App />
                </WebSocket>
            </PersistGate>
        </Provider>
    </Router>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
