import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import BaseLayout from "../../layouts/BaseLayout/BaseLayout";
import instance from "../../util/api";
import {Card, CardBody, CardHeader, Col, FormGroup, Form, Row, Button} from "react-bootstrap";
import InputType from "../../components/inputType/InputType";
import Dropzone from "../../components/dropzone/Dropzone";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {getImage, getImages} from "../../util/helper/images";
import {connect, ConnectedProps} from "react-redux";
import BaseLayoutUser from "../../layouts/BaseLayout/BaseLayoutUser";
import {ADMIN_AUTH} from "../../util/auth/auth";
import {toast} from "react-toastify";
import FusionToast from "../../components/toast/FusionToast";
import {IRegisterState, registerInitState, stepOne} from "../../state/reducer/user/userRegisterSlice";
import {AppDispatch, RootState} from "../../store";
import {ActionMeta} from "react-select";
import {Formik, Form as FormFormik} from "formik";
import * as yup from 'yup';
import uuid from "uuid";
import {getFile} from "../../util/helper/files";
import {bindActionCreators} from "redux";
import {userActivationAction} from "../../state/action/user/userAction";

const formInit = registerInitState;

export interface IProfileState {
    id: string,
    email: string,
    password: string,
    title: string,
    firstname: string,
    lastname: string,
    username: string,
    birthday: string,
    socialNumber: string,
    nationality: string,
    address: string,
    city: string,
    postcode: string,
    telephone: string,
    info: string,
    vertrag_unterschrieben: boolean,
    is_active: boolean
    attr: IProfileStateAttr
}

interface IProfileStateAttr {
    fuhrerschein: string,
    haarfarbe: string,
    schuhroe: string,
    konfektionsgroe: string,
    korpergroe: string,
    brille: string,
    eigenes_auto: string
}

const initForm = {
    id: "",
    email: "",
    password: "",
    title: "",
    firstname: "",
    lastname: "",
    username: "",
    birthday: "",
    socialNumber: "",
    nationality: "",
    address: "",
    city: "",
    postcode: "",
    telephone: "",
    info: "",
    vertrag_unterschrieben: false,
    is_active: false,
    attr: {
        fuhrerschein: "",
        haarfarbe: "",
        schuhroe: "",
        konfektionsgroe: "",
        korpergroe: "",
        brille: "",
        eigenes_auto: ""
    }
} as IProfileState;


interface IProfile extends PropsFromRedux {

}


type TImage = {
    content: string | null,
    preview: string | null
}

type TImageState = {
    fullscreen: TImage,
    profile: TImage,
    unset: TImage[]
}

const initImages = {
    fullscreen: {
        content: null,
        preview: null
    },
    profile: {
        content: null,
        preview: null
    },
    unset: [{
        content: null,
        preview: null
    }]
} as TImageState;

type SelectOptionType = { label: string, value: string }

type FormControlElement = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

const formValuesSchema = yup.object({
    title: yup.string().required("Bitte wähle einen Titel aus!"),
    firstname: yup.string().required("Bitte trage deinen Vornamen ein!"),
    lastname: yup.string().required("Bitte trage deinen Nachnamen ein!"),
    birthday: yup.string().required("Bitte trage dein Geburtsdatum ein!"),
    socialNumber: yup.number().required("Bitte trage deine Sozialversicherung ein!"),
    nationality: yup.string().required("Bitte trage deine Nationalität ein!"),
    address: yup.string().required("Bitte trage deine Adresse ein!"),
    city: yup.string().required("Bitte trage deine Stadt ein!"),
    postcode: yup.number().required("Bitte trage deine PLZ ein!"),
    telephone: yup.string().required("Bitte trage deine Telefonnummer ein!"),
    attr: yup.object({
        fuhrerschein: yup.string().required("Bitte wähle einen Führerschein aus!"),
        haarfarbe: yup.string().required("Bitte wähle eine Haarfarbe aus!"),
        schuhroe: yup.string().required("Bitte wähle eine Schuhgröße aus!"),
        konfektionsgroe: yup.string().required("Bitte wähle eine Konfektionsgröße aus!"),
        korpergroe: yup.string().required("Bitte wähle eine Körpergröße aus!"),
        brille: yup.string().required("Bitte wähle aus, ob du eine Brille trägst!"),
        eigenes_auto: yup.string().required("Bitte wähle aus, ob du ein eigenes Auto hast!"),
    })
});

interface IAttribute {
    id: string,
    name: string,
    slug: keyof IProfileStateAttr,
    type: string,
    options?: string[],
    required?: boolean,
    multiple?: boolean,
    value: string
}

const Profile : React.FC<IProfile> = (props) => {
    const [form, setForm] = useState(initForm);
    const [attrForm, setAttrForm] = useState<any>({});
    const [contract, setContract] = useState("");
    const [attributes, setAttributes] = useState<IAttribute[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [contractUrl, setContractUrl] = useState("");
    const history = useHistory();

    const formValid = {} as {[key : string] : boolean};
    (Object.keys(registerInitState) as (keyof IRegisterState)[]).forEach((key) => {
        formValid[key] = false;
    })

    const [userImages, setImages] = useState<TImageState>(initImages);

    const [error, setError] = useState<any[]>([]);


    const {user} = props;

    useEffect(() => {
        instance.get("/user/profile")
            .then(async resp => {
                //console.log(resp.data);
                if (!resp.data.error){
                    const userAttr = resp.data.user.UserInfos;
                    console.log("USER ATTR", userAttr)

                    const userData = resp.data.user;
                    setAttributes(resp.data.attr);
                    const formData = resp.data.attr.reduce((obj : any, item : any) => {
                        const atrF = userAttr.find((a : any) => a.attribute === item.slug);
                        if (userAttr && atrF && atrF.attribute === "ubernachtungsmoglichkeit"){
                            const ueberValue = userAttr.find((a : any) => a.attribute === "ubernachtungsmoglichkeit_value")

                            try{

                                const JSONparsed = JSON.parse(ueberValue.value);
                                const data = Object.keys(JSONparsed).map((key) => {
                                    const name = "ubernachtungsmoglichkeit_value[" + key + "]";
                                    return {
                                        [name]: JSONparsed[key][0]
                                    }
                                }).reduce((acc, curr) => ({...acc, ...curr}))

                                //console.log(ueberValue, data);

                                return {...obj,
                                    [item.slug]: atrF ? atrF.value : "",
                                    ...data
                                }
                            } catch (e){

                            }
                        }
                        return {...obj,
                            [item.slug]: atrF ? atrF.value : ""
                        }
                    }, {});
                    userData.attr = formData;

                    const images = userAttr.filter((attr : any) => attr.attribute === "image");
                    const fullscreenImage = userAttr.find((attr : any) => attr.attribute === "fullscreen_image");
                    const profileImage = userAttr.find((attr : any) => attr.attribute === "profile_image");
                    const contract = userAttr.find((attr : any) => attr.attribute === "contract");

                    const imagesObjects = await getImages(images);
                    const fullscreenImageObject = fullscreenImage ? await getImage(fullscreenImage.value) : "";
                    const profileImageObject = profileImage ? await getImage(profileImage.value) : "";

                    setImages({
                        fullscreen: {content: '', preview: fullscreenImageObject},
                        profile: {content: '', preview: profileImageObject},
                        unset: imagesObjects.map(img => ({content: '', preview: img.url, id: img.id}))
                    })

                    setContract(contract ? contract.value : "");

                    setForm(userData);
                    setAttrForm(formData);
                    setLoaded(true);
                    //console.log(resp.data, formData, imagesObjects);
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Profil konnte nicht abgefragt werden" message="Anscheinend gibt es ein Problem mit deinem Profil" />);
                }
            }).catch(err => {
                console.error(err);
                toast(<FusionToast className="danger" header="Profil konnte nicht abgefragt werden" message="Anscheinend gibt es ein Problem mit deinem Profil" />);
        });


        instance.get('/admin/documentation', {
            params: {
                type: "contract"
            }
        })
            .then(response => {
                console.log(response.data);
                setContractUrl("/api/file/pdfs/" + response.data.documentation[0].name)
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    const handleChange = (event : ChangeEvent<FormControlElement>) => {
        setForm({
            ...form,
            [event.target.name] : event.target.value
        })
    };

    const uploadContract = (event : ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        const selectedFiles = files as FileList;

        const formData = new FormData();
        formData.append("contract", selectedFiles?.[0]);
        formData.append("type", "contract");

        instance.post('/user/file', formData)
            .then(res => {
                console.log(res.data);
                if (!res.data.error){
                    setForm({
                        ...form,
                        vertrag_unterschrieben: true
                    })
                    setContract(res.data.file.filename);
                    props.userActivationAction("contract", true);
                    toast(<FusionToast className="success" header="Vertrag gespeichert" message="Vielen Dank dein Vertrag wurde hochgeladen. Wir prüfen nochmals die Daten und schalten dich dann komplett frei" />);
                }
            })
            .catch(err => console.error(err));
    }

    const handleChangeAttr = (event : ChangeEvent<HTMLInputElement> | SelectOptionType | null, actionMeta?: ActionMeta<SelectOptionType>) => {
        console.log("HANDLE CHANGE", event, actionMeta);

        const changeEvent = event as ChangeEvent<HTMLInputElement>;
        const reactSelect = event as SelectOptionType;
        if (changeEvent && changeEvent.target) {
            if (changeEvent.target.type === "checkbox"){
                if (changeEvent.target.checked){
                    setAttrForm({
                        ...attrForm,
                        [changeEvent.target.name]: [...attrForm[changeEvent.target.name], changeEvent.target.value]
                    })
                } else {
                    setAttrForm({
                        ...attrForm,
                        [changeEvent.target.name]: [...attrForm[changeEvent.target.name].filter((name : string) => name !== changeEvent.target.value)]
                    })
                }
            } else {
                setAttrForm({
                    ...attrForm,
                    [changeEvent.target.name]: changeEvent.target.value
                })
            }
        } else if (reactSelect.label && actionMeta && actionMeta.name) {
            console.log("HANDLE CHANGE", actionMeta);

            setAttrForm({
                ...attrForm,
                [actionMeta.name]: reactSelect.value
            })
        }
    };


    const handleSubmit = (values: IProfileState) => {
        setError([]);
        let error = 0;

        const formData = new FormData();
        const files = []
        const test = [];
        Object.keys(userImages).forEach((key: string) => {
            if (Array.isArray(userImages[key as keyof TImageState])){
                (userImages[key as keyof TImageState] as TImage[]).forEach(image => {
                    formData.append("file", image.content || "");
                    files.push(image.content);
                })
            }
        })

        formData.append("fullscreen", userImages.fullscreen.content || "");
        formData.append("profile", userImages.profile.content || "");

        for (let [key, value] of Object.entries(values)){
            if (key === "attr"){
                for (let [key, value] of Object.entries(values.attr)) {
                    key = `attr[${key}]`

                    formData.append(key, value as string);
                    test.push({key, value})
                }
            } else {
                test.push({key, value});
                formData.append(key, value);
            }
        }

        for (let [key, value] of Object.entries(attrForm)) {
            const keyArray = key.split(/[\[*\]*]/g)
            let attrKey = "attr";

            keyArray.forEach(k => attrKey += `[${k}]`);

            if (!formData.has(attrKey)){
                formData.append(attrKey, value as string);
                test.push({attrKey, value})
            }
        }

        console.log("TEST", test);

        instance.post('/user/saveProfile', formData)
            .then(res => {
                //console.log(res);
                if (!res.data.error){
                    if (!user.activation_request_sent) {
                        props.userActivationAction("activation_request", true);
                    }
                    toast(<FusionToast className="success" header="Profil erfolgreich gespeichert" message="Dein Profil wurde erfolgreich gespeichert" />);
                }
            })
            .catch(err => console.error(err));
    };

    const deleteAdditionalImages = (e : SyntheticEvent<any>, url : string, id : number) => {
       // console.log(userImages, e, url, id);

        if (id) {
            instance.delete('/user/profile/image', {
                params: {
                    id: id
                }
            }).then(resp => {
                if (!resp.data.error){
                    toast(<FusionToast className="success" header="Profil update" message="Das Foto wurde erfolgreich gelöscht" />);
                }
            }).catch(err => console.error(err));
        }
    }

    return (
        <UserLayout user={user} className="EditUser">
            <div className="user-info">
                <div className="header-info">
                    <div className="container header-container">
                        <div className="row align-items-center">
                            <Col md={2} className="row justify-content-center">
                                <div className="img-holder avatar-cubed" style={{backgroundImage: `url(${userImages.profile ? userImages.profile.preview : ""})`}}></div>
                            </Col>
                            <Col md={5}>
                                <div className="main-info-detail">
                                    <h3 className="main-info-header">Hallo {form.firstname} {form.lastname}</h3>
                                    <p className="main-info-calendar">Das ist dein Profil. Du kannst es direkt hier editieren. Manche Daten sind jedoch fixiert und können nicht mehr nachträglich geändert werden!</p>
                                </div>
                            </Col>
                        </div>
                    </div>
                </div>
                <div className="container main-area-container">
                {loaded ?
                    <Formik
                        initialValues={form}
                        validationSchema={formValuesSchema}
                        id="RegisterForm"
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                    >
                        {({ handleSubmit, handleChange, setFieldValue, values, errors }) => {
                            console.log("VALUES", values)
                            return (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Row className="mt-5">
                                        <Col md={9}>
                                            <Row>
                                                <Col md={6}>
                                                    <Card>
                                                        <CardHeader className="bold">Deine persönlichen Daten</CardHeader>
                                                        <CardBody>
                                                            <FormGroup>
                                                                <Form.Label htmlFor="birthday">Titel</Form.Label>
                                                                <Form.Select name="title" required value={values.title} id="title" placeholder="Title"
                                                                             onChange={handleChange}
                                                                             isInvalid={!!errors.title} >
                                                                    <option>Title</option>
                                                                    <option>Herr</option>
                                                                    <option>Frau</option>
                                                                    <option>Anderes</option>
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Form.Label htmlFor="birthday">Geburtstag</Form.Label>
                                                                <Form.Control type="date" name="birthday" value={values.birthday.slice(0,10)} id="birthday"
                                                                              placeholder="Geburtsdatum" onChange={handleChange} required
                                                                              isInvalid={!!errors.birthday} />
                                                                <Form.Control.Feedback  type="invalid">{errors.birthday}</Form.Control.Feedback>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Form.Label htmlFor="address">Straße</Form.Label>
                                                                <Form.Control type="text" name="address" value={values.address} id="address" placeholder="Straße"
                                                                              onChange={handleChange} required
                                                                              isInvalid={!!errors.address}/>
                                                                <Form.Control.Feedback  type="invalid">{errors.address}</Form.Control.Feedback>
                                                            </FormGroup>
                                                            <FormGroup as={Row}>
                                                                <Col xs={4}>
                                                                    <Form.Label htmlFor="postcode">PLZ</Form.Label>
                                                                    <Form.Control type="text" name="postcode" value={values.postcode} id="postcode" placeholder="PLZ"
                                                                                  onChange={handleChange} required
                                                                                  isInvalid={!!errors.postcode}/>
                                                                    <Form.Control.Feedback  type="invalid">{errors.postcode}</Form.Control.Feedback>

                                                                </Col>
                                                                <Col xs={8}>
                                                                    <Form.Label htmlFor="city">Stadt</Form.Label>
                                                                    <Form.Control type="text" name="city" value={values.city} id="city" placeholder="Stadt"
                                                                                  onChange={handleChange} required
                                                                                  isInvalid={!!errors.city}/>
                                                                    <Form.Control.Feedback  type="invalid">{errors.city}</Form.Control.Feedback>
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Form.Label htmlFor="nationality">Staatsangehörigkeit</Form.Label>
                                                                <Form.Control type="text" name="nationality" value={values.nationality} id="nationality"
                                                                              placeholder="Staatsangehörigkeit" onChange={handleChange} required
                                                                              isInvalid={!!errors.nationality}/>
                                                                <Form.Control.Feedback  type="invalid">{errors.nationality}</Form.Control.Feedback>
                                                            </FormGroup>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col md={6}>
                                                    <Card>
                                                        <CardHeader className="bold">Deine persönlichen Daten</CardHeader>
                                                        <CardBody>
                                                            <FormGroup>
                                                                <Form.Label htmlFor="firstname">Vorname</Form.Label>
                                                                <Form.Control type="text" name="firstname" value={values.firstname} id="firstname"
                                                                              placeholder="Vorname" onChange={handleChange} required
                                                                              isInvalid={!!errors.firstname} />

                                                                <Form.Control.Feedback  type="invalid">{errors.firstname}</Form.Control.Feedback>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Form.Label htmlFor="lastname">Nachname</Form.Label>
                                                                <Form.Control type="text" name="lastname" value={values.lastname} id="lastname"
                                                                              placeholder="Nachname" onChange={handleChange} required
                                                                              isInvalid={!!errors.lastname} />
                                                                <Form.Control.Feedback  type="invalid">{errors.lastname}</Form.Control.Feedback>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Form.Label htmlFor="socialNumber">Sozialversicherung</Form.Label>
                                                                <Form.Control type="text" name="socialNumber" value={values.socialNumber} id="socialNumber"
                                                                              placeholder="Sozialversicherung" onChange={handleChange} required
                                                                              isInvalid={!!errors.socialNumber}/>
                                                                <Form.Control.Feedback  type="invalid">{errors.socialNumber}</Form.Control.Feedback>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Form.Label htmlFor="telephone">Telefonnummer</Form.Label>
                                                                <Form.Control type="text" name="telephone" value={values.telephone} id="telephone"
                                                                              placeholder="Telefonnummer" onChange={handleChange} required
                                                                              isInvalid={!!errors.telephone}/>
                                                                <Form.Control.Feedback  type="invalid">{errors.socialNumber}</Form.Control.Feedback>
                                                            </FormGroup>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col md={6}>
                                                    <Card className="mt-2">
                                                        <CardHeader className="bold">Weitere Informationen über dich (Pflicht)</CardHeader>
                                                        <CardBody>
                                                            {attributes.map((attr, index) => {
                                                                console.log("ERRORS", errors)
                                                                    return (index <= 7 &&
                                                                    <InputType type={attr.type} data={attr}
                                                                               required={true} handleChange={handleChange}
                                                                               setFieldValue={setFieldValue}
                                                                               errors={errors.attr}
                                                                               value={values.attr[attr.slug]}
                                                                               form={values.attr} key={"attr." + attr.slug}
                                                                               name={"attr." + attr.slug}/>)
                                                                }
                                                            )}
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col md={6}>
                                                    <Card className="mt-2">
                                                        <CardHeader className="bold">Weitere Informationen über dich (Optional)</CardHeader>
                                                        <CardBody>
                                                            {attributes.map((attr : any, index) => index > 7 && <InputType type={attr.type} data={attr} handleChange={handleChangeAttr} value={attrForm[attr.slug]} form={attrForm} key={attr.slug} name={attr.slug} />)}

                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Control type="submit" value="Daten speichern" className="btn btn-primary mb-4" />

                                            {!form.vertrag_unterschrieben ?
                                                <Card className="mt-2">
                                                    <CardHeader className="bold">Vertragliches</CardHeader>
                                                    <CardBody>
                                                        <p>Bitte downloade den Vertrag, unterschreibe ihn und lade ihn im Anschluss hier wieder hinauf:</p>
                                                        <a href={contractUrl} target="_blank">Download Vertrag</a>

                                                        <Form.Group controlId="vertragFile" className="mb-3">
                                                            <Form.Label>Vertrag uploaden</Form.Label>
                                                            <Form.Control type="file" name="vertrag" size="sm" onChange={uploadContract} />
                                                        </Form.Group>
                                                    </CardBody>
                                                </Card> :
                                                <Card className="mt-2">
                                                    <CardHeader className="bold">Vertragliches</CardHeader>
                                                    <CardBody>
                                                        <p>Vielen Dank für das Hochladen des Vertrages. Du kannst diesen hier jederzeit einsehen.</p>
                                                        <Button variant={"link"} onClick={() => getFile(`/secured/${user._id}/${contract}`, `Vertrag_Fusion_${form.firstname}_${form.lastname}`)}>Download Vertrag</Button>
                                                    </CardBody>
                                                </Card>
                                            }

                                            <Card className="mt-2">
                                                <CardHeader className="bold">Profil Foto</CardHeader>
                                                <CardBody>
                                                    <Dropzone files={userImages} name="profile" setFiles={setImages} acceptedFiles={""} deleteAble={true} />
                                                </CardBody>
                                            </Card>

                                            <Card className="mt-2">
                                                <CardHeader className="bold">Weitere Fotos hinzufügen? (Mehrere per Drag&Drop möglich!)</CardHeader>
                                                <CardBody>
                                                    <Dropzone files={userImages} name="unset" deleteAble={true} onDeleteCb={deleteAdditionalImages} setFiles={setImages} acceptedFiles={""} />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2 justify-content-end">
                                        <Col md={3}>
                                            <Form.Control type="submit" value="Daten speichern" className="btn btn-primary" />
                                            <p></p>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        }}
                    </Formik> : <div>Loading Data</div>
                }
                </div>
            </div>
        </UserLayout>
    );
};

interface IUserLayout {
    user: any,
    className: any
}
const UserLayout : React.FC<IUserLayout> = (props) => {
    if (props.user.roles.find((role : string) => role === ADMIN_AUTH)){
        return <BaseLayout className={props.className}>{props.children}</BaseLayout>
    } else {
        return <BaseLayoutUser className={props.className}>{props.children}</BaseLayoutUser>
    }
}

const mapStateToProps = (state : RootState) => ({
    user: state.user
});

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return bindActionCreators({
        userActivationAction: userActivationAction
    }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Profile);
