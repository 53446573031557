

export const userStatusList = {
    active: "Verfügbar",
    inactive: "Noch nicht bestätigt",
    deactivate: "Deaktiviert",
    deleted: "Gelöscht",
    busy: "Im Einsatz",
    not_available: "Derzeit nicht Verfügbar",
    not_confirmed: 'Nicht bestätigt',
    confirmed: 'Einsatz bestätigt',
    at_place: 'Vor Ort',
    imported: "User noch nicht aktiviert"
};
