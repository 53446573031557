import {autoLoginInterceptor} from "./middleware/login";
import axios from "axios";

declare var process : {
    env: {
        REACT_APP_DEV_LEVEL: string
    }
}
export const SYSTEM_VARS : any = {
    development: {
        baseUrl:  '/api',
        authUrl: '/auth',
        socket_endpoint: '/',
        client_id: 'app-client-id',
        client_secret: 'U0Z20JDrgBfQUEnSmVxmil2ia6nngefW'
    },
    staging: {
        baseUrl:  '/api',
        authUrl: '/auth',
        socket_endpoint: '/',
        client_id: '',
        client_secret: ''
    },
    main: {
        baseUrl:  '/api',
        authUrl: '/auth',
        socket_endpoint: '/',
        client_id: '',
        client_secret: ''
    }
}

export const OAUTH_CLIENT_ID = SYSTEM_VARS[process.env.REACT_APP_DEV_LEVEL].client_id ? SYSTEM_VARS[process.env.REACT_APP_DEV_LEVEL].client_id :  "";
export const OAUTH_CLIENT_KEY = SYSTEM_VARS[process.env.REACT_APP_DEV_LEVEL].client_secret ? SYSTEM_VARS[process.env.REACT_APP_DEV_LEVEL].client_secret : "";

let refresh_token: string | null = null;
let relogin_process = false;
let relogin_success = false;
export const setRefreshToken = (token: string) => {
    refresh_token = token;
};
export const getRefreshToken = () => {
    return refresh_token;
};
export const setInReloginProcess = (boolean: boolean)  => {
    relogin_process = boolean;
    relogin_success = false;
}
export const getReloginProcess = () => {
    return relogin_process;
}
export const setReloginWasSuccessfull = () => {
    relogin_success = true;
}
export const getReloginWasSuccessfull = () => {
    return relogin_success
}

const instance = axios.create({
    baseURL: SYSTEM_VARS[process.env.REACT_APP_DEV_LEVEL].baseUrl,
    timeout: 60*5*100
});

const authInstance = axios.create({
    baseURL: SYSTEM_VARS[process.env.REACT_APP_DEV_LEVEL].authUrl,
    timeout: 60*5*100
});

console.log(process.env, SYSTEM_VARS[process.env.REACT_APP_DEV_LEVEL].baseUrl);

autoLoginInterceptor(instance);

export default instance;
export {
    authInstance
}
