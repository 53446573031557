import React, {useState} from 'react';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import AttributeValues from "./AttributeValues";
import instance from "../../../util/api";

import "./ConfigurationUserAttr.scss";

const stdAttribute = {
    id: 0,
    name: "",
    slug: "",
    type: "text",
    description: "",
    required: 0,
    value: [""]
};

interface IConfigurationUserAttr {

}

const ConfigurationUserAttr : React.FC<IConfigurationUserAttr> = () => {
    const [attributes, setAttributes] = useState([stdAttribute]);
    const [attrValues, setAttrValues] = useState([{}]);

    const onChangeAttrValue = (value : any, id : number) => {
        setAttrValues(attr => (
            attr.map((a, i) => {
                if (i === id){
                    return value
                }
                return a;
            })
        ));
    };

    const onChange = (target : HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement, id : number) => {
        setAttributes(attrs => (
            attrs.map((ev, i) => {
                if (i === id){
                    return {...ev, [target.name]: target.value, id: id}
                }
                return ev;
            })
        ));
    };

    const onAddNewRow = () => {
        setAttributes(attr => ([...attr, stdAttribute]));
        setAttrValues(attr => ([...attr, {}]));
    };

    const onRemoveRow = (id : number) => {
        setAttributes(events => (events.slice(0, id).concat(events.slice(id + 1, events.length))));
        setAttrValues(events => (events.slice(0, id).concat(events.slice(id + 1, events.length))));
    };

    const onSaveAttributes = () => {
        const attrToSend = attributes.map((attr : any) => {
            attr.value = attrValues[attr.id];
            return attr;
        });

        instance.post("/user/userAttribute", attrToSend)
            .then((resp) => {
                //console.log(resp);
            }).catch((err) => {
            //console.log(err);
        })
        //console.log(attrToSend);
    };

    return (
        <div className="ConfigurationUserAttr">
            <div className="row">
                <div className="col-8"><h1>User Attributes</h1></div>
                <div className="col-4"><Button onClick={onSaveAttributes}>Speichern</Button></div>
            </div>

            <div className="row">
                <div className="col-1"></div>
                <div className="col">Attribute Name</div>
                <div className="col">Slug</div>
                <div className="col">Beschreibung</div>
                <div className="col">Type</div>
                <div className="col">Wert/e</div>
                <div className="col">Zwingend?</div>
                <div className="col-1"></div>
            </div>

            {attributes.map((attr, id) => (
                <div className="form-row small-row" key={id}>
                    <div className="col-1">
                        <Button className="event-delete" onClick={() => onRemoveRow(id)}><FontAwesomeIcon icon={faMinus}/></Button>
                    </div>
                    <div className="col">
                        <input type="text" name="name" value={attr.name} onChange={(event) => onChange(event.target, id)} className="form-control" placeholder="Attribute Name" />
                    </div>
                    <div className="col">
                        <input type="text" name="slug" value={attr.slug} className="form-control" placeholder="Attribute Slug (Autogenerate)" readOnly />
                    </div>
                    <div className="col">
                        <textarea name="description" value={attr.description} onChange={(event) => onChange(event.target, id)} className="form-control" placeholder="Attribute Description" />
                    </div>
                    <div className="col">
                        <select name="type" value={attr.type} onChange={(event) => onChange(event.target, id)} className="form-control" placeholder="Attribute Type">
                            <option value="text">Text</option>
                            <option value="select">Select</option>
                            <option value="multiple-select">Multiple Select</option>
                            <option value="textarea">Textarea</option>
                            <option value="radio">Radio Selection</option>
                            <option value="checkbox">Checkbox Selection</option>
                            <option value="radio-input">Radio Selection with Input Field</option>
                            <option value="city">Stadt Selection</option>
                            <option value="range">Range (From - To)</option>
                        </select>
                    </div>
                    <AttributeValues attr={attr} id={id} onChange={onChangeAttrValue} />
                    <div className="col">
                        <input type="checkbox" name="required" value={attr.required} className="form-control" onChange={(event) => onChange(event.target, id)}/>
                    </div>
                    <div className="col-1">
                        <Button className="event-add" onClick={onAddNewRow}><FontAwesomeIcon icon={faPlus}/></Button>
                    </div>
                </div>
            ))}
        </div>
    );

};

export default ConfigurationUserAttr;
