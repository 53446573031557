import React, {ChangeEvent, useEffect, useState} from 'react';
import {Button, Col, Container, Form, FormGroup} from "react-bootstrap";
import {createError} from "../../util/helper/error";
import instance from "../../util/api";
import {toast} from "react-toastify";
import FusionToast from "../../components/toast/FusionToast";
import {LOGIN} from "../../util/routes/routes";
import NoLayout from "../../layouts/NoLayout/NoLayout";
import Background from "../../assets/images/loginRegisterBg.png";
import {useHistory} from "react-router-dom";
import {Field, Formik, FormikValues} from "formik";
import TwoColumnLayout from "../../layouts/TwoColumnLayout/TwoColumnLayout";

const ResetPassword = () => {
    const [form, setForm] = useState({
        password: "",
        confirm_password: "",
        email: "",
    });
    const [success, setSuccess] = useState(false);

    const [error, setError] = useState<any[]>([]);
    const [emailCheck, setEmailCheck] = useState({
        code: "",
        generateCode: null
    });
    const history = useHistory();

    const sendPasswordReset = (values: FormikValues) => {
        setError([]);
        let error = 0;

        if (values.password !== values.confirm_password){
            error += 1;
            setError(error => [...error, createError("Passwörter stimmen nicht überein")])
        }

        if (error === 0){
            instance.post("/user/password/reset", values)
                .then(resp => {
                    if (resp.data.error){
                        console.error(resp);
                        toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                    } else {
                        toast(<FusionToast className="success" header="Passwort erfolgreich zurückgesetzt" message="" />);
                        setSuccess(true);
                    }
                }).catch(err => {
                    console.error(err);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
            })
        }
    };

    /*const handleInvalidSubmit = (event, errors, values) => {
        setError(error => [...error, createError("Einige Felder müssen noch ausgefüllt werden!")])
    }*/


    return (
        <TwoColumnLayout className="Login" background={Background}>
            <div className="login-main pb-5">
                <h1 className="text-center">Passwort zurücksetzen</h1>
            </div>
            {!success ?
                <div className="login-container">
                    {error.map(error => (error.error ? <div className="alert alert-danger" role="alert">
                        {error.message}
                    </div> : ""))}
                    <Formik
                        id="RegisterForm"
                        onSubmit={sendPasswordReset}
                        initialValues={form}
                        //onInvalidSubmit={handleInvalidSubmit}
                    >
                        {({ handleSubmit, handleChange, setFieldValue, values, touched, errors, isSubmitting }) => {
                            return (<Form onSubmit={handleSubmit}>
                                <FormGroup className="mb-4">
                                    <Form.Control type="email" name={"email"} value={values.email} id="email" placeholder="E-Mail" onChange={handleChange} />
                                </FormGroup>
                                <div className="text-center w-100">
                                    <Button className="w-100" variant="primary" type="submit">Passwort zurücksetzen</Button>
                                </div>
                            </Form>)
                        }}
                    </Formik>
                    <div className="text-center w-100">
                        <Button className="small" onClick={() => history.push(LOGIN)} variant="link">Zum login</Button>
                    </div>
                </div> :
                <div className="login-container">
                    <p>Das zurücksetzen deines Passwortes wurde gestartet. Wenn deine E-Mail existiert, erhälst du in Kürze eine E-Mail mit einem Reset-Link.</p>
                </div>
            }
        </TwoColumnLayout>
    );
};

export default ResetPassword;
