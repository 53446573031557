import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import NoLayout from "../../layouts/NoLayout/NoLayout";
import {Button, Form, FormGroup} from "react-bootstrap";
import {connect, ConnectedProps} from "react-redux";
import {NavLink, RouteComponentProps, useHistory, useLocation, withRouter} from "react-router-dom";
import * as ROUTES from "../../util/routes/routes";
import {REGISTER, RESET_PASSWORD} from "../../util/routes/routes";

import "./Login.scss";
import {hasRole, PROMOTER_AUTH, SUPPLIER_AUTH} from "../../util/auth/auth";
import Background from "../../assets/images/loginRegisterBg.png";
import {AppDispatch, RootState} from "../../store";
import {bindActionCreators} from "redux";
import {userLoginRequestAction, userLogoutAction} from "../../state/action/user/userAction";
import TwoColumnLayout from "../../layouts/TwoColumnLayout/TwoColumnLayout";


interface ILogin extends PropsFromRedux {
    
}
const Login : React.FC<ILogin> = (props) => {
    const [form, setForm] = useState({
        email: "",
        password: ""
    });
    const [formInvalid, setFormInvalid] = useState({
        email: false,
        password: false
    })
    const [error, setError] = useState({error: false, message: ""});
    const location = useLocation() as any;
    const history = useHistory();

    const handleChange = (event : ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.id] : event.target.value
        })
    };

    const handleSubmit = (event : SyntheticEvent) => {
        event.preventDefault();

        setFormInvalid(f => ({...f, email: (form.email === null || form.email === "")}));
        setFormInvalid(f => ({...f, password: (form.password === null || form.password === "")}));

        if (form.email && form.password){
            setFormInvalid({email: false, password: false})
            props.userLoginRequest(form.email, form.password)
        }
    };

    useEffect(() => {
        if (props.status.isAuthenticated) {
            if (hasRole(props.status, [PROMOTER_AUTH, SUPPLIER_AUTH])){
                //props.history.push(ROUTES.HOME.path);
                //console.log("LOCATION", location)
                if (location.state && location.state.referrer){
                    //props.history.replace(location.state.referrer.pathname);
                    history.push(location.state.referrer)
                } else {
                    history.replace(ROUTES.HOME.path);
                }
            } else {
                props.userLogout();
            }
        }
        if (props.status.error.error){
            setError(props.status.error);
        }
    }, [props.status]);

    //console.log("LOGIN ERROR", error);

    return (
        <TwoColumnLayout className="Login" background={Background}>

        <div className="login-main pb-5">
            <h1 className="text-center">Willkommen beim <br/> Fusion Promotion Dashboard</h1>
        </div>
        <div className="login-container">
            <div className={"login-header"}>
                <h4>Login</h4>
            </div>
            <Form onSubmit={handleSubmit}>
                {error.error ? <div className="alert alert-danger" role="alert">
                    {error.message}
                </div> : ""}
                <FormGroup className="mb-2">
                    <Form.Control type="text" name="email" value={form.email} id="email" placeholder="Email / Username" onChange={handleChange} isInvalid={formInvalid.email} />
                    <Form.Control.Feedback>Bitte gib einen Username oder Email an!</Form.Control.Feedback>
                </FormGroup>
                <FormGroup className="mb-2">
                    <Form.Control type="password" name="password" value={form.password} id="password" placeholder="Passwort" onChange={handleChange} isInvalid={formInvalid.password} />
                    <Form.Control.Feedback>Bitte trage ein Password ein!</Form.Control.Feedback>
                </FormGroup>
                <div className="register-link pb-3">
                    <p className="small">Noch kein Teil des Teams? Registriere dich <NavLink to={REGISTER} className="">HIER</NavLink></p>
                </div>
                <Button className="w-100" variant="primary" type={"submit"}>Login</Button>
            </Form>
            <div className="login-footer text-center">
                <Button className="small" onClick={() => history.push(RESET_PASSWORD.path)} variant="link">Passwort vergessen?</Button>
            </div>
        </div>
    </TwoColumnLayout>
    );
};

const mapState = (state : RootState) => {
    return {
        status: state.user

    }
};

const mapDispatchToPorps = (dispatch : AppDispatch) => {
    return bindActionCreators({
        userLoginRequest: userLoginRequestAction,
        userLogout: userLogoutAction
    }, dispatch)
}


const connector = connect(mapState, mapDispatchToPorps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Login);
