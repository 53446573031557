import {REHYDRATE} from "redux-persist";
import instance from "../index";
import {Middleware} from "redux";
import {PersistRootState} from "../../../store";
import {
    userLoginInitAction,
    userLoginSuccessAction,
    userRefreshTokenAction
} from "../../../state/action/user/userAction";

export const authenticationMiddleware: Middleware<
{},
    PersistRootState
    > = ({dispatch, getState}) => next => action => {
    console.log("AXIOS MIDDLEWARE NEXT ACTION", action, action.type,  userLoginSuccessAction.type, userRefreshTokenAction.type);

    if (action.payload && action.type === REHYDRATE){
        //console.log("REHYDRATE", action.payload.userData);
        instance.defaults.headers.common['Authorization'] = action.payload.user.token;
    }
    if (action.payload && (action.type === userLoginSuccessAction.type || action.type === userRefreshTokenAction.type)){
        console.log("USER_LOGIN_SUCCESS", action);
        instance.defaults.headers.common['Authorization'] = action.payload.access_token;
    }
    if (action.type === userLoginInitAction.type){
        instance.defaults.headers.common['Authorization'] = "";
    }
    return next(action);
}
