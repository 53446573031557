import React, {useEffect, useState} from 'react';
import {Button, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

const stdValues = {
    id: 0,
    attrId: 0,
    value: "",
};

interface IAttributeValues {
    id: number,
    onChange : (values : any, id: number) => void,
    attr: any,
}
type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

const AttributeValues : React.FC<IAttributeValues> = (props) => {
    const [attributeValue, setAttrValue] = useState(stdValues);
    const [multipleValues, setMultipleValues] = useState([stdValues]);
    const [attrId, setAttrId] = useState(-1);

    const onChangeAttrValue = (target : HTMLInputElement, attrId : number, id : number) => {
        const values = {id: id, attrId: attrId, value: target.value};
        setAttrValue(values);
        props.onChange(values, props.id);
    };


    const onChangeMultipleAttrValue = (target : FormControlElement, attrId : number, id : number) => {
        setMultipleValues(attrs => (
            attrs.map((ev, i) => {
                if (i === attrId){
                    return {...ev, value: target.value, id: id, attrId: attrId}
                }
                return ev;
            })
        ));
    };

    useEffect(() => {
        setAttrId(props.id);
    }, []);

    useEffect(() => {
        if (props.attr.type === "range"){
            onAddNewRow(1);
            onAddNewRow(2);
        }
    }, [props.attr.type]);

    useEffect(() => {
        props.onChange(multipleValues, attrId);
    }, [multipleValues]);

    useEffect(() => {
        props.onChange(attributeValue, attrId);
    }, [attributeValue]);

    const onAddNewRow = (i : number) => {
        setMultipleValues(attr => ([...attr, {...stdValues, attrId: i}]));
    };

    const onRemoveRow = (id : number) => {
        setMultipleValues(events => (events.slice(0, id).concat(events.slice(id + 1, events.length))));
    };

    switch (props.attr.type){
        case "text":
        case "textarea":
        case "city":
            return (
                <div className="col">
                    <input type="text" name="value" value={attributeValue.value} onChange={(e) => onChangeAttrValue(e.target, 0, props.id)} className="form-control" placeholder="Attribute value" />
                </div>
            );
        case "select":
        case "multiple-select":
        case "radio":
        case "radio-input":
        case "checkbox":
            return (<div className="col d-flex flex-column">
                {multipleValues.map((a, i) => {
                return (
                    <div className="col" key={i}>
                        <InputGroup>
                            <Button className="event-add" onClick={() => onRemoveRow(i)}><FontAwesomeIcon icon={faMinus}/></Button>
                            <Form.Control type="text" name={`value_${props.id}_${i}`} value={a.value} onChange={(e) => onChangeMultipleAttrValue(e.target, i, props.id)} />
                            <Button className="event-add" onClick={() => onAddNewRow(i+1)}><FontAwesomeIcon icon={faPlus}/></Button>
                        </InputGroup>`
                    </div>
                );
            })}
            </div>
        );
        case "range":
            return (
                <div className="col">
                    <input type="number" name={`value_${props.id}_0`} value={multipleValues[0] ? multipleValues[0].value : 0} onChange={(e) => onChangeMultipleAttrValue(e.target, 0, props.id)} className="form-control" placeholder="From" />
                    <input type="number" name={`value_${props.id}_1`}  value={multipleValues[1] ? multipleValues[1].value : 0} onChange={(e) => onChangeMultipleAttrValue(e.target, 1, props.id)} className="form-control" placeholder="To" />
                    <input type="number" name={`value_${props.id}_2`}  value={multipleValues[2] ? multipleValues[2].value : 1} onChange={(e) => onChangeMultipleAttrValue(e.target, 2, props.id)} className="form-control" placeholder="interval" />
                </div>
            );
        default:
            return <></>;
    }
};

export default AttributeValues;