import React from 'react';
import {useHistory} from "react-router-dom";
import {Button} from "react-bootstrap";

const BackButton = () => {
    const history = useHistory();

    return (
        <div className="button-holder">
            <Button variant="primary" onClick={() => history.goBack()}>Zurück</Button>
        </div>
    );
};

export default BackButton;
