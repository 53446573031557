import React, {FC, useEffect, useState} from 'react';
import BaseLayout from "../../../layouts/BaseLayout/BaseLayout";
import Search from "../../../components/search/Search";
import List, {ListType} from "../../../components/list/List";
import instance from "../../../util/api";
import {UserModel} from "../../../model/UserModel";
import {userStatusList} from "../../../util/helper/userHelper";
import {
    ADMIN_EDIT_LOGISTIC,
    ADMIN_EDIT_PROMOTER,
    ADMIN_NEW_LOGISTIC,
    ADMIN_NEW_PROMOTER
} from "../../../util/routes/routes";
import Filter from "../../../components/filter/Filter";
import {NavLink, RouteComponentProps} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect, ConnectedProps} from "react-redux";
import Loading from "../../../components/loading/Loading";
import {toast} from "react-toastify";
import FusionToast from "../../../components/toast/FusionToast";
import {AppDispatch} from "../../../store";
import {save} from "../../../state/reducer/admin/userSlice";

const classNames = require('classnames');

interface MatchProps {
    type: string
}
interface IUserList extends PropsFromRedux, RouteComponentProps<MatchProps>  {

}

const UserList : React.FC<IUserList> = (props) => {
    const [users, setUsers] = useState<any[]>([]);
    const [bkupUsers, setBkupUsers] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [done, setDone] = useState<boolean>(false);

    const {saveUserList} = props;

    //console.log(props);

    useEffect(() => {
        setLoading(true);
        setUsers([]);
        setDone(false);
        instance.get(`/admin/${props.match.params.type}/all`)
            .then(resp => {
                if (!resp.data.error) {
                    const userData = resp.data.promoters ? resp.data.promoters : resp.data.suppliers

                    const _users = userData.map((us: any) => {
                        const userModel = new UserModel();
                        userModel.createModel(us);
                        return userModel;
                    })

                    console.log("USERS2", _users);
                    setUsers(_users);
                    setBkupUsers(_users);
                    saveUserList(_users);
                    setLoading(false);
                    /*setTimeout(() => {
                        setDone(true);

                        console.log("USERS", _users, users, bkupUsers);
                    }, 300);*/

                    /*for (const user of _users){
                        user.createImageLink().then((thisUser : any) => {
                            setUsers(users => ([
                                ...users.map(user => {
                                    if (user._id === thisUser._id){
                                        return thisUser;
                                    }
                                    return user
                                }),
                            ]))
                        })
                    }*/

                    setDone(true);

                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
            }).catch(err => {
            //console.log(err);
        })
    }, [props.match.params.type]);


    const onSearch = (values : any[], searchVal : string) => {
        if (done) {
            instance.get("/admin/user/search", {
                params: {
                    s: searchVal
                }
            }).then(resp => {
                const _users = resp.data.proposals.map((us: any) => {
                    const userModel = new UserModel();
                    userModel.createModel(us);
                    return userModel;
                })


                setUsers(_users);

                /*for (const user of _users){
                    user.createImageLink().then((thisUser : any) => {
                        setUsers(users => ([
                            ...users.map(user => {
                                if (user._id === thisUser._id){
                                    return thisUser;
                                }
                                return user
                            }),
                        ]))
                    })
                }*/

            }).catch(err => {
                console.log("ERROR", err)
            })


            if (searchVal !== "") {
                //const foundUsers = bkupUsers.filter(user => values.find(val => val.id === user.id));
                //setUsers(foundUsers)
            }  else {
                //setUsers(bkupUsers);
            }
        }
    }

    const onFilter = (values : any[], filterNr : number) => {
        if (done) {
            console.log("ON FILTER", values, filterNr);
            if (filterNr > 0) {
                const foundUsers = bkupUsers.filter(user => values.find(val => val.id === user.id));
                setUsers(foundUsers)
            }  else {
                setUsers(bkupUsers);
            }
        }
    }

    const headers = [
        {slug: "firstname", name: "Name"},
        {slug: "email", name: "E-Mail"},
        {slug: "phone", name: "Telefon"},
        {slug: "status", name: "Status"},
    ];

    console.log("USERS", users);
    const rowData = users.slice().sort((a, b) => {
        const nameA = a.lastname ? a.lastname.toUpperCase() : "";
        const nameB = b.lastname ? b.lastname.toUpperCase() : "";

        if (nameA < nameB){
            return -1;
        }
        if (nameA > nameB){
            return 1;
        }
        return 0;
    }).map(us => {
        const dataClass = classNames("status", us.status);
        //const image = us.images.find((img : any) => img.type === "profile_image");
        return {
            show: props.match.params.type === "promoters" ? ADMIN_EDIT_PROMOTER.path.replace(":id", us.id) : ADMIN_EDIT_PROMOTER.path.replace(":id", us.id),
            edit: props.match.params.type === "promoters" ? ADMIN_EDIT_PROMOTER.path.replace(":id", us.id) : ADMIN_EDIT_PROMOTER.path.replace(":id", us.id),
            delete: 'user',
            id: us.id,
            data: [
                {val: us.firstname + " " + us.lastname, classes: [], type: ListType.STRING, slug: "firstname", spec_val: us.firstname},
                {val: us.email, classes: [], type: ListType.STRING, slug: "email"},
                {val: us.telephone, classes: [], type: ListType.STRING, slug: "phone"},
                {val: <div className={dataClass}>
                        <h5>{userStatusList[us.status as keyof typeof userStatusList]}</h5>
                    </div> , classes: [], type: ListType.HTML, slug: "status", spec_val: userStatusList[us.status as keyof typeof userStatusList]},
            ]
        }
    });

    let header = "Promotoren";
    if (props.match.params.type === "registries") {
        header = "Registrierungen";
    } else if (props.match.params.type === "logistics") {
        header = "Logistiker"
    }

    return (
        <BaseLayout
            sidebar={<div className="">
            <Filter onChange={onFilter} />
        </div>}>
            <div className="row align-items-center justify-content-between pb-5">
                <div className="col-12 col-md-4 col-lg-7">
                    <h1>{header}</h1>
                </div>
                <div className="col-12 col-md-8 col-lg-5">
                    <Search onChange={onSearch} placeholder={"Suche nach..."} searchType="user"/>
                </div>
                {/*<div className="col-6 col-md-6 col-lg-5">
                    <Filter onChange={onFilter} />
                </div>*/}
            </div>
            {!done ?
                <Loading loading={loading} />
                :
                <List data={rowData} headers={headers} type="user" noEdit={false}/>
            }
        </BaseLayout>
    );
};

const mapDispatchToPorps = (dispatch : AppDispatch) => {
    return bindActionCreators({
        saveUserList: save
    }, dispatch)
}

const connector = connect(null, mapDispatchToPorps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UserList);
