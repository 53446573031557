import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup} from "react-bootstrap";
import {NavLink, RouteComponentProps} from "react-router-dom";
import {connect, ConnectedProps} from "react-redux";
import {EventModel} from "../../../model/EventModel";
import {
    ADMIN_EVENT_LOGISTIC_CREATE,
    ADMIN_EVENT_LOGISTIC_EDIT,
    ADMIN_EVENT_LOGISTIC_SHOW,
    ADMIN_SERIAL_EVENT_LOGISTIC_CREATE,
    ADMIN_SERIAL_EVENT_LOGISTIC_EDIT,
    ADMIN_SERIAL_EVENT_LOGISTIC_SHOW
} from "../../../util/routes/routes";
import {eventInfoStatusRanking, EventStatus} from "../../../util/helper/eventHelper";
import BaseLayout from "../../../layouts/BaseLayout/BaseLayout";
import Search from "../../../components/search/Search";
import Loading from "../../../components/loading/Loading";
import List, {ListType} from "../../../components/list/List";
import instance from "../../../util/api";
import moment from "moment";
import {toast} from "react-toastify";
import FusionToast from "../../../components/toast/FusionToast";
import AccordionList from "../../../components/list/AccordionList";
import {RootState} from "../../../store";
import {
    EVENT_SECTION_STATUS,
    IEvent,
    IEventSection,
    ISerialEvent,
    TStatusEvent
} from "../../../model/interfaces/event/IEvent";
import {AxiosResponse} from "axios";

const classNames = require('classnames');


interface MatchProps {
    eventType: string
}
interface IEventList extends PropsFromRedux, RouteComponentProps<MatchProps> {

}

export type IEventResponse = IEvent | ISerialEvent;

const EventList : React.FC<IEventList> = (props) => {
    const [events, setEvents] = useState<IEventResponse[]>([]);
    const [bkupEvents, setBkupEvents] = useState<IEventResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [done, setDone] = useState<boolean>(false);

    //let selectedData = [];

    useEffect(() => {
        setLoading(true);
        instance.get(`/admin/event/all`, {
            params: {
                type: props.match.params.eventType.toUpperCase(),
                username: props.userData.username
            }
        })
            .then((resp: AxiosResponse<IEventResponse[]>)=> {
                console.log("ALL EVENTES", resp.data);
                const events = resp.data;
                setEvents(events);
                setBkupEvents(events);
                setLoading(false);
                setTimeout(() => {
                    setDone(true);
                }, 1000);
            }).catch(err => {
                //console.log(err);
        })
    }, [props.match.params.eventType]);

    const onSearch = (values : any[], searchVal: string) => {
        if (searchVal !== "") {
            const foundUsers = bkupEvents.filter(user => values.find(val => val.id === user.id));
            setEvents(foundUsers)
        }  else {
            setEvents(bkupEvents);
        }
    }

    const onFilter = (values : any[], filterNr : number) => {
        if (filterNr > 0) {
            const foundUsers = bkupEvents.filter(user => values.find(val => val.id === user.id));
            setEvents(foundUsers)
        }  else {
            setEvents(bkupEvents);
        }
    }

    const onDownloadExcelFile = () => {
        instance.get("/admin/event/excel", {
            params: {
                type: props.match.params.eventType.toUpperCase()
            }
        })
            .then(async resp => {
                //console.log(resp);
                if (!resp.data.error){
                    const foundFile = await instance.get("/file/files/" + resp.data.file, {
                        responseType: 'blob',
                    })
                    //console.log(foundFile);
                    if (foundFile.data) {
                        const url = URL.createObjectURL(new Blob([foundFile.data], {type: foundFile.headers["content-type"]}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', resp.data.file); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }

            }).catch(err => {
                //console.log(err);
        })
    }

    const startEventCheck = () => {
        instance.get("/admin/event/check")
            .then(resp => {
                toast(<FusionToast className="success" header="Aktion gestartet" message="Der Event check wurde ausgelögt" />);
            })
            .catch(err => {
                toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
            })
    }

    const sendAllNewEvents = () => {
        instance.get("/admin/event/send")
            .then(resp => {
                toast(<FusionToast className="success" header="Aktion gestartet" message="Events erfolgreich versendet" />);
            })
            .catch(err => {
                toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
            })
    }

    const headers = [
        {slug: "zuteilung", name: "Zuteilung"},
        {slug: "title", name: "Name"},
        {slug: "date", name: "Datum", sort: "DESC"},
        {slug: "place", name: "Ort"},
        {slug: "status", name: "Status"},
    ];

    const currentDate = moment();

    console.log("EVENT LISTE", events);

    const rowData = events.filter(ev => (!ev.is_serial && ev.eventSections.length > 0))
        .sort((ev1, ev2) => moment(ev1.eventSections[0].date).diff(moment(ev2.eventSections[0].date)))
        .map(ev => {
        let nextEventStatus;
        let eventStatusList;
        let nextEventInfo;
        if (ev.status !== "completed" && ev.status !== "finished"){
            nextEventInfo = ev.eventSections.filter((ev : any) => ev.status !== "").sort((a : any,b : any) => moment(a.date).diff(currentDate) - moment(b.date).diff(currentDate));
            eventStatusList = nextEventInfo.map((ev : any) => eventInfoStatusRanking[ev.status as keyof typeof eventInfoStatusRanking]);
            nextEventStatus = nextEventInfo.length > 0 ? nextEventInfo[0].status : EVENT_SECTION_STATUS.SECTION_CREATED;
        } else {
            eventStatusList = [7];
            nextEventInfo = ev.eventSections;
            //nextEventStatus = ev.status;
            nextEventStatus = EVENT_SECTION_STATUS.SECTION_FINISHED;
        }

        const dataClass = classNames("status", nextEventStatus);

        //console.log(ev.infos[0].place);
        const currentZuteilung = eventStatusList.filter((s : number) => s >=2);

         return {
             show: ADMIN_EVENT_LOGISTIC_SHOW.path.replace(":eventType", props.match.params.eventType).replace(":id", ev.id.toString()),
             edit: ADMIN_EVENT_LOGISTIC_EDIT.path.replace(":eventType", props.match.params.eventType).replace(":id", ev.id.toString()),
             delete: 'event',
             copy: 'event',
             copyLink: ADMIN_EVENT_LOGISTIC_EDIT.path.replace(":eventType", props.match.params.eventType),
             id: ev.id,
            multiple: nextEventInfo.length > 1,
             serial: ev.is_serial,
            data: [
                {val: currentZuteilung.length + " / " + eventStatusList.length, classes: [], type: ListType.STRING, slug: "zuteilung"},
                {val: ev.title, classes: [], type: ListType.STRING, slug: "title"},
                {val: nextEventInfo[0].date, classes: [], type: ListType.DATE, slug: "date"},
                {val: nextEventInfo[0].locationLabel, classes: [], type: ListType.STRING, slug: "locationLabel"},
                {val: <div className={dataClass}>
                        <h5>{EventStatus[nextEventStatus]}</h5>
                    </div> , classes: [], type: ListType.HTML, slug: "status"},
            ],
             nextData: nextEventInfo.filter((n : any, i : number) => i > 0).map((info : IEventSection) => {
                 const dataClass = classNames("status", info.status);

                 return [
                 {val: "", classes: [], type: ListType.STRING, slug: "zuteilung"},
                 {val: "", classes: [], type: ListType.STRING, slug: "title"},
                 {val: info.date, classes: [], type: ListType.DATE, slug: "date"},
                 {val: info.locationLabel, classes: [], type: ListType.STRING, slug: "locationLabel"},
                 {val: <div className={dataClass}>
                         <h5>{EventStatus[info.status]}</h5>
                     </div> , classes: [], type: ListType.HTML, slug: "status"},
             ]})
         }
        });

    const serialEventRow = events.filter(ev => ev.is_serial).map(ev => {
        const serialEvent = ev as ISerialEvent;

        const nextEvents = [];
        let nextEventStatus = [];
        let eventStatusList = [];
        let nextEventInfo = [] as any[];

        serialEvent.events = serialEvent.events.map((event : any) => {
            event.infos = event.infos.sort((a : any,b : any) => moment(a.date).diff(currentDate) - moment(b.date).diff(currentDate));
            return event;
        })

        serialEvent.events.forEach((event : any) => {
            nextEventInfo.push(event.infos);
        })

        nextEventInfo = nextEventInfo.reduce((acc, curr) => [...acc, ...curr], []).sort((a : any,b : any) => moment(a.date).diff(currentDate) - moment(b.date).diff(currentDate));

        eventStatusList = nextEventInfo.map(ev => eventInfoStatusRanking[ev.status as keyof typeof eventInfoStatusRanking]);
        nextEventStatus = nextEventInfo.length > 0 ? nextEventInfo[0].status : EVENT_SECTION_STATUS.SECTION_CREATED;

        //console.log(nextEventInfo, eventStatusList);

        const dataClass = classNames("status", nextEventStatus);

        //console.log(ev.infos[0].place);

        const currentZuteilung = eventStatusList.filter(s => s >=2);

        return {
            show: ADMIN_SERIAL_EVENT_LOGISTIC_SHOW.path.replace(":eventType", props.match.params.eventType).replace(":id", ev.id.toString()),
            edit: ADMIN_SERIAL_EVENT_LOGISTIC_EDIT.path.replace(":eventType", props.match.params.eventType).replace(":id", ev.id.toString()),
            delete: 'serial_event',
            copy: 'serial_event',
            copyLink: ADMIN_SERIAL_EVENT_LOGISTIC_EDIT.path.replace(":eventType", props.match.params.eventType),
            id: serialEvent.id,
            multiple: serialEvent.is_serial,
            serial: serialEvent.is_serial,
            data: [
                {val: currentZuteilung.length + " / " + eventStatusList.length, classes: [], type: ListType.STRING, slug: "zuteilung"},
                {val: ev.title, classes: [], type: ListType.STRING, slug: "title"},
                {val: nextEventInfo[0] ? nextEventInfo[0].date : "", classes: [], type: ListType.DATE, slug: "date"},
                {val: nextEventInfo[0] ? nextEventInfo[0].place : "", classes: [], type: ListType.STRING, slug: "place"},
                {val: <div className={dataClass}>
                        {/*<h5>{EventStatus[nextEventStatus]}</h5>*/}
                    </div> , classes: [], type: ListType.HTML, slug: "status"},
            ],
            nextData: serialEvent.events.filter((event : IEvent) => event.eventSections.length > 0).map((event : any) => {
                const info = event.eventSections[0] as IEventSection;
                const dataClass = classNames("status", info.status);

                return {
                    show: ADMIN_EVENT_LOGISTIC_SHOW.path.replace(":eventType", props.match.params.eventType).replace(":id", event.id),
                    edit: ADMIN_EVENT_LOGISTIC_EDIT.path.replace(":eventType", props.match.params.eventType).replace(":id", event.id),
                    delete: 'event',
                    copy: 'event',
                    copyLink: ADMIN_EVENT_LOGISTIC_EDIT.path.replace(":eventType", props.match.params.eventType),
                    id: event.id,

                    data: [
                        {val: "", classes: [], type: ListType.STRING, slug: "zuteilung"},
                        {val: event.title, classes: [], type: ListType.STRING, slug: "title"},
                        {val: info.date, classes: [], type: ListType.DATE, slug: "date"},
                        {val: info.locationLabel, classes: [], type: ListType.STRING, slug: "locationLabel"},
                        {val: <div className={dataClass}>
                                <h5>{EventStatus[info.status]}</h5>
                            </div> , classes: [], type: ListType.HTML, slug: "status"},
                    ]
                }})
        }
    });

    console.log(serialEventRow);
    //rowData.push(...serialEventRow);

    return (
        <BaseLayout>
            <div className="row align-items-center justify-content-between pb-5">
                <div className="col-12 col-md-4 col-lg-7">
                    <h1>{props.match.params.eventType === "event" ? "Event Liste" : "Logistik Einsatz Liste"}</h1>
                </div>
                <div className="col-12 col-md-8 col-lg-5">
                    <Search onChange={onSearch}
                            placeholder={props.match.params.eventType === "event" ? "Suche nach Events..." : "Suche nach Logistik Events..."}
                            searchType={props.match.params.eventType}/>
                </div>
                <div className="col-6 col-md-6 col-lg-2">
                    <Button className="btn-secondaryDark btn-small" onClick={onDownloadExcelFile}>Download as
                        Excel</Button>
                </div>
                <div className="col-6 col-md-6 col-lg-2">
                    <Button className="btn-link btn-small" onClick={() => startEventCheck()}>Start Event check</Button>
                </div>
                <div className="col-6 col-md-6 col-lg-2">
                    <Button className="btn-link btn-small" onClick={() => sendAllNewEvents()}>Send all new
                        events</Button>
                </div>
            </div>
            {!done ?
                <Loading loading={loading}/>
                :
                <AccordionList headers={headers} data={rowData} noEdit={false} noCopy={false}/>
            }
        </BaseLayout>
    );
};

const mapStateToProps = (state: RootState) => ({
    userData: state.user
})


const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EventList);