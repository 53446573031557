export class EventInfoModel {
    _id? : number;
    _status? : string;
    _title? : string;
    _place? : string;
    _date? : string;
    _time? : string;
    _person? : number;
    _users? : any[] = [];
    _createdAt? : string;
    _updatedAt? : string;

    createModel(data : any) {
        this._id = data.id;
        this._status = data.status;
        this._title = data.title;
        this._place = data.place;
        this._date = data.date;
        this._time = data.time;
        this._person = data.person;
        this._users = data.eventInfoUsers;
        this._createdAt = data.createdAt;
        this._updatedAt = data._updatedAt;
    };


    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }


    get status() {
        return this._status;
    }

    set status(value) {
        this._status = value;
    }

    get title() {
        return this._title;
    }

    set title(value) {
        this._title = value;
    }


    get place() {
        return this._place;
    }

    set place(value) {
        this._place = value;
    }

    get date() {
        return this._date;
    }

    set date(value) {
        this._date = value;
    }

    get time() {
        return this._time;
    }

    set time(value) {
        this._time = value;
    }

    get person() {
        return this._person;
    }

    set person(value) {
        this._person = value;
    }


    get users() {
        return this._users;
    }

    set users(value) {
        this._users = value;
    }

    get createdAt() {
        return this._createdAt;
    }

    set createdAt(value) {
        this._createdAt = value;
    }

    get updatedAt() {
        return this._updatedAt;
    }

    set updatedAt(value) {
        this._updatedAt = value;
    }
}
