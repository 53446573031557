import {EventInfoModel} from "./EventInfoModel";


export class EventModel {
    _id? : number;
    _isSerial? : boolean;
    _title? : string;
    _content? : string;
    _emailText? : string;
    _type? : string;
    _subEvents : any[] = [];
    _infos : any[] = [];
    _users : any[] = [];
    _status? : string;
    _userStatus? : string;
    _createdAt? : string;
    _updatedAt? : string;


    constructor() {

    }

    createModel(data: any, userStatus = "") {
        this._id = data.id;
        this._isSerial = data.is_serial;
        this._title = data.title;
        this._content = data.content;
        this._emailText = data.emailText;
        this._type = data.type;
        this._userStatus = userStatus;
        this._createdAt = data.createdAt;
        this._updatedAt = data._updatedAt;
        this._infos = [];
        this._users = [];

        //console.log("EVENT DATA", data);

        if (!data.is_serial) {

            if (data.eventSections) {

                this._status = data.status;
                data.eventSections.forEach((evInfo: any) => {
                    const evInfoModel = new EventInfoModel();
                    evInfoModel.createModel(evInfo);
                    this._infos.push(evInfoModel);
                })
                data.users.forEach((evUser : any) => {
                    this._users.push(evUser);
                })

            }
        } else {
            this._subEvents = [];
            data.events.forEach((event : any) => {
                const eventModel = new EventModel();
                eventModel.createModel(event);
                this._subEvents.push(eventModel);
            })
        }
    };


    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get title() {
        return this._title;
    }

    set title(value) {
        this._title = value;
    }

    get content() {
        return this._content;
    }

    set content(value) {
        this._content = value;
    }

    get emailText() {
        return this._emailText;
    }

    set emailText(value) {
        this._emailText = value;
    }

    get infos() {
        return this._infos;
    }

    set infos(value) {
        this._infos = value;
    }


    get type() {
        return this._type;
    }

    set type(value) {
        this._type = value;
    }

    get users() {
        return this._users;
    }

    set users(value) {
        this._users = value;
    }

    get status() {
        return this._status;
    }

    set status(value) {
        this._status = value;
    }


    get userStatus() {
        return this._userStatus;
    }

    set userStatus(value) {
        this._userStatus = value;
    }

    get createdAt() {
        return this._createdAt;
    }

    set createdAt(value) {
        this._createdAt = value;
    }

    get updatedAt() {
        return this._updatedAt;
    }

    set updatedAt(value) {
        this._updatedAt = value;
    }


    get isSerial() {
        return this._isSerial;
    }

    set isSerial(value) {
        this._isSerial = value;
    }


    get subEvents() {
        return this._subEvents;
    }

    set subEvents(value) {
        this._subEvents = value;
    }
};
