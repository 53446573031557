import React, {ChangeEvent, EventHandler, SyntheticEvent, useState} from 'react';
import {Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader} from "react-bootstrap";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DatePicker} from "rsuite";
import {Mouse} from "@testing-library/user-event/system/pointer/mouse";

interface IBriefingModal {
    className?: any,
    event: any,
    onClick(event: any, id: number, date?: any): void
}

const BriefingModal : React.FC<IBriefingModal> = (props) => {

    const {
        onClick,
        event,
        className
    } = props;

    const [modal, setModal] = useState(false);
    const [date, setDate] = useState<Date | null>(new Date());

    const toggle = () => setModal(!modal);

    const sendImmediately = (e : SyntheticEvent<any>) => {
        if (onClick){
            onClick(e, event.id)
        }
        toggle();
    }

    const sendOnSpecificDate = (e : SyntheticEvent<any>) => {
        if (onClick){
            onClick(e, event.id, date)
        }
        toggle();
    }


    return (
        <div>
            <Button variant="outline-secondary" onClick={toggle}><FontAwesomeIcon icon={faPaperPlane} /> Briefing senden</Button>
            <Modal fade={false} show={modal} onHide={toggle} centered={true}>
                <ModalHeader closeButton>Briefing senden...</ModalHeader>
                <ModalBody>
                    <p>Soll das Briefing sofort gesendet werden, oder zu einem bestimmten Zeitpunkt?</p>
                    <FormGroup>
                        <Form.Label htmlFor="mailDate">Datum auswählen</Form.Label>
                        <DatePicker className="w-100" placeholder="Datum" format="dd.MM.yyyy HH:mm" oneTap name="mailDate" value={date} onChange={(date) => setDate(date)} />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button variant="primary" onClick={sendImmediately}>Sofort</Button>{' '}
                    <Button variant="secondary" onClick={sendOnSpecificDate}>An Datum senden</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default BriefingModal;