import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface IUserList {
    userList: any[],
    selectUserList: any[]
}

const usersInitState = {
    userList: [],
    selectUserList: []
} as IUserList;

export const userSlice = createSlice({
    name: "userList",
    initialState: usersInitState,
    reducers: {
        save(state, action: PayloadAction<any[]>) {
            state.userList = action.payload
            return state;
        },
        select(state, action: PayloadAction<any[]>) {
            state.selectUserList = action.payload
            return state;
        }
    }
})

export const { save, select } = userSlice.actions;
export default userSlice.reducer;
