import React, {SyntheticEvent, useEffect, useState} from 'react';
import instance from "../../../util/api";
import {faArchive, faEnvelope, faInbox, faPaperPlane, faPencilRuler, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Mail.scss";
import Moment from "react-moment";
import {Button, Col, Form, FormGroup, Nav, NavItem, Row} from "react-bootstrap";
import Draft, {ContentBlock, Editor, EditorState, RichUtils, DraftBlockType} from "draft-js";
import {convertToHTML} from 'draft-convert';
import BaseLayoutIcons from "../../../layouts/BaseLayout/BaseLayoutIcons";
import FusionToast from "../../../components/toast/FusionToast";
import {toast} from "react-toastify";
import BaseLayout from "../../../layouts/BaseLayout/BaseLayout";

interface IMail {

}
const MailPage : React.FC<IMail> = (props) => {
    const [emails, setEmails] = useState<any[]>([]);
    const [selectedEmailId, selectEmailId] = useState<number>(0);
    const [currentSection, setCurrentSection] = useState<string>("CREATE");

    useEffect(() => {
        /*instance.get("admin/emails")
            .then(resp => {
                //console.log(resp.data.emails);
                if (!resp.data.error){
                    setEmails(resp.data.emails);
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
            }).catch(err => {
                //console.log(err);
        })*/
    }, [])

    const openEmail = (id : number) => {
        setSidebarSection("BROWSE")
        selectEmailId(id);
    }

    const deleteMessage = (id : number) => {
        const index = emails.findIndex(x => x.id === id);
        emails[index].status = "DELETED";

        instance.delete("admin/email", {
            params: {
                id: id
            }
        }).then(resp => {
            if (!resp.data.error){
                setEmails(resp.data.emails);
            } else {
                console.error(resp);
                toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
            }
        }).catch(err => {
            console.error(err);
        })
    }

    const archiveMessage = (id : number) => {
        const index = emails.findIndex(x => x.id === id);
        emails[index].status = "ARCHIVE";

        instance.put("admin/email", {
            params: {
                id: id,
                action: "ARCHIVE"
            }
        }).then(resp => {
            if (!resp.data.error){
                setEmails(resp.data.emails);
            } else {
                console.error(resp);
                toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
            }
        }).catch(err => {
            console.error(err);
        })
    }

    const setSidebarSection = (section : string) => {
        if (section !== currentSection){
            selectEmailId(-1);
        }
        setCurrentSection(section);
    }

    const currentEmail = emails.find(x => x.id === selectedEmailId);

    return (
        <BaseLayout
            className="MailLayout">
            <div className="mail row">
                <div className="inbox-container col-12 row">
                    <EmailCreate />
                </div>
            </div>
        </BaseLayout>
    );
}

interface ISidebar {
    emails : any[],
    setSidebarSection : (section: string) => void,
    currentSection: string
}

/* Sidebar */
const Sidebar : React.FC<ISidebar> = (props) => {
    const { emails, setSidebarSection, currentSection } = props;
    return (
        <Nav className="flex-column mail-sidebar-inboxes">
            <h6 className="nav-bread-header">BROWSE <button onClick={() => setSidebarSection("CREATE")} className="badge badge-secondary">+</button></h6>

            <NavItem onClick={() => { setSidebarSection('CREATED'); }} className={currentSection === "CREATED" ? "active" : ""}>
                <a href="#">
                    <FontAwesomeIcon icon={faInbox} /> Erstellt
                    <span className="item-count">{emails.filter(mail => mail.status === "CREATED").length}</span>
                </a>
            </NavItem>
            <NavItem onClick={() => { setSidebarSection('SEND'); }} className={currentSection === "SEND" ? "active" : ""}>
                <a href="#">
                    <FontAwesomeIcon icon={faPaperPlane} /> Gesendet
                    <span className="item-count">{emails.filter(mail => mail.status === "SEND").length}</span>
                </a>
            </NavItem>
            <NavItem onClick={() => { setSidebarSection('RESEND'); }} className={currentSection === "RESEND" ? "active" : ""}>
                <a href="#">
                    <FontAwesomeIcon icon={faPaperPlane} /> Erneut gesendet
                    <span className="item-count">{emails.filter(mail => mail.status === "RESEND").length}</span>
                </a>
            </NavItem>
            <NavItem onClick={() => { setSidebarSection('ARCHIVE'); }} className={currentSection === "ARCHIVE" ? "active" : ""}>
                <a href="#">
                    <FontAwesomeIcon icon={faPencilRuler} /> Archiv
                    <span className="item-count">{emails.filter(mail => mail.status === "ARCHIVE").length}</span>
                </a>
            </NavItem>
            <NavItem onClick={() => { setSidebarSection('DELETED'); }} className={currentSection === "DELETED" ? "active" : ""}>
                <a href="#">
                    <FontAwesomeIcon icon={faTrash}  /> Gelöscht
                    <span className="item-count">{emails.filter(mail => mail.status === "DELETED").length}</span>
                </a>
            </NavItem>
        </Nav>
    );
};

interface IEmailList {
    emails : any[],
    onEmailSelected : (id: number) => void,
    selectedEmailId: number
}

const EmailList : React.FC<IEmailList> = (props) => {
    const {emails, onEmailSelected, selectedEmailId} = props;

    if (emails.length === 0){
        return (
            <div className="email-list empty col-3">
                Derzeit befinden sich keine E-Mails hier!
            </div>
        );
    }

    return (
        <div className="email-list col-3">
            {
                emails.map(email => {
                    return (
                        <EmailListItem
                            onEmailClicked={onEmailSelected}
                            email={email}
                            selected={selectedEmailId === email.id} />
                    );
                })
            }
        </div>
    );
}

interface IEmailListItem {
    email : any,
    onEmailClicked : (id: number) => void,
    selected: boolean
}
const EmailListItem : React.FC<IEmailListItem> = (props) => {
    const { email, onEmailClicked, selected } = props;
    let classes = "email-item";
    if (selected) {
        classes += " selected"
    }

    return (
        <div onClick={() => { onEmailClicked(email.id); }} className={classes}>
            <div className="email-item-subject bold truncate">{email.title}</div>
            <div className="email-item-details">
                <span className="email-item-detail-text truncate bold">Event Type: </span>
                <span className="email-item-detail truncate">{email.refmodel}</span>
            </div>
            <div className="email-item-details">
                <span className="email-item-detail-text truncate bold">Datum: </span>
                <span className="email-item-detail truncate"><Moment format="DD.MM.YYYY">{email.date}</Moment></span>
            </div>
        </div>
    );
}

interface IEmailDetails {
    email: any,
    onDelete: (id: number) => void
}
const EmailDetails: React.FC<IEmailDetails> = (props) => {
    const { email, onDelete } = props;

    if (!email) {
        return (
            <div className="email-content col-9 empty"></div>
        );
    }

    //const date = `${getPrettyDate(email.time)} · ${getPrettyTime(email.time)}`;

    const getDeleteButton = () => {
        if (email.tag !== 'deleted') {
            return <span onClick={() => { onDelete(email.id); }} className="delete-btn fa fa-trash-o"></span>;
        }
        return undefined;
    }

    const sendEmail = () => {
        instance.post("admin/email/send", {
            emails: email.emails
        }).then(resp => {
            if (!resp.data.error) {
                toast(<FusionToast className="success" header="Email gesendet" message={`E-Mail wurde erfolgreich an ${email.to} gesendet`} />)
            } else {

            }
        }).catch(err => {
            //console.log(err);
        })
    }

    return (
        <div className="email-content col-9">
            <div className="email-content-header">
                <h3 className="email-content-subject">{email.subject}</h3>
                {getDeleteButton()}
                <div className="email-content-time">{}</div>
                <div className="email-content-from">Von noreply@fusionpromotion.at</div>
                <div className="email-content-button">
                    <Button onClick={sendEmail} variant="primary">Sofort senden</Button>
                </div>
            </div>
            <div className="email-content-message" dangerouslySetInnerHTML={{__html: email.text}} />
        </div>
    );
};

type TEmailSend = {
    from: string,
    to: string,
    subject: string,
    content: string,
    attachment: any[]
}

type TBlockStyleControls = {
    editorState: EditorState,
    onToggle: (block: DraftBlockType) => void
}

type TStyleButton = {
    onToggle: (style: any) => void,
    active: boolean,
    style: any,
    label: string

}

type TInlineStyleControls = {
    onToggle: (style: string) => void,
    editorState: EditorState,
}

const initEmailSend = {
    from: "noreply@fusionpromotion.at",
    to: "",
    subject: "",
    content: "",
    attachment: []
} as TEmailSend;

const basePrediction = [
    "all@fusion.at",
    "imported@fusion.at",
    "all_and_imported@fusion.at",
    "event@fusion.at",
    "logicstic@fusion.at",
    "men@fusion.at",
    "women@fusion.at"
]

const EmailCreate = () => {
    const [email, setEmail] = useState<TEmailSend>(initEmailSend)
    const [content, setContent] = useState(EditorState.createEmpty());
    const [predict, setPrediction] = useState<any[]>([]);

    const onChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        e.persist();

        if (e.target.name === "to"){
            instance.get("admin/user/email", {
                params: {
                    search: e.target.value
                }
            }).then(resp => {
                if (!resp.data.error){
                    setPrediction([...basePrediction, ...resp.data.emails.map((data : any) => data.email)]);
                } else {

                }
            }).catch(err => {
                //console.log(err);
            })

        }

        setEmail(email => ({...email, [e.target.name]: e.target.value}))
    }

    const onAddFile = (e : React.ChangeEvent<HTMLInputElement>) => {
        e.persist();

        const files = e.target.files;

        if (files) {
            setEmail(email => ({
                ...email,
                attachment: [...files]
            }))
        }

    }

    const onSetPredictEmail = (mailAddr : string) => {
        setPrediction([]);
        setEmail(email => ({...email, to: mailAddr}))
    }

    const onSend = (e : SyntheticEvent) => {
        e.preventDefault();

        const data = {...email} as any;
        data.content = convertToHTML(content.getCurrentContent());

        const formData = new FormData();

        Object.keys(data).forEach(key => {
            //console.log(key, data[key])
            if (Array.isArray(data[key])){
                data[key].forEach((d : any) => {
                    formData.append(key, d);
                })
            } else {
                formData.append(key, data[key])
            }
        })

        //console.log(data);

        instance.post("admin/email/send/custom", formData)
            .then(resp => {
                if (!resp.data.error) {
                    toast(<FusionToast className="success" header="Email gesendet" message={`E-Mail wurde erfolgreich an ${email.to} gesendet`} />)
                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
            }).catch(err => {
                //console.log(err);
            })
    }

    const onChangeEditorState = (editorState : EditorState) => setContent(editorState)


    const BLOCK_TYPES = [
        {label: 'Überschift 1', style: 'header-one'},
        {label: 'Überschift 2', style: 'header-two'},
        {label: 'Überschift 3', style: 'header-three'},
        {label: 'Überschift 4', style: 'header-four'},
        {label: 'Überschift 5', style: 'header-five'},
        {label: 'Überschift 6', style: 'header-six'},
        {label: 'Liste', style: 'unordered-list-item'},
        {label: 'Nummerrierte Liste', style: 'ordered-list-item'}
    ];

    const INLINE_STYLES = [
        {label: 'Fett', style: 'BOLD'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Unterstrichen', style: 'UNDERLINE'}
    ];

    const BlockStyleControls : React.FC<TBlockStyleControls> = (props) => {
        const {editorState} = props;
        const selection = editorState.getSelection();
        const blockType = editorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey())
            .getType();

        return (
            <div className="RichEditor-controls">
                {BLOCK_TYPES.map((type) =>
                    <StyleButton
                        key={type.label}
                        active={type.style === blockType}
                        label={type.label}
                        onToggle={props.onToggle}
                        style={type.style}
                    />
                )}
            </div>
        );
    };

    const StyleButton: React.FC<TStyleButton> = (props) => {


        const onToggle = (e : SyntheticEvent) => {
            e.preventDefault();
            props.onToggle(props.style)
        }

        let className = 'RichEditor-styleButton';
        if (props.active) {
            className += ' RichEditor-activeButton';
        }
        return (
            <span className={className} onMouseDown={onToggle}>
              {props.label}
            </span>
        )
    }

    const InlineStyleControls : React.FC<TInlineStyleControls> = (props) => {
        const currentStyle = props.editorState.getCurrentInlineStyle();
        return (
            <div className="RichEditor-controls">
                {INLINE_STYLES.map(type =>
                    <StyleButton
                        key={type.label}
                        active={currentStyle.has(type.style)}
                        label={type.label}
                        onToggle={props.onToggle}
                        style={type.style}
                    />
                )}
            </div>
        );
    };

    const getBlockStyle = (block : ContentBlock) => {
        switch (block.getType()) {
            case 'blockquote': return 'RichEditor-blockquote';
            default: return "";
        }
    }

    const toggleBlockType = (blockType : DraftBlockType) => {
        onChangeEditorState(
            RichUtils.toggleBlockType(
                content,
                blockType
            )
        );
    }

    const toggleInlineStyle = (inlineStyle : string) => {
        onChangeEditorState(
            RichUtils.toggleInlineStyle(
                content,
                inlineStyle
            )
        );
    }

    //console.log(email.attachment)

    return (
        <div className="email-content">
            <div className="email-content-header row justify-content-center">
                <Col sm={2} >
                    <button className="btn btn-outline-primary h-100" onClick={onSend}>
                        <FontAwesomeIcon icon={faPaperPlane} />   Senden
                    </button>
                </Col>
                <Col sm={10}>
                    <div className="email-from-to-subject">
                        <Form>
                            <FormGroup as={Row} className="mb-3">
                                <Form.Label column className="bold" htmlFor="fromEmail" sm={2}>Von</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="email" name="from" id="fromEmail" value="noreply@fusionpromotion.at" disabled />
                                </Col>
                            </FormGroup>
                            <FormGroup as={Row} className="mb-3">
                                <Form.Label column className="bold" htmlFor="toEmail" sm={2}>An</Form.Label>
                                <Col sm={10} className="position-relative">
                                    <Form.Control type="email" name="to" id="toEmail" value={email.to} onChange={onChange} />
                                    <div className="prediction email-prediction">
                                        <ul className="list-group">
                                            {predict.map(pre => <li className="list-group-item clickable" onClick={() => onSetPredictEmail(pre)}>{pre}</li> )}
                                        </ul>
                                    </div>
                                </Col>
                            </FormGroup>
                            <FormGroup as={Row} className="mb-3">
                                <Form.Label column className="bold" htmlFor="subjectEmail" sm={2}>Betreff</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="text" name="subject" id="subjectEmail" value={email.subject} onChange={onChange} />
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>
                </Col>
            </div>
            <Row>
                <Col sm={2}>

                </Col>
                <Col sm={10}>
                    <div className="email-content-attachment mt-3">
                        <FormGroup>
                            <Form.Label className="bold" htmlFor="fileAttachment">Attachment</Form.Label>
                            <Form.Control type="file" name="attachment" id="fileAttachment" multiple
                                          onChange={onAddFile}/>
                            <p className="mt-4">Hochgeladene Files:</p>
                            <p>{email.attachment.map(f => f.name).join(", ")}</p>
                        </FormGroup>
                    </div>
                    <div className="mt-5 mb-5">
                        <div className="RichEditor-root">
                            <BlockStyleControls
                                editorState={content}
                                onToggle={toggleBlockType}
                            />
                            <InlineStyleControls
                                editorState={content}
                                onToggle={toggleInlineStyle}
                            />
                            <Editor
                                blockStyleFn={getBlockStyle}
                                editorState={content}
                                onChange={setContent}
                                placeholder="Füge eine Notiz hinzu"
                                spellCheck={true}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default MailPage;
