import React, {useState} from 'react';
import {Button, Collapse} from "react-bootstrap";
import {v4 as uuidv4} from "uuid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";

interface ISimpleCollapse {
    buttonText: string
    id?: string
}

const SimpleCollapse : React.FC<ISimpleCollapse> = (props) => {
    const [open, setOpen] = useState(false);

    const id = props.id ? props.id : uuidv4();

    return (
        <>
            <Button variant="link" className="mt-5 p-0" type="button"
                    aria-expanded={open}
                    aria-controls={id}
                    onClick={() => setOpen(!open)}>{props.buttonText} <FontAwesomeIcon icon={open ? faCaretUp : faCaretDown} /></Button>
            <Collapse in={open}>
                <div id={id}>
                    {props.children}
                </div>
            </Collapse>
        </>
    );
};

export default SimpleCollapse;