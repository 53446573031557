
export const ADMIN_AUTH = "ADMIN";
export const AUTHOR_AUTH = "AUTHOR";
export const PROMOTER_AUTH = "PROMOTER";
export const SUPPLIER_AUTH = "SUPPLIER";

export const isAuthenticated = (user : any) => !!user.isAuthenticated;

export const hasRole = (user : any, roles : any[]) => {
    return     roles.some(role => user.roles.includes(role));
}
