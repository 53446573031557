import {createReducer, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {number} from "prop-types";
import {
    userActivationAction,
    userLoginFailureAction,
    userLoginInitAction,
    userLoginSuccessAction, userLogoutAction,
    userRefreshTokenAction
} from "../../action/user/userAction";

const uuid = require("uuid");

const initialState = {
    _id: number,
    token: null,
    refreshToken: null,
    isAuthenticated: false,
    roles: [],
    gender: "",
    privileges: [],
    firstname: "",
    lastname: "",
    email: "",
    username: uuid.v4(),
    thumbnail: {} as any,
    socketId: "",
    is_active: false,
    activation_request_sent: false,
    vertrag_unterschrieben: false,
    error: {error: false, message: ""}
};

const userData = createReducer(initialState, {
    [userLoginInitAction.type]: (state, action) => {
        return {
            ...initialState
        }
    },
    [userLoginSuccessAction.type]: (state, action) => {
        const privileges : string[] = [];

        console.log("LOGIN_SUCCESSFULL_REDUCER", action);

        /*if (account.roles){
            account.roles.forEach((role: IAccountRole) => privileges.push(...role.privileges.map(privilege => privilege.handle)));
        }*/
        const {user, access_token, refresh_token} = action.payload;

        return {
            ...action.payload.user,
            token: access_token,
            refreshToken: refresh_token,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            username: user.username,
            isAuthenticated: true,
            thumbnail: user.thumbnail,
            roles: user.roles.map((role: any) => role.role),
            is_active: user.is_active,
            error: {error: false, message: ""}
        };
    },
    [userRefreshTokenAction.type]: (state, action) => {

        console.log("LOGIN_REFRESH_TOKEN", action);
        const {user, access_token, refresh_token} = action.payload;

        return {
            ...state,
            token: access_token,
            refreshToken: refresh_token,
            isAuthenticated: true,
            error: {error: false, message: ""}
        };
    },
    [userLoginFailureAction.type]: (state, action) => {
        return {
            ...state,
            error: {error: true, message: action.payload}
        }
    },
    [userLogoutAction.type]: (state, action) => {
        return {
            ...action.payload,
            ...initialState
        }
    },
    [userActivationAction.type]: (state, action) => {
        if (action.payload.type === "contract") {
            return {
                ...state,
                vertrag_unterschrieben: action.payload.success
            }
        } else if (action.payload.type === "activation") {
            return {
                ...state,
                is_active: action.payload.success
            }
        } else if (action.payload.type === "activation_request") {
            return {
                ...state,
                activation_request_sent: action.payload.success
            }
        }

        return state;
    }
})


export default userData;

