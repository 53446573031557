import React, {ChangeEvent, ChangeEventHandler, SyntheticEvent, useEffect, useState} from 'react';
import {Button, Form, InputGroup} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from '@fortawesome/free-solid-svg-icons';

import "./search.scss"
import instance from "../../util/api";
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../store";

interface ISearch extends PropsFromRedux {
    onChange(values: any, searchVal: string): void,
    placeholder?: string,
    searchType: string,
    prepend?: boolean
}

const Search : React.FC<ISearch> = (props) => {
    const [search, onSearch] = useState('');
    const [proposals, setProposals] = useState([]);
    const {user} = props;

    //console.log(user);

    useEffect(() => {
        if (props.onChange){
            props.onChange(proposals, search)
        }
    }, [proposals]);

    const onSearchInput = (event : ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        onSearch(event.target.value);

        if (event.target.value !== ""){
            if (user.roles.find(role => role === "ADMIN")) {
                instance.get(`/admin/${props.searchType}/search`, {
                    params: {
                        type: props.searchType,
                        s: event.target.value
                    }
                }).then(resp => {
                    if (!resp.data.error) {
                        setProposals(resp.data.proposals)
                    } else {
                        setProposals([])
                    }
                })
            } else {
                instance.get(`/user/${props.searchType}/search`, {
                    params: {
                        type: props.searchType,
                        s: event.target.value
                    }
                }).then(resp => {
                    if (!resp.data.error) {
                        setProposals(resp.data.proposals)
                    } else {
                        setProposals([])
                    }
                })
            }
        } else {
            setProposals([]);
        }
    };

    const onSearchButton = (event : SyntheticEvent) => {
        event.preventDefault();

        if (search !== ""){
            if (user.roles.find(role => role === "ADMIN")) {
                instance.get(`/admin/${props.searchType}/search`, {
                    params: {
                        type: props.searchType,
                        s: search
                    }
                }).then(resp => {
                    if (!resp.data.error) {
                        setProposals(resp.data.proposals)
                    } else {
                        setProposals([])
                    }
                })
            } else {
                instance.get(`/user/${props.searchType}/search`, {
                    params: {
                        type: props.searchType,
                        s: search
                    }
                }).then(resp => {
                    if (!resp.data.error) {
                        setProposals(resp.data.proposals)
                    } else {
                        setProposals([])
                    }
                })
            }
        } else {
            setProposals([]);
        }
    }

    return (
        <div className="search-container">
            <form className="search-field">
                <InputGroup>
                    {props.prepend && <Button onClick={onSearchButton}><FontAwesomeIcon icon={faSearch} /></Button>}
                    <Form.Control placeholder={props.placeholder} value={search} onChange={onSearchInput} onKeyDown={(e) => {
                        if (e.key === "Enter"){
                            onSearchButton(e);
                        }
                    }} />
                    {!props.prepend && <Button onClick={onSearchButton}><FontAwesomeIcon icon={faSearch} /></Button>}
                </InputGroup>
                {/*<div className="search-autocomplete">
                    <ListGroup>
                        {proposals.map((p) => <ListGroupItem>{p.firstname} {p.lastname}</ListGroupItem>)}
                    </ListGroup>
                </div>*/}
            </form>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    user: state.user
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Search);
