import React from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "react-bootstrap";
import Moment from "react-moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {connect, ConnectedProps} from "react-redux";
import {ADMIN_EDIT_PROMOTER, ADMIN_EVENT_LOGISTIC_SHOW, USER_EVENT_LOGISTIC_DETAIL} from "../../util/routes/routes";
import {NavLink, useHistory} from "react-router-dom";
import {ADMIN_AUTH, AUTHOR_AUTH, hasRole} from "../../util/auth/auth";
import {RootState} from "../../store";
import {userLogoutAction} from "../../state/action/user/userAction";
import {readMessageAction, removeMessageAction} from "../../state/action/message/messageAction";
import className from "classnames";
import moment from "moment";
import DOMPurify from 'dompurify';

interface ISideMessage extends PropsFromRedux {

}

function useSanitizedHtml(htmlString : string) {
    return DOMPurify.sanitize(htmlString);
}

function HtmlContent(params: { htmlString: string, className?: string }) {
    const sanitizedHtml = useSanitizedHtml(params.htmlString);
    return <p className={params.className} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
}
const SideMessages : React.FC<ISideMessage> = (props) => {
    const messages = props.messages;
    const history = useHistory();

    const onRemoveMessage = (messageId : number) => {
        props.removeMessage(messageId);
    }

    const onReadMessage = (messageId : number) => {
        props.readMessage(messageId);
    }

    const onRemoveAllMessage = () => {
        messages.forEach(message => {
            props.removeMessage(message.id);
        })
    }

    const messageList = Array.isArray(messages) ? messages
        .filter(message => message.createdAt)
        .map(message => {
        const attr = message.attributes ? JSON.parse(message.attributes) : {};
        let link;

        if (attr && attr.type) {
            if (hasRole(props.user, [ADMIN_AUTH, AUTHOR_AUTH])) {
                switch (attr.type.toLowerCase()) {
                    case "user":
                        link = ADMIN_EDIT_PROMOTER.path.replace(":id", attr.id)
                        break;
                    case "event":
                    case "logistic":
                        link = ADMIN_EVENT_LOGISTIC_SHOW.path.replace(":eventType", attr.type).replace(":id", attr.id);
                        break;
                    default:
                        break;
                }
            } else {
                switch (attr.type.toLowerCase()) {
                    case "event":
                    case "logistic":
                        link = USER_EVENT_LOGISTIC_DETAIL.path.replace(":eventType", attr.type).replace(":id", attr.id);
                        break;
                    default:
                        break;
                }
            }
        }

        return {
            ...message,
            createdAt: moment(message.createdAt),
            link: link
        };
    }).sort((a, b) => b.createdAt.unix() - a.createdAt.unix())
        : []

    return (
        <Card className="card-transparent">
            <CardHeader>
                <div className="row align-items-center">
                    <div className="col-8">
                        Nachrichten
                        <p className="small">Alle Nachrichten löschen:</p>
                    </div>
                    <div className="col-4 text-end">
                        <button className="btn btn-color-white border-0" onClick={onRemoveAllMessage}><FontAwesomeIcon icon={faTrash} /></button>
                    </div>
                </div>
            </CardHeader>
            <CardBody>
                <div className="message-row-holder">
                    <div className="messages">
                        {(Array.isArray(messageList) && messageList.length > 0) ? messageList.map(message => (
                            <div className="message">
                                    <div className="message-row" key={message.id}>
                                        <Row
                                            className={className({
                                                "align-items-center": true,
                                                "clickable": true,
                                                "unread" : message.status === "unread"
                                            })}
                                            onClick={() => {
                                            if (message.link) {
                                                history.push(message.link)
                                            }
                                            onReadMessage(message.id)
                                            //onRemoveMessage(message.id)
                                        }}>
                                            <Col sm={8}>
                                                <h6>{message.subject}</h6>
                                                <HtmlContent className="small message-content" htmlString={message.message || ""} />
                                                <p className="small"><Moment format="DD.MM.YYYY">{message.createdAt}</Moment></p>
                                            </Col>
                                            <Col sm={4} className="text-end">
                                                <button className="btn btn-color-white border-0" onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    onRemoveMessage(message.id)
                                                }}><FontAwesomeIcon icon={faTrash} /></button>
                                            </Col>
                                        </Row>
                                    </div>
                            </div>
                        )) : <div className="message-row">
                            <h6>Derzeit keine Nachrichten!</h6>
                        </div>}
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

const mapReducerToProp = {
    logout : userLogoutAction,
    readMessage : readMessageAction,
    removeMessage : removeMessageAction
};

const mapStateToProps = (state : RootState) => ({
    messages: state.message.messages,
    user: state.user
});


const connector = connect(mapStateToProps, mapReducerToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SideMessages);
