import React, {FC, useContext, useEffect} from 'react';
import NavigationLinks from "../util/routes/NavigationLinks";
import ViewportProvider from "../util/ViewportProvider";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import {connect, ConnectedProps} from "react-redux";
import {toast, ToastContainer} from "react-toastify";
import {RootState} from "../store";
import moment from "moment";
import FusionToast from "../components/toast/FusionToast";

interface IAppProps extends PropsFromRedux{

}

const App: FC<IAppProps> = (props) => {

    return (
        <div className="fusion-db">
            <ViewportProvider>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <ToastContainer
                        autoClose={8000}
                        draggablePercent={60}
                    />
                    <NavigationLinks/>
                </MuiPickersUtilsProvider>
            </ViewportProvider>
         </div>
  );
}

const mapStateToProps = (state : RootState) => ({
    user: state.user,
    state: state
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(App);
