import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, FormGroup, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "react-bootstrap";
import Moment from "react-moment";
import instance from "../../../../util/api";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import FusionToast from "../../../../components/toast/FusionToast";

interface IEventUserFeedback {
    eventSections: any[],
    eventUser: any[]
}
const EventUserFeedback : React.FC<IEventUserFeedback> = props => {
    const {
        eventUser,
        eventSections,
    } = props;

    //console.log(eventSections);
    console.log("ALL USERS", eventUser);

    const history = useHistory();
    const [selectedData, selectData] = useState<any[]>([]);
    const [form, setForm] = useState<any>({selectEvent:{}, allFeedback: false});
    const [modal, setModal] = useState<boolean>(false);
    const [change, setChange] = useState<number>(0);

    useEffect(() => {
        const selectedEvent = {} as any;

        eventSections.forEach(event => {
            event.eventInfoUsers.forEach((infoUser : any) => {
                console.log("INFO USER", infoUser);
                if(selectedEvent[infoUser.id]){
                    selectedEvent[infoUser.id ] = [...selectedEvent[infoUser.id], {
                        userId: infoUser.user_id,
                        checked: infoUser.user_must_feedback
                    }]
                } else {
                    selectedEvent[infoUser.id] = [
                        {
                            userId: infoUser.user_id,
                            checked: infoUser.user_must_feedback
                        }
                    ]
                }
            })
        })

        console.log("SELECTED EVENTS", selectedEvent);
        setForm((form : any) => {
            form.selectEvent = selectedEvent;
            return form;
        })

    }, [eventUser])

    const toggle = () => {
        setModal(!modal);
    }

    const onChange = (event : any) => {
        event.persist();

        const values = event.target.value.split(",");
        const id = values[0];
        const user = parseInt(values[1]);

        console.log(event.target.name, values, event.target.checked, eventSections);

        setForm((form : any) => {
            if (event.target.name === "selectEvent") {
                if (form[event.target.name][id]) {
                    const foundInd = form[event.target.name][id].findIndex((n : any) => n.userId === user);
                    if (foundInd !== -1) {
                        form[event.target.name][id][foundInd].checked = event.target.checked
                    } else {
                        form[event.target.name][id] = [
                            ...form[event.target.name][id],
                            {
                                userId: user,
                                checked: event.target.checked
                            }
                        ]
                    }
                } else {
                    form[event.target.name][id] = [
                        {
                            userId: user,
                            checked: event.target.checked
                        }
                    ];

                }
            } else {
                form[event.target.name] = event.target.checked
            }
            return form;
        });
        setChange(change => change+1);

    }

    const onSave = () => {
        const userToEventFeedback = eventSections.map(eventInfo => {
            console.log(form.selectEvent[eventInfo.id]);
            const formData = form.selectEvent[eventInfo.id].reduce((obj : any, item : any) => {
                const index = obj.findIndex((v: any) => v.userId === item.userId);
                if (index === -1){
                    obj.push(item);
                } else {
                    if (item.checked){
                        obj[index].checked = item.checked
                    }
                }
                return obj
            }, [])

            console.log(formData);

            let newMap = [] as any[];
            if (form.allFeedback){
                newMap.push(eventInfo.times.map((time : any) => {
                    const info = {} as any;
                    time.eventInfoUsers.forEach((eventUser : any) => {
                        if (!info.feedbackUser){
                            info.feedbackUser = [];
                            info.EventId = time.EventId;
                            info.id = time.id;
                        }
                        if (eventUser) {
                            info.feedbackUser = [...info.feedbackUser, eventUser.user_id]
                        }
                    })
                    return info;
                }))
            } else {
                formData.forEach((data : any) => {

                    console.log(newMap);

                    newMap.push(eventInfo.times.map((time : any) => {
                        const info = {} as any;
                        const user = time.eventInfoUsers.find((eventUser : any) => (eventUser.user_id === data.userId && data.checked));
                        if (!info.feedbackUser){
                            info.feedbackUser = [];
                            info.EventId = time.EventId;
                            info.id = time.id;
                        }
                        if (user) {
                            info.feedbackUser = [...info.feedbackUser, data.userId]
                        }
                        return info;
                    }))
                })
            }

            return newMap.reduce((acc, curr) => [...acc, ...curr], []);
        }).reduce((acc, curr) => [...acc, ...curr], []);


        const data = userToEventFeedback.reduce((obj : any, item : any) => {
            const index = obj.findIndex((v : any) => v.id === item.id);
            if (index === -1){
                obj.push(item);
            } else {
                obj[index].feedbackUser.push(...item.feedbackUser)
            }
            return obj
        }, [])

        console.log(data);

        instance.post("/admin/event/feedbackUser", data).then(resp => {
            //console.log(resp.data);
            if (!resp.data.error){
                toggle();
            } else {
                console.error(resp);
                toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
            }
        }).catch(err => {
            //console.log(err);
        })
    }


    return (
        <>
            <Button variant="primary" className="w-100" onClick={toggle} >Feedback User zuteilen</Button>
            <Modal fade={false} centered={true} size="xl" show={modal} onHide={toggle}>
            <ModalHeader closeButton>Feedback zu spezifischen User zuteilen</ModalHeader>
            <ModalBody>
                <div className="event-main-promoter">
                    <div className="list-container">
                        <div className="list-holder">
                            <Table bordered className="table table-hover list-table">
                                <thead className="list-header">
                                <tr>
                                    <th>Für Event bestätigte User</th>
                                    { eventSections.map((eventInfo, index, array) =>
                                            <th key={index + "_" + eventInfo.id}><Moment format={"DD.MM.YYYY"}>{eventInfo.date}</Moment></th>
                                    ) }
                                </tr>
                                </thead>
                                <tbody className="list-body">
                                {eventUser.map(user => {

                                    return (
                                        <tr key={user.id}>
                                            <td>
                                                <div className="d-flex">
                                                    <Col md={8}>
                                                        {user.firstname + " " + user.lastname}
                                                    </Col>
                                                </div>
                                            </td>
                                            { eventSections.map((eventInfo, index, array) => {
                                                let isChecked = false;
                                                let isDisabled = true;

                                                if (form.selectEvent[eventInfo.id]){
                                                    const found = form.selectEvent[eventInfo.id].find((ev : any) => ev.userId === user.id);
                                                    //console.log(found);
                                                    isChecked = found ? found.checked : false;
                                                }

                                                console.log(eventInfo.times);

                                                eventInfo.eventInfoUsers.forEach((eventUser : any) => {
                                                    if (eventUser.user_id === user.id && eventUser.status !== "not_apply"){
                                                        isDisabled = false;
                                                    }
                                                })

                                                return (
                                                    <td key={eventInfo.id}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            label={""}
                                                            name="selectEvent"
                                                            disabled={isDisabled}
                                                            checked={isChecked}
                                                            value={[eventInfo.id, user.id]}
                                                            onChange={onChange} />
                                                    </td>
                                                )
                                            }) }
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                            <Form.Check
                                type="checkbox"
                                label={"Alle User müssen ein Feedback abgeben! <small>(Wenn nirgends ein Hackerl gesetzt ist, gilt das erste Feedback als erledigt!)</small>"}
                                name="allFeedback"
                                checked={form.allFeedback}
                                value={1}
                                onChange={onChange} />
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button variant="primary" onClick={toggle}>Abbrechen</Button>{' '}
                <Button variant="secondary" onClick={onSave}>Zuteilen</Button>
            </ModalFooter>
        </Modal>
        </>
    );
};

export default EventUserFeedback;
