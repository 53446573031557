import {Epic, ofType} from "redux-observable";
import {getMessagesApi, readMessageApi, removeMessageApi} from "../../../util/api/message";
import {Action} from "@reduxjs/toolkit";
import {Observable, filter, mergeMap} from "rxjs";
import {
    addAllMessagesAction,
    addMessageAction,
    errorMessageAction,
    readMessageAction, removeMessageAction
} from "../../action/message/messageAction";


export const getMessageEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    filter(addMessageAction.match),
    mergeMap((action) => {
        //console.log("MESSAGE REQUEST");
        return getMessagesApi()
            .then(resp => {
                console.log("MESSAGE API", resp.data);
                return addAllMessagesAction(resp.data.messages)
            }).catch(error => {
                if (error.response){
                    //console.log(error.response);
                } else if (error.request){
                    //console.log(error.request);
                } else {
                    //console.log(error.message);
                }
                return errorMessageAction(error);
            })
    })
)

export const readMessage = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    filter(readMessageAction.match),
    mergeMap((action) => {
        //console.log("MESSAGE READ", action);
        return readMessageApi(action.payload)
            .then(resp => {
                return addAllMessagesAction(resp.data.messages)
            }).catch(error => {
                if (error.response){
                    //console.log(error.response);
                } else if (error.request){
                    //console.log(error.request);
                } else {
                    //console.log(error.message);
                }
                return errorMessageAction(error);
            })
    })
);

export const deleteMessage = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    filter(removeMessageAction.match),
    mergeMap((action) => {
        console.log("MESSAGE REMOVE", action);
        return removeMessageApi(action.payload)
            .then(resp => {
                return addAllMessagesAction(resp.data.messages)
            }).catch(error => {
                if (error.response){
                    //console.log(error.response);
                } else if (error.request){
                    //console.log(error.request);
                } else {
                    //console.log(error.message);
                }
                return errorMessageAction(error);
            })
    })
)
