import React, {ChangeEvent} from 'react';
import {Col, FormGroup, FormText, Form, Row} from "react-bootstrap";
import classNames from "classnames";
import Select, {ActionMeta} from "react-select";
import {Field, FormikErrors} from "formik";

type HTMLInput = HTMLInputElement | HTMLAreaElement
export type SelectOptionType = { label: string, value: string }

interface IInputType {
    data: any,
    handleChange (event : ChangeEvent<HTMLInputElement> | SelectOptionType | null, actionMeta?: ActionMeta<SelectOptionType>): void,
    setFieldValue? (name: string, value: any): void
    type: any,
    value: any,
    form: any,
    name: string,
    required?: boolean,
    errors?: FormikErrors<any>
}
const InputType : React.FC<IInputType> = (props) => {
    let options = [];
    let selectData = [];
    const errors = props.errors || {};
    let className = "";

    switch(props.type){
        case "text":
        case "textarea":
            return (
                <FormGroup as={Row}>
                    <Col md={12} lg={5}><Form.Label className={classNames({"required" : props.data.required})}><strong>{props.data.name}{props.required ? "*" : ""}</strong></Form.Label></Col>
                    <Col md={12} lg={7}>
                        <Form.Control type={props.type} onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleChange(e)}
                                      name={props.name}
                                      value={props.value}
                                      required={props.data.required}
                                      isInvalid={errors[props.data.slug] !== undefined}
                        />
                        <FormText>{props.data.description}</FormText>
                        <Form.Control.Feedback type="invalid">{errors[props.data.slug]}</Form.Control.Feedback>
                    </Col>
                </FormGroup>
            );
        case "radio":
            options = JSON.parse(props.data.value);
            console.log("RADIO", props.form[props.data.slug], options);
            return (
                <Row>
                    <Col md={12} lg={5}><Form.Label className={classNames({"required" : props.data.required})}><strong>{props.data.name}{props.required ? "*" : ""}</strong></Form.Label></Col>
                    <Col md={12} lg={7}>
                        {/*<Field name={props.name} required={props.data.required} errorMessage="Bitte wähle!">*/}
                        {options.map((opt : any, id: number) => (
                            <Form.Check
                                type="radio"
                                label={opt.value}
                                name={props.name}
                                id={props.data.slug + "_" + id}
                                value={opt.value}
                                checked={props.form[props.data.slug] === opt.value}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleChange(e)}
                                isInvalid={errors[props.data.slug] !== undefined}
                            />
                        ))}
                            <FormText>{props.data.description}</FormText>
                        <Form.Control.Feedback type="invalid">{errors[props.data.slug]}</Form.Control.Feedback>
                        {/*</Field>*/}
                    </Col>
                </Row>
            );
        case "checkbox":
            options = JSON.parse(props.data.value);
            if (!Array.isArray(props.form[props.data.slug])){
                props.form[props.data.slug] = props.form[props.data.slug].split(",");
            }
            return (
                <Row>
                    <Col md={12} lg={5}><Form.Label className={classNames({"required" : props.data.required})}><strong>{props.data.name}{props.required ? "*" : ""}</strong></Form.Label></Col>
                    <Col md={12} lg={7}>
                        {/*<Field name={props.name} required={props.data.required} errorMessage="Bitte wähle!">*/}
                            {options.map((opt : any, id: number) => (
                                <Form.Check
                                    type="checkbox"
                                    label={opt.value}
                                    name={props.name}
                                    id={props.data.slug + "_" + id}
                                    value={opt.value}
                                    checked={(Array.isArray(props.form[props.data.slug]) && props.form[props.data.slug].find((d : string) => d === opt.value))}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleChange(e)}
                                    isInvalid={errors[props.data.slug] !== undefined}
                                />
                                                ))}
                            <FormText>{props.data.description}</FormText>
                        <Form.Control.Feedback type="invalid">{errors[props.data.slug]}</Form.Control.Feedback>
                        {/*</Field>*/}
                    </Col>
                </Row>
            );
        case "radio-input":
            options = JSON.parse(props.data.value);
            if (!Array.isArray(props.form[props.data.slug])){
                props.form[props.data.slug] = props.form[props.data.slug].split(",");
            }
            return (
                <Row>
                    <Col md={12} lg={5}><Form.Label className={classNames({"required" : props.data.required})}><strong>{props.data.name}{props.required ? "*" : ""}</strong></Form.Label></Col>
                    <Col md={12} lg={7}>
                        {/*<Field name={props.name} required={props.data.required} errorMessage="Bitte wähle!">*/}
                            {options.map((opt : any, id: number) => (
                            <div key={`ri_${id}`} className="d-flex">
                                <input type="radio" key={`cr_${id}`} name={props.name} value={opt.value}
                                       checked={props.form[props.data.slug] === opt.value}
                                       onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleChange(e)}
                                />{' '}<Form.Label htmlFor={props.data.slug} check>{opt.value}</Form.Label>
                                <Form.Control type="text" name={props.data.slug + `_value[${opt.value}]`} className="form-control col-6" value={props.form[props.data.slug + `_value[${opt.value}]`]}
                                       onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleChange(e)} placeholder="Stadt" required={props.data.required}/>{' '} {}
                            </div>
                        ))}
                        {/*</Field>*/}
                        <FormText>{props.data.description}</FormText>
                    </Col>
                </Row>
            );
        case "checkbox-input":
            options = JSON.parse(props.data.value);
            if (!Array.isArray(props.form[props.data.slug])){
                props.form[props.data.slug] = props.form[props.data.slug].split(",");
            }
            return (
                <Row>
                    <Col md={12}><Form.Label className={classNames({"required" : props.data.required})}><strong>{props.data.name}{props.required ? "*" : ""}</strong></Form.Label></Col>
                    <Col md={12}>
                        {/*<Field name={props.name} required={props.data.required} errorMessage="Bitte wähle!">*/}
                            {options.map((opt : any, id: number) => (
                            <div key={`ri_${id}`} className="d-flex">
                                <div className="col-6">
                                    <Form.Check
                                        type="checkbox"
                                        label={opt.value}
                                        name={props.name}
                                        id={props.data.slug + "_" + id}
                                        value={opt.value}
                                        checked={(Array.isArray(props.form[props.data.slug]) && props.form[props.data.slug].find((d : string) => d === opt.value))}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleChange(e)}
                                        />
                                </div>
                                <div className="col-6">
                                    <Form.Control type="text" className="form-control" name={props.data.slug + `_value[${opt.value}]`} value={props.form[props.data.slug + `_value[${opt.value}]`]}
                                           onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleChange(e)} placeholder="Stadt" required={props.data.required}/>{' '} {}
                                </div>
                            </div>
                        ))}
                        {/*</Field>*/}
                        <FormText>{props.data.description}</FormText>
                        <Form.Control.Feedback>Bitte wähle!</Form.Control.Feedback>
                    </Col>
                </Row>
            );
        case "select":
            options = JSON.parse(props.data.value);
            selectData = options.map((opt: any, id : number) => ({label: opt.value, value: opt.value} as SelectOptionType));
            className = classNames({"is-invalid" : errors[props.data.slug] !== undefined});
            console.log("SELECT INFO", options, selectData)
            return (
                <FormGroup as={Row}>
                    <Col md={12} lg={5}><Form.Label className={classNames({"required" : props.data.required})}><strong>{props.data.name}{props.required ? "*" : ""}</strong></Form.Label></Col>
                    <Col md={12} lg={7}>
                        <Select isMulti={false} name={props.name} defaultValue={{ value: props.value, label: props.value }}
                                className={className}
                               onChange={(newValue, actionMeta) => props.setFieldValue ? props.setFieldValue(props.name, newValue ? newValue.value : "") : props.handleChange(newValue)} required={props.data.required}
                                options={selectData}
                        />
                        <FormText>{props.data.description}</FormText>
                        <Form.Control.Feedback type="invalid">{errors[props.data.slug]}</Form.Control.Feedback>
                    </Col>
                </FormGroup>
            );
        case "range":
            const rangeData = JSON.parse(props.data.value);
            for (let i = rangeData[0].value; i <= rangeData[1].value; i++){
                options.push({value: i});
            }
            selectData = options.map((opt : any, id: number) => ({label: opt.value, value: opt.value}));
            className = classNames({"is-invalid" : errors[props.data.slug] !== undefined});

            return (
                <FormGroup as={Row}>
                    <Col md={12} lg={5}><Form.Label className={classNames({"required" : props.data.required})}><strong>{props.data.name}{props.required ? "*" : ""}</strong></Form.Label></Col>
                    <Col md={12} lg={7}>
                        <Select name={props.name} defaultValue={{ value: props.value, label: props.value }}
                                className={className}
                                onChange={(newValue, actionMeta) => props.setFieldValue ? props.setFieldValue(props.name, newValue ? newValue.value : "") : props.handleChange(newValue)} required={props.data.required}
                                options={selectData}
                        />
                        <FormText>{props.data.description}</FormText>
                        <Form.Control.Feedback type="invalid">{errors[props.data.slug]}</Form.Control.Feedback>
                    </Col>
                </FormGroup>
            );
        case "city":
            return (
                <FormGroup as={Row}>
                    <Col md={12} lg={5}><Form.Label className={classNames({"required" : props.data.required})}><strong>{props.data.name}{props.required ? "*" : ""}</strong></Form.Label></Col>
                    <Col md={12} lg={7}>
                        <Field type={props.type} className="form-control" onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleChange(e)} name={props.name} value={props.value} required={props.data.required} />
                        <FormText>{props.data.description}</FormText>
                        <Form.Control.Feedback>Bitte wähle!</Form.Control.Feedback>

                        {/*<Form.ControlGroup>
                            <Form.Control type={props.type} onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleChange(e)} name={props.name} value={props.value} required={props.data.required} />
                            <Button variant="secondary">+</Button>
                            <FormText>{props.data.description}</FormText>
                        </Form.ControlGroup>*/}
                    </Col>
                </FormGroup>
            );
        default:
            return <div></div>
    }
};

export default InputType;
