import {combineReducers} from "redux";
import userList from "./reducer/admin/userSlice";
import userRegister from "./reducer/user/userRegisterSlice";
import userData from "./reducer/user/userReducer";
import messageData from "./reducer/message/messageReducer";

export default combineReducers({
    userList: userList,
    user: userData,
    userRegister: userRegister,
    message: messageData
})