import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import {
    Button,
    FormGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form, OverlayTrigger, Tooltip
} from "react-bootstrap";
import {faPaperPlane, faThList} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DatePicker} from "rsuite";
import instance from "../../../../util/api";

const initForm = {
    titel: "",
    content: "",
    done: false,
    create: true,
    userId: -1,
    eventInfoId: -1
}

interface ITodoModal {
    info: any,
    user: any
}

const TodoModal : React.FC<ITodoModal> = (props) => {

    const {
        info,
        user
    } = props;

    const [modal, setModal] = useState<boolean>(false);
    const [form, setForm] = useState<typeof initForm>(initForm)

    useEffect(() => {
        //console.log("FORM INIT", info);

        if (info.eventUserToDos){
            const data = info.eventUserToDos;

            setForm(form => ({
                ...data,
                create: false,
                userId: user.id,
                eventInfoId: info.EventInfoId
            }))
        } else {
            setForm(form => ({
                ...form,
                userId: user.id,
                eventInfoId: info.EventInfoId
            }))
        }
    }, []);

    const onChange = (e : ChangeEvent<HTMLInputElement>) => {
        e.persist();

        const data = e.target;

        setForm(form => ({
            ...form,
            [data.name]: data.value
        }))
    }

    const onSave = (e : SyntheticEvent<any>) => {
        e.preventDefault();

        console.log("TODO-FORM", form);

        instance.post("/admin/event/todo", form)
            .then(resp => {
                toggle();
            }).catch(err => {
                console.error(err);
        })
    }

    const onDone = (e : SyntheticEvent<any>) => {
        e.preventDefault();

        setForm(form => ({
            ...form,
            done: !form.done
        }))

        form.done = !form.done;

        console.log("TODO-FORM", form);

        instance.post("/admin/event/todo", form)
            .then(resp => {
                toggle();
            }).catch(err => {
            console.error(err);
        })
    }

    const toggle = () => setModal(!modal);

    const TooltipCreator = (props : any) => (
        <OverlayTrigger overlay={<Tooltip id={props.id}>{props.text}</Tooltip>}>
            {props.children}
        </OverlayTrigger>
    )
    
    return (
        <span>
            <span className="ml-2" style={{color: "#000"}} onClick={toggle}>
                <TooltipCreator id={"eventInfoUserTodo_" + info.id + "_" + user.id} text="UserTodos festlegen / editieren">
                    <FontAwesomeIcon icon={faThList} id={"eventInfoUserTodo_" + info.id + "_" + user.id} />
                </TooltipCreator>
            </span>

            <Modal fade={false} show={modal} onHide={toggle} centered={true}>
                <ModalHeader closeButton>Todos für {user.firstname} {user.lastname}</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Form.Label htmlFor="title">Titel</Form.Label>
                        <Form.Control type="text" name="titel" value={form.titel} onChange={onChange} />
                    </FormGroup>
                    <FormGroup>
                        <Form.Label htmlFor="content">ToDos</Form.Label>
                        <Form.Control as="textarea" rows={5} name="content" value={form.content} onChange={onChange} />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    {!form.create ? <>
                        {form.done ? <>
                            <Button variant="secondary" onClick={onDone}>Nicht erledigt</Button>{' '}
                            </>: <>
                            <Button variant="secondary" onClick={onDone}>Erledigt</Button>{' '}
                            </>}
                    </> : <></>}
                    <Button variant="primary" onClick={onSave}>Speichern</Button>{' '}
                </ModalFooter>
            </Modal>
        </span>
    );
};

export default TodoModal;