
export class TagModel {

    _id? : number;
    _name? : string;
    _type? : string;
    _createdAt? : string;


    constructor(id : number, name : string, type: string, createdAt: string) {
        this._id = id;
        this._name = name;
        this._type = type;
        this._createdAt = createdAt;
    }


    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get name() {
        return this._name;
    }

    set name(value) {
        this._name = value;
    }

    get type() {
        return this._type;
    }

    set type(value) {
        this._type = value;
    }

    get createdAt() {
        return this._createdAt;
    }

    set createdAt(value) {
        this._createdAt = value;
    }
}