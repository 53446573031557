import React from 'react';
import Logo from "../../assets/images/logo_fusion_alone.png";

import "./TwoColumnLayout.scss";

const classNames = require('classnames');

interface ITwoColumnLayout {
    className: any,
    background?: string,
    logo?: string
}
const TwoColumnLayout : React.FC<ITwoColumnLayout> = (props) => {
    const twoColumnClassNames = classNames('TwoColumnLayout', 'row', props.className);
    const logo = props.logo ? props.logo : Logo;

    return (
        <div className={twoColumnClassNames}>
            <div className="d-sm-none d-lg-flex col-lg-4 background-holder" style={{background: `url(${props.background})`}}>
                <div className="logo-holder">
                    <img src={logo} alt="Fusion Logo" />
                </div>
            </div>
            <div className="col-lg-8 fusion-container">
                {props.children}
            </div>
        </div>
    );
};

export default TwoColumnLayout;
