import {RouteComponentProps, useHistory} from "react-router-dom";
import moment from "moment/moment";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import classNames from "classnames";
import {EventStatus} from "../../../util/helper/eventHelper";
import React, {FC} from "react";
import {EVENT_SECTION_STATUS, ICalendarEvent, TStatusEvent} from "../../../model/interfaces/event/IEvent";
import EventDetailView from "../../event/EventDetailView";
import {boolean} from "yup";

interface IEventModal {
    modal: boolean,
    toggle: () => void,
    event: ICalendarEvent
}
const EventModal : FC<IEventModal> = (props) => {
    const {modal, toggle, event} = props;



    return (
        <Modal
            dialogClassName="modal-90w"
            show={modal} fade={false} onHide={toggle} className="modal-dialog-centered event-modal">
            <ModalHeader
                closeButton>
                {/*<div className="task-headline row">
                    <div className="task-icon col-1">
                        <FontAwesomeIcon icon={faCalendar} />
                    </div>
                    <div className="task-info col-8">
                        <h2>{event.title}</h2>
                        <h3><Moment format={"DD.MM.YYYY"}>{event.eventSections[0].date}</Moment></h3>
                        <h5><Moment format={"HH:mm"}>{event.eventSections[0].starting_time}</Moment> - <Moment format={"HH:mm"}>{event.eventSections[event.eventSections.length-1].ending_time}</Moment></h5>
                    </div>
                    <div className="task-status col-3 align-self-center">
                        <div className={classNames("status", event.statusEvent)}>
                            <h3>{EventStatus[nextstatusEvent]}</h3>
                        </div>
                    </div>
                </div>*/}
            </ModalHeader>
            <ModalBody>
                <EventDetailView eventType={event.type} id={event.id} isModal={true} />
            </ModalBody>
        </Modal>
    )
}

export default EventModal;