import React, {useEffect, useState} from 'react';
import Search from "../../../components/search/Search";
import {Button, ButtonGroup} from "react-bootstrap";
import BaseLayout from "../../../layouts/BaseLayout/BaseLayout";
import NewCustomerModal from "./components/NewCustomerModal";
import instance from "../../../util/api";
import Loading from "../../../components/loading/Loading";
import List, {ListType} from "../../../components/list/List";
import {ADMIN_CUSTOMER_SHOW_EVENTS, ADMIN_EDIT_PROMOTER} from "../../../util/routes/routes";
import {userStatusList} from "../../../util/helper/userHelper";

const headers = [
    {slug: "type", name: "Typ"},
    {slug: "customer_number", name: "Knd.-Nr."},
    {slug: "name", name: "Name"},
    {slug: "address", name: "Ort"},
];

const CustomerList : React.FC = () => {
    const [customerList, setCustomerList] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [done, setDone] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        setCustomerList([]);
        setDone(false);
        instance.get("/admin/customers")
            .then(resp => {
                setCustomerList(resp.data.customers)

                setLoading(false);
                setTimeout(() => {
                    setDone(true);
                }, 300);

            }).catch(err => {
                console.error(err);
        })
    }, [])

    const onSearch = () => {

    }

    const onSave = (newCustomer : any) => {

    }

    const rowData = customerList.sort((a: any, b : any) => a.customer_number - b.customer_number)
        .map(customer => ({
            show: ADMIN_CUSTOMER_SHOW_EVENTS.path.replace(":customer", customer.id),
            //edit: props.match.params.type === "promoters" ? ADMIN_EDIT_PROMOTER.path.replace(":id", us.id) : ADMIN_EDIT_PROMOTER.path.replace(":id", us.id),
            //delete: 'user',
            id: customer.id,
            data: [
                {val: customer.type, classes: [], type: ListType.STRING, slug: "type"},
                {val: customer.customer_number, classes: [], type: ListType.STRING, slug: "customer_number"},
                {val: customer.name || customer.name_organisation, classes: [], type: ListType.STRING, slug: "name"},
                {val: customer.street + ", " + customer.plz + " " + customer.city, classes: [], type: ListType.STRING, slug: "address"},
            ]
        }))

    return (
        <BaseLayout>
            <div className="row align-items-center justify-content-between pb-5">
                <div className="col-12 col-md-8 col-lg-5">
                    <Search onChange={onSearch} placeholder="Suche nach Kunden"  searchType="customer"/>
                </div>
                <div className="col-6 col-md-6 col-lg-4 text-right">
                    <ButtonGroup>
                        <NewCustomerModal onSave={onSave} />
                    </ButtonGroup>
                </div>
            </div>

            {!done ?
                <Loading loading={loading} />
                :
                <List data={rowData} headers={headers} type="customer" noEdit={true} />
            }

        </BaseLayout>
    );
};

export default CustomerList;