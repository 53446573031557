import React, {ChangeEvent, KeyboardEvent, KeyboardEventHandler, SyntheticEvent, useState} from 'react';
import {Button, Form, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSearch} from "@fortawesome/free-solid-svg-icons";
import instance from "../../util/api";
import PropTypes from 'prop-types';
import "./tagfinder.scss"
import {toast} from "react-toastify";
import FusionToast from "../toast/FusionToast";
interface ITagFinder {
    modal: any,
    refId?: number,
    setTags: Function,
    tagType: string
}
const TagFinder : React.FC<ITagFinder> = (props) => {
    const [modal, setModal] = useState<boolean>(false);
    const [searchTag, onSearchTag] = useState<string>('')
    const [searchedTags, setSearchTags] = useState<any[]>([]);
    const [tags, setTags] = useState<any[]>([]);

    const toggleModals = () => {
        setModal(!modal)
    }

    const onSearchInput = (event : ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        onSearchTag(event.target.value);

        if (event.target.value !== ""){
            instance.get('/admin/tag', {
                params: {
                    type: props.tagType,
                    s: event.target.value
                }
            }).then(resp => {
                //console.log(resp.data);
                if (!resp.data.error){
                    setSearchTags(resp.data.tags)
                } else {
                    setSearchTags([""])
                }
            })
        } else {
            setSearchTags([""]);
        }
    };

    const saveTags = () => {
        if (props.refId && tags.length > 0){
            const data = {
                refId: props.refId,
                tagType: props.tagType,
                tags: [] as any[]
            };

           /* formData.append("refId", props.refId.toString());
            formData.append("type", props.type);*/
            tags.forEach((tag : any) => {
                data.tags.push(tag)
/*
                data.append("tag", tag);
*/
            })

            instance.post('/admin/tag', data).then(resp => {
                //console.log(resp.data);
                if (!resp.data.error){

                } else {
                    console.error(resp);
                    toast(<FusionToast className="danger" header="Aktion konnte nicht durchgeführt werden" message="Anscheinend gab es wohl ein Problem" />);
                }
            })
        }
    }

    const onKeyDown = (event : KeyboardEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        if (event.key === 'Enter' && val){
            event.preventDefault();
            setTags(tags => [...tags, val]);
            onSearchTag("");
        }
    }

    //console.log(tags);

    return (
        <div>
            <button className="btn btn-add-tag" onClick={() => toggleModals()}><FontAwesomeIcon icon={faPlus} /></button>


            <Modal show={modal} fade={false} onHide={() => toggleModals()} className="modal-dialog-centered">
                <ModalHeader closeButton>{props.modal.header}</ModalHeader>
                <ModalBody>
                    <form className="search-field-small">
                        <InputGroup>
                            <Form.Control value={searchTag} onChange={onSearchInput} onKeyDown={onKeyDown} placeholder="Mit Enter bestätigen" />
                            <Button><FontAwesomeIcon icon={faSearch} /></Button>
                        </InputGroup>
                        {searchedTags.length > 0 && searchedTags[0] !== "" ?
                            <div className="search-field-list">
                                {searchedTags.map((tag : any) => <div className="search-field-list-item" onClick={() => {
                                    setTags(tags => [...tags, tag.name]);
                                    onSearchTag("");
                                    setSearchTags([""]);
                                }}>{tag.name}</div> )}
                            </div>
                            : <div></div>
                        }
                    </form>
                    <div className="search-list">
                        {tags.map(tag => <div className="search-list-field">{tag}</div> )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button variant="primary" onClick={() => {
                        toggleModals();
                        saveTags();
                        props.setTags((tagState : any) => ([...tagState, ...tags]));
                    }}>{props.modal.button}</Button>{' '}
                    <Button variant="secondary" onClick={() => toggleModals()}>Abbrechen</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default TagFinder;
