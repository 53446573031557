import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import {v4, v4 as uuidv4} from "uuid";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faEdit, faSortDown, faSortUp, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Collapse} from "react-bootstrap";
import {ListField} from "../AccordionList";
interface IMultipleRow {
    classes: any,
    noEdit: boolean,
    noCopy: boolean,
    onCopy: Function,
    onClick: Function,
    onDeleteModal: Function,
    row: any
}

const MultipleRow : React.FC<IMultipleRow> = props => {

    const {row, onDeleteModal, onClick, noEdit, noCopy, onCopy, classes} = props;

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <>
            <tr className={classes} key={row.id}>
                {row.data.map((d : any) => {
                    const dataClass = classNames("align-middle", ...d.classes);
                    return <ListField onClick={() => onClick(row.id, row.show, row.serial)} key={v4()} value={d.val} classes={dataClass} type={d.type}/>
                })}
                {!noEdit && <td className="align-middle btn-td"><NavLink className="btn btn-link" to={{pathname: row.edit, state: {id: row.id}}}><FontAwesomeIcon icon={faEdit} /></NavLink></td>}
                {!noCopy && <td className="align-middle btn-td"><button className="btn btn-link" onClick={() => onCopy(row)}><FontAwesomeIcon icon={faCopy} /></button></td>}
                {!noEdit && <td className="align-middle btn-td"><button className="btn btn-link" onClick={() => onDeleteModal(row)}><FontAwesomeIcon icon={faTrash} /></button></td>}
                {<td className="align-middle btn-td"><button onClick={toggle} className="btn btn-link"><FontAwesomeIcon icon={isOpen ? faSortUp : faSortDown} /></button></td>}
            </tr>
            <Collapse in={isOpen}>
                <div id={"event-multipe-row-" + row.id}>
                    {
                        row.nextData.map((next : any) => {
                            if (row.serial) {
                                return (
                                    <tr key={next.id}>
                                        {next.data.map((d : any) => {
                                            const dataClass = classNames("align-middle", ...d.classes);
                                            return <ListField onClick={() => onClick(next.id, next.show)} key={v4()} value={d.val} classes={dataClass} type={d.type}/>
                                        })}
                                        {!noEdit && <td className="align-middle btn-td"><NavLink className="btn btn-link" to={{pathname: next.edit, state: {id: next.id}}}><FontAwesomeIcon icon={faEdit} /></NavLink></td>}
                                        {!noCopy && <td className="align-middle btn-td"><button className="btn btn-link" onClick={() => onCopy(next)}><FontAwesomeIcon icon={faCopy} /></button></td>}
                                        {!noEdit && <td className="align-middle btn-td"><button className="btn btn-link" onClick={() => onDeleteModal(next)}><FontAwesomeIcon icon={faTrash} /></button></td>}
                                    </tr>
                                )
                            } else {
                                return (
                                    <tr key={v4()}>
                                        {next.map((d : any) => {
                                            const dataClass = classNames("align-middle", ...d.classes);
                                            return <ListField onClick={() => onClick(row.id, row.show)} key={v4()} value={d.val} classes={dataClass} type={d.type}/>
                                        })}
                                    </tr>
                                )
                            }
                        })
                    }
                </div>
            </Collapse>
        </>
    );
};


export default MultipleRow;
